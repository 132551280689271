export function isEmptyOrNull(str: string | null | undefined) {
  return str == null || str === "";
}

export async function sha256(value: string) {
  const msgUint8 = new TextEncoder().encode(value); // encode as (utf-8) Uint8Array
  const hashBuffer = await crypto.subtle.digest("SHA-256", msgUint8); // hash the message
  const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, "0"))
    .join(""); // convert bytes to hex string
  return hashHex;
}

export function equalsCaseInsensitive(
  str1?: string | null,
  str2?: string | null
): boolean {
  if (str1 == null && str2 == null && str1 === str2) {
    return true;
  }
  if (str1 == null || str2 == null) {
    return false;
  }
  return str1.toLowerCase() === str2.toLowerCase();
}

export function formatStringForCompare(str: string): string {
  if (str == null) {
    return str;
  }
  return str.replace(/\s/g, "").toLowerCase();
}
