export const scenarioScripts = [
  {
    key: "scenario#2",
    content: `---
[User]: show me top 3 assets that need to improve annotations.

---
[System]: Fetching assets missing annotations...
---
[System]: Fetching assets with negative feedbacks...

---
[Assistant]: Sure, here are the top 3 assets that need improvement on annotations:
- [e-commerce Sales][1] (Azure Blob Storage) The asset miss **description** and **glossary term**, and it has **negative feedbacks**.
- [Healthcare e-commerce CRM][2] (Azure Blob Storage) The asset miss **description** and **contacts**, and it has **negative feedbacks**.
- [Cost of Goods Sold (COGS)][3] (Azure Blob Storage) The asset miss *glossary term**, and it has **negative feedbacks**.
Reference Names: dict_keys(['[e-commerce Sales][1]', '[Healthcare e-commerce CRM][2]', '[Cost of Goods Sold (COGS)][3]'])

---
[User]: why do all assets belong to azure blob storage?

---
[System]: Fetching more assets...
---
[System]: Analyzing...

---
[Assistant]: All these assets belong to Azure Blob Storage because they have some **negative feedbacks** and **insufficient annotations** compared to assets from other data sources like Azure SQL Database and Fabric.

---
[User]: what about the other data sources?

---
[System]: Analyzing assets annotations through various data sources...

---
[Assistant]: Based on the available information, here are the annotation improvement suggestions for the other data sources:

- **Azure SQL Database (20 assets)**: Most assets are well-annotated with glossary terms and descriptions, but some assets could benefit from additional contacts and classifications.
- **Fabric (7 assets)**: Most assets are well-annotated with descriptions and contacts, but some assets could benefit from additional glossary terms and classifications.

---
[User]: then show me the top 3 fabric assets.

---
[System]: Fetching Fabric assets missing annotations...
---
[System]: Fetching Fabric assets with negative feedbacks...

---
[Assistant]: Sure, here are the top 3 assets from Fabric that require improvement on annotations:
- 'Consumer Healthcare Retail Report' (Fabric) The asset miss **glossary term** and **classification**.
- 'Consumer Healthcare E-Commerce Report' (Fabric) The asset miss **description**, **glossary term**, **contacts**, and **classification**.
- 'Consumer Healthcare Products' (Fabric) The asset miss **glossary term**, **contacts**, and **classification**.
Reference Names: dict_keys(['[Consumer Healthcare Retail Report][1]', '[Consumer Healthcare E-Commerce Report][2]', '[Consumer Healthcare Products][3]'])

---
[User]: what glossary term should I add to Consumer Healthcare Retail Report?

---
[System]: Fetching lineage for 'Consumer Healthcare Retail Report'...
---
[System]: Analyzing asset with lineage...

---
[Assistant]: Based on the upstream and downstream assets, the suggested Glossary Term is [Retail].

This Glossary Term is suggested because both upstream asset [RetailSales][2] and downstream asset [Consumer Healthcare Retail.pbix][6] are annotated with the same Glossary Term [Retail]. Additionally, the main asset [Consumer Healthcare Retail Report][1] focuses on retail prices and retail sales status, which aligns with the concept of [Retail].
Suggestions: ["action Go to edit Consumer Healthcare Retail Report {'action': 'editAsset', 'assetId': '0ed43636-5163-4294-90bd-148176e4bcbf'}"]
Reference Names: dict_keys(['[Consumer Healthcare Retail Report][1]', '[RetailSales][2]', '[Consumer Healthcare Products][3]', '[ConsumerHealthcareProducts][4]', '[Retail Sales][5]', '[Consumer Healthcare Retail.pbix][6]', '[Yalei Zhao]', '[Retail]'])

---
[User]: can you suggest contacts for the next asset?

---
[System]: Fetching lineage for 'Consumer Healthcare E-Commerce Report'...
---
[System]: Analyzing asset with lineage...

---
[Assistant]: Suggested Contact Name: [Yalei Zhao]

Based on the annotations of the upstream assets, the only consistent Contact Name is [Yalei Zhao]. As the main asset is related to e-commerce sales and the upstream assets are also related to transactions and e-commerce sales, it is reasonable to assume that [Yalei Zhao] is an expert in this field and can provide valuable insights and information regarding the main asset.
Suggestions: ["action Go to edit Consumer Healthcare E-Commerce Report {'action': 'editAsset', 'assetId': '26aa45d4-6c05-423d-93c9-7d9d3e1936c3'}"]
Reference Names: dict_keys(['[Consumer Healthcare E-Commerce Report][1]', '[e-commerce Sales][2]', '[Electronic Commerce Sales][3]', '[Yalei Zhao]', '[TransactionID]'])
`,
  },
  {
    key: "scenario#3",
    content: `---

[User]: what are the recent changed assets?

 

---

[System]: Searching for assets have been changed in last 1 hour...

---

[System]: Searching for assets have been changed in last 24 hours…

 

---

[Assistant]: In the last 24 hours, **411** assets were created and **0** were updated. **400** assets were created or updated by **Scans**, while **11** were created or updated by **Users**.

 

The assets were distributed across **3** data sources, with **Azure Blob Storage** having the highest number of assets at **220**, followed by **Azure SQL Database** with **137**, and **Azure Data Lake Storage Gen2** with **43**.

 

The top **3** classification categories that indicate assets may contain **sensitive information** are **Personal** with **69** assets, **Financial** with **59** assets, and **Government** with **47** assets.

 

Suggestions: ['Show assets containing sensitive information.', 'Show assets requiring improved curation.', 'Show top scans.', 'Categorize assets by types.']

 

---

[User]: Show assets containing sensitive information.

 

---

[System]: Searching for assets have been recently changed and have sensitive information...

 

---

[Assistant]: There are **108 assets** containing sensitive information in the given aggregated data. The majority of these assets fall under the **Personal** classification category with **69** assets, followed by **Financial** with **59** assets and **Government** with **47** assets.

The top three classifications are **[MICROSOFT.PERSONAL.NAME]** with **61** assets, **[MICROSOFT.FINANCIAL.CREDIT_CARD_NUMBER]** with **56** assets, and **[MICROSOFT.GOVERNMENT.US.MEDICAL_BENEFICIARY_IDENTIFIER_CARD]** with **47** assets.

The top data source for these assets is **Azure SQL Database** with **68** assets, followed by **Azure Blob Storage** with **23** assets and **Azure Data Lake Storage Gen2** with **16** assets.



 

---

[User]: show me some sample assets

 

---

[System]: Fetching top assets have been recently changed and have sensitive information...

 

---

[Assistant]: Based on your search scope, I have merged two intentions to show you the top assets that are recently changed and have sensitive information. Here are the top assets:

 

- [AccountBalances.json][3]: Processed account balance data for financial reporting and analysis. Annotated with sensitive information like medical beneficiary identifier card and personal email.

- [MedicalEducation][2]: Educational materials and training programs for healthcare professionals. Annotated with sensitive information like medical beneficiary identifier card and credit card number.

- [HealthCareNetworks][5]: Network of healthcare providers linked through referrals, transfers etc. Annotated with sensitive information like medical beneficiary identifier card and personal name.

 

Note that all of these assets have been updated in the last 6 hours.

 

---

[User]: how to deal with such sensitive information?

---

[System]: Searching for documents about sensitive information...
 

---

[Assistant]: I found the following documents which may help you for how to handle sensitive information:

- [Learn about sensitive information types](https://learn.microsoft.com/en-us/purview/sensitive-information-type-learn-about): this document gives you an overview of the sensitive information types that are available in Microsoft Purview.

- [Protect your sensitive data with Microsoft Purview](https://learn.microsoft.com/en-us/purview/information-protection): this document provides a step-by-step guide on how to help you discover, classify, protect, and govern sensitive information wherever it lives or travels.
`,
  },
];
