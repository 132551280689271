import { AxiosError } from "axios";
import { EventRow } from "src/logger/EventRow";

export function stringifyError(error: unknown): string {
  function getErrorDetail(err: unknown) {
    if (err instanceof AxiosError) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const result = err as any;
      delete result?.config?.headers;
      delete result?.config?.data;
      return result;
    }

    if (typeof err === "object" && err != null && "config" in err) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      delete (err as any)?.config?.headers;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      delete (err as any)?.config?.data;
      return err;
    }

    return err;
  }

  if (error == null) {
    return "";
  }

  if (typeof error === "string") {
    return error;
  }

  const msg = {
    stack: "",
    json: "",
    getters: "",
  };

  if (typeof error === "object" && error != null) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((error as any).stack != null) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      msg.stack = (error as any).stack + "";
    }

    try {
      const props = Object.getOwnPropertyDescriptors(
        Object.getPrototypeOf(error)
      );
      Object.entries(props).forEach(([k, v]) => {
        if (v.get != null && k !== "config") {
          // eslint-disable-next-line security/detect-object-injection,@typescript-eslint/no-explicit-any
          msg.getters += `${k}=>${JSON.stringify((error as any)[k])}`;
        }
      });
    } catch (e) {
      msg.getters += "getters are not serializable.";
    }
  }

  try {
    msg.json = JSON.stringify(getErrorDetail(error));
    return JSON.stringify(msg);
  } catch (error) {
    return "" + error;
  }
}

export interface UnhandledError {
  source: string;
  error?: Error;
  extra?: string;
}

export const UnhandledErrors = {
  errorConfigEmpty: (error?: Error, extra?: string): UnhandledError => ({
    source: "configEmpty",
    error,
    extra,
  }),
};

export class UnhandledErrorEvent extends EventRow {
  constructor(source: string, error?: Error, extra?: string) {
    super("UnhandledError", {
      source,
      error: stringifyError(error),
      extra,
    });
  }
}
