/** @jsxImportSource @emotion/react */
import { IContextualMenuItem } from "@fluentui/react";
import { useGetContainer } from "@msbabylon/shell-core";
import {
  AppTopBarButtonData,
  containerBuilderStore,
  extensionConstants,
  rootModels,
} from "@msbabylon/shell-framework";
import { compact, uniqueId } from "lodash-es";
import { useCallback, useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { getFeatures } from "src/features";
import { useDependencies } from "src/hook";
import { babylonInstanceModel } from "src/store/shell-models/babylon/instance";
import { releaseNotesModel } from "src/store/shell-models/babylon/releaseNotes";
import { embedTeamsChatModel } from "src/store/shell-models/ui/EmbedTeamsChat";
import { uiCopilotModel } from "src/store/shell-models/ui/copilot";
import messageIds from "../locales/messageIds";

export function buildAppTopBarCommonMenuButtonDataHooks() {
  return compact([
    buildToggleCopilotButtonDataHook(),
    buildEmbedTeamsChatButtonDataHook(),
    buildToggleReleaseNoteButtonDataHook(),
    buildToggleInstanceButtonDataHook(),
    containerBuilderStore.store.buildNotificationButtonDataHook(),
    getFeatures().settings && buildSettingsButtonDataHook(),
    buildToggleHelpButtonDataHook(),
    buildFeedbackButtonDataHook(),
  ]);
}

export const buildToggleCopilotButtonDataHook = () => {
  const id = uniqueId("id_");
  return () => {
    const intl = useIntl();
    const getContainer = useGetContainer();
    const dependencies = useDependencies();
    const copilot = getContainer(uiCopilotModel);

    const visible = useSelector(() => copilot.state.visible);

    useEffect(() => {
      copilot.actions.setTargetId.dispatch(id);
    }, [copilot.actions.setTargetId]);

    const toggleCopilot = useCallback(() => {
      copilot.actions.setVisible.dispatch(!visible);
    }, [copilot.actions.setVisible, visible]);

    const isECEnabled = useSelector(
      () => dependencies.application.features.enableCopilot
    );

    return useMemo(
      () => ({
        key: id,
        text: intl.formatMessage({
          id: messageIds.copilot.topBar.$buttonTitle,
        }),
        icon: "fabric:Robot",
        hoverIcon: undefined,
        checked: visible,
        hidden: !isECEnabled,
        onClick: toggleCopilot,
      }),
      [intl, isECEnabled, toggleCopilot, visible]
    );
  };
};

export const buildEmbedTeamsChatButtonDataHook = () => {
  const id = uniqueId("id_");
  return () => {
    const intl = useIntl();
    const getContainer = useGetContainer();
    const embedTeamsChatContainer = getContainer(embedTeamsChatModel);
    useEffect(() => {
      embedTeamsChatContainer.actions.setTargetId.dispatch(id);
    }, [embedTeamsChatContainer.actions.setTargetId]);
    const toggleEmbedTeamsChat = useCallback(() => {
      window.open("https://teams.microsoft.com", "_blank");
    }, []);
    return useMemo<AppTopBarButtonData>(
      () => ({
        key: id,
        text: intl.formatMessage({
          id: messageIds.teams.topBar.$buttonTitle,
        }),
        icon: "fabric:TeamsLogo",
        checked: false,
        hidden: false,
        onClick: toggleEmbedTeamsChat,
      }),
      [intl, toggleEmbedTeamsChat]
    );
  };
};

export const buildToggleInstanceButtonDataHook = () => () => {
  const intl = useIntl();
  const getContainer = useGetContainer();
  const uiPanelListEntity = getContainer(rootModels.ui.list.panel.entity);
  const isInstanceOpened = useSelector(
    () =>
      uiPanelListEntity.getters.currentUIItem?.shellExtensionUIComponent
        ?.type === "panelInstance"
  );
  const isNotLandingPage = useMemo(
    () => getContainer(rootModels.shared).getters.resourceName,
    [getContainer]
  );
  const isErrorPage = useSelector(
    () => getContainer(rootModels.app).state.appErrorInfo
  );
  const uiPanelListCommonHelper = getContainer(
    rootModels.ui.list.panel.commonHelper
  );
  const closePanel = useCallback(() => {
    uiPanelListCommonHelper.actions.closeUntil.dispatch({});
  }, [uiPanelListCommonHelper]);
  const toggleInstance = useCallback(() => {
    if (isInstanceOpened) {
      closePanel();
    } else {
      uiPanelListCommonHelper.actions.replace.dispatch({
        extensionName: extensionConstants.shell,
        component: {
          type: "panelInstance",
          params: {},
        },
      });
    }
  }, [closePanel, isInstanceOpened, uiPanelListCommonHelper]);
  return useMemo(
    () => ({
      key: "instance",
      text: intl.formatMessage({ id: messageIds.instance.$title }),
      icon: "babylon:SwitchWorkspace",
      checked: isInstanceOpened,
      onClick: toggleInstance,
      hidden: !!isErrorPage || !isNotLandingPage,
    }),
    [intl, isInstanceOpened, toggleInstance, isErrorPage, isNotLandingPage]
  );
};

export const buildToggleReleaseNoteButtonDataHook = () => () => {
  const intl = useIntl();
  const getContainer = useGetContainer();
  const uiPanelListEntity = getContainer(rootModels.ui.list.panel.entity);
  const isNotesOpened = useSelector(
    () =>
      uiPanelListEntity.getters.currentUIItem?.shellExtensionUIComponent
        ?.type === "panelReleaseNotes"
  );
  const uiPanelListCommonHelper = getContainer(
    rootModels.ui.list.panel.commonHelper
  );
  const releaseNotesHelper = getContainer(releaseNotesModel);
  const readCount = useSelector(
    () => releaseNotesHelper.state.readReleaseNotesCount
  );
  const totalCount = useSelector(
    () => releaseNotesHelper.state.releaseNotes.length
  );
  const badge = useMemo(
    () => (isNaN(readCount) ? 1 : Math.max(totalCount - readCount, 0) || null),
    [readCount, totalCount]
  );

  const closePanel = useCallback(() => {
    uiPanelListCommonHelper.actions.closeUntil.dispatch({});
  }, [uiPanelListCommonHelper]);
  const toggleReleaseNotes = useCallback(() => {
    if (isNotesOpened) {
      closePanel();
    } else {
      releaseNotesHelper.actions.setReadReleaseNotes.dispatch({});
      uiPanelListCommonHelper.actions.replace.dispatch({
        extensionName: extensionConstants.shell,
        component: {
          type: "panelReleaseNotes",
          params: {},
        },
      });
    }
  }, [closePanel, isNotesOpened, releaseNotesHelper, uiPanelListCommonHelper]);
  return useMemo(
    () => ({
      key: "releaseNotes",
      text: intl.formatMessage({ id: messageIds.releaseNotes.$title }),
      icon: "babylon:Announcement",
      hoverIcon: undefined,
      checked: isNotesOpened,
      onClick: toggleReleaseNotes,
      badge,
    }),
    [badge, intl, isNotesOpened, toggleReleaseNotes]
  );
};

export const buildToggleHelpButtonDataHook = () => () => {
  const intl = useIntl();
  const getContainer = useGetContainer();
  const dependencies = useDependencies();
  const uiPanelListCommonHelper = getContainer(
    rootModels.ui.list.panel.commonHelper
  );

  const babylonModel = getContainer(babylonInstanceModel);
  const hasInstance = useSelector(() => babylonModel.getters.hasInstance);

  const isErrorPage = useSelector(
    () => getContainer(rootModels.app).state.appErrorInfo
  );

  const language = useMemo(
    () => dependencies.locale.languageName,
    [dependencies.locale.languageName]
  );

  const openKnowledgeCenter = useCallback(() => {
    babylonModel.actions.openKnowledgeCenter.dispatch({});
  }, [babylonModel]);
  const openPrivacyLink = useCallback(() => {
    window.open("https://go.microsoft.com/fwlink/?LinkId=521839", "_blank");
  }, []);
  const openAccessibilityLink = useCallback(() => {
    if (language === "fr") {
      window.open("https://go.microsoft.com/fwlink/?LinkId=2121428", "_blank");
    } else if (language === "it") {
      window.open("https://go.microsoft.com/fwlink/?linkid=2214210", "_blank");
    }
  }, [language]);
  const openDiagnosticInfo = useCallback(() => {
    uiPanelListCommonHelper.actions.open.dispatch({
      extensionName: extensionConstants.shell,
      component: {
        type: "panelDiagnostics",
        params: {},
      },
    });
  }, [uiPanelListCommonHelper]);
  const openGuidedTour = useCallback(() => {
    getContainer(rootModels.ui.tour).actions.refreshGuidedTourPosition.dispatch(
      {}
    );
  }, [getContainer]);

  const menuItem = useMemo<IContextualMenuItem[]>(() => {
    return compact([
      hasInstance && {
        key: "knowledge",
        text: intl.formatMessage({ id: messageIds.help.knowledgeCenter }),
        onClick: openKnowledgeCenter,
      },
      hasInstance && {
        key: "tour",
        text: intl.formatMessage({ id: messageIds.help.guidedTour }),
        onClick: openGuidedTour,
      },
      hasInstance && {
        key: "diagnostic",
        text: intl.formatMessage({ id: messageIds.help.diagnostic }),
        onClick: openDiagnosticInfo,
      },
      {
        key: "privacy",
        text:
          language === "ko"
            ? intl.formatMessage({ id: messageIds.help.privacyStatement })
            : intl.formatMessage({ id: messageIds.help.privacy }),
        onClick: openPrivacyLink,
        role: "link",
      },
      (language === "fr" || language === "it") && {
        key: "accessibility",
        text: intl.formatMessage({ id: messageIds.help.accessibility }),
        onClick: openAccessibilityLink,
        role: "link",
      },
    ]);
  }, [
    hasInstance,
    intl,
    openKnowledgeCenter,
    openGuidedTour,
    openDiagnosticInfo,
    openPrivacyLink,
    language,
    openAccessibilityLink,
  ]);

  return useMemo(
    () => ({
      key: "help",
      text: intl.formatMessage({ id: messageIds.help.$title }),
      icon: "fabric:Help",
      checked: false,
      menus: menuItem,
      hidden: !!isErrorPage,
    }),
    [intl, menuItem, isErrorPage]
  );
};

export const buildSettingsButtonDataHook = () => () => {
  const intl = useIntl();
  const getContainer = useGetContainer();
  const uiPanelListEntity = getContainer(rootModels.ui.list.panel.entity);
  const uiPanelListCommonHelper = getContainer(
    rootModels.ui.list.panel.commonHelper
  );
  const closePanel = useCallback(() => {
    uiPanelListCommonHelper.actions.closeUntil.dispatch({});
  }, [uiPanelListCommonHelper]);
  const isSettingsOpened = useSelector(
    () =>
      uiPanelListEntity.getters.currentUIItem?.shellExtensionUIComponent
        ?.type === "panelSettings"
  );
  const toggleSettings = useCallback(() => {
    if (isSettingsOpened) {
      closePanel();
    } else {
      uiPanelListCommonHelper.actions.replace.dispatch({
        extensionName: extensionConstants.shell,
        component: {
          type: "panelSettings",
          params: {},
        },
      });
    }
  }, [closePanel, isSettingsOpened, uiPanelListCommonHelper]);
  return useMemo(
    () => ({
      key: "settings",
      text: intl.formatMessage({ id: messageIds.settings.$title }),
      icon: "fabric:Settings",
      hoverIcon: undefined,
      checked: isSettingsOpened,
      onClick: toggleSettings,
    }),
    [intl, isSettingsOpened, toggleSettings]
  );
};

export const buildFeedbackButtonDataHook = () => () => {
  const intl = useIntl();
  const title = useMemo(
    () => intl.formatMessage({ id: messageIds.feedback.$title }),
    [intl]
  );
  const getContainer = useGetContainer();
  const uiPanelListEntity = getContainer(rootModels.ui.list.panel.entity);
  const uiPanelListCommonHelper = getContainer(
    rootModels.ui.list.panel.commonHelper
  );
  const closePanel = useCallback(() => {
    uiPanelListCommonHelper.actions.closeUntil.dispatch({});
  }, [uiPanelListCommonHelper]);
  const isFeedbackOpened = useSelector(
    () =>
      uiPanelListEntity.getters.currentUIItem?.shellExtensionUIComponent
        ?.type === "panelBabylonFeedback"
  );
  const toggleFeedback = useCallback(() => {
    if (isFeedbackOpened) {
      closePanel();
    } else {
      uiPanelListCommonHelper.actions.replace.dispatch({
        extensionName: extensionConstants.shell,
        component: {
          type: "panelBabylonFeedback",
          params: {},
        },
      });
    }
  }, [closePanel, isFeedbackOpened, uiPanelListCommonHelper]);
  return useMemo(
    () => ({
      key: "feedback",
      text: title,
      icon: "fabric:Feedback",
      hoverIcon: undefined,
      checked: isFeedbackOpened,
      onClick: toggleFeedback,
    }),
    [title, isFeedbackOpened, toggleFeedback]
  );
};
