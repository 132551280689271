/** @jsxImportSource @emotion/react */
import { LabelWrapper, useTheme } from "@adux/common-react";
import { css } from "@emotion/react";
import React, { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import {
  ICopilotReference,
  ICopilotReferenceAssetProp,
  ICopilotReferenceClassificationProp,
  ICopilotReferenceContactProp,
  ICopilotReferenceGlossaryProp,
  ICopilotReferenceScanProp,
} from "src/apis/CopilotApi/interface";
import messageIds from "src/locales/messageIds";
import ContactInfo from "./ContactInfo";

interface LinkTooltipContentProps {
  messageId: string;
  references?: StringMap<ICopilotReference>;
  href?: string;
}

export default React.memo(function LinkTooltipContent(
  props: LinkTooltipContentProps
) {
  const { references, href } = props;
  const intl = useIntl();
  const theme = useTheme();

  // const referenceById = useSelector(() => copilot.getters.referenceById);
  const currentRef = useMemo(() => {
    // eslint-disable-next-line security/detect-object-injection
    return href && references ? references[decodeURI(href)] : undefined;
  }, [href, references]);

  const RenderAssetInfo = useCallback(
    (assetProps: ICopilotReferenceAssetProp) => {
      return (
        <div
          css={css`
            display: flex;
            flex-direction: column;
            color: ${theme.palette.neutralSecondary};
          `}
        >
          <LabelWrapper
            label={intl.formatMessage({
              id: messageIds.copilot.messages.assetType,
            })}
            styles={{
              label: { fontSize: "12px" },
              content: { fontSize: "12px" },
            }}
          >
            {assetProps.assetType}
          </LabelWrapper>
          <br />
          <LabelWrapper
            label={intl.formatMessage({
              id: messageIds.copilot.messages.assetDescription,
            })}
            styles={{
              label: { fontSize: "12px" },
              content: { fontSize: "12px" },
            }}
          >
            {assetProps.description}
          </LabelWrapper>
          <br />
          <LabelWrapper
            label={intl.formatMessage({
              id: messageIds.copilot.messages.fullyQualifiedName,
            })}
            styles={{
              label: { fontSize: "12px" },
              content: { fontSize: "12px" },
            }}
          >
            {assetProps.qualifiedName}
          </LabelWrapper>
        </div>
      );
    },
    [intl, theme]
  );

  const RenderClassificationInfo = useCallback(
    (classificationProps: ICopilotReferenceClassificationProp) => {
      return (
        <div
          css={css`
            display: flex;
            flex-direction: column;
            color: ${theme.palette.neutralSecondary};
          `}
        >
          <LabelWrapper
            label={intl.formatMessage({
              id: messageIds.copilot.messages.classificationName,
            })}
            styles={{
              label: { fontSize: "12px" },
              content: { fontSize: "12px" },
            }}
          >
            {classificationProps.name}
          </LabelWrapper>
          <br />
          <LabelWrapper
            label={intl.formatMessage({
              id: messageIds.copilot.messages.classificationDescription,
            })}
            styles={{
              label: { fontSize: "12px" },
              content: { fontSize: "12px" },
            }}
          >
            {classificationProps.description}
          </LabelWrapper>
          <br />
          {classificationProps.options?.displayName && (
            <>
              (
              <LabelWrapper
                label={intl.formatMessage({
                  id: messageIds.copilot.messages.classificationDisplayName,
                })}
                styles={{
                  label: { fontSize: "12px" },
                  content: { fontSize: "12px" },
                }}
              >
                {classificationProps.options.displayName}
              </LabelWrapper>
              <br />)
            </>
          )}
          <LabelWrapper
            label={intl.formatMessage({
              id: messageIds.copilot.messages.isSystemType,
            })}
            styles={{
              label: { fontSize: "12px" },
              content: { fontSize: "12px" },
            }}
          >
            {classificationProps.isSystemType ? "System" : "Custom"}
          </LabelWrapper>
        </div>
      );
    },
    [intl, theme]
  );

  const RenderGlossaryInfo = useCallback(
    (glossaryProps: ICopilotReferenceGlossaryProp) => {
      return (
        <div
          css={css`
            display: flex;
            flex-direction: column;
            color: ${theme.palette.neutralSecondary};
          `}
        >
          <LabelWrapper
            label={intl.formatMessage({
              id: messageIds.copilot.messages.glossaryTermName,
            })}
            styles={{
              label: { fontSize: "12px" },
              content: { fontSize: "12px" },
            }}
          >
            {glossaryProps.name}
          </LabelWrapper>
          <br />
          <LabelWrapper
            label={intl.formatMessage({
              id: messageIds.copilot.messages.glossaryName,
            })}
            styles={{
              label: { fontSize: "12px" },
              content: { fontSize: "12px" },
            }}
          >
            {glossaryProps.glossaryName}
          </LabelWrapper>
        </div>
      );
    },
    [intl, theme]
  );

  const RenderScanInfo = useCallback(
    (scanProps: ICopilotReferenceScanProp) => {
      return (
        <div
          css={css`
            display: flex;
            flex-direction: column;
            color: ${theme.palette.neutralSecondary};
          `}
        >
          <LabelWrapper
            label={intl.formatMessage({
              id: messageIds.copilot.messages.dataSource,
            })}
            styles={{
              label: { fontSize: "12px" },
              content: { fontSize: "12px" },
            }}
          >
            {scanProps.dataSource}
          </LabelWrapper>
          <br />
          <LabelWrapper
            label={intl.formatMessage({
              id: messageIds.copilot.messages.scan,
            })}
            styles={{
              label: { fontSize: "12px" },
              content: { fontSize: "12px" },
            }}
          >
            {scanProps.scan}
          </LabelWrapper>
        </div>
      );
    },
    [intl, theme]
  );

  return currentRef && currentRef.type === "asset" ? (
    RenderAssetInfo(currentRef.properties as ICopilotReferenceAssetProp)
  ) : currentRef && currentRef.type === "classificationType" ? (
    RenderClassificationInfo(
      currentRef.properties as ICopilotReferenceClassificationProp
    )
  ) : currentRef && currentRef.type === "glossaryTerm" ? (
    RenderGlossaryInfo(currentRef.properties as ICopilotReferenceGlossaryProp)
  ) : currentRef && currentRef.type === "scan" ? (
    RenderScanInfo(currentRef.properties as ICopilotReferenceScanProp)
  ) : currentRef && currentRef.type === "contact" ? (
    <ContactInfo
      contact={currentRef.properties as ICopilotReferenceContactProp}
    />
  ) : null;
});
