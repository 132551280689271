import { rootModels } from "@msbabylon/shell-framework";
import { GetContainer } from "nyax";
import { isTestEnv } from "src/constants";
import { productDocumentations } from "src/constants/documentationLinks";
import { dependencies } from "src/dependencies";
import { getUnifiedPortalAppUrl, getUnifiedPortalUrl } from "src/features";
import messageIds from "src/locales/messageIds";
import { babylonInstanceModel } from "src/store/shell-models/babylon/instance";

export const azurePurviewDNS = isTestEnv
  ? "ci.web.purview.azure-test.com"
  : "web.purview.azure.com";

export function switchBackPurview(getContainer: GetContainer) {
  const sharedRoot = getContainer(rootModels.shared);
  const name = sharedRoot.getters.resourceName;
  let url = new URL(
    `https://${azurePurviewDNS}?${dependencies.urlFeature.getFeatureQuery()}`
  );
  if (name) {
    url = new URL(
      `https://${azurePurviewDNS}/resource/${name}?${dependencies.urlFeature.getFeatureQuery()}`
    );
  }
  window.location.href = url.toString();
}

export default async function (getContainer: GetContainer) {
  const bannerMessageHelper = getContainer(rootModels.bannerMessage.helper);
  const babylonModel = getContainer(babylonInstanceModel);
  const intl = dependencies.intl;

  if (!dependencies.application.resourceName) {
    // The portal is still in account selection page. Won't show banner in this case.
    return;
  }
  try {
    const isReconciledAccount = babylonModel.state.isReconciledAccount;
    const accountInfo = babylonModel.state.azureAccountInstance;
    if (isReconciledAccount) {
      const switchFuncId = dependencies.shellExtension.registerFunc(
        async () => {
          const url = await getUnifiedPortalAppUrl(dependencies); // Create the URL lazily so that it will correspond to the CURRENT EXTENSION
          window.location.href = url.toString();
        }
      );
      bannerMessageHelper.actions.create.dispatch({
        message: {
          type: 100,
          content: [
            intl.formatMessage({ id: messageIds.m365.switchToNewMessage }),
            {
              type: "link",
              text: intl.formatMessage({
                id: messageIds.reconcile.learnMoreAboutTheNewPortal,
              }),
              href: productDocumentations.upgradeForUnifiedPortal,
              external: true,
            },
            {
              type: "button",
              text: intl.formatMessage({
                id: messageIds.reconcile.tryNewPortal,
              }),
              onClick: { funcId: switchFuncId, args: [] },
              position: "right",
              buttonType: "primary",
            },
          ],
          isNotDismissible: true,
          position: "outerTop",
        },
      });
    } else if (
      dependencies.application.features.accountMerge &&
      accountInfo != null
    ) {
      // FIXME: remove after latest API version ready
      const latestAccountInfo = await dependencies.babylonApi.getInstance(
        accountInfo.name,
        true
      );
      if (
        latestAccountInfo == null ||
        latestAccountInfo.properties.mergeInfo?.typeOfAccount !== "Secondary"
      ) {
        return;
      }

      const mergeStatus = latestAccountInfo.properties.mergeInfo?.mergeStatus;
      if (mergeStatus === "InProgress") {
        const url = await getUnifiedPortalUrl(dependencies);
        const switchFuncId = dependencies.shellExtension.registerFunc(
          async () => {
            window.location.href = url.toString();
          }
        );
        bannerMessageHelper.actions.create.dispatch({
          message: {
            type: 0,
            content: [
              intl.formatMessage({
                id: messageIds.reconcile.accountMerge.mergingMessage,
              }),
              {
                type: "link",
                text: intl.formatMessage({
                  id: messageIds.shared.learnMore,
                }),
                href: productDocumentations.mergeToTenantAccount,
                external: true,
              },
              {
                type: "button",
                text: intl.formatMessage({
                  id: messageIds.reconcile.accountMerge.openNewPortal,
                }),
                onClick: { funcId: switchFuncId, args: [] },
                position: "right",
                buttonType: "primary",
              },
            ],
            isNotDismissible: true,
            position: "outerTop",
          },
        });
      } else if (mergeStatus === "Succeeded") {
        const url = await getUnifiedPortalUrl(dependencies);
        const switchFuncId = dependencies.shellExtension.registerFunc(
          async () => {
            window.location.href = url.toString();
          }
        );
        bannerMessageHelper.actions.create.dispatch({
          message: {
            type: 0,
            content: [
              intl.formatMessage({
                id: messageIds.reconcile.accountMerge.mergedMessage,
              }),
              {
                type: "link",
                text: intl.formatMessage({
                  id: messageIds.shared.learnMore,
                }),
                href: productDocumentations.mergeToTenantAccount,
                external: true,
              },
              {
                type: "button",
                text: intl.formatMessage({
                  id: messageIds.reconcile.accountMerge.switchToNewPortal,
                }),
                onClick: { funcId: switchFuncId, args: [] },
                position: "right",
                buttonType: "primary",
              },
            ],
            isNotDismissible: true,
            position: "outerTop",
          },
        });
      }
    }
  } catch {
    // swallow
  }
}
