import { ExtensionManifest } from "@msbabylon/core";
import {
  ExtensionApi as _ExtensionApi,
  getResponseData,
} from "@msbabylon/shell-framework";
import axios from "axios";
import { ShellApplication } from "src/models/app";

export class ExtensionApi extends _ExtensionApi {
  private readonly _axiosInstance = axios.create();

  constructor(private readonly _application: ShellApplication) {
    super();
  }

  public async getExtensionManifests(): Promise<ExtensionManifest[]> {
    return getResponseData(
      this._axiosInstance.get(this._application.tier.manifestExtensionUrl)
    );
  }
}
