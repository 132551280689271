/** @jsxImportSource @emotion/react */
import { SvgIcon } from "@adux/common-react";
import { css } from "@emotion/react";
import { Link } from "@fluentui/react";
import { Container } from "nyax";
import { memo, useCallback, useMemo } from "react";
import { useIntl as useIntlOrigin } from "react-intl";
import { useSelector } from "react-redux";
import IconFeature from "src/assets/icon-feature.svg";
import IconFeedback from "src/assets/icon-feedback.svg";
import IconRedirect from "src/assets/icon-redirect.svg";
import RadioGroup from "src/components/radio/RadioGroup";
import RadioItem from "src/components/radio/RadioItem";
import messageIds from "src/locales/messageIds";
import {
  FeedbackType,
  uiViewPanelFeedbackModel,
} from "src/store/shell-models/ui/view/UIPanelFeedback";
import RadioContent from "./RadioContent";

function useIntl() {
  const intl = useIntlOrigin();
  return useMemo(
    () => ({
      intlHaveFeedback: intl.formatMessage({
        id: messageIds.feedback.haveFeedback,
      }),
      intlHaveFeature: intl.formatMessage({
        id: messageIds.feedback.haveFeature,
      }),
      intlFeedbackDescription: intl.formatMessage({
        id: messageIds.feedback.feedbackDescription,
      }),
      intlFeatureDescription: intl.formatMessage({
        id: messageIds.feedback.featureDescription,
      }),
    }),
    [intl]
  );
}

export default memo(
  ({ uiView }: { uiView: Container<typeof uiViewPanelFeedbackModel> }) => {
    const {
      intlHaveFeature,
      intlHaveFeedback,
      intlFeedbackDescription,
      intlFeatureDescription,
    } = useIntl();
    const feedbackType = useSelector(() => uiView.state.type);
    const haveFeatureTitle = useMemo(
      () => (
        <Link
          css={css`
            display: inline-flex;
            align-items: center;
          `}
          href={"https://go.microsoft.com/fwlink/?linkid=2147831"}
          target="_blank"
        >
          <span>
            {intlHaveFeature}
            &nbsp;
            <span aria-hidden>
              <SvgIcon
                src={IconRedirect}
                altTitle={intlHaveFeature}
                styles={{
                  root: {
                    display: "inline-block",
                  },
                }}
              />
            </span>
          </span>
        </Link>
      ),
      [intlHaveFeature]
    );
    const handleChangeType = useCallback(
      (type: FeedbackType) => {
        uiView.actions.setType.dispatch(type);
      },
      [uiView.actions.setType]
    );
    return (
      <RadioGroup value={feedbackType} onChange={handleChangeType}>
        <div
          css={css`
            display: flex;
            gap: 16px 16px;
            flex-wrap: wrap;
          `}
        >
          <RadioItem value={FeedbackType.Feedback} role="button">
            <RadioContent
              title={intlHaveFeedback}
              iconUrl={IconFeedback}
              description={intlFeedbackDescription}
              titleText={intlHaveFeedback}
            />
          </RadioItem>
          <RadioItem value={FeedbackType.Feature} role="button">
            <RadioContent
              title={haveFeatureTitle}
              iconUrl={IconFeature}
              description={intlFeatureDescription}
              titleText={intlHaveFeature}
            />
          </RadioItem>
        </div>
      </RadioGroup>
    );
  }
);
