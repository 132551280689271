/* eslint-disable security/detect-object-injection */
/** @jsxImportSource @emotion/react */
import {
  FilterBox,
  HintIcon,
  MessageBar,
  TableList,
  useFonts,
  useTheme,
} from "@adux/common-react";
import { css } from "@emotion/react";
import {
  DefaultButton,
  Icon,
  MessageBarType,
  PrimaryButton,
  SelectionMode,
  Toggle,
  TooltipHost,
} from "@fluentui/react";
import {
  ShellExtensionUIComponentProps,
  useGetContainer,
  useUIView,
} from "@msbabylon/shell-core";
import { rootModels } from "@msbabylon/shell-framework";
import { difference, sortBy, uniq } from "lodash-es";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useIntl } from "react-intl";
import AppHyperLink from "src/components/AppHyperLink";
import { productDocumentations } from "src/constants/documentationLinks";
import { useDependencies } from "src/hook";
import messageIds from "src/locales/messageIds";
import { uiViewDialogMergeSelectCategoryModel } from "src/store/shell-models/ui/view/UIDialogMergeSelectCategory";

// #region - constants
const CATEGORY_MAP: Record<
  string,
  {
    isRequired?: boolean;
    canAutoResolve?: boolean;
    tooltipTextId?: string;
    deselectDependent?: string[]; // when deselecting current category, also deselect these categories
    warningDependent?: Array<{
      category: string;
      warningTextId: string;
    }>; // if any of these categories not selected, display warning on current category
  }
> = {
  Asset: {
    canAutoResolve: true,
    warningDependent: [
      {
        category: "Custom type",
        warningTextId:
          messageIds.reconcile.accountMerge.categoryWarning.asset.customType,
      },
      {
        category: "Contact assignment",
        warningTextId:
          messageIds.reconcile.accountMerge.categoryWarning.asset
            .contactAssignment,
      },
      {
        category: "Glossary",
        warningTextId:
          messageIds.reconcile.accountMerge.categoryWarning.asset.glossary,
      },
    ],
  },
  "Bring your own event hub": {},
  "Custom classification rule": {
    canAutoResolve: true,
    deselectDependent: ["Custom scan rule set"],
    tooltipTextId:
      messageIds.reconcile.accountMerge.categoryTooltip
        .customClassificationRule,
  },
  Collection: {
    isRequired: true,
  },
  Connection: {
    canAutoResolve: true,
  },
  "Contact assignment": {
    tooltipTextId:
      messageIds.reconcile.accountMerge.categoryTooltip.contactAssignment,
  },
  Credential: {
    canAutoResolve: true,
    tooltipTextId: messageIds.reconcile.accountMerge.categoryTooltip.credential,
    deselectDependent: ["Connection"],
  },
  "Data factory connection": {},
  "Data share": {},
  "Data source": {
    canAutoResolve: true,
    tooltipTextId: messageIds.reconcile.accountMerge.categoryTooltip.dataSource,
    deselectDependent: ["Scan", "Policy", "Connection", "Resource set rule"],
  },
  Glossary: {
    canAutoResolve: true,
    tooltipTextId: messageIds.reconcile.accountMerge.categoryTooltip.glossary,
    warningDependent: [
      {
        category: "Contact assignment",
        warningTextId:
          messageIds.reconcile.accountMerge.categoryWarning.glossary
            .contactAssignment,
      },
      {
        category: "Custom type",
        warningTextId:
          messageIds.reconcile.accountMerge.categoryWarning.glossary.customType,
      },
    ],
  },
  Insights: {},
  "Self-hosted integration runtime": {
    tooltipTextId: messageIds.reconcile.accountMerge.categoryTooltip.shir,
    deselectDependent: ["Connection"],
  },
  "Key vault connection": {
    canAutoResolve: true,
    tooltipTextId: messageIds.reconcile.accountMerge.categoryTooltip.keyVault,
    deselectDependent: ["Credential", "Connection"],
  },
  "Managed event hub": {},
  "Managed virtual network": {
    tooltipTextId:
      messageIds.reconcile.accountMerge.categoryTooltip.managedVirtualNetwork,
    deselectDependent: ["Connection"],
  },
  Policy: {},
  "Resource set rule": {
    tooltipTextId:
      messageIds.reconcile.accountMerge.categoryTooltip.resourceSetRule,
  },
  Scan: {
    canAutoResolve: true,
    warningDependent: [
      {
        category: "Credential",
        warningTextId:
          messageIds.reconcile.accountMerge.categoryWarning.scan.credential,
      },
      {
        category: "Custom scan rule set",
        warningTextId:
          messageIds.reconcile.accountMerge.categoryWarning.scan
            .customScanRuleSet,
      },
      {
        category: "Managed virtual network",
        warningTextId:
          messageIds.reconcile.accountMerge.categoryWarning.scan
            .managedVirtualNetwork,
      },
      {
        category: "Self-hosted integration runtime",
        warningTextId:
          messageIds.reconcile.accountMerge.categoryWarning.scan.shir,
      },
    ],
  },
  "Custom scan rule set": {
    canAutoResolve: true,
    tooltipTextId:
      messageIds.reconcile.accountMerge.categoryTooltip.customScanRuleSet,
  },
  "Synapse connection": {},
  "Custom type": {
    tooltipTextId: messageIds.reconcile.accountMerge.categoryTooltip.customType,
  },
  Workflow: {
    canAutoResolve: true,
    warningDependent: [
      {
        category: "Glossary",
        warningTextId:
          messageIds.reconcile.accountMerge.categoryWarning.workflow.glossary,
      },
    ],
  },
  "Private endpoint": {},
  "User assigned managed identity": {
    tooltipTextId: messageIds.reconcile.accountMerge.categoryTooltip.uami,
  },
};
const OBJECT_CATEGORIES = Object.keys(CATEGORY_MAP);
const REQUIRED_CATEGORIES = Object.entries(CATEGORY_MAP)
  .filter(([, obj]) => !!obj.isRequired)
  .map(([category]) => category);
const SELECT_DEPENDENT_CATEGORIES: Record<string, string[]> = Object.entries(
  CATEGORY_MAP
).reduce((res, [category, obj]) => {
  if (obj.deselectDependent != null) {
    const newRes = { ...res };
    obj.deselectDependent.forEach((dependent) => {
      newRes[dependent] = [...(newRes[dependent] || []), category];
    });
    return newRes;
  }
  return res;
}, {} as Record<string, string[]>);
// #endregion

interface CategoryItem {
  key: string;
  category: string;
  selected: boolean;
  canAutoResolve: boolean;
  autoResolve: boolean;
  tooltipTextId?: string;
  warningMessageIds?: string[];
}

export default React.memo<
  ShellExtensionUIComponentProps<{
    assessmentOnly?: boolean;
    primaryButtonCallbackId: string;
  }>
>((props) => {
  const { uiId, params } = props;
  const { assessmentOnly, primaryButtonCallbackId } = params;

  const dependencies = useDependencies();
  const getContainer = useGetContainer();
  const uiDialogListCommonHelper = getContainer(
    rootModels.ui.list.dialog.commonHelper
  );
  const uiView = getContainer(uiViewDialogMergeSelectCategoryModel, uiId);
  const uiItemHelper = getContainer(rootModels.ui.item.helperByUIId, uiId);
  useUIView(uiView, uiItemHelper);

  const intl = useIntl();
  const theme = useTheme();
  const cssTypography = useFonts();

  useEffect(() => {
    return () => {
      dependencies.shellExtension.unregisterFunc(primaryButtonCallbackId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // #region - filter
  const [selectedCategories, setSelectedCategories] =
    useState<string[]>(OBJECT_CATEGORIES);
  const [autoResolveCategories, setAutoResolveCategories] = useState<string[]>(
    []
  );
  const [filterText, setFilterText] = useState<string>("");
  const filterTextRef = useRef<string>("");
  const onFilterChange = useCallback(
    (
      event: React.ChangeEvent<HTMLInputElement> | undefined,
      newValue?: string | undefined
    ) => {
      const updateValue = newValue || event?.currentTarget.value || "";
      filterTextRef.current = updateValue;
      setSelectedCategories([...selectedCategories]);
      setFilterText(updateValue);
    },
    [selectedCategories]
  );
  // #endregion

  // #region - table
  const tableItems: CategoryItem[] = useMemo(() => {
    return sortBy(Object.entries(CATEGORY_MAP), [
      ([, obj]) => !obj.isRequired,
      ([category]) => category,
    ])
      .map(([category, obj]) => {
        const isSelected = selectedCategories.includes(category);
        const warningMessageIds = [] as string[];
        if (isSelected && obj.warningDependent != null) {
          obj.warningDependent.forEach(
            ({ category: dependentCategory, warningTextId }) => {
              if (!selectedCategories.includes(dependentCategory)) {
                warningMessageIds.push(warningTextId);
              }
            }
          );
        }
        return {
          key: category,
          category: category,
          selected: isSelected,
          canAutoResolve: !!obj.canAutoResolve,
          autoResolve: autoResolveCategories.includes(category),
          tooltipTextId: obj.tooltipTextId,
          warningMessageIds: warningMessageIds,
        };
      })
      .filter((item) =>
        item.category.toLowerCase().includes(filterText.toLowerCase())
      );
  }, [autoResolveCategories, filterText, selectedCategories]);
  const onSelectCategory = useCallback(
    (keys: string[]) => {
      // Skip if triggered by filter change
      const searchFilter = filterTextRef.current;
      if (searchFilter != null && searchFilter !== filterText) {
        return;
      }

      const selectedNotShown = selectedCategories.filter(
        (category) => !tableItems.map((i) => i.key).includes(category)
      );
      const oldValue = selectedCategories.filter((category) =>
        tableItems.find((i) => i.category === category)
      );
      let newValue = keys;
      if (newValue.length === oldValue.length - 1) {
        const removedItem = oldValue.find((i) => !newValue.includes(i));
        if (removedItem != null) {
          const relatedItems = CATEGORY_MAP[removedItem]?.deselectDependent;
          if (relatedItems != null) {
            newValue = newValue.filter((i) => !relatedItems.includes(i));
          }
        }
      } else if (newValue.length === oldValue.length + 1) {
        const addedItem = newValue.find((i) => !oldValue.includes(i));
        if (addedItem != null) {
          const relatedItems = SELECT_DEPENDENT_CATEGORIES[addedItem];
          if (relatedItems != null) {
            newValue = uniq([...newValue, ...relatedItems]);
          }
        }
      }
      const newSelectedCategories = uniq([
        ...newValue,
        ...selectedNotShown,
        ...REQUIRED_CATEGORIES,
      ]);
      setSelectedCategories(newSelectedCategories);
      setAutoResolveCategories((prev) =>
        prev.filter((category) => newSelectedCategories.includes(category))
      );
    },
    [filterText, selectedCategories, tableItems]
  );
  const tableColumns = useMemo(() => {
    return [
      {
        key: "category",
        name: intl.formatMessage({
          id: messageIds.reconcile.accountMerge.categoryName,
        }),
        onRender: (item: CategoryItem) => {
          return (
            <div
              css={css`
                display: flex;
                flex-direction: row;
              `}
            >
              {item.category}
              {item.tooltipTextId != null && (
                <HintIcon
                  content={intl.formatMessage({ id: item.tooltipTextId })}
                />
              )}
              {item.warningMessageIds != null &&
                item.warningMessageIds.length > 0 && (
                  <TooltipHost
                    content={
                      <>
                        {item.warningMessageIds.map((msgId, idx) => (
                          <div
                            key={idx}
                            css={css`
                              margin: 4px;
                            `}
                          >
                            {intl.formatMessage(
                              { id: msgId },
                              {
                                b: (str) => <b>{str}</b>,
                                br: () => (
                                  <>
                                    <br />
                                    &nbsp;&nbsp;
                                  </>
                                ),
                              }
                            )}
                          </div>
                        ))}
                      </>
                    }
                  >
                    <div
                      css={css`
                        display: flex;
                        flex-direction: row;
                        margin-left: 12px;
                        cursor: pointer;
                        color: ${theme.palette.yellowDark};
                      `}
                    >
                      <Icon iconName="WarningSolid" />
                      <span
                        css={css`
                          margin-left: 2px;
                        `}
                      >
                        {item.warningMessageIds.length}
                      </span>
                    </div>
                  </TooltipHost>
                )}
            </div>
          );
        },
      },
      {
        key: "autoResolve",
        name: intl.formatMessage({
          id: messageIds.reconcile.accountMerge.autoResolveName,
        }),
        minWidth: 200,
        onRender: (item: CategoryItem) => {
          if (item.canAutoResolve) {
            return (
              <Toggle
                checked={item.selected && item.autoResolve}
                disabled={!item.selected}
                onText={intl.formatMessage({ id: messageIds.shared.on })}
                offText={intl.formatMessage({ id: messageIds.shared.off })}
                onChange={(_, checked) => {
                  setAutoResolveCategories((prev) =>
                    checked
                      ? [...prev, item.category]
                      : prev.filter((category) => category !== item.category)
                  );
                }}
                styles={{ root: { marginBottom: 0 } }}
              />
            );
          }
          return <></>;
        },
      },
    ];
  }, [intl, theme.palette.yellowDark]);
  // #endregion

  // #region - dialog actions
  const [isProcessing, setProcessing] = useState(false);
  const onClickPrimaryButton = useCallback(async () => {
    setProcessing(true);
    dependencies.shellExtension.invokeFunc(
      primaryButtonCallbackId,
      difference(OBJECT_CATEGORIES, selectedCategories),
      autoResolveCategories
    );
    setProcessing(false);
    uiDialogListCommonHelper.actions.close.dispatch({ uiId });
  }, [
    autoResolveCategories,
    dependencies.shellExtension,
    primaryButtonCallbackId,
    selectedCategories,
    uiDialogListCommonHelper.actions.close,
    uiId,
  ]);
  const onCancel = useCallback(() => {
    uiDialogListCommonHelper.actions.close.dispatch({ uiId });
  }, [uiDialogListCommonHelper.actions.close, uiId]);
  // #endregion

  return (
    <div
      css={css`
        flex: 1;
        display: flex;
        flex-direction: column;
      `}
    >
      <div
        css={css`
          flex: 1;
          display: flex;
          flex-direction: column;
          padding: 24px;
        `}
      >
        <div
          css={[
            css`
              margin-bottom: 12px;
              color: ${theme.semanticColors.bodyText};
            `,
            cssTypography.subtitle1,
          ]}
        >
          {intl.formatMessage({
            id: assessmentOnly
              ? messageIds.reconcile.accountMerge.selectCategoryAssessmentTitle
              : messageIds.reconcile.accountMerge.selectCategoryMergeTitle,
          })}
        </div>
        <div
          css={[
            css`
              flex: 1;
              display: flex;
              flex-direction: column;
              margin-top: 12px;
            `,
            cssTypography.body1,
          ]}
        >
          <div
            css={css`
              margin-bottom: 16px;
            `}
          >
            {intl.formatMessage({
              id: assessmentOnly
                ? messageIds.reconcile.accountMerge
                    .selectCategoryAssessmentMessage
                : messageIds.reconcile.accountMerge.selectCategoryMergeMessage,
            })}
          </div>
          <MessageBar
            messageBarType={MessageBarType.warning}
            styles={{ root: { padding: 0, marginBottom: "15px" } }}
          >
            {intl.formatMessage({
              id: messageIds.reconcile.accountMerge
                .selectCategoryWarningMessage,
            })}
            &nbsp;
            <AppHyperLink
              href={productDocumentations.mergeToTenantAccount}
              externalLink={true}
            >
              {intl.formatMessage({
                id: messageIds.shared.learnMore,
              })}
            </AppHyperLink>
          </MessageBar>
          <FilterBox
            iconProps={{ iconName: "Search" }}
            placeholder={intl.formatMessage({
              id: messageIds.reconcile.accountMerge.searchByCategory,
            })}
            value={filterText}
            onChange={onFilterChange}
          />
          <div
            css={css`
              margin-top: 8px;
              margin-bottom: 16px;
            `}
          >
            {intl.formatMessage(
              { id: messageIds.reconcile.accountMerge.numOfSelected },
              {
                num: selectedCategories.length,
                total: OBJECT_CATEGORIES.length,
              }
            )}
          </div>
          <div
            css={css`
              flex: 1;
            `}
          >
            <TableList
              columns={tableColumns}
              items={tableItems}
              selectionMode={SelectionMode.multiple}
              selectedKeys={selectedCategories}
              selectionPersistence={true}
              onSelectedKeysChange={onSelectCategory}
              disabledRows={REQUIRED_CATEGORIES}
              stickHeader={true}
              styles={{
                subComponentStyles: {
                  detailsRow: {
                    fields: { height: "100%" },
                    cell: {
                      display: "flex",
                      alignItems: "center",
                      opacity: "1",
                    },
                  },
                  scrollablePane: {
                    contentContainer: { overflowX: "hidden" },
                  },
                },
              }}
            />
          </div>
        </div>
      </div>
      <div
        css={css`
          display: flex;
          flex-direction: row;
          border-top: 1px solid ${theme.semanticColors.bodyDivider};
          padding: 16px 24px;
        `}
      >
        <PrimaryButton
          text={intl.formatMessage({
            id: isProcessing
              ? messageIds.shared.processing
              : messageIds.shared.submit,
          })}
          onClick={onClickPrimaryButton}
          disabled={isProcessing}
          css={css`
            margin-right: 8px;
          `}
        />
        <DefaultButton
          text={intl.formatMessage({ id: messageIds.shared.cancel })}
          onClick={onCancel}
        />
      </div>
    </div>
  );
});
