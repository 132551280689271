/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Spinner } from "@fluentui/react";
import { toastContainerIds } from "@msbabylon/shell-framework";
import { PropsWithChildren, Suspense, lazy, memo, useMemo } from "react";
import { toast } from "react-toastify";
import { SurveyType } from "src/apis/CesAPI";
import { ToastCloseButton } from "src/components/ToastCloseButton";

const SurveyComponent = lazy(() => import("./SurveyComponent"));

let existingToastId: string | number | undefined;

export function popUpCES(surveyType: SurveyType) {
  if (existingToastId != null) {
    // skip if there is already a survey popped up
    return;
  }

  existingToastId = toast(
    <Suspense
      fallback={
        // for lazy loading causes the layout shrinkage
        <ContainerLayout center>
          <Spinner />
        </ContainerLayout>
      }
    >
      <ContainerLayout center>
        {/* for SurveyComponent inner slow loading */}
        <Spinner />
        <div
          css={css`
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1;
          `}
        >
          <SurveyComponent
            surveyType={surveyType}
            onClose={manuallyCloseToast}
          />
        </div>
      </ContainerLayout>
    </Suspense>,
    {
      style: {
        backgroundColor: "#fbfbfb",
      },
      position: "bottom-right",
      autoClose: false,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: true,
      pauseOnFocusLoss: false,
      draggable: false,
      closeButton: (
        // in case survey sdk crashed
        <ToastCloseButton
          onClose={manuallyCloseToast}
          styles={{ top: 22, right: 23 }}
        />
      ),
      containerId: toastContainerIds.starRatingFeedback,
    }
  );

  async function manuallyCloseToast() {
    toast.dismiss(existingToastId);
    existingToastId = undefined;
  }
}

const ContainerLayout = memo<PropsWithChildren<{ center?: boolean }>>(
  (props) => {
    const centerStyle = useMemo(() => {
      if (!props.center) {
        return;
      }
      return css`
        display: flex;
        align-items: center;
        justify-content: center;
      `;
    }, [props.center]);

    return (
      <div
        css={css`
          width: 514px;
          height: 404px;
          ${centerStyle}
        `}
      >
        {props.children}
      </div>
    );
  }
);
