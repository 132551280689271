import { rootModels } from "@msbabylon/shell-framework";
import { createModel, mergeSubModels } from "nyax";
import { PurviewAccountArmResource } from "src/models/azure";
import { AccountInList, BabylonAccount } from "src/models/babylon";
import { createItemsReadWriteModel } from "src/store/shared/itemsReadWrite";
import {
  babylonAccountArmEntityModel,
  babylonAccountEntityModel,
} from "src/store/shell-models/babylon/account/entity";

export const babylonAccountHelperModel = createModel(
  class extends mergeSubModels({
    _rw: createItemsReadWriteModel<BabylonAccount & { $tenantId: string }>({
      getItemId: (item) => item.id,
      setItems: ({ getContainer }, items) =>
        getContainer(babylonAccountEntityModel).actions.setItems.dispatch(
          items
        ),
    }),
    _rwBySubscriptionId: createItemsReadWriteModel(),
  }) {
    public selectors() {
      return {
        ...super.selectors(),
        items: () => this.getContainer(babylonAccountEntityModel).getters.items,
      };
    }

    public effects() {
      return {
        ...super.effects(),

        readFromGateway: async (payload: {
          tenantId: string;
          force?: boolean;
        }) => {
          const { ids: beginReadIds, timestamp } =
            await this.actions._rwBySubscriptionId.beginRead.dispatch({
              ids: [payload.tenantId],
              force: payload.force,
            });

          try {
            if (beginReadIds.length > 0) {
              const accounts: AccountInList[] =
                await this.dependencies.babylonApi.getAccountList(
                  payload.tenantId
                );
              const babylonAccounts: BabylonAccount[] = accounts.map(
                (account) => ({
                  ...account,
                  $tenantId: payload.tenantId,
                  id: `${payload.tenantId}/${account.name}`,
                })
              );

              const { ids: endReadIds } =
                await this.actions._rwBySubscriptionId.endRead.dispatch({
                  items: beginReadIds,
                  timestamp,
                });

              if (endReadIds.length > 0) {
                await this.actions._rw.endRead.dispatch({
                  items: babylonAccounts,
                  timestamp,
                });
              }
            }

            return this.getters.items;
          } catch (error) {
            await this.actions._rwBySubscriptionId.endRead.dispatch({
              items: [],
              timestamp,
            });

            throw error;
          }
        },
      };
    }
  }
);

export const babylonAccountArmHelperModel = createModel(
  class extends mergeSubModels({
    _rw: createItemsReadWriteModel<PurviewAccountArmResource>({
      getItemId: (item) => item.id,
      setItems: ({ getContainer }, items) =>
        getContainer(babylonAccountArmEntityModel).actions.setItems.dispatch(
          items
        ),
    }),
    _rwBySubscriptionId: createItemsReadWriteModel(),
  }) {
    public selectors() {
      return {
        ...super.selectors(),
        items: () =>
          this.getContainer(babylonAccountArmEntityModel).getters.items,
      };
    }

    public effects() {
      return {
        ...super.effects(),
        readFromResourceGraph: async (payload: {
          tenantId: string;
          force?: boolean;
        }) => {
          let subscriptionIds: string[] = [];
          try {
            const subscriptions = await this.getContainer(
              rootModels.azure.subscription.helper
            ).actions.readByTenantIds.dispatch({
              tenantIds: [payload.tenantId],
              force: payload.force,
            });
            subscriptionIds = subscriptions.map((e) => e.subscriptionId);
          } catch (error) {
            await this.actions._rwBySubscriptionId.endRead.dispatch({
              items: [],
              timestamp: Date.now(),
            });

            throw error;
          }

          const { ids: beginReadIds, timestamp } =
            await this.actions._rwBySubscriptionId.beginRead.dispatch({
              ids: subscriptionIds,
              force: payload.force,
            });

          try {
            if (beginReadIds.length > 0) {
              const resources = await this.dependencies.shellAzureApi
                .listPurviewAccountsByResourceGraph(
                  beginReadIds,
                  payload.tenantId
                )
                .then((accounts) =>
                  accounts.map((account) => ({
                    ...account,
                  }))
                );

              const { ids: endReadIds } =
                await this.actions._rwBySubscriptionId.endRead.dispatch({
                  items: beginReadIds,
                  timestamp,
                });

              if (endReadIds.length > 0) {
                const readIdSet = new Set(endReadIds);

                const deleteResourceIds = this.getters.items
                  .filter((e) => readIdSet.has(e.id.split("/")[2]))
                  .map((e) => e.id);

                const readResources = resources.filter((e) =>
                  readIdSet.has(e.id.split("/")[2])
                );

                await this.actions._rw.endRead.dispatch({
                  items: [...deleteResourceIds, ...readResources],
                  timestamp,
                });
              }
            }

            const subscriptionIdSet = new Set(subscriptionIds);

            return this.getters.items.filter((e) =>
              subscriptionIdSet.has(e.id.split("/")[2])
            );
          } catch (error) {
            await this.actions._rwBySubscriptionId.endRead.dispatch({
              items: [],
              timestamp,
            });

            throw error;
          }
        },
      };
    }
  }
);
