import { isNullOrEmpty } from "@adux/common-react";
import { Nullable } from "@adux/common-react/lib/types/nullable";
import {
  CountryOrRegionCode,
  regionOptionType,
  regions,
} from "@msbabylon/sdk-hub";
import { IntlShape } from "react-intl";
import messageIds from "src/locales/messageIds";
import { formatStringForCompare } from "src/util/string";

function formatRegionString(region: string): Nullable<regionOptionType> {
  switch (formatStringForCompare(region)) {
    case formatStringForCompare(regions.AutoResolve):
      return regions.AutoResolve;
    case formatStringForCompare(regions.AustraliaEast):
      return regions.AustraliaEast;
    case formatStringForCompare(regions.AustraliaSoutheast):
      return regions.AustraliaSoutheast;
    case formatStringForCompare(regions.BrazilSouth):
      return regions.BrazilSouth;
    case formatStringForCompare(regions.CanadaCentral):
      return regions.CanadaCentral;
    case formatStringForCompare(regions.CanadaEast):
      return regions.CanadaEast;
    case formatStringForCompare(regions.CentralIndia):
      return regions.CentralIndia;
    case formatStringForCompare(regions.CentralUS):
      return regions.CentralUS;
    case formatStringForCompare(regions.CentralUSEUAP):
      return regions.CentralUSEUAP;
    case formatStringForCompare(regions.EastAsia):
      return regions.EastAsia;
    case formatStringForCompare(regions.EastUS):
      return regions.EastUS;
    case formatStringForCompare(regions.EastUS2):
      return regions.EastUS2;
    case formatStringForCompare(regions.EastUS2EUAP):
      return regions.EastUS2EUAP;
    case formatStringForCompare(regions.FranceCentral):
      return regions.FranceCentral;
    case formatStringForCompare(regions.GermanyWestCentral):
      return regions.GermanyWestCentral;
    case formatStringForCompare(regions.JapanEast):
      return regions.JapanEast;
    case formatStringForCompare(regions.JapanWest):
      return regions.JapanWest;
    case formatStringForCompare(regions.KoreaCentral):
      return regions.KoreaCentral;
    case formatStringForCompare(regions.KoreaSouth):
      return regions.KoreaSouth;
    case formatStringForCompare(regions.NorthCentralUS):
      return regions.NorthCentralUS;
    case formatStringForCompare(regions.NorthEurope):
      return regions.NorthEurope;
    case formatStringForCompare(regions.NorwayEast):
      return regions.NorwayEast;
    case formatStringForCompare(regions.SouthAfricaNorth):
      return regions.SouthAfricaNorth;
    case formatStringForCompare(regions.SouthAfricaWest):
      return regions.SouthAfricaWest;
    case formatStringForCompare(regions.SouthCentralUS):
      return regions.SouthCentralUS;
    case formatStringForCompare(regions.SouthIndia):
      return regions.SouthIndia;
    case formatStringForCompare(regions.SoutheastAsia):
      return regions.SoutheastAsia;
    case formatStringForCompare(regions.SwitzerlandNorth):
      return regions.SwitzerlandNorth;
    case formatStringForCompare(regions.UKSouth):
      return regions.UKSouth;
    case formatStringForCompare(regions.UKWest):
      return regions.UKWest;
    case formatStringForCompare(regions.WestCentralUS):
      return regions.WestCentralUS;
    case formatStringForCompare(regions.WestEurope):
      return regions.WestEurope;
    case formatStringForCompare(regions.WestIndia):
      return regions.WestIndia;
    case formatStringForCompare(regions.WestUS):
      return regions.WestUS;
    case formatStringForCompare(regions.WestUS2):
      return regions.WestUS2;
    case formatStringForCompare(regions.WestUS3):
      return regions.WestUS3;
    case formatStringForCompare(regions.UAENorth):
      return regions.UAENorth;
    case formatStringForCompare(regions.USGovArizona):
      return regions.USGovArizona;
    case formatStringForCompare(regions.USGovVirginia):
      return regions.USGovVirginia;
    case formatStringForCompare(regions.USGovIowa):
      return regions.USGovIowa;
    case formatStringForCompare(regions.USGovTexas):
      return regions.USGovTexas;
    case formatStringForCompare(regions.USDoDCentral):
      return regions.USDoDCentral;
    case formatStringForCompare(regions.USDoDEast):
      return regions.USDoDEast;
    case formatStringForCompare(regions.ChinaEast):
      return regions.ChinaEast;
    case formatStringForCompare(regions.ChinaEast2):
      return regions.ChinaEast2;
    case formatStringForCompare(regions.ChinaNorth):
      return regions.ChinaNorth;
    case formatStringForCompare(regions.ChinaNorth2):
      return regions.ChinaNorth2;
    case formatStringForCompare(regions.UsNatEast):
      return regions.UsNatEast;
    case formatStringForCompare(regions.UsNatWest):
      return regions.UsNatWest;
    case formatStringForCompare(regions.UsSecEast):
      return regions.UsSecEast;
    case formatStringForCompare(regions.UsSecWest):
      return regions.UsSecWest;
    default:
      return undefined;
  }
}

function localizeRegionString(
  region: Nullable<string>,
  intl: IntlShape
): string {
  if (isNullOrEmpty(region)) {
    return "---";
  }
  switch (formatRegionString(region)) {
    case regions.AutoResolve:
      return intl.formatMessage({ id: messageIds.region.AutoResolve });
    case regions.AustraliaEast:
      return intl.formatMessage({ id: messageIds.region.AustraliaEast });
    case regions.AustraliaSoutheast:
      return intl.formatMessage({ id: messageIds.region.AustraliaSoutheast });
    case regions.BrazilSouth:
      return intl.formatMessage({ id: messageIds.region.BrazilSouth });
    case regions.CanadaCentral:
      return intl.formatMessage({ id: messageIds.region.CanadaCentral });
    case regions.CanadaEast:
      return intl.formatMessage({ id: messageIds.region.CanadaEast });
    case regions.CentralIndia:
      return intl.formatMessage({ id: messageIds.region.CentralIndia });
    case regions.CentralUS:
      return intl.formatMessage({ id: messageIds.region.CentralUS });
    case regions.CentralUSEUAP:
      return intl.formatMessage({ id: messageIds.region.CentralUSEUAP });
    case regions.EastAsia:
      return intl.formatMessage({ id: messageIds.region.EastAsia });
    case regions.EastUS:
      return intl.formatMessage({ id: messageIds.region.EastUS });
    case regions.EastUS2:
      return intl.formatMessage({ id: messageIds.region.EastUS2 });
    case regions.EastUS2EUAP:
      return intl.formatMessage({ id: messageIds.region.EastUS2EUAP });
    case regions.FranceCentral:
      return intl.formatMessage({ id: messageIds.region.FranceCentral });
    case regions.GermanyWestCentral:
      return intl.formatMessage({ id: messageIds.region.GermanyWestCentral });
    case regions.JapanEast:
      return intl.formatMessage({ id: messageIds.region.JapanEast });
    case regions.JapanWest:
      return intl.formatMessage({ id: messageIds.region.JapanWest });
    case regions.KoreaCentral:
      return intl.formatMessage({ id: messageIds.region.KoreaCentral });
    case regions.KoreaSouth:
      return intl.formatMessage({ id: messageIds.region.KoreaSouth });
    case regions.NorthCentralUS:
      return intl.formatMessage({ id: messageIds.region.NorthCentralUS });
    case regions.NorthEurope:
      return intl.formatMessage({ id: messageIds.region.NorthEurope });
    case regions.NorwayEast:
      return intl.formatMessage({ id: messageIds.region.NorwayEast });
    case regions.SouthAfricaNorth:
      return intl.formatMessage({ id: messageIds.region.SouthAfricaNorth });
    case regions.SouthAfricaWest:
      return intl.formatMessage({ id: messageIds.region.SouthAfricaWest });
    case regions.SouthCentralUS:
      return intl.formatMessage({ id: messageIds.region.SouthCentralUS });
    case regions.SouthIndia:
      return intl.formatMessage({ id: messageIds.region.SouthIndia });
    case regions.SoutheastAsia:
      return intl.formatMessage({ id: messageIds.region.SoutheastAsia });
    case regions.SwitzerlandNorth:
      return intl.formatMessage({ id: messageIds.region.SwitzerlandNorth });
    case regions.UKSouth:
      return intl.formatMessage({ id: messageIds.region.UKSouth });
    case regions.UKWest:
      return intl.formatMessage({ id: messageIds.region.UKWest });
    case regions.WestCentralUS:
      return intl.formatMessage({ id: messageIds.region.WestCentralUS });
    case regions.WestEurope:
      return intl.formatMessage({ id: messageIds.region.WestEurope });
    case regions.WestIndia:
      return intl.formatMessage({ id: messageIds.region.WestIndia });
    case regions.WestUS:
      return intl.formatMessage({ id: messageIds.region.WestUS });
    case regions.WestUS2:
      return intl.formatMessage({ id: messageIds.region.WestUS2 });
    case regions.WestUS3:
      return intl.formatMessage({ id: messageIds.region.WestUS3 });
    case regions.UAENorth:
      return intl.formatMessage({ id: messageIds.region.UAENorth });
    // Fairfax Regions
    case regions.USGovVirginia:
      return intl.formatMessage({ id: messageIds.region.USGovVirginia });
    case regions.USGovArizona:
      return intl.formatMessage({ id: messageIds.region.USGovArizona });
    case regions.USGovIowa:
      return intl.formatMessage({ id: messageIds.region.USGovIowa });
    case regions.USGovTexas:
      return intl.formatMessage({ id: messageIds.region.USGovTexas });
    case regions.USDoDCentral:
      return intl.formatMessage({ id: messageIds.region.USDoDCentral });
    case regions.USDoDEast:
      return intl.formatMessage({ id: messageIds.region.USDoDEast });
    // Mooncake Regions
    case regions.ChinaEast:
      return intl.formatMessage({ id: messageIds.region.ChinaEast });
    case regions.ChinaEast2:
      return intl.formatMessage({ id: messageIds.region.ChinaEast2 });
    case regions.ChinaNorth:
      return intl.formatMessage({ id: messageIds.region.ChinaNorth });
    case regions.ChinaNorth2:
      return intl.formatMessage({ id: messageIds.region.ChinaNorth2 });
    // UsNat Regions
    case regions.UsNatEast:
      return intl.formatMessage({ id: messageIds.region.UsNatEast });
    case regions.UsNatWest:
      return intl.formatMessage({ id: messageIds.region.UsNatWest });
    // UsSec Regions
    case regions.UsSecEast:
      return intl.formatMessage({ id: messageIds.region.UsSecEast });
    case regions.UsSecWest:
      return intl.formatMessage({ id: messageIds.region.UsSecWest });
    default:
      return region;
  }
}

export const getAzureRegionDisplayName = (intl: IntlShape, region?: string) => {
  if (region == null) {
    return "";
  }
  return localizeRegionString(region, intl);
};

export const getCountryOrRegionDisplayName = (
  intl: IntlShape,
  countryOrRegion?: CountryOrRegionCode
) => {
  if (countryOrRegion == null) {
    return "";
  }
  const _countryOrRegion =
    countryOrRegion as keyof typeof messageIds.countriesOrRegions;
  const displayNameId =
    // eslint-disable-next-line security/detect-object-injection
    messageIds.countriesOrRegions[_countryOrRegion] || "---";
  return intl.formatMessage({ id: displayNameId });
};
