export default (str: string | null) => {
  if (str == null) {
    return null;
  }

  if (str === "undefined" || str === "null") {
    return null;
  }

  return str;
};
