import { uiViewDialogMergeSelectCategoryModel } from "src/store/shell-models/ui/view/UIDialogMergeSelectCategory";
import { uiViewMainAllAppsModel } from "src/store/shell-models/ui/view/UIMainAllApps";
import { uiViewMainHomeModel } from "src/store/shell-models/ui/view/UIMainHome";
import { uiViewPanelDiagnosticsModel } from "src/store/shell-models/ui/view/UIPanelDiagnostics";
import { uiViewPanelFeedbackModel } from "src/store/shell-models/ui/view/UIPanelFeedback";
import { uiViewPanelHelpingCenterModel } from "src/store/shell-models/ui/view/UIPanelHelpingCenter";
import { uiViewPanelInstanceModel } from "src/store/shell-models/ui/view/UIPanelInstance";
import { uiViewPanelReleaseNotesModel } from "src/store/shell-models/ui/view/UIPanelReleaseNotes";
import { uiViewPanelSettingsModel } from "src/store/shell-models/ui/view/UIPanelSettings";

export const uiViewModels = {
  uiInstance: uiViewPanelInstanceModel,
  uiDiagnostic: uiViewPanelDiagnosticsModel,
  uiReleaseNotes: uiViewPanelReleaseNotesModel,
  uiSettings: uiViewPanelSettingsModel,
  uiFeedback: uiViewPanelFeedbackModel,
  uiHelp: uiViewPanelHelpingCenterModel,
  uiAllApps: uiViewMainAllAppsModel,
  uiHome: uiViewMainHomeModel,
  uiMergeSelectCategory: uiViewDialogMergeSelectCategoryModel,
};
