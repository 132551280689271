import pako from "pako";

function extractClusterName(kqlString: string): string | null {
  const clusterRegex = /cluster\(['"]([^'"]+)['"]\)/i;
  const match = kqlString.match(clusterRegex);
  return match ? match[1] : null;
}

function extractDatabaseName(kqlString: string): string | null {
  const databaseRegex = /database\(['"]([^'"]+)['"]\)/i;
  const match = kqlString.match(databaseRegex);
  return match ? match[1] : null;
}

const DEFAULT_CULSTER_NAME = "catalogtelemetrykusto.eastus";
const DEFAULT_DATABASE_NAME = "Common";

export function KqlToADXUrl(kql: string) {
  try {
    const compressedBuffer = pako.gzip(kql);
    const uint8Array = new Uint8Array(compressedBuffer);
    const base64EncodedQuery = btoa(
      uint8Array.reduce((acc, byte) => acc + String.fromCharCode(byte), "")
    );

    const clusterName = extractClusterName(kql) || DEFAULT_CULSTER_NAME;
    const databaseName = extractDatabaseName(kql) || DEFAULT_DATABASE_NAME;
    const adxUrl =
      clusterName && databaseName
        ? `https://dataexplorer.azure.com/clusters/${clusterName}/databases/${databaseName}?query=${base64EncodedQuery}`
        : `https://dataexplorer.azure.com`;

    return adxUrl;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Error to generate ADXUrl", error);
    return "";
  }
}
