import { newGuid } from "@msbabylon/core";
import {
  createApiVersionParamInterceptor,
  createClientNameHeaderInterceptor,
  createErrorHandlingInterceptor,
} from "@msbabylon/purview-util/axios";
import {
  AuthService,
  createAuthorizationHeaderInterceptor,
  getResponseData,
} from "@msbabylon/shell-core";
import axios from "axios";
import { ShellApplication } from "src/models/app";
import {
  UserRequestType,
  WorkflowAccountMergeRequestPayload,
  WorkflowQueryResult,
  WorkflowReconcileRequestPayload,
} from "src/models/workflow";
import { LoggerService } from "src/services/LoggerService";
import {
  createTrackEvent,
  createTrackUnhandledErrorEvent,
} from "src/util/axios";

const tenantHeaderName = `__TENANT_${newGuid()}`;

export class WorkflowApi {
  private readonly _apiVersion: string = "2021-03-01";
  private _generateAccountBaseUrl = (name?: string) =>
    this._application.appEnv.gatewayEndpoint.replace(
      "{0}",
      name ?? this._application.resourceName ?? ""
    );

  private readonly _axios = axios.create({
    baseURL: this._generateAccountBaseUrl(),
  });

  constructor(
    private readonly _application: ShellApplication,
    private readonly _authService: AuthService,
    private readonly _logger: LoggerService
  ) {
    const requestInterceptors = [
      createAuthorizationHeaderInterceptor((config) => {
        if (config.headers == null) {
          config.headers = {};
        }
        // It's not a user input in the object key. https://github.com/nodesecurity/eslint-plugin-security/blob/master/docs/the-dangers-of-square-bracket-notation.md
        // eslint-disable-next-line security/detect-object-injection
        const tenantId = config.headers[tenantHeaderName] as string;
        // eslint-disable-next-line security/detect-object-injection
        delete config.headers[tenantHeaderName];
        return this._authService.acquireToken({
          scopes: [this._application.appEnv.gatewayAuthResourceUri],
          tenant: tenantId || undefined,
        });
      }),
      createClientNameHeaderInterceptor(),
      createApiVersionParamInterceptor(this._apiVersion),
    ];

    this._axios.interceptors.request.use(async (config) => {
      await Promise.all(
        requestInterceptors.map((interceptor) => interceptor(config))
      );

      return config;
    });

    this._axios.interceptors.response.use(
      (resp) => resp,
      createErrorHandlingInterceptor({
        trackUnhandledErrorEvent: createTrackUnhandledErrorEvent(this._logger),
        trackEvent: createTrackEvent(this._logger),
      })
    );
  }

  public async queryWorkflow(
    type: string,
    accountName?: string,
    payload?: object
  ) {
    try {
      const response = (await getResponseData(
        this._axios.post(
          `${this._generateAccountBaseUrl(
            accountName
          )}workflow/userrequests/query-workflow`,
          {
            operations: [
              {
                type: type,
                ...(payload ?? {}),
              },
            ],
          }
        )
      )) as WorkflowQueryResult;
      const operationWorkflows = response?.workflows[0];
      return operationWorkflows !== undefined && operationWorkflows.length > 0;
    } catch (e) {
      return false;
    }
  }

  public async submitReconcileRequest(
    request: WorkflowReconcileRequestPayload
  ) {
    await getResponseData(
      this._axios.post("/workflow/userrequests", {
        operations: [
          {
            type: UserRequestType.ReconcileAccount,
            payload: request,
          },
        ],
      })
    );
  }

  public async submitAccountMergeRequest(
    request: WorkflowAccountMergeRequestPayload,
    accountName?: string,
    isSecondary?: boolean,
    assessmentOnly?: boolean,
    excludeCategories?: string[],
    autoResolveCategories?: string[]
  ) {
    await getResponseData(
      this._axios.post(
        `${this._generateAccountBaseUrl(accountName)}workflow/userrequests`,
        {
          operations: [
            {
              type: isSecondary
                ? UserRequestType.MergePurviewAccountFromSecondary
                : UserRequestType.MergePurviewAccount,
              payload: {
                secondaryAccount: request,
                assessmentOnly,
                filters: {
                  excludeCategories: excludeCategories ?? [],
                },
                conflictResolvingRules: (autoResolveCategories ?? []).map(
                  (i) => ({
                    objectCategory: i,
                    conflictResolvingStrategy: "SKIP",
                  })
                ),
              },
            },
          ],
        }
      )
    );
  }
}
