import { ExtensionLogLevel, ExtensionLogLevelName } from "@msbabylon/core";
import { EventRow } from "src/logger/EventRow";
import { ExtensionLogEvent, Logger } from "src/services/Logger/interface";

export class LoggerService {
  constructor(private readonly _logger: Logger) {}

  public logEvent(
    logLevel: ExtensionLogLevel | ExtensionLogLevelName,
    eventName: string,
    message: string,
    data?: unknown,
    isPII?: boolean
  ): void;
  public logEvent(event: ExtensionLogEvent): void;
  public logEvent(...args: unknown[]) {
    this._logger.logEvent(...args);
  }

  public flush() {
    this._logger.flush();
  }

  trackEvent(
    event: EventRow,
    level: ExtensionLogLevel = ExtensionLogLevel.Info
  ): void {
    this.logEvent(level, event.eventName, "", {
      ...event.properties,
      _buildVersion: window.getBuildVersion ? window.getBuildVersion() : "",
    });
  }
}
