export interface AccountInList {
  name: string;
  location?: string;
  friendlyName?: string;
}

export interface AccountListData {
  accounts: AccountInList[];
  skipToken?: string;
}

export interface BabylonAccount {
  id: string;
  name: string;
  location?: string;
  friendlyName?: string;
  $tenantId: string;
}

export interface TenantDefaultAccount {
  id: string;
  defaultAccountId?: string;
}

export enum PermissionType {
  DataRead = `Microsoft.Purview/accounts/data/read`,
  DataWrite = `Microsoft.Purview/accounts/data/write`,
  ScanRead = `Microsoft.Purview/accounts/scan/read`,
  ScanWrite = `Microsoft.Purview/accounts/scan/write`,
  CollectionRead = `Microsoft.Purview/accounts/collection/read`,
  CollectionWrite = `Microsoft.Purview/accounts/collection/write`,
  ShareRead = `Microsoft.Purview/accounts/share/read`,
  ShareWrite = `Microsoft.Purview/accounts/share/write`,
  InsightsRead = `Microsoft.Purview/accounts/insights/read`,
}
