import { featureConstants, rootModels } from "@msbabylon/shell-framework";
import { AxiosError } from "axios";
import { createModel, createSelector } from "nyax";
import { featureQueryPairs } from "src/features";
import { BabylonAccount, PermissionType } from "src/models/babylon";
import { defaultShellModelBuilder } from "src/store/defaultModelBuilder";
import { babylonAccountEntityModel } from "src/store/shell-models/babylon/account/entity";
import { babylonAccountHelperModel } from "src/store/shell-models/babylon/account/helper";
import { tenantDefaultAccountHelperModel } from "src/store/shell-models/babylon/defaultAccount/helper";
import { equalsCaseInsensitive } from "src/util/string";
import { isAxiosError } from "src/util/type-util";
import { tenantDefaultAccountEntityModel } from "../babylon/defaultAccount/entity";

const redirect = (() => {
  const matches = /goto=([^&]*)/i.exec(window.location.search);
  return matches ? matches[1] : "";
})();

export enum AccountValidationStatus {
  None,
  Validating,
  NoPermission,
  PupNoPermission,
  AccountProtectedByPE,
  ServiceUnavailable,
}

export const uiSharedBabylonInstanceSwitcherModel = createModel(
  class extends defaultShellModelBuilder {
    public initialState() {
      return {
        selectedTenantId: null as string | null,
        selectedInstance: null as BabylonAccount | null,
        validationStatus:
          AccountValidationStatus.None as AccountValidationStatus,
        selectedInstancePermissions: [] as string[],
        listAccountFailedReason: null as string | null,
        latestListAccountReqTimestamp: null as number | null,
      };
    }

    public selectors() {
      return {
        tenants: () =>
          this.getContainer(rootModels.azure.tenant.entity).getters.items,
        defaultAccount: createSelector(
          () => this.getContainer(tenantDefaultAccountEntityModel).state.byId,
          () => this.state.selectedTenantId,
          (byId, selectedTenantId) => {
            // eslint-disable-next-line security/detect-object-injection
            return selectedTenantId ? byId[selectedTenantId] : undefined;
          }
        ),
        instances: createSelector(
          () =>
            this.getContainer(babylonAccountEntityModel).getters.sortedItems,
          () => this.state.selectedTenantId,
          (items, selectedTenantId) =>
            items.filter((e) => e.$tenantId === selectedTenantId)
        ),
        isTenantsLoading: createSelector(
          () =>
            this.getContainer(rootModels.azure.tenant.helper).state._rwAll
              .readingTimestampById,
          (reading) => Object.entries(reading).some((e) => e[1])
        ),
        isInstancesLoading: createSelector(
          () =>
            this.getContainer(babylonAccountHelperModel).state
              ._rwBySubscriptionId.readingTimestampById,
          () =>
            this.getContainer(tenantDefaultAccountHelperModel).state._rw
              .readingTimestampById,
          (instanceReading, defaultAccountReading) =>
            Object.entries(instanceReading).some((e) => e[1]) ||
            Object.entries(defaultAccountReading).some((e) => e[1])
        ),
        canSwitch: () =>
          !!(
            this.state.selectedTenantId &&
            this.state.selectedInstance &&
            this.state.validationStatus === AccountValidationStatus.None
          ),
      };
    }

    public reducers() {
      return {
        setSelectTenantId: (value: string) => {
          this.state.selectedTenantId = value;
        },
        setSelectInstance: (value: BabylonAccount | null) => {
          this.state.selectedInstance = value;
        },
        setValidationStatus: (value: AccountValidationStatus) => {
          this.state.validationStatus = value;
        },
        setSelectInstancePermissions: (value: string[]) => {
          this.state.selectedInstancePermissions = value;
        },
        setlistAccountFailedReason: (
          listAccountFailedReason: string | null
        ) => {
          this.state.listAccountFailedReason = listAccountFailedReason;
        },
        setLatestListAccountReqTimestamp: (
          latestListAccountReqTimestamp: number
        ) => {
          this.state.latestListAccountReqTimestamp =
            latestListAccountReqTimestamp;
        },
      };
    }

    public effects() {
      return {
        selectTenantId: async (value: string): Promise<void> => {
          if (this.state.selectedTenantId !== value) {
            await this.actions.setSelectTenantId.dispatch(value);
            await this.actions.setSelectInstance.dispatch(null);
            await this.actions.setValidationStatus.dispatch(
              AccountValidationStatus.None
            );

            const reqTimestamp = new Date().getTime();
            await this.actions.setLatestListAccountReqTimestamp.dispatch(
              reqTimestamp
            );

            const [accoutListResult, defaultAccountResult] =
              await Promise.allSettled([
                this.getContainer(
                  babylonAccountHelperModel
                ).actions.readFromGateway.dispatch({ tenantId: value }),
                this.getContainer(
                  tenantDefaultAccountHelperModel
                ).actions.batchRead.dispatch({ tenantIds: [value] }),
              ]);

            // Check whether tenant is already changed
            if (this.state.latestListAccountReqTimestamp === reqTimestamp) {
              if (accoutListResult.status === "rejected") {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const axiosError = accoutListResult.reason as AxiosError<any>;
                await this.actions.setlistAccountFailedReason.dispatch(
                  axiosError.response?.data?.error?.message ||
                    axiosError.response?.data?.errorMessage ||
                    axiosError.message
                );
                throw accoutListResult.reason;
              } else {
                this.actions.setlistAccountFailedReason.dispatch(null);
              }

              if (defaultAccountResult.status === "rejected") {
                throw defaultAccountResult.reason;
              }
            }
          }
        },
        selectInstance: async (value: BabylonAccount) => {
          await this.actions.setValidationStatus.dispatch(
            AccountValidationStatus.Validating
          );

          const [validationResult, tenantAccountName] = await Promise.all([
            this.actions.validateAccount.dispatch(value),
            this.actions.validateTenantAccount.dispatch(value),
          ]);
          const byPassPermissionCheck = equalsCaseInsensitive(
            tenantAccountName,
            value.name
          );
          if (byPassPermissionCheck) {
            await this.actions.setValidationStatus.dispatch(
              AccountValidationStatus.None
            );
          } else {
            await this.actions.setValidationStatus.dispatch(validationResult);
          }
        },
        validateTenantAccount: async (
          value: BabylonAccount
        ): Promise<string | undefined> => {
          try {
            const tenantAccount =
              await this.dependencies.babylonApi.checkTenant(value);
            return tenantAccount?.provisionedAccountProperties?.accountName;
          } catch (error) {
            return;
          }
        },
        validateAccount: async (
          value: BabylonAccount
        ): Promise<AccountValidationStatus> => {
          let validationResult = AccountValidationStatus.None;

          if (this.state.selectedInstance !== value) {
            await this.actions.setSelectInstance.dispatch(value);
            try {
              const permissionResult =
                await this.dependencies.babylonApi.getSpecifiedInstancePermission(
                  value.name,
                  value.$tenantId
                );
              this.actions.setSelectInstancePermissions.dispatch(
                permissionResult.permissions
              );
              if (
                !(
                  permissionResult.permissions.includes(
                    PermissionType.DataRead
                  ) ||
                  permissionResult.permissions.includes(
                    PermissionType.CollectionRead
                  ) ||
                  permissionResult.permissions.includes(
                    PermissionType.ShareRead
                  )
                )
              ) {
                validationResult = AccountValidationStatus.NoPermission;
              }
            } catch (error) {
              validationResult = AccountValidationStatus.NoPermission;
              if (isAxiosError(error)) {
                const responseCode = error.response?.status || 0;
                if (responseCode >= 500) {
                  validationResult = AccountValidationStatus.ServiceUnavailable;
                }

                const responseErrorCode = error.response?.data?.error?.code;
                switch (responseErrorCode) {
                  case "AccountProtectedByPrivateEndpoint":
                    validationResult =
                      AccountValidationStatus.AccountProtectedByPE;
                    break;
                }
              }
            }

            if (this.state.selectedInstance?.name === value.name) {
              return validationResult;
            }
          }

          return validationResult;
        },
        switch: async (): Promise<void> => {
          if (this.getters.canSwitch) {
            const tenantParamName = `${featureConstants.featurePrefix}${featureConstants.tenant}`;
            const queryPairs = featureQueryPairs.filter(
              (e) => e[0] !== tenantParamName
            );
            queryPairs.push([tenantParamName, this.state.selectedTenantId!]);
            const query = queryPairs.map((e) => e.join("=")).join("&");
            if (
              // has data read permission
              this.state.selectedInstancePermissions.includes(
                PermissionType.DataRead
              )
            ) {
              window.location.href =
                window.location.origin +
                "/resource/" +
                this.state.selectedInstance?.name +
                `/${redirect}?` +
                query;
            } else if (
              // has share read permission
              this.state.selectedInstancePermissions.includes(
                PermissionType.ShareRead
              )
            ) {
              window.location.href =
                window.location.origin +
                "/resource/" +
                this.state.selectedInstance?.name +
                "/main/datashare/overview?" +
                query;
            } else {
              // has collection write action
              window.location.href =
                window.location.origin +
                "/resource/" +
                this.state.selectedInstance?.name +
                "/main/datasource/collections?" +
                query;
            }
          }
        },
      };
    }
  }
);
