export type AccountMergeStatus =
  | "MergeStart"
  | "MergeEnd"
  | "AssessmentStart"
  | "AssessmentEnd"
  | "AssessmentEndWithoutConflicts"
  | "AssessmentEndWithConflicts"
  | "AssessmentFailed"
  | "MigrationStart"
  | "MigrationEnd"
  | "MigrationFailed"
  | "CleanUpStart"
  | "CleanUpEnd"
  | "CleanUpFailed"
  | "PostMigrationStart"
  | "PostMigrationEnd"
  | "PostMigrationFailed";

export interface AccountMergeInfo {
  status: AccountMergeStatus;
  secondaryAccountName: string;
  assessmentOnly?: boolean;
}

export const isMergeStatusSucceeded = (status?: AccountMergeStatus) => {
  return status === "MergeEnd";
};

export const isMergeStatusInProgress = (status?: AccountMergeStatus) => {
  return (
    status === "MergeStart" ||
    status === "AssessmentStart" ||
    status === "AssessmentEnd" ||
    status === "AssessmentEndWithoutConflicts" ||
    status === "MigrationStart" ||
    status === "MigrationEnd" ||
    status === "CleanUpStart" ||
    status === "PostMigrationStart" ||
    status === "PostMigrationEnd"
  );
};
