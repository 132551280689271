/** @jsxImportSource @emotion/react */
import { LabelWrapper, SelectOption } from "@adux/common-react";
import { css } from "@emotion/react";
import { Link } from "@fluentui/react";
import {
  useDependencies,
  useGetContainer,
  useUIView,
} from "@msbabylon/shell-core";
import {
  ShellExtensionUIComponentProps,
  extensionConstants,
  languages,
  rootModels,
} from "@msbabylon/shell-framework";
import React, { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import AppPanel from "src/components/AppPanel";
import AppSelect from "src/components/AppSelect";
import { uiViewPanelSettingsModel } from "src/store/shell-models/ui/view/UIPanelSettings";
import messageIds from "../locales/messageIds";

export default React.memo<ShellExtensionUIComponentProps>(
  function UIPanelSettings(props) {
    const dependencies = useDependencies();
    const { uiId } = props;
    const intl = useIntl();
    const getContainer = useGetContainer();
    const uiView = getContainer(uiViewPanelSettingsModel, uiId);
    const uiItemHelper = getContainer(rootModels.ui.item.helperByUIId, uiId);
    useUIView(uiView, uiItemHelper);
    const themeName = useSelector(() => uiView.getters.theme);
    const language = useSelector(() => uiView.getters.language);
    const uiPanelListCommonHelper = getContainer(
      rootModels.ui.list.panel.commonHelper
    );

    const okEnabled = useSelector(() => uiView.state.isDirty);

    const onThemeNameChange = useCallback(
      (value: "light" | "dark") => {
        if (value) {
          uiView.actions.setTheme.dispatch(value);
        }
      },
      [uiView]
    );
    const themeNameOptions = useMemo(
      () => [
        {
          value: "light",
          label: intl.formatMessage({ id: messageIds.settings.theme.light }),
        },
        {
          value: "dark",
          label: intl.formatMessage({ id: messageIds.settings.theme.dark }),
        },
      ],
      [intl]
    );

    const onLanguageChange = useCallback(
      (value: string) => {
        if (value) {
          uiView.actions.setLanguage.dispatch(value);
        }
      },
      [uiView]
    );

    const onOk = useCallback(async () => {
      await uiView.actions.apply.dispatch({});
      uiPanelListCommonHelper.actions.closeUntil.dispatch({});
    }, [uiPanelListCommonHelper, uiView]);

    const onCancel = useCallback(async () => {
      await uiPanelListCommonHelper.actions.closeUntil.dispatch({});
    }, [uiPanelListCommonHelper]);

    const langOptions = useMemo<SelectOption<string>[]>(() => {
      return Object.keys(languages)
        .map((key) => ({
          value: key,
          // It's not a user input in the object key. https://github.com/nodesecurity/eslint-plugin-security/blob/master/docs/the-dangers-of-square-bracket-notation.md
          // eslint-disable-next-line @typescript-eslint/no-explicit-any,security/detect-object-injection
          label: (languages as any)[key] as string,
        }))
        .reduce<SelectOption<string>[]>((prev, cur) => {
          prev.push(cur);
          return prev;
        }, []);
    }, []);

    const onSwitchInstanceClick = useCallback(() => {
      uiPanelListCommonHelper.actions.replace.dispatch({
        extensionName: extensionConstants.shell,
        component: {
          type: "panelInstance",
          params: {},
        },
      });
    }, [uiPanelListCommonHelper.actions.replace]);

    return (
      <AppPanel
        onNext={onOk}
        isNextDisabled={!okEnabled}
        labelNext={intl.formatMessage({
          id: messageIds.settings.language.apply,
        })}
        onCancel={onCancel}
      >
        <div
          css={css`
            flex: 1 1 0;
          `}
        >
          <div>
            <span>
              {intl.formatMessage({
                id: messageIds.settings.switchInstanceDescription,
              })}
            </span>
            <span> </span>
            <span>
              <Link onClick={onSwitchInstanceClick} underline={true}>
                {intl.formatMessage({ id: messageIds.shared.clickHere })}
              </Link>
            </span>
          </div>
          {dependencies.application.features.next && (
            <LabelWrapper
              label={intl.formatMessage({
                id: messageIds.settings.theme.$label,
              })}
              css={css`
                margin-top: 10px;
              `}
            >
              <AppSelect
                options={themeNameOptions}
                value={themeName}
                onValueChange={onThemeNameChange}
                ariaLabel={intl.formatMessage({
                  id: messageIds.settings.theme.$label,
                })}
              />
            </LabelWrapper>
          )}

          <LabelWrapper
            label={intl.formatMessage({
              id: messageIds.settings.language.$label,
            })}
            css={css`
              margin-top: 10px;
            `}
          >
            <AppSelect
              options={langOptions}
              value={language}
              onValueChange={onLanguageChange}
              ariaLabel={intl.formatMessage({
                id: messageIds.settings.language.$label,
              })}
            />
          </LabelWrapper>
        </div>
      </AppPanel>
    );
  }
);
