/** @jsxImportSource @emotion/react */
import { FabricDefaultButton, FabricPrimaryButton } from "@adux/common-react";
import { css } from "@emotion/react";
import { Link, Modal, Spinner, SpinnerSize } from "@fluentui/react";
import React, { useCallback, useState } from "react";
import { useIntl } from "react-intl";
import messageIds from "src/locales/messageIds";

export default React.memo<{
  isOpen?: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}>(({ isOpen, onConfirm, onCancel }) => {
  const intl = useIntl();
  const [isProcessing, setIsProcessing] = useState(false);

  const onClickConfirm = useCallback(() => {
    setIsProcessing(true);
    onConfirm();
  }, [onConfirm]);
  const onClickCancel = useCallback(() => {
    onCancel();
  }, [onCancel]);

  return (
    <Modal
      isOpen={isOpen}
      styles={{
        main: {
          padding: 32,
          borderRadius: 12,
          maxWidth: 774,
        },
      }}
      isModeless={true}
    >
      <div
        css={css`
          font-weight: 600;
          font-size: 36px;
          line-height: 48px;
        `}
      >
        {intl.formatMessage({
          id: messageIds.reconcile.confirmBasePlan.title,
        })}
      </div>
      <div
        css={css`
          padding: 24px 0 32px;
        `}
      >
        <span>
          {intl.formatMessage({
            id: messageIds.reconcile.confirmBasePlan.message,
          })}
          &nbsp;
        </span>
        <Link
          href="https://go.microsoft.com/fwlink/?LinkId=2249060"
          target="_blank"
        >
          {intl.formatMessage({ id: messageIds.shared.learnMore })}
        </Link>
      </div>
      <div
        css={css`
          display: flex;
          gap: 8px;
        `}
      >
        <FabricPrimaryButton
          onClick={onClickConfirm}
          disabled={isProcessing}
          styles={{
            root: {
              outlineOffset: "-1px",
            },
          }}
        >
          {isProcessing && (
            <Spinner
              size={SpinnerSize.small}
              css={css`
                margin-right: 4px;
              `}
            />
          )}
          {intl.formatMessage({ id: messageIds.shared.confirm })}
        </FabricPrimaryButton>
        <FabricDefaultButton onClick={onClickCancel} disabled={isProcessing}>
          {intl.formatMessage({ id: messageIds.shared.cancel })}
        </FabricDefaultButton>
      </div>
    </Modal>
  );
});
