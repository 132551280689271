import { ShellDependencies } from "src/dependencies";
import { isEmptyOrNull } from "src/util/string";

export function redirectToLandingPage(
  path: string,
  dependencies: Omit<ShellDependencies, "role">
) {
  const urlFeature = dependencies.urlFeature.getFeatureQuery();
  const features = !isEmptyOrNull(urlFeature) ? urlFeature : undefined;
  const url = new URL(
    window.location.origin + path + (features ? `?${features}` : "")
  );
  window.location.href = url.toString();
}
