import { ExtensionLogLevel } from "@msbabylon/core";
import { AppError } from "@msbabylon/shell-core";
import { ErrorInfo } from "@msbabylon/shell-framework";
import React, { useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { useDependencies } from "src/hook";
import messageIds from "src/locales/messageIds";

export const buildAppError = () => {
  return React.memo(function (props: {
    className?: string | undefined;
    errorInfo: ErrorInfo;
  }) {
    const { className, errorInfo } = props;

    const deps = useDependencies();
    const intl = useIntl();

    useEffect(() => {
      deps.logger.logEvent(
        ExtensionLogLevel.Critical,
        "ProductError",
        errorInfo.title ?? "",
        {
          type: errorInfo.type,
          messages: errorInfo.message,
          details: errorInfo.details,
          subTitles: errorInfo.subtitles,
        }
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const mergedErrorInfo = useMemo(() => {
      if (errorInfo.type == null) {
        if (errorInfo.details != null) {
          // tenant id invalid error
          if (errorInfo.details.indexOf("replaceTenant") >= 0) {
            return {
              ...errorInfo,
              title: intl.formatMessage({
                id: messageIds.errors.invalidTenant.$title,
              }),
              message: intl.formatMessage(
                {
                  id: messageIds.errors.invalidTenant.$message,
                },
                { tenant: deps.application.features.tenant }
              ),
            };
          }
        }
        return errorInfo;
      }
      return errorInfo;
    }, [deps, errorInfo, intl]);

    return (
      <AppError className={className} errorInfo={mergedErrorInfo}></AppError>
    );
  });
};
