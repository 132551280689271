import { ExtensionLogLevel } from "@msbabylon/core";
import axios from "axios";
import { ApiFailureEvent } from "src/logger/ApiFailureEvent";
import {
  UnhandledErrorEvent,
  UnhandledErrors,
} from "src/logger/UnhandledErrorEvent";
import type { LoggerService } from "src/services/LoggerService";

const AUTHORIZATION_HEADER = "Authorization";

export function removeAxiosErrorAuthorization(err: Error) {
  if (axios.isAxiosError(err)) {
    // eslint-disable-next-line security/detect-object-injection
    delete err.response?.headers?.[AUTHORIZATION_HEADER];
  }
  return err;
}

export function createTrackUnhandledErrorEvent(logger: LoggerService) {
  return (errorMessage: string) => {
    const event = UnhandledErrors.errorConfigEmpty(undefined, errorMessage);
    logger.trackEvent(
      new UnhandledErrorEvent(event.source, event.error, event.extra)
    );
  };
}

export function createTrackEvent(logger: LoggerService) {
  return (
    url: string,
    httpStatus: string | number,
    message: string,
    details: string
  ) => {
    logger.trackEvent(
      new ApiFailureEvent(url, httpStatus, message, details),
      ExtensionLogLevel.Warning
    );
  };
}
