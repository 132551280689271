import { createModel } from "nyax";
import { defaultShellModelBuilder } from "src/store/defaultModelBuilder";

interface ReleaseNotesInternal {
  title: string;
  link?: string;
  description: string;
  date: Date;
}

export const releaseNotesModel = createModel(
  class extends defaultShellModelBuilder {
    public initialState() {
      return {
        releaseNotes: [] as ReleaseNotesInternal[],
        readReleaseNotesCount: NaN,
      };
    }

    public selectors() {
      return {};
    }

    public reducers() {
      return {
        setReleaseNotes: (value: ReleaseNotesInternal[]) => {
          this.state.releaseNotes = value;
        },
        setReadReleaseNotesCount: (value: number) => {
          this.state.readReleaseNotesCount = value;
        },
      };
    }

    public effects() {
      return {
        readReleaseNotes: async () => {
          try {
            const language = this.dependencies.locale.languageName;
            const notesResponse = await this.dependencies.releaseNotesApi.getReleaseNotes(
              language
            );
            const readCount = await this.dependencies.releaseNotesApi.getReadReleaseNotesCount();

            const notes = notesResponse
              .map((v) => ({
                title: v.title,
                link: v.link,
                description: v.description,
                date: new Date(v.date),
              }))
              .sort((a, b) => {
                if (a.date > b.date) {
                  return -1;
                } else if (a.date < b.date) {
                  return 1;
                } else {
                  return 0;
                }
              });
            await this.actions.setReadReleaseNotesCount.dispatch(readCount);
            await this.actions.setReleaseNotes.dispatch(notes);
            return notes;
          } catch {
            // swallow the exception, release notes should not block the prod.
            return [];
          }
        },
        setReadReleaseNotes: async () => {
          await this.actions.setReadReleaseNotesCount.dispatch(
            this.state.releaseNotes.length
          );
          await this.dependencies.releaseNotesApi.setReadReleaseNotesCount(
            this.state.releaseNotes.length
          );
        },
      };
    }
  }
);
