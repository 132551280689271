import { createUIViewModel } from "@msbabylon/shell-core";
import { createModel, mergeModels, mergeSubModels } from "nyax";
import messageIds from "src/locales/messageIds";
import { babylonInstanceModel } from "src/store/shell-models/babylon/instance";
import { uiSharedBabylonInstanceSwitcherModel } from "src/store/shell-models/ui/SharedBabylonInstanceSwitcher";

export const uiViewPanelInstanceModel = createModel(
  class extends mergeModels(
    createUIViewModel(),
    mergeSubModels({ instanceSwitcher: uiSharedBabylonInstanceSwitcherModel })
  ) {
    public selectors() {
      return {
        ...super.selectors(),
        title: () =>
          this.dependencies.intl.formatMessage({
            id: messageIds.instance.$title,
          }),
        azureAccountInstance: () =>
          this.getContainer(babylonInstanceModel).state.azureAccountInstance,
      };
    }
  },
  {
    isLazy: true,
    isDynamic: true,
  }
);
