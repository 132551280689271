/** @jsxImportSource @emotion/react */
import { HyperLink } from "@adux/common-react";
import { css } from "@emotion/react";
import { MessageBarType } from "@fluentui/react";
import { memo, useCallback, useMemo, useState } from "react";
import { useIntl as useIntlOrigin } from "react-intl";
import AppMessageBar from "src/components/AppMessageBar";
import messageIds from "src/locales/messageIds";

const messageFadeTime = 500; // ms

export default memo(() => {
  const intl = useIntlOrigin();
  const [visible, setVisible] = useState(true);
  const [removed, setRemoved] = useState(false);
  const { intlContactSupport, intlNeedHelp } = useMemo(
    () => ({
      intlNeedHelp: intl.formatMessage({
        id: messageIds.feedback.needHelp,
      }),
      intlContactSupport: intl.formatMessage({
        id: messageIds.feedback.contactSupport,
      }),
    }),
    [intl]
  );
  const handleDismiss = useCallback(() => {
    setVisible(false);
    setTimeout(() => setRemoved(true), messageFadeTime);
  }, []);

  return removed ? null : (
    <AppMessageBar
      css={css`
        opacity: ${visible ? 1 : 0};
        transition: opacity ${messageFadeTime / 1000}s;
        margin-bottom: 16px;
      `}
      onDismiss={handleDismiss}
      messageBarType={MessageBarType.info}
    >
      {intlNeedHelp}{" "}
      <HyperLink
        href="https://go.microsoft.com/fwlink/?linkid=2147793"
        externalLink={true}
        underline={true}
      >
        {intlContactSupport}
      </HyperLink>
    </AppMessageBar>
  );
});
