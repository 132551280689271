/** @jsxImportSource @emotion/react */
import { Checkbox, SvgIcon, TextBox } from "@adux/common-react";
import { css } from "@emotion/react";
import { Link } from "@fluentui/react";
import { Container } from "nyax";
import { Fragment, memo, useCallback, useMemo } from "react";
import { useIntl as useIntlOrigin } from "react-intl";
import { useSelector } from "react-redux";
import IconRedirect from "src/assets/icon-redirect.svg";
import Block from "src/components/Block";
import messageIds from "src/locales/messageIds";
import { uiViewPanelFeedbackModel } from "src/store/shell-models/ui/view/UIPanelFeedback";

const checkboxStyles = {
  text: {
    lineHeight: 18,
  },
  checkbox: {
    height: 18,
    width: 18,
  },
};

function useIntl() {
  const intl = useIntlOrigin();
  return useMemo(
    () => ({
      intlEmailMe: intl.formatMessage({
        id: messageIds.feedback.emailMe,
      }),
      intlPrivacyPolicy: intl.formatMessage({
        id: messageIds.feedback.privacyPolicy,
      }),
      intlEmailMePlaceholder: intl.formatMessage({
        id: messageIds.feedback.emailMePlaceholder,
      }),
    }),
    [intl]
  );
}

export default memo(
  ({ uiView }: { uiView: Container<typeof uiViewPanelFeedbackModel> }) => {
    const { intlEmailMe, intlPrivacyPolicy, intlEmailMePlaceholder } =
      useIntl();
    const email = useSelector(() => uiView.state.email);
    const handleChangeEmail = useCallback(
      (emailAddr: string) => {
        uiView.actions.setEmail.dispatch(emailAddr);
      },
      [uiView.actions.setEmail]
    );

    const emailMe = useSelector(() => uiView.state.emailMe);
    const handleChangeEmailMe = useCallback(
      (_, checked: boolean | undefined) => {
        if (checked !== undefined) {
          uiView.actions.setEmailMe.dispatch(checked);
        }
      },
      [uiView.actions.setEmailMe]
    );

    const checkboxLabel = useCallback(() => {
      return (
        <span>
          {intlEmailMe}
          &nbsp;
          <Link
            css={css`
              display: inline-flex;
            `}
            href="https://go.microsoft.com/fwlink/?linkid=2147794"
            target="_blank"
            underline={true}
          >
            {intlPrivacyPolicy}&nbsp;
            <SvgIcon src={IconRedirect} altTitle={intlPrivacyPolicy} />
          </Link>
        </span>
      );
    }, [intlEmailMe, intlPrivacyPolicy]);

    return (
      <Fragment>
        <div
          css={css`
            display: flex;
          `}
        >
          <Checkbox
            onRenderLabel={checkboxLabel}
            styles={checkboxStyles}
            checked={emailMe}
            onChange={handleChangeEmailMe}
          />
        </div>
        <Block h={9} />
        <TextBox
          disabled={!emailMe}
          value={email}
          onValueChange={handleChangeEmail}
          placeholder={intlEmailMePlaceholder}
          autoComplete={!email ? "nope" : "email"}
        />
      </Fragment>
    );
  }
);
