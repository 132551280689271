/** @jsxImportSource @emotion/react */
import { AnnouncementPopover, useTheme } from "@adux/common-react";
import React, { useCallback, useState } from "react";
import { useIntl } from "react-intl";
import IconNewPortal from "src/assets/icon-new-portal.svg";
import AppHyperLink from "src/components/AppHyperLink";
import { productDocumentations } from "src/constants/documentationLinks";
import messageIds from "src/locales/messageIds";

export default React.memo<{
  onClose: () => void;
  onSubmit: () => Promise<void>;
  canUpgrade: boolean;
  isSingleAccount?: boolean;
}>(({ onClose, onSubmit, canUpgrade, isSingleAccount }) => {
  const theme = useTheme();
  const intl = useIntl();

  const [submitting, setSubmitting] = useState(false);
  const onClickSubmit = useCallback(async () => {
    setSubmitting(true);
    await onSubmit();
    setSubmitting(false);
  }, [onSubmit]);

  return (
    <AnnouncementPopover
      headline={intl.formatMessage({
        id: messageIds.reconcile.releaseNewPortal,
      })}
      open={true}
      onDismiss={onClose}
      svgIconProps={{
        src: IconNewPortal,
      }}
      primaryButtonProps={{
        text: intl.formatMessage({
          id: canUpgrade
            ? isSingleAccount
              ? messageIds.reconcile.upgradeAccount
              : messageIds.reconcile.selectAccount
            : messageIds.reconcile.submitRequest,
        }),
        onClick: onClickSubmit,
        disabled: submitting,
      }}
      secondaryButtonProps={{
        text: intl.formatMessage({
          id: messageIds.reconcile.notNow,
        }),
        onClick: onClose,
      }}
      strings={{ close: intl.formatMessage({ id: messageIds.shared.close }) }}
      styles={{
        imageContainer: {
          justifyContent: "flex-end",
        },
      }}
    >
      {intl.formatMessage(
        {
          id: canUpgrade
            ? messageIds.reconcile.$selectReconcileAccountMessage
            : messageIds.reconcile.$submitRequestMessage,
        },
        {
          learnMore: (
            <AppHyperLink
              externalLink={true}
              styles={{
                root: {
                  color: theme.palette.white,
                  selectors: {
                    ":hover": {
                      color: theme.palette.white,
                    },
                  },
                },
              }}
              href={productDocumentations.upgradeForUnifiedPortal}
            >
              {intl.formatMessage({ id: messageIds.shared.learnMore })}
            </AppHyperLink>
          ),
        }
      )}
    </AnnouncementPopover>
  );
});
