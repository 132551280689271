/** @jsxImportSource @emotion/react */
import React from "react";
import { ICopilotMessage } from "src/apis/CopilotApi/interface";
import AssistantMessage from "src/containers/Copilot/AppCopilotMessage/AssistantMessage";
import SystemMessage from "src/containers/Copilot/AppCopilotMessage/SystemMessage";
import UserMessage from "src/containers/Copilot/AppCopilotMessage/UserMessage";

interface AppCopilotMessageProps {
  message: ICopilotMessage;
}
export default React.memo(function AppCopilotMessage(
  props: AppCopilotMessageProps
) {
  const { message } = props;

  if (!message.content) {
    return null;
  }

  return (
    <>
      {message.role === "User" && <UserMessage text={message.content} />}
      {message.role === "System" && <SystemMessage text={message.content} />}
      {message.role === "Assistant" && (
        <AssistantMessage
          text={message.content}
          messageId={message.messageId}
          isCompleted={message.isCompleted}
          references={message.references}
        />
      )}
    </>
  );
});
