/** @jsxImportSource @emotion/react */
import { useFonts } from "@adux/common-react";
import { css } from "@emotion/react";
import { Icon } from "@fluentui/react";
import React from "react";
import { useIntl } from "react-intl";
import messageIds from "src/locales/messageIds";

export default React.memo(function AppCopilotHeader() {
  const cssTypography = useFonts();
  const intl = useIntl();

  return (
    <div
      css={css`
        display: flex;
        flex-direction: row;
        justify-content: center;
      `}
    >
      <Icon
        iconName="Robot"
        css={css`
          display: flex;
          align-items: center;
          width: 36px;
          height: 36px;
          font-size: 24px;
        `}
      />
      <span
        css={[
          css`
            display: flex;
            align-items: center;
            justify-content: center;
          `,
          cssTypography.subtitle1,
        ]}
      >
        {intl.formatMessage({
          id: messageIds.copilot.header.title,
        })}
      </span>
    </div>
  );
});
