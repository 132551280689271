/** @jsxImportSource @emotion/react */
import { cssTypography, useTheme } from "@adux/common-react";
import { css } from "@emotion/react";
import { useGetContainer, useUIView } from "@msbabylon/shell-core";
import {
  parseAzureResourceId,
  rootModels,
  ShellExtensionUIComponentProps,
} from "@msbabylon/shell-framework";
import React, { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import AppPanel from "src/components/AppPanel";
import messageIds from "src/locales/messageIds";
import { uiViewPanelDiagnosticsModel } from "src/store/shell-models/ui/view/UIPanelDiagnostics";

export default React.memo<ShellExtensionUIComponentProps>(
  function UIPanelDiagnostics(props) {
    const { uiId } = props;

    const intl = useIntl();
    const theme = useTheme();

    const getContainer = useGetContainer();

    const uiView = getContainer(uiViewPanelDiagnosticsModel, uiId);
    const uiItemHelper = getContainer(rootModels.ui.item.helperByUIId, uiId);
    useUIView(uiView, uiItemHelper);

    const uiPanelListCommonHelper = getContainer(
      rootModels.ui.list.panel.commonHelper
    );

    const currentInstance = useSelector(
      () => uiView.getters.azureAccountInstance
    );
    const currentInstanceInfo = useMemo(
      () => parseAzureResourceId(currentInstance?.id ?? ""),
      [currentInstance]
    );

    const onCloseClick = useCallback(async () => {
      await uiPanelListCommonHelper.actions.closeUntil.dispatch({});
    }, [uiPanelListCommonHelper]);

    const cssLabel = css`
      margin-bottom: 10px;
      color: ${theme.palette.neutralSecondary};

      ${cssTypography.bodyText};
    `;
    const cssValue = css`
      margin-bottom: 20px;
      color: ${theme.palette.neutralPrimary};

      ${cssTypography.tooltipText};
    `;

    return (
      <AppPanel
        labelNext={intl.formatMessage({
          id: messageIds.shared.close,
        })}
        onNext={onCloseClick}
      >
        {currentInstance && (
          <div
            css={css`
              display: flex;
              flex-direction: column;
              margin-bottom: 20px;
            `}
          >
            <div css={cssLabel}>
              {intl.formatMessage({
                id: messageIds.diagnostics.instanceName,
              })}
            </div>
            <div css={cssValue}>{currentInstance.name}</div>

            <div css={cssLabel}>
              {intl.formatMessage({ id: messageIds.instance.region })}
            </div>
            <div css={cssValue}>{currentInstance.location}</div>

            <div css={cssLabel}>
              {intl.formatMessage({
                id: messageIds.instance.resourceGroup,
              })}
            </div>
            <div css={cssValue}>{currentInstanceInfo.resourceGroup}</div>

            <div css={cssLabel}>
              {intl.formatMessage({
                id: messageIds.instance.subscriptionId,
              })}
            </div>
            <div css={cssValue}>{currentInstanceInfo.subscriptionId}</div>
          </div>
        )}
      </AppPanel>
    );
  }
);
