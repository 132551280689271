/** @jsxImportSource @emotion/react */
import { useTheme } from "@adux/common-react";
import { css } from "@emotion/react";
import { memo, PropsWithChildren } from "react";

export interface ToastStyleGuardProps {
  className?: string;
}

export const ToastStyleGuard = memo(
  ({ className, children }: PropsWithChildren<ToastStyleGuardProps>) => {
    const theme = useTheme();
    return (
      <div
        css={css`
          color: ${theme.semanticColors.bodyText};
        `}
        className={className}
      >
        {children}
      </div>
    );
  }
);
