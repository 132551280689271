/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import {
  DirectionalHint,
  IButtonProps,
  PrimaryButton,
  TeachingBubble,
} from "@fluentui/react";
import { useGetContainer } from "@msbabylon/shell-core";
import React, { useCallback, useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import AppCopiloadLoading from "src/containers/Copilot/AppCopiloadLoading";
import AppCopilotHeader from "src/containers/Copilot/AppCopilotHeader";
import AppCopilotInput from "src/containers/Copilot/AppCopilotInput";
import AppCopilotMessages from "src/containers/Copilot/AppCopilotMessages";
import { getFeatures } from "src/features";
import { useDependencies } from "src/hook";
import messageIds from "src/locales/messageIds";
import { uiCopilotModel } from "src/store/shell-models/ui/copilot";

const ScriptsButtons = React.memo(function AppCopilotScriptsButtons(props) {
  const getContainer = useGetContainer();
  const copilot = getContainer(uiCopilotModel);

  const setScriptModeKey = useCallback(
    (key: string | null) => () => {
      copilot.actions.scriptsDomain.setScriptMessageKey.dispatch(key);
    },
    [copilot.actions.scriptsDomain.setScriptMessageKey]
  );

  return (
    <div
      css={css`
        display: flex;
        gap: 4px;
        justify-content: flex-start;
      `}
    >
      <PrimaryButton onClick={setScriptModeKey("scenario#2")}>
        scenario#2
      </PrimaryButton>
      <PrimaryButton onClick={setScriptModeKey("scenario#3")}>
        scenario#3
      </PrimaryButton>
      <PrimaryButton
        css={css`
          margin-left: auto;
        `}
        onClick={setScriptModeKey(null)}
        content="scenario#3"
      >
        Chat
      </PrimaryButton>
    </div>
  );
});

const AppCopilot = React.memo(function AppSidebarPage(props) {
  const intl = useIntl();
  const dependencies = useDependencies();
  const getContainer = useGetContainer();
  const copilot = getContainer(uiCopilotModel);

  const visible = useSelector(() => copilot.state.visible);
  const isLoading = useSelector(() => copilot.getters.isLoading);
  const messages = useSelector(() => copilot.getters.allMessages);
  const actionSuggestions = useSelector(() => copilot.state.actionSuggestions);
  const textSuggestions = useSelector(() => copilot.state.currentSuggestions);

  const bubbleData = useSelector(() => copilot.state.bubbleData);
  const bubbleTargetId = useSelector(() => copilot.state.targetId);

  const enableScripts = useMemo(() => getFeatures().enableCopilotScripts, []);
  const scriptMessageKey = useSelector(
    () => copilot.state.scriptsDomain.scriptMessageKey
  );
  const scriptMessages = useSelector(
    () => copilot.getters.scriptsDomain.scriptMessages
  );

  const isECEnabled = useSelector(
    () => dependencies.application.features.enableCopilot
  );

  useEffect(() => {
    enableScripts &&
      copilot.actions.scriptsDomain.initScriptMessages.dispatch({});
  }, [copilot.actions.scriptsDomain.initScriptMessages, enableScripts]);

  const hideBubble = useCallback(() => {
    copilot.actions.triggerBubble.dispatch(null);
  }, [copilot.actions.triggerBubble]);

  const onClickConfirm = useCallback(() => {
    copilot.actions.setVisible.dispatch(true);
  }, [copilot.actions.setVisible]);

  const buttonProps: IButtonProps = useMemo(() => {
    return {
      text: intl.formatMessage({
        id: messageIds.copilot.bubble.tryIt,
      }),
      iconProps: {
        iconName: "Send",
      },
      onClick: onClickConfirm,
    };
  }, [intl, onClickConfirm]);

  if (!isECEnabled) {
    return <></>;
  }

  return (
    <>
      {!visible && bubbleData && (
        <TeachingBubble
          target={`#${bubbleTargetId}`}
          calloutProps={{
            directionalHint: DirectionalHint.bottomCenter,
            gapSpace: 0,
            preventDismissOnEvent: () => true,
          }}
          hasCloseButton
          primaryButtonProps={buttonProps}
          onDismiss={hideBubble}
          styles={{
            bodyContent: {
              padding: "10px 12px",
            },
            body: {
              marginBottom: "12px !important",
            },
            closeButton: {
              margin: "4px 4px 0px 0px",
            },
          }}
        >
          {bubbleData.description}
        </TeachingBubble>
      )}
      {visible ? (
        <div
          css={css`
            width: 424px;
            padding: 12px;
            display: flex;
            flex-direction: column;
            gap: 8px;
            box-shadow: inset 0px 0px 8px rgba(0, 0, 0, 0.2);
          `}
        >
          <AppCopilotHeader />
          {enableScripts && scriptMessages.length > 0 ? (
            <AppCopilotMessages
              messages={scriptMessages}
              actionSuggestions={[]}
              textSuggestions={[]}
              isLoading={false}
            />
          ) : (
            <AppCopilotMessages
              messages={messages}
              actionSuggestions={actionSuggestions}
              textSuggestions={textSuggestions}
              isLoading={isLoading}
            />
          )}
          <AppCopiloadLoading />
          {!scriptMessageKey && <AppCopilotInput />}
          {enableScripts && <ScriptsButtons />}
        </div>
      ) : null}
    </>
  );
});

export const buildAppCopilot = () => () => {
  return <AppCopilot />;
};
