import { ExtensionUISize } from "@msbabylon/core";
import { createUIViewModel } from "@msbabylon/shell-core";
import { createModel } from "nyax";
import messageIds from "src/locales/messageIds";
import { babylonInstanceModel } from "src/store/shell-models/babylon/instance";

export const uiViewPanelDiagnosticsModel = createModel(
  class extends createUIViewModel() {
    public selectors() {
      return {
        ...super.selectors(),
        size: (): ExtensionUISize => "small",
        title: () =>
          this.dependencies.intl.formatMessage({
            id: messageIds.diagnostics.$title,
          }),
        azureAccountInstance: () =>
          this.getContainer(babylonInstanceModel).state.azureAccountInstance,
      };
    }
  },
  {
    isLazy: true,
    isDynamic: true,
  }
);
