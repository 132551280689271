import { ExtensionLogLevel } from "@msbabylon/core";
import { createModel } from "nyax";
import { defaultShellModelBuilder } from "src/store/defaultModelBuilder";
const CopilotTelemetryEventName = "CopilotEvent";

interface ICopilotTelemetry {
  messageId: string;
  event: "like" | "dislike";
}

export const uiCopilotLoggerModel = createModel(
  class extends defaultShellModelBuilder {
    private trackEvent(event: ICopilotTelemetry): void {
      this.dependencies.logger.logEvent(
        ExtensionLogLevel.Info,
        CopilotTelemetryEventName,
        "",
        {
          ...event,
          _buildVersion: window.getBuildVersion ? window.getBuildVersion() : "",
        }
      );
    }

    effects() {
      return {
        trackMessageLike: async ({ messageId }: { messageId: string }) => {
          this.trackEvent({
            messageId,
            event: "like",
          });
        },
        trackMessageDislike: async ({ messageId }: { messageId: string }) => {
          this.trackEvent({
            messageId,
            event: "dislike",
          });
        },
      };
    }
  },
  {
    isDynamic: false,
  }
);
