import {
  containsCaseInsensitive,
  equalsCaseInsensitive,
} from "@adux/common-react";
import { StandardPurviewAccountInfo } from "@msbabylon/sdk-hub";
import { rootModels, toErrorMessage } from "@msbabylon/shell-framework";
import { createModel, createSelector } from "nyax";
import {
  EMPTY,
  distinctUntilChanged,
  filter,
  map,
  switchMapTo,
  tap,
} from "rxjs";
import { TenantAccountInfo } from "src/apis/BabylonApi";
import { getUnifiedPortalUrl } from "src/features";
import messageIds from "src/locales/messageIds";
import {
  isMergeStatusInProgress,
  isMergeStatusSucceeded,
} from "src/models/account-merge";
import { defaultShellModelBuilder } from "src/store/defaultModelBuilder";
import {
  babylonAccountArmHelperModel,
  babylonAccountHelperModel,
} from "src/store/shell-models/babylon/account/helper";
import { babylonInstanceEntityModel } from "src/store/shell-models/babylon/instance";
import { reconcileModel } from "src/store/shell-models/babylon/reconcile";
import { redirectToLandingPage } from "src/util/redirect";
import { naturalSort } from "src/util/sort";

export interface AccountItem {
  accountName: string;
  friendlyName?: string;
  subscription?: string;
  resourceId?: string;
  location?: string;
  publicNetworkAccess?: string;
  identityType?: string;
  provisioningState?: string;
}
export interface DisplayedAccountItem extends AccountItem {
  key: string;
  displayText: string;
  disabled: boolean;
  disabledMessage?: string;
  accountFetched?: boolean | "INPROGRESS";
}
export const uiMergeSelectAccountModel = createModel(
  class extends defaultShellModelBuilder {
    public initialState() {
      return {
        ...super.initialState(),
        initialized: false,
        loadingAccounts: false,
        loadingAccountsFromArm: false,
        loadingTenantAccount: false,
        merging: false,
        isFromGAPortal: false,

        tenantInfo: undefined as TenantAccountInfo | undefined,
        tenantAccountInfo: undefined as StandardPurviewAccountInfo | undefined,

        accountFetchedMap: {} as Record<string, boolean | "INPROGRESS">,
        accountMergeStatusMap: {} as Record<
          string,
          "Merging" | "Merged" | undefined
        >,

        selectedAccount: undefined as undefined | string,
        filterText: "",
        currentPage: 0,
        pageSize: 10,
      };
    }

    public reducers() {
      return {
        ...super.reducers(),
        setInitialized: () => {
          this.state.initialized = true;
        },
        setLoadingAccounts: (value: boolean) => {
          this.state.loadingAccounts = value;
        },
        setLoadingAccountsFromArm: (value: boolean) => {
          this.state.loadingAccountsFromArm = value;
        },
        setLoadingTenantAccount: (value: boolean) => {
          this.state.loadingTenantAccount = value;
        },
        setMerging: (value: boolean) => {
          this.state.merging = value;
        },
        setIsFromGAPortal: (value: boolean) => {
          this.state.isFromGAPortal = value;
        },
        setTenantInfo: (value?: TenantAccountInfo) => {
          this.state.tenantInfo = value;
        },
        setTenantAccountInfo: (value?: StandardPurviewAccountInfo) => {
          this.state.tenantAccountInfo = value;
        },
        setAccountFetchedMap: (payload: {
          accountName: string;
          value: boolean | "INPROGRESS";
        }) => {
          // eslint-disable-next-line security/detect-object-injection
          this.state.accountFetchedMap[payload.accountName] = payload.value;
        },
        setAccountMergeStatusMap: (payload: {
          accountName: string;
          value: "Merging" | "Merged";
        }) => {
          // eslint-disable-next-line security/detect-object-injection
          this.state.accountMergeStatusMap[payload.accountName] = payload.value;
        },
        setSelectedAccount: (value?: string) => {
          this.state.selectedAccount = value;
        },
        setFilterText: (value: string) => {
          this.state.filterText = value;
        },
        setCurrentPage: (value: number) => {
          this.state.currentPage = value;
        },
      };
    }

    public selectors() {
      return {
        tenantAccountName: createSelector(
          () => this.state.tenantInfo,
          (tenantInfo) => {
            return tenantInfo?.provisionedAccountProperties?.accountName;
          }
        ),
        tenantAccountDisplayName: createSelector(
          () => this.state.tenantInfo,
          () => this.state.tenantAccountInfo,
          (tenantInfo, tenantAccountInfo) => {
            return (
              tenantAccountInfo?.properties.friendlyName ||
              tenantInfo?.provisionedAccountProperties?.accountName ||
              ""
            );
          }
        ),
        tenantAccountSubscription: createSelector(
          () => this.state.tenantAccountInfo,
          () =>
            this.getContainer(rootModels.azure.subscription.entity).getters
              .bySubscriptionId,
          (tenantAccountInfo, subscriptionById) => {
            const resourceId = tenantAccountInfo?.id;
            const subscriptionId =
              resourceId != null ? resourceId.split("/")[2] : undefined;
            return subscriptionId
              ? // eslint-disable-next-line security/detect-object-injection
                subscriptionById[subscriptionId]?.displayName ?? subscriptionId
              : undefined;
          }
        ),
        combinedAccounts: createSelector(
          () => this.getContainer(babylonAccountHelperModel).getters.items,
          () => this.getContainer(babylonAccountArmHelperModel).getters.items,
          () =>
            this.getContainer(rootModels.azure.subscription.entity).getters
              .bySubscriptionId,
          () => this.getContainer(babylonInstanceEntityModel).state.byId,
          (): string | undefined => this.getters.tenantAccountName,
          (
            babylonAccounts,
            armAccounts,
            subscriptionById,
            instanceById,
            tenantAccountName
          ) => {
            return babylonAccounts
              .filter((account) => account.name !== tenantAccountName)
              .map((account) => {
                const matchedArmAccount = armAccounts.find((armAccount) =>
                  equalsCaseInsensitive(armAccount.name, account.name)
                );
                const matchedBabylonInstance = instanceById[account.name];
                const resourceId =
                  matchedArmAccount?.id ?? matchedBabylonInstance?.id;
                const subscriptionId =
                  resourceId != null ? resourceId.split("/")[2] : undefined;
                return {
                  accountName: account.name,
                  friendlyName: account.friendlyName,
                  subscription: subscriptionId
                    ? // eslint-disable-next-line security/detect-object-injection
                      subscriptionById[subscriptionId]?.displayName ??
                      subscriptionId
                    : undefined,
                  location:
                    account.location ??
                    matchedArmAccount?.location ??
                    matchedBabylonInstance?.location,
                  resourceId,
                  publicNetworkAccess:
                    matchedArmAccount?.properties?.publicNetworkAccess ??
                    matchedBabylonInstance?.properties?.publicNetworkAccess,
                  identityType:
                    matchedArmAccount?.identity?.type ??
                    matchedBabylonInstance?.identity.type,
                  provisioningState:
                    matchedArmAccount?.properties?.provisioningState ??
                    matchedBabylonInstance?.properties?.provisioningState,
                };
              });
          }
        ),
        selectedAccount: createSelector(
          () => this.state.selectedAccount,
          (): AccountItem[] => this.getters.combinedAccounts,
          (accountName, accounts) =>
            accounts.find((account) => account.accountName === accountName)
        ),
        filteredAccounts: createSelector(
          () => this.state.filterText,
          (): AccountItem[] => this.getters.combinedAccounts,
          (filterText, accounts) => {
            return accounts
              .filter((account) => {
                return containsCaseInsensitive(
                  `${account.accountName}\n${account.friendlyName}\n${account.subscription}\n${account.location}`,
                  filterText
                );
              })
              .sort((a, b) => naturalSort(a.accountName, b.accountName));
          }
        ),
        currentPageAccounts: createSelector(
          (): AccountItem[] => this.getters.filteredAccounts,
          () => this.state.currentPage,
          () => this.state.pageSize,
          (filteredAccounts, currentPage, pageSize) => {
            return filteredAccounts.slice(
              currentPage * pageSize,
              (currentPage + 1) * pageSize
            );
          }
        ),
        currentPageAccountsTableItems: createSelector(
          (): AccountItem[] => this.getters.currentPageAccounts,
          () => this.state.accountFetchedMap,
          () => this.state.accountMergeStatusMap,
          () => this.state.loadingAccountsFromArm,
          (
            currentPageAccounts,
            accountFetchedMap,
            accountMergeStatusMap,
            loading
          ): DisplayedAccountItem[] => {
            return currentPageAccounts.map((item) => {
              let disabledMessageId;
              let disabled = false;
              if (
                loading ||
                accountFetchedMap[item.accountName] === "INPROGRESS"
              ) {
                disabled = true;
              } else if (
                accountMergeStatusMap[item.accountName] === "Merging"
              ) {
                // Account merging in progress
                disabledMessageId =
                  messageIds.reconcile.accountMerge.notSelectableMerging;
                disabled = true;
              } else if (accountMergeStatusMap[item.accountName] === "Merged") {
                // Account already merged
                disabledMessageId =
                  messageIds.reconcile.accountMerge.notSelectableMerged;
                disabled = true;
              } else if (
                equalsCaseInsensitive(item.publicNetworkAccess, "Disabled")
              ) {
                // Account with PE enabled
                disabledMessageId =
                  messageIds.reconcile.accountMerge.noPublicNetworkAccess;
                disabled = true;
              } else if (
                item.identityType != null &&
                containsCaseInsensitive(item.identityType, "UserAssigned")
              ) {
                // Account with UAMI
                disabledMessageId =
                  messageIds.reconcile.accountMerge.notSelectableUAMI;
                disabled = true;
              } else if (
                equalsCaseInsensitive(item.provisioningState, "Failed")
              ) {
                // Account in failed status
                disabledMessageId =
                  messageIds.reconcile.accountMerge.notSelectableFailed;
                disabled = true;
              }
              return {
                ...item,
                key: item.accountName,
                displayText:
                  item.friendlyName && item.friendlyName !== item.accountName
                    ? `${item.friendlyName} (${item.accountName})`
                    : item.accountName,
                disabled,
                disabledMessage: disabledMessageId
                  ? this.dependencies.intl.formatMessage({
                      id: disabledMessageId,
                    })
                  : undefined,
                accountFetched: accountFetchedMap[item.accountName],
              };
            });
          }
        ),
        pageCount: createSelector(
          (): AccountItem[] => this.getters.filteredAccounts,
          () => this.state.pageSize,
          (filteredAccounts, pageSize) => {
            return Math.ceil(filteredAccounts.length / pageSize);
          }
        ),
      };
    }

    public effects() {
      return {
        ...super.effects(),
        onInitialize: async () => {
          if (document.referrer !== "") {
            const referrerURL = new URL(document.referrer);
            this.actions.setIsFromGAPortal.dispatch(
              referrerURL.origin === window.location.origin &&
                referrerURL.pathname !== "/" &&
                referrerURL.pathname !== window.location.pathname
            );
          }
          const reconcileHelper = this.getContainer(reconcileModel);
          try {
            await this.actions.checkIsEligible.dispatch({});
            const tenantInfo = this.state.tenantInfo;
            const tenantAccountName =
              tenantInfo?.provisionedAccountProperties?.accountName;
            if (!tenantAccountName) {
              redirectToLandingPage("", this.dependencies);
            }
            await reconcileHelper.actions.checkIfCanTriggerMerge.dispatch({
              primaryAccountName: tenantAccountName,
            });
            const canTriggerMerge = reconcileHelper.state.canTriggerMerge;
            if (!canTriggerMerge) {
              redirectToLandingPage("", this.dependencies);
            } else {
              await this.actions.fetchTenantAccountInfo.dispatch({});
              this.actions.fetchAccounts.dispatch({});
              this.actions.fetchAccountsFromArm.dispatch({});
              this.actions.fetchExistingMerges.dispatch({});
            }
          } catch (error) {
            redirectToLandingPage("", this.dependencies);
          } finally {
            this.actions.setInitialized.dispatch({});
          }
        },
        checkIsEligible: async () => {
          const tenantInfo = await this.dependencies.babylonApi.checkTenant();
          this.actions.setTenantInfo.dispatch(tenantInfo);
        },
        fetchTenantAccountInfo: async () => {
          const tenantAccountName =
            this.state.tenantInfo?.provisionedAccountProperties?.accountName;
          if (!tenantAccountName) {
            return;
          }
          try {
            this.actions.setLoadingTenantAccount.dispatch(true);
            const accountInstance =
              await this.dependencies.babylonApi.getInstance(
                tenantAccountName,
                true
              );
            this.actions.setTenantAccountInfo.dispatch(accountInstance);
          } catch (e) {
            this.dependencies.toast.create({
              type: "error",
              title: this.dependencies.intl.formatMessage({
                id: messageIds.shared.error,
              }),
              description: toErrorMessage(e),
              time: new Date().toISOString(),
            });
          } finally {
            this.actions.setLoadingTenantAccount.dispatch(false);
          }
        },
        fetchAccounts: async () => {
          try {
            this.actions.setLoadingAccounts.dispatch(true);
            const userTenantId = this.getContainer(rootModels.auth).getters
              .tenantId;
            if (userTenantId) {
              await this.getContainer(
                babylonAccountHelperModel
              ).actions.readFromGateway.dispatch({ tenantId: userTenantId });
            }
          } catch (e) {
            this.dependencies.toast.create({
              type: "error",
              title: this.dependencies.intl.formatMessage({
                id: messageIds.shared.error,
              }),
              description: toErrorMessage(e),
              time: new Date().toISOString(),
            });
          } finally {
            this.actions.setLoadingAccounts.dispatch(false);
          }
        },
        fetchAccountsFromArm: async () => {
          try {
            this.actions.setLoadingAccountsFromArm.dispatch(true);
            const userTenantId = this.getContainer(rootModels.auth).getters
              .tenantId;
            if (userTenantId) {
              await this.getContainer(
                babylonAccountArmHelperModel
              ).actions.readFromResourceGraph.dispatch({
                tenantId: userTenantId,
              });
            }
          } catch (e) {
            this.dependencies.toast.create({
              type: "error",
              title: this.dependencies.intl.formatMessage({
                id: messageIds.shared.error,
              }),
              description: toErrorMessage(e),
              time: new Date().toISOString(),
            });
          } finally {
            this.actions.setLoadingAccountsFromArm.dispatch(false);
          }
        },
        fetchExistingMerges: async () => {
          try {
            const tenantAccountName = this.state.tenantAccountInfo?.name;
            if (tenantAccountName) {
              const merges =
                await this.dependencies.babylonApi.listAccountMerges(
                  tenantAccountName
                );
              merges.forEach((merge) => {
                if (
                  !merge.assessmentOnly &&
                  isMergeStatusSucceeded(merge.status)
                ) {
                  this.actions.setAccountMergeStatusMap.dispatch({
                    accountName: merge.secondaryAccountName,
                    value: "Merged",
                  });
                } else if (
                  !merge.assessmentOnly &&
                  isMergeStatusInProgress(merge.status)
                ) {
                  this.actions.setAccountMergeStatusMap.dispatch({
                    accountName: merge.secondaryAccountName,
                    value: "Merging",
                  });
                }
              });
            }
          } catch (e) {
            // swallow
          }
        },
        fetchAccountsInfoIfNotExist: async (accounts: AccountItem[]) => {
          await Promise.all(
            accounts
              .filter(
                (account) =>
                  this.state.accountFetchedMap[account.accountName] ===
                  undefined
              )
              .map((account) =>
                (async () => {
                  try {
                    this.actions.setAccountFetchedMap.dispatch({
                      accountName: account.accountName,
                      value: "INPROGRESS",
                    });
                    this.actions.fetchAccountLocation.dispatch(
                      account.accountName
                    );
                  } catch (error) {
                    // swallow
                  } finally {
                    this.actions.setAccountFetchedMap.dispatch({
                      accountName: account.accountName,
                      value: true,
                    });
                  }
                })()
              )
          );
        },
        fetchAccountLocation: async (accountName: string) => {
          try {
            const armAccounts = this.getContainer(babylonAccountArmHelperModel)
              .getters.items;
            const accountFromArm = armAccounts.find((armAccount) =>
              equalsCaseInsensitive(armAccount.name, accountName)
            );
            if (accountFromArm) {
              return;
            }
            if (
              // eslint-disable-next-line security/detect-object-injection
              this.getContainer(babylonInstanceEntityModel).state.byId[
                accountName
              ] == null
            ) {
              const accountInfo =
                await this.dependencies.babylonApi.getInstance(
                  accountName,
                  true
                );
              await this.getContainer(
                babylonInstanceEntityModel
              ).actions.setItems.dispatch([accountInfo]);
            }
          } catch {
            // noop
          }
        },
        checkMergeCreatingState: async () => {
          const tenantAccountName = this.state.tenantAccountInfo?.name;
          const secondaryAccountName =
            this.getters.selectedAccount?.accountName;
          if (tenantAccountName && secondaryAccountName) {
            try {
              let timesRun = 0;
              await new Promise((resolve) => {
                const interval = setInterval(async () => {
                  const merges =
                    await this.dependencies.babylonApi.listAccountMerges(
                      tenantAccountName
                    );
                  const newMerge = merges.find(
                    (merge) =>
                      merge.secondaryAccountName === secondaryAccountName
                  );
                  if (newMerge && isMergeStatusInProgress(newMerge.status)) {
                    clearInterval(interval);
                    resolve(true);
                  }
                  timesRun++;
                  if (timesRun >= 10) {
                    clearInterval(interval);
                    resolve(true);
                  }
                }, 3000);
              });
            } catch (e) {
              // noop
            }
          }
        },
        mergeAsync: async (payload: {
          assessmentOnly?: boolean;
          excludeCategories?: string[];
          autoResolveCategories?: string[];
        }) => {
          const { assessmentOnly, excludeCategories, autoResolveCategories } =
            payload;
          this.actions.setMerging.dispatch(true);
          const account = this.getters.selectedAccount;
          const tenantAccount = this.state.tenantAccountInfo;
          if (!account || !tenantAccount) {
            return;
          }
          const toastId = await this.dependencies.toast.create(
            {
              type: "progress",
              title: this.dependencies.intl.formatMessage({
                id: messageIds.shared.inProgress,
              }),
              description: this.dependencies.intl.formatMessage({
                id: messageIds.reconcile.accountMerge.submittingMerge,
              }),
              time: new Date().toISOString(),
            },
            { autoClose: false }
          );
          this.dependencies.logger.logEvent(
            "info",
            "AccountMerge",
            "start to merge account."
          );
          try {
            await this.dependencies.workflowApi.submitAccountMergeRequest(
              { accountName: account.accountName },
              this.getters.tenantAccountName,
              undefined,
              assessmentOnly,
              excludeCategories,
              autoResolveCategories
            );
            await this.actions.checkMergeCreatingState.dispatch({});
            await this.dependencies.toast.update(toastId, {
              type: "success",
              title: this.dependencies.intl.formatMessage({
                id: messageIds.shared.success,
              }),
              description: this.dependencies.intl.formatMessage({
                id: messageIds.reconcile.accountMerge.submitMergeSuccessfully,
              }),
              time: new Date().toISOString(),
            });
            if (this.state.isFromGAPortal) {
              redirectToLandingPage(
                `/resource/${this.state.tenantAccountInfo?.name}/main/datasource/monitoring/homepage`,
                this.dependencies
              );
            } else {
              const monitorPageUrl = await getUnifiedPortalUrl(
                this.dependencies,
                "/datamap/governance/main/datasource/monitoring/homepage"
              );
              window.location.href = monitorPageUrl.toString();
            }
          } catch (e) {
            this.dependencies.logger.logEvent(
              "error",
              "AccountMerge",
              "failed to merge account."
            );
            this.dependencies.toast.update(toastId, {
              type: "error",
              title: this.dependencies.intl.formatMessage({
                id: messageIds.shared.error,
              }),
              description: toErrorMessage(e),
              time: new Date().toISOString(),
            });
            this.actions.setMerging.dispatch(false);
          }
        },
      };
    }

    public epics() {
      return {
        ...super.epics(),
        fetchAccountInfoOnPageChange: () => {
          return this.rootAction$.pipe(
            filter(() => this.state.initialized),
            map(() =>
              JSON.stringify(
                this.getters.currentPageAccounts.map((e) => e.accountName)
              )
            ),
            distinctUntilChanged(),
            tap(() => {
              const currentPageAccounts = this.getters.currentPageAccounts;
              this.actions.fetchAccountsInfoIfNotExist.dispatch(
                currentPageAccounts
              );
            }),
            switchMapTo(EMPTY)
          );
        },
      };
    }
  }
);
