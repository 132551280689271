import { ExtensionLogLevel } from "@msbabylon/core";
import { createModel } from "nyax";
import messageIds from "src/locales/messageIds";
import { defaultShellModelBuilder } from "src/store/defaultModelBuilder";

const StarRatingFeedbackEventName = "UserStarRatingFeedback";
const StarRatingTelemetryEventName = "StarRatingTelemetry";

interface StarRatingTelemetry {
  id: string;
  title: string;
  event: "request" | "refuse" | "submit";
}

export const starRatingFeedbackModel = createModel(
  class extends defaultShellModelBuilder {
    private trackEvent(event: StarRatingTelemetry): void {
      this.dependencies.logger.logEvent(
        "info",
        StarRatingTelemetryEventName,
        "",
        {
          ...event,
          _buildVersion: window.getBuildVersion ? window.getBuildVersion() : "",
        }
      );
    }

    public effects() {
      return {
        // for telemetry
        trackRequest: async ({ id, title }: { id: string; title: string }) => {
          this.trackEvent({
            id,
            title,
            event: "request",
          });
        },
        // for telemetry
        trackRefuse: async ({ id, title }: { id: string; title: string }) => {
          this.trackEvent({
            id,
            title,
            event: "refuse",
          });
        },
        // for telemetry
        trackSubmit: async ({ id, title }: { id: string; title: string }) => {
          this.trackEvent({
            id,
            title,
            event: "submit",
          });
        },
        submit: async (payload: {
          id: string;
          title: string;
          rating: number;
          comment?: string;
          definition?: string;
        }) => {
          this.dependencies.logger.logEvent(
            ExtensionLogLevel.Info,
            StarRatingFeedbackEventName,
            "",
            payload
          );
          this.dependencies.logger.flush();
          // wait some time for better user experience...
          await new Promise((resolve) => setTimeout(resolve, 500));
          await this.dependencies.toast.create({
            type: "success",
            title: this.dependencies.intl.formatMessage({
              id: messageIds.feedback.$sentTitle,
            }),
            description: this.dependencies.intl.formatMessage({
              id: messageIds.feedback.$sentMessage,
            }),
            time: new Date().toISOString(),
          });
        },
      };
    }
  }
);
