import { createModel, createSelector } from "nyax";
import { ICopilotMessage } from "src/apis/CopilotApi/interface";
import { scenarioScripts } from "src/constants/copilotScriptsSample";
import { defaultShellModelBuilder } from "src/store/defaultModelBuilder";

export const uiCopilotScriptsModel = createModel(
  class extends defaultShellModelBuilder {
    public initialState() {
      return {
        ...super.initialState(),
        scriptMessagesMap: {} as { [key: string]: ICopilotMessage[] },
        scriptMessageKey: null as string | null,
      };
    }

    public reducers() {
      return {
        ...super.reducers(),
        setScriptMessagesMap: (value: {
          key: string;
          messages: ICopilotMessage[];
        }) => {
          this.state.scriptMessagesMap[value.key] = value.messages;
        },
        setScriptMessageKey: (value: string | null) => {
          this.state.scriptMessageKey = value;
        },
      };
    }

    public selectors() {
      return {
        ...super.selectors(),
        scriptMessages: createSelector(
          () => this.state.scriptMessagesMap,
          () => this.state.scriptMessageKey,
          (messagesMap, mapKey) => {
            // eslint-disable-next-line security/detect-object-injection
            return mapKey ? messagesMap[mapKey] || [] : [];
          }
        ),
      };
    }

    public effects() {
      return {
        ...super.effects(),
        initScriptMessages: async () => {
          scenarioScripts.forEach((script) => {
            const scriptArray = script.content
              .split("---")
              .filter((text) => !!text);
            const parsedMessage: ICopilotMessage[] = [];
            scriptArray.forEach((text, idx) => {
              if (text.includes("[Assistant]:")) {
                parsedMessage.push({
                  messageId: `${script.key}-${idx}`,
                  role: "Assistant",
                  content: text.replace("[Assistant]:", "").trim(),
                  isCompleted: true,
                  suggestions: [],
                });
              } else if (text.includes("[System]:")) {
                parsedMessage.push({
                  messageId: `${script.key}-${idx}`,
                  role: "System",
                  content: text.replace("[System]:", "").trim(),
                  isCompleted: true,
                  suggestions: [],
                });
              } else if (text.includes("[User]:")) {
                parsedMessage.push({
                  messageId: `${script.key}-${idx}`,
                  role: "User",
                  content: text.replace("[User]:", "").trim(),
                  isCompleted: true,
                  suggestions: [],
                });
              }
            });

            this.actions.setScriptMessagesMap.dispatch({
              key: script.key,
              messages: parsedMessage,
            });
          });
        },
      };
    }
  },
  {
    isDynamic: false,
  }
);
