/** @jsxImportSource @emotion/react */
import { cssLayout, cssTypography } from "@adux/common-react";
import { css } from "@emotion/react";
import { FocusZone, Icon } from "@fluentui/react";
import { UICard, useGetContainer, useUIView } from "@msbabylon/shell-core";
import {
  extensionConstants,
  rootModels,
  ShellExtensionUIComponentProps,
} from "@msbabylon/shell-framework";
import React, { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import IconArrowRight from "src/assets/M365/home/icon-arrow-right.svg";
import AppHyperLink from "src/components/AppHyperLink";
import {
  PurviewApp,
  useAppLabel,
  usePurviewAllApps,
  useShellNavigate,
} from "src/containers/M365/hook";
import {
  M365ShellAllAppsId,
  M365ShellAllAppsPathname,
} from "src/entries/constant";
import messageIds from "src/locales/messageIds";
import { uiViewMainHomeModel } from "src/store/shell-models/ui/view/UIMainHome";
import { cssTextOverflowMultiLine } from "src/styles/util";

const AppItem = React.memo<{ app: PurviewApp; onClick: () => void }>(
  ({ app, onClick }) => {
    const title = useAppLabel(app);
    return (
      <div
        css={css`
          margin: 8px;
          width: 130px;
        `}
      >
        <div
          data-is-focusable
          role="button"
          css={css`
            width: 130px;
            height: 130px;
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.13);
            border-radius: 2px;
            cursor: pointer;

            :hover {
              box-shadow: rgb(0 0 0 / 14%) 0px 3px 8px;
            }
          `}
          onClick={onClick}
          title={app.label}
        >
          <img
            css={css`
              width: 56px;
              height: 56px;
            `}
            src={app.icon}
            alt={app.label}
          />
        </div>
        <div
          css={[
            css`
              font-weight: 400;
              font-size: 14px;
              line-height: 32px;
            `,
            cssTextOverflowMultiLine(1),
          ]}
          title={title}
        >
          {title}
        </div>
      </div>
    );
  }
);

const AppItems = React.memo(() => {
  const intl = useIntl();
  const { apps, navigateToApp } = usePurviewAllApps();
  const navigateToAllAppsPage = useShellNavigate(M365ShellAllAppsId);
  const createOpenApp = useCallback(
    (app: PurviewApp) => () => {
      if (app.extensionName === extensionConstants.shell) {
        navigateToAllAppsPage();
      }
      navigateToApp(app);
    },
    [navigateToApp, navigateToAllAppsPage]
  );
  const slicedApps = useMemo<PurviewApp[]>(() => {
    return apps.slice(0, 4).concat({
      id: "seeAll",
      extensionName: extensionConstants.shell,
      label: intl.formatMessage({
        id: messageIds.m365.home.viewAll,
      }),
      icon: IconArrowRight,
      href: M365ShellAllAppsPathname,
    });
  }, [apps, intl]);
  return (
    <FocusZone
      css={css`
        display: flex;
        flex-wrap: wrap;
      `}
    >
      {slicedApps.map((app) => (
        <AppItem key={app.id} app={app} onClick={createOpenApp(app)} />
      ))}
    </FocusZone>
  );
});
const homepageUsefulLinks = [
  {
    contentId: messageIds.m365.home.usefulLinks.overviewTitle,
    descriptionId: messageIds.m365.home.usefulLinks.overviewDescription,
    url: "https://go.microsoft.com/fwlink/?linkid=2148717",
  },
  {
    contentId: messageIds.m365.home.usefulLinks.startedTitle,
    descriptionId: messageIds.m365.home.usefulLinks.startedDescription,
    url: "https://go.microsoft.com/fwlink/?linkid=2149760",
  },
  {
    contentId: messageIds.m365.home.usefulLinks.documentationTitle,
    descriptionId: messageIds.m365.home.usefulLinks.documentationDescription,
    url: "https://go.microsoft.com/fwlink/?linkid=2148916",
  },
];

const HomeExternalLinks = React.memo(() => {
  const intl = useIntl();
  return (
    <div>
      {homepageUsefulLinks.map((link) => (
        <div
          key={link.contentId}
          css={css`
            margin-bottom: 16px;
          `}
        >
          <AppHyperLink
            href={link.url}
            css={[
              cssTypography.bodyText,
              css`
                margin-bottom: 2px;
              `,
            ]}
            target="_blank"
          >
            {intl.formatMessage({ id: link.contentId })}&nbsp;
            <Icon iconName="NavigateExternalInline" />
          </AppHyperLink>
          <div css={[cssTypography.tooltipText]}>
            {intl.formatMessage({ id: link.descriptionId })}
          </div>
        </div>
      ))}
    </div>
  );
});

export default React.memo<ShellExtensionUIComponentProps>(
  function M365UIMainHome(props) {
    const intl = useIntl();
    const { uiId } = props;
    const getContainer = useGetContainer();
    const uiView = getContainer(uiViewMainHomeModel, uiId);
    const uiItemHelper = getContainer(rootModels.ui.item.helperByUIId, uiId);
    useUIView(uiView, uiItemHelper);
    return (
      <div
        css={css`
          padding: 32px 56px;
        `}
      >
        <AppItems />
        <div
          css={css`
            display: flex;
            margin-top: 60px;
            ${cssLayout.mediaQueries.down.xl} {
              flex-direction: column;
            }
          `}
        >
          <div
            css={css`
              flex: 1;
              min-width: 360px;
              max-width: 800px;
            `}
          >
            <div css={[cssTypography.heading1]}>
              {intl.formatMessage({
                id: messageIds.m365.home.quickAccess,
              })}
            </div>
            <UICard
              css={css`
                margin-top: 18px;
                height: 325px;
              `}
              ui={{
                extensionName: "catalog",
                component: {
                  type: "_homeRecent",
                  params: {},
                },
              }}
            />
          </div>
          <div
            css={css`
              min-width: 360px;
              max-width: 400px;
              padding-left: 20px;
            `}
          >
            <div
              css={[
                cssTypography.heading1,
                css`
                  margin-bottom: 24px;
                `,
              ]}
            >
              {intl.formatMessage({
                id: messageIds.m365.home.links,
              })}
            </div>
            <HomeExternalLinks />
          </div>
        </div>
      </div>
    );
  }
);
