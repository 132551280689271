export function isEnterKey(event: React.KeyboardEvent<HTMLElement>) {
  return event.key === "Enter" || event.keyCode === 13;
}

export function isSpaceKey(event: React.KeyboardEvent<HTMLElement>) {
  return event.key === " " || event.key === "Spacebar" || event.keyCode === 32;
}

export function handleKeyPress(
  keyTests: ((event: React.KeyboardEvent<HTMLElement>) => boolean)[],
  callback?: () => unknown
) {
  return (event: React.KeyboardEvent<HTMLElement>) => {
    if (callback && keyTests.some((keyTest) => keyTest(event))) {
      callback();
    }
  };
}
