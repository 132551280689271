/** @jsxImportSource @emotion/react */
import { Callout } from "@fluentui/react";
import { useGetContainer } from "@msbabylon/shell-core";
import { memo, useCallback, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { embedTeamsChatModel } from "src/store/shell-models/ui/EmbedTeamsChat";

export const EmbedTeamsChat = memo(() => {
  const conversationContainerRef = useRef<HTMLDivElement>(null);

  const getContainer = useGetContainer();
  const embedTeamsChatContainer = getContainer(embedTeamsChatModel);
  const hidden = useSelector(() => embedTeamsChatContainer.state.hidden);
  const targetId = useSelector(() => embedTeamsChatContainer.state.targetId);
  const loadingSDK = useSelector(
    () => embedTeamsChatContainer.state.loadingSDK
  );

  useEffect(() => {
    const id = setTimeout(() => {
      embedTeamsChatContainer.actions.initSDK.dispatch({ windowRef: window });
    }, 20000);
    return () => clearTimeout(id);
  }, [embedTeamsChatContainer.actions.initSDK]);

  useEffect(() => {
    if (loadingSDK === false && conversationContainerRef.current !== null) {
      embedTeamsChatContainer.actions.renderConversation.dispatch({
        windowRef: window,
        conversationContainer: conversationContainerRef.current,
      });
    }
  }, [embedTeamsChatContainer.actions.renderConversation, loadingSDK]);

  const handleDismiss = useCallback(() => {
    embedTeamsChatContainer.actions.setHidden.dispatch(true);
  }, [embedTeamsChatContainer.actions.setHidden]);

  return (
    <Callout
      hidden={hidden}
      target={targetId === undefined ? undefined : `#${targetId}`}
      isBeakVisible={false}
      onDismiss={handleDismiss}
    >
      <div ref={conversationContainerRef} />
    </Callout>
  );
});

export const buildAppDynamicContent = () => () => {
  return <></>;
};
