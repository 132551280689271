import { rootModels } from "@msbabylon/shell-core";
import type { ShellDependencies } from "src/dependencies";

// Compose a complete URL with shell domain
// No `extensionName` call returns home page link
// No `resourceName` call return domain link with querystring
export function createShellHrefByHref(
  params: {
    resourceName?: string;
    extensionName?: string;
    href?: string;
    search?: string;
  },
  deps: ShellDependencies
): string {
  const { resourceName, extensionName } = params;
  let { href = "", search = "" } = params;
  if (href.startsWith("/")) {
    href = href.slice(1);
  }
  const displayResourceName = resourceName;
  const pathname = [
    ...(displayResourceName ? ["resource", resourceName] : []),
    ...(extensionName ? ["main", extensionName, href] : []),
  ].join("/");
  const u = new URL(pathname, window.location.origin);
  const featureQueryString = deps.urlFeature.getFeatureQuery();
  if (search && featureQueryString) {
    search += "&";
  }
  search += featureQueryString;
  if (u.search && search) {
    u.search += "&";
  }
  u.search += search;
  return u.toString();
}

export function getCurrentMainUIItem(
  dependencies: Pick<ShellDependencies, "redux">
) {
  const uiItem = dependencies.redux.getContainer(rootModels.ui.list.main.entity)
    .getters.currentUIItem;
  return uiItem;
}
