import { rootModels, useGetContainer } from "@msbabylon/shell-core";
import { extensionConstants } from "@msbabylon/shell-framework";
import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import IconCatalog from "src/assets/icon-catalog.svg";
import { catalogToc } from "src/containers/AppSidebarHomeMenu";
import messageIds from "src/locales/messageIds";

export function useIsShellPage(pageId: string) {
  const getContainer = useGetContainer();
  const uiMainListCommonHelper = getContainer(rootModels.ui.list.main.entity);
  const uiMainCurrentUIItem = useSelector(
    () => uiMainListCommonHelper.getters.currentUIItem
  );
  return useMemo(() => {
    return uiMainCurrentUIItem?.shellExtensionUIComponent?.type === pageId;
  }, [uiMainCurrentUIItem, pageId]);
}

export interface PurviewApp {
  id: string;
  extensionName: string;
  label: string;
  labelId?: string | undefined;
  icon: string;
  href: string;
}

export function useAppLabelFormat() {
  const intl = useIntl();
  return useCallback(
    (app: PurviewApp) => {
      const labelId = app.labelId;
      return labelId
        ? intl.formatMessage({ id: labelId, defaultMessage: app.label })
        : app.label;
    },
    [intl]
  );
}

export function useAppLabel(app: PurviewApp) {
  const format = useAppLabelFormat();
  return useMemo(() => format(app), [format, app]);
}

export function usePurviewAllApps() {
  const getContainer = useGetContainer();
  const intl = useIntl();
  const uiSidebar = getContainer(rootModels.ui.sidebar);
  const menuItems = useSelector(() => uiSidebar.getters.menuItems);
  const sortedMenuItems = useMemo(() => {
    return menuItems.sort((a, b) => {
      const aIndex = a.attributes?.index ?? 0;
      const bIndex = b.attributes?.index ?? 0;
      if (aIndex < bIndex) {
        return -1;
      } else if (aIndex > bIndex) {
        return 1;
      } else if (a.label < b.label) {
        return -1;
      } else if (a.label > b.label) {
        return 1;
      }
      return 0;
    });
  }, [menuItems]);
  const filteredListMenuItems = useMemo(
    () => sortedMenuItems.filter((item) => item && !item.attributes?.hide),
    [sortedMenuItems]
  );
  const apps = useMemo<PurviewApp[]>(() => {
    return [
      {
        id: "catalog/catalogTocPlaceholder",
        extensionName: catalogToc.extensionName,
        label: intl.formatMessage({ id: messageIds.home.$title }),
        icon: IconCatalog,
        href: catalogToc.href,
      },
      ...filteredListMenuItems,
    ];
  }, [filteredListMenuItems, intl]);
  const navigateToApp = useCallback(
    (app: PurviewApp) => {
      uiSidebar.actions.onMenuItemClick.dispatch({
        extensionName: app.extensionName,
        href: app.href,
      });
    },
    [uiSidebar.actions.onMenuItemClick]
  );
  return {
    apps,
    navigateToApp,
  };
}

export function useShellNavigate(pageId: string) {
  const getContainer = useGetContainer();
  const uiMainListCommonHelper = getContainer(
    rootModels.ui.list.main.commonHelper
  );
  return useCallback(() => {
    uiMainListCommonHelper.actions.replace.dispatch({
      extensionName: extensionConstants.shell,
      component: {
        type: pageId,
        params: {},
      },
    });
  }, [uiMainListCommonHelper.actions.replace, pageId]);
}
