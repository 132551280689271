import { newGuid } from "@msbabylon/core";
import {
  createClientNameHeaderInterceptor,
  createErrorHandlingInterceptor,
} from "@msbabylon/purview-util/axios";
import {
  AuthService,
  createAuthorizationHeaderInterceptor,
} from "@msbabylon/shell-core";
import axios from "axios";
import { ShellApplication } from "src/models/app";
import {
  PurviewAccountArmResource,
  RoleAssignmentsResponse,
} from "src/models/azure";
import { LoggerService } from "src/services/LoggerService";
import {
  createTrackEvent,
  createTrackUnhandledErrorEvent,
} from "src/util/axios";

const tenantHeaderName = `__TENANT_${newGuid()}`;

export class AzureApi {
  private readonly _axios = axios.create({
    baseURL: this._application.environment.armEndpoint,
  });

  constructor(
    private readonly _application: ShellApplication,
    private readonly _authService: AuthService,
    private readonly _logger: LoggerService
  ) {
    const requestInterceptors = [
      createAuthorizationHeaderInterceptor((config) => {
        if (config.headers == null) {
          config.headers = {};
        }
        // It's not a user input in the object key. https://github.com/nodesecurity/eslint-plugin-security/blob/master/docs/the-dangers-of-square-bracket-notation.md
        // eslint-disable-next-line security/detect-object-injection
        const tenantId = config.headers[tenantHeaderName] as string;
        // eslint-disable-next-line security/detect-object-injection
        delete config.headers[tenantHeaderName];
        return this._authService.acquireToken({
          scopes: [this._application.appEnv.armAuthResourceUri],
          tenant: tenantId || undefined,
        });
      }),
      createClientNameHeaderInterceptor(),
    ];

    this._axios.interceptors.request.use(async (config) => {
      await Promise.all(
        requestInterceptors.map((interceptor) => interceptor(config))
      );

      return config;
    });

    this._axios.interceptors.response.use(
      (resp) => resp,
      createErrorHandlingInterceptor({
        trackUnhandledErrorEvent: createTrackUnhandledErrorEvent(this._logger),
        trackEvent: createTrackEvent(this._logger),
      })
    );
  }

  public async getTenantDefaultPurviewAccount(
    tenantId: string
  ): Promise<string | undefined> {
    try {
      const response = await this._axios.get(
        `/providers/Microsoft.Purview/getDefaultAccount`,
        {
          params: {
            scopeType: "Tenant",
            scope: tenantId,
            scopeTenantId: tenantId,
            "api-version": "2020-12-01-preview",
          },
          headers: {
            [tenantHeaderName]: tenantId,
          },
        }
      );
      const content = response.data;
      return `/subscriptions/${content.subscriptionId}/resourceGroups/${content.resourceGroupName}/providers/Microsoft.Purview/accounts/${content.accountName}`;
    } catch (e) {
      // it's normal to call api failed due to insufficient permission or default account not set
      return undefined;
    }
  }

  public async getRoleAssignments(
    accountId: string,
    userId: string
  ): Promise<RoleAssignmentsResponse> {
    const response = await this._axios.get(
      `${accountId}/providers/Microsoft.Authorization/roleAssignments`,
      {
        params: {
          $filter: `atScope() and assignedTo('${userId}')`,
          "api-version": "2020-04-01-preview",
        },
      }
    );
    return response.data;
  }

  public async listPurviewAccountsByResourceGraph(
    subscriptions: string[],
    tenantId?: string
  ): Promise<PurviewAccountArmResource[]> {
    interface AzureResourceGraphResponse {
      $skipToken?: string;
      data: {
        rows: unknown[][];
      };
    }

    const baseRequestData = {
      subscriptions,
      query: `where (type =~ ('Microsoft.Purview/accounts')) | project id,name,type,location,identity,properties.publicNetworkAccess,properties.provisioningState`,
    };

    const $top = 1000;
    let $skip = 0;
    let $skipToken = "";

    const resources: PurviewAccountArmResource[] = [];
    // eslint-disable-next-line no-constant-condition
    while (true) {
      const { data } = await this._axios.post<AzureResourceGraphResponse>(
        "/providers/Microsoft.ResourceGraph/resources",
        {
          ...baseRequestData,
          options: {
            $skip,
            $skipToken,
            $top,
          },
        },
        {
          params: {
            "api-version": "2018-09-01-preview",
          },
          headers: {
            [tenantHeaderName]: tenantId!,
          },
        }
      );

      const items = data.data.rows.map((row) => ({
        id: row[0],
        name: row[1],
        type: row[2],
        location: row[3],
        identity: row[4],
        properties: {
          publicNetworkAccess: row[5],
          provisioningState: row[6],
        },
      })) as PurviewAccountArmResource[];
      resources.push(...items);

      if (data.$skipToken) {
        $skip += $top;
        $skipToken = data.$skipToken;
      } else {
        break;
      }
    }

    return resources;
  }
}
