/** @jsxImportSource @emotion/react */
import {
  cssLayout,
  HyperLink,
  isNullOrEmpty,
  useTheme,
} from "@adux/common-react";
import { css } from "@emotion/react";
import { Spinner, SpinnerSize } from "@fluentui/react";
import {
  SharedIcon,
  useGetContainer,
  useThemeSet,
} from "@msbabylon/shell-core";
import {
  AppTopBarButton,
  MainTheme,
  rootModels,
  TopbarTheme,
} from "@msbabylon/shell-framework";
import React, { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { catalogToc } from "src/containers/AppSidebarHomeMenu";
import { useDependencies } from "src/hook";
import messageIds from "src/locales/messageIds";
import { babylonInstanceModel } from "src/store/shell-models/babylon/instance";
import { cssTextTruncate } from "src/styles/util";
import { createShellHrefByHref } from "src/util/createShellHrefByHref";

export const buildAppTopBarProduct = () => {
  return React.memo(function AppTopBarProduct(props) {
    const theme = useTheme();
    const topbarTheme = useThemeSet().topbar as TopbarTheme;
    const mainTheme = useThemeSet().main as MainTheme;
    const intl = useIntl();
    const getContainer = useGetContainer();
    const dependencies = useDependencies();

    const uiSidebar = getContainer(rootModels.ui.sidebar);
    const babylonInstance = getContainer(babylonInstanceModel);

    const buttonOutline =
      topbarTheme.buttonOutline ??
      `${theme.semanticColors.bodyText} dashed 1px !important;`;

    const cssLink = useMemo(
      () => css`
        font-size: 14px;
        line-height: ${mainTheme.topbarHeight}px;

        // overwrite default link style
        display: inline-block !important;
        color: ${theme.semanticColors.bodyText} !important;
        text-decoration: none !important;
        :focus {
          outline: ${buttonOutline} !important;
          outline-offset: -1px !important;
        }
      `,
      [theme.semanticColors.bodyText, buttonOutline, mainTheme.topbarHeight]
    );

    const resourceName = useSelector(
      () => getContainer(rootModels.shared).getters.resourceName
    );
    const isNotLandingPage = useSelector(
      () => !getContainer(rootModels.app).state.showLandingPage
    );
    const isErrorPage = useSelector(
      () => getContainer(rootModels.app).state.appErrorInfo
    );

    const customLogoImageUrl = useSelector(
      () => babylonInstance.getters.logoImageUrl
    );
    const showCustomLogo = useMemo(
      () =>
        dependencies.application.features.logo &&
        !isNullOrEmpty(customLogoImageUrl),
      [customLogoImageUrl, dependencies]
    );

    const onboard = useMemo(
      () => isNotLandingPage && !isErrorPage,
      [isNotLandingPage, isErrorPage]
    );

    const displayedName = useSelector(
      () => babylonInstance.getters.getDisplayedName
    );

    const homeLink = useMemo(() => {
      return createShellHrefByHref(
        {
          resourceName: resourceName ?? "",
        },
        dependencies
      );
    }, [resourceName, dependencies]);

    const sidebarMode = useSelector(() => uiSidebar.state.mode);
    const openSidebar = useCallback(() => {
      uiSidebar.actions.setOpen.dispatch(true);
    }, [uiSidebar]);

    const navigateToHome = useCallback(() => {
      if (resourceName) {
        uiSidebar.actions.onMenuItemClick.dispatch({
          extensionName: catalogToc.extensionName,
          href: catalogToc.href,
        });

        uiSidebar.actions.setOpen.dispatch(false);
      } else {
        getContainer(
          rootModels.ui.list.main.commonHelper
        ).actions.closeUntil.dispatch({});
      }
    }, [
      resourceName,
      uiSidebar.actions.onMenuItemClick,
      uiSidebar.actions.setOpen,
      getContainer,
    ]);

    return (
      <>
        {displayedName && (
          <AppTopBarButton
            css={[
              css`
                flex-shrink: 0;
                width: 48px;
              `,
              sidebarMode !== "flyout" &&
                css`
                  @media (min-width: ${`${cssLayout.breakpoints.xl}px`}) and (min-height: ${`${cssLayout.breakpoints.md}px`}) {
                    display: none;
                  }
                `,
            ]}
            onClick={openSidebar}
            title={intl.formatMessage({ id: messageIds.topbar.showMenu })}
          >
            <SharedIcon src="fabric:GlobalNavButton" />
          </AppTopBarButton>
        )}

        <div
          css={[
            css`
              flex-shrink: 0;
              padding: 0 0 0 20px;
              font-size: 15px;
              font-weight: 600;
              line-height: 20px;
              cursor: pointer;
            `,
            displayedName &&
              css`
                ${cssLayout.mediaQueries.down.xl} {
                  display: none;
                }
              `,
            showCustomLogo &&
              css`
                ${cssLayout.mediaQueries.down.xxl} {
                  display: none;
                }
              `,
          ]}
          onClick={navigateToHome}
        >
          {intl.formatMessage({ id: messageIds.topbar.microsoftAzure })}
        </div>

        <div
          css={css`
            flex: 1 1 0;
            padding: 0 16px 0 0;
            overflow: hidden;
            display: flex;
            align-items: center;
          `}
        >
          {showCustomLogo && (
            <img
              src={customLogoImageUrl}
              alt={"Logo"}
              css={[
                css`
                  height: 24px;
                  max-width: 112px;
                  margin-left: 16px;
                `,
                css`
                  ${cssLayout.mediaQueries.down.xl} {
                    margin-left: 0px;
                  }
                `,
              ]}
            />
          )}

          {onboard && (
            <>
              <SharedIcon
                css={
                  showCustomLogo
                    ? css`
                        margin: 0 12px;
                      `
                    : css`
                        margin: 0 12px;
                        ${cssLayout.mediaQueries.down.xl} {
                          display: none;
                        }
                      `
                }
                src="fabric:CaretSolidRight"
                size={8}
              />
              {displayedName ? (
                <HyperLink
                  css={css`
                    ${cssLink}
                    ${cssTextTruncate}
                    // Todo: remove 500px max width
                      max-width: 500px;
                  `}
                  onClick={navigateToHome}
                  href={homeLink}
                  title={displayedName}
                >
                  {displayedName}
                </HyperLink>
              ) : (
                <Spinner
                  css={css`
                    display: inline-block;
                    ${cssLayout.mediaQueries.down.xl} {
                      margin-left: 12px;
                    }
                  `}
                  size={SpinnerSize.xSmall}
                />
              )}
            </>
          )}
        </div>
      </>
    );
  });
};
