import { babylonAccountModels } from "src/store/shell-models/babylon/account";
import { cesModel } from "src/store/shell-models/babylon/ces";
import { defaultAccountModels } from "src/store/shell-models/babylon/defaultAccount";
import {
  babylonInstanceEntityModel,
  babylonInstanceModel,
} from "src/store/shell-models/babylon/instance";
import { reconcileModel } from "src/store/shell-models/babylon/reconcile";
import { releaseNotesModel } from "src/store/shell-models/babylon/releaseNotes";
import { starRatingFeedbackModel } from "src/store/shell-models/babylon/starRatingFeedback";

export const babylonModels = {
  account: babylonAccountModels,
  defaultAccount: defaultAccountModels,
  instance: babylonInstanceModel,
  instanceEntity: babylonInstanceEntityModel,
  releaseNotes: releaseNotesModel,
  ces: cesModel,
  starRatingFeedback: starRatingFeedbackModel,
  reconcile: reconcileModel,
};
