import { getResponseData } from "@msbabylon/shell-core";
import axios from "axios";
import { ShellApplication } from "src/models/app";
import { ReleaseNote } from "src/models/releaseNotes";

const readReleaseCountKey = "cmVhZFJlbGVhc2VDb3VudEtleQ==";

export class ReleaseNotesApi {
  private readonly _axiosInstance = axios.create();

  public constructor(private readonly _appConfig: ShellApplication) {}

  private getReleaseNoteUrl(language?: string) {
    return language == null || language === "en"
      ? `${this._appConfig.tier.cdnExtensionUrl}/releasenotes/releasenotes.json`
      : `${this._appConfig.tier.cdnExtensionUrl}/releasenotes/releasenotes.${language}.json`;
  }

  public async getReleaseNotes(language?: string): Promise<ReleaseNote[]> {
    try {
      return getResponseData(
        this._axiosInstance.get(this.getReleaseNoteUrl(language))
      );
    } catch {
      // fallback to English version
      return getResponseData(this._axiosInstance.get(this.getReleaseNoteUrl()));
    }
  }

  public async getReadReleaseNotesCount(): Promise<number> {
    try {
      const value = localStorage.getItem(readReleaseCountKey);
      if (value != null) {
        return parseInt(value, 10);
      } else {
        return NaN;
      }
    } catch {
      return NaN;
    }
  }

  public async setReadReleaseNotesCount(count: number): Promise<void> {
    try {
      localStorage.setItem(readReleaseCountKey, count + "");
    } catch {
      return;
    }
  }
}
