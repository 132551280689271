import { newGuid } from "@msbabylon/core";
import { createClientNameHeaderInterceptor } from "@msbabylon/purview-util/axios";
import {
  AuthService,
  createAuthorizationHeaderInterceptor,
  getResponseData,
} from "@msbabylon/shell-core";
import axios from "axios";
import {
  ICopilotContext,
  ICopilotResponse,
} from "src/apis/CopilotApi/interface";
import { ShellApplication } from "src/models/app";

const tenantHeaderName = `__TENANT_${newGuid()}`;

export class CopilotApi {
  private _generateAccountBaseUrl = (name?: string) =>
    this._application.appEnv.gatewayEndpoint.replace(
      "{0}",
      name ?? this._application.resourceName ?? ""
    );

  private readonly _axios = axios.create({
    baseURL: `${this._generateAccountBaseUrl()}catalog`,
  });

  constructor(
    private readonly _application: ShellApplication,
    private readonly _authService: AuthService
  ) {
    const requestInterceptors = [
      createAuthorizationHeaderInterceptor((config) => {
        if (config.headers == null) {
          config.headers = {};
        }
        // It's not a user input in the object key. https://github.com/nodesecurity/eslint-plugin-security/blob/master/docs/the-dangers-of-square-bracket-notation.md
        // eslint-disable-next-line security/detect-object-injection
        const tenantId = config.headers[tenantHeaderName] as string;
        // eslint-disable-next-line security/detect-object-injection
        delete config.headers[tenantHeaderName];
        return this._authService.acquireToken({
          scopes: [this._application.appEnv.gatewayAuthResourceUri],
          tenant: tenantId || undefined,
        });
      }),
      createClientNameHeaderInterceptor(),
    ];

    this._axios.interceptors.request.use(async (config) => {
      await Promise.all(
        requestInterceptors.map((interceptor) => interceptor(config))
      );

      return config;
    });
  }

  public async createChat(
    userMessage: string,
    currentContext?: ICopilotContext
  ) {
    const response = await getResponseData<{ chatId: string }>(
      this._axios.post(
        "api/search/suggest",
        {
          userMessage,
          currentContext,
        },
        {
          params: {
            mode: "chat",
            op: "newMessage",
          },
        }
      )
    );

    return response;
  }

  public async sendMessage(payload: {
    chatId: string;
    userMessage?: string;
    userChoice?: string; // choice id
    currentContext?: ICopilotContext;
  }) {
    const { chatId, userMessage, userChoice, currentContext } = payload;
    const response = await getResponseData<{ chatId: string }>(
      this._axios.post(
        "api/search/suggest",
        userChoice // only one filed is required
          ? {
              userChoice,
              currentContext,
            }
          : {
              userMessage,
              currentContext,
            },
        {
          params: {
            mode: "chat",
            op: "newMessage",
            chatId,
          },
        }
      )
    );

    return response;
  }

  public async pullMessage(chatId: string) {
    const response = await getResponseData<ICopilotResponse>(
      this._axios.post("api/search/suggest", undefined, {
        params: {
          mode: "chat",
          op: "fetchResponse",
          chatId,
        },
      })
    );

    return response;
  }

  public async pullMessageFrom(chatId: string, lastMessageId: string) {
    const response = await getResponseData<ICopilotResponse>(
      this._axios.post("api/search/suggest", undefined, {
        params: {
          mode: "chat",
          op: "fetchResponse",
          chatId,
          startingMessageId: lastMessageId,
        },
      })
    );

    return response;
  }

  public async stopPull(chatId: string) {
    const response = await getResponseData(
      this._axios.post("api/search/suggest", undefined, {
        params: {
          mode: "chat",
          op: "stopResponding",
          chatId,
        },
      })
    );

    return response;
  }

  public async closeChat(chatId: string) {
    const response = await getResponseData(
      this._axios.post("api/search/suggest", undefined, {
        params: {
          mode: "chat",
          op: "closeConversation",
          chatId,
        },
      })
    );

    return response;
  }
}
