import { rootModels } from "@msbabylon/shell-framework";
import { createModel, createSelector, mergeSubModels } from "nyax";
import type { Subscription } from "rxjs";
import {
  EMPTY,
  distinctUntilChanged,
  filter,
  map,
  mergeMapTo,
  switchMap,
  switchMapTo,
  tap,
  timer,
} from "rxjs";
import {
  ICopilotContext,
  ICopilotMessage,
  ICopilotReferenceAssetProp,
  ICopilotReferenceClassificationProp,
  ICopilotReferenceContactProp,
  ICopilotReferenceGlossaryProp,
  ICopilotReferenceScanProp,
  ICopilotSuggestion,
} from "src/apis/CopilotApi/interface";
import messageIds from "src/locales/messageIds";
import { uiCopilotLoggerModel } from "src/store/shell-models/ui/copilotLogger";
import { ofAction } from "src/util/rxjs";
import { uiCopilotScriptsModel } from "./copilotScripts";

// #region - post message type
export type CopilotPostMessageType =
  | "copilot.navigateAsset"
  | "copilot.navigateClassification"
  | "copilot.createPolicy"
  | "copilot.navigateAssetEdit"
  | "copilot.navigateGlossary"
  | "copilot.navigateScan";

export type CopilotPostMessageTarget = "catalog" | "policy" | "datasource";

export interface CopilotPostMessageNavigateAsset {
  type: CopilotPostMessageType;
  assetId: string;
}

export interface CopilotPostMessageNavigateClassification {
  type: CopilotPostMessageType;
  id: string;
}

export interface CopilotPostMessageCreatePolicy {
  type: CopilotPostMessageType;
  assetId: string;
}

export interface CopilotPostMessageEditAsset {
  type: CopilotPostMessageType;
  assetId: string;
}

export interface CopilotPostMessageNavigateGlossary {
  type: CopilotPostMessageType;
  guid: string;
}

export interface CopilotPostMessageNavigateScan {
  type: CopilotPostMessageType;
  dataSource: string;
  scanName: string;
}

export type CopilotPostMessagePayload = { target: CopilotPostMessageTarget } & (
  | CopilotPostMessageNavigateAsset
  | CopilotPostMessageNavigateClassification
  | CopilotPostMessageCreatePolicy
  | CopilotPostMessageEditAsset
  | CopilotPostMessageNavigateGlossary
  | CopilotPostMessageNavigateScan
);
// #endregion

const POLLING_DELAY = 1000;
const uiRegisteredSourcesSourceDetailScanHistoryPath =
  "/registeredSources/source/scanHistoryList";
const uiCatalogEntityPath = "/main/catalog/entity";
const BUBBLE_TIMEOUT = 3 * 1000;

export const uiCopilotModel = createModel(
  class extends mergeSubModels({
    loggerDomain: uiCopilotLoggerModel,
    scriptsDomain: uiCopilotScriptsModel,
  }) {
    public initialState() {
      return {
        ...super.initialState(),
        visible: false,
        chatId: null as string | null,

        isSending: false as boolean,
        isPolling: false as boolean,

        messageContext: {} as ICopilotContext,
        messages: [] as ICopilotMessage[],
        cachedMessages: [] as ICopilotMessage[],

        actionSuggestions: [] as ICopilotSuggestion[],
        currentSuggestions: [] as ICopilotSuggestion[],

        lastCompletedMessageId: null as string | null,

        markedMessage: {} as { [messageId: string]: boolean | undefined },

        // for bubble content
        targetId: undefined as string | undefined,
        bubbleData: undefined as
          | { description: string; initMessage: string }
          | undefined,
      };
    }

    public reducers() {
      return {
        ...super.reducers(),
        resetState: () => {
          this.state.chatId = null;
          this.state.isSending = false;
          this.state.isPolling = false;
          this.state.messages = [];
          this.state.cachedMessages = [];
          this.state.actionSuggestions = [];
          this.state.currentSuggestions = [];
          this.state.lastCompletedMessageId = null;
        },
        setVisible: (value: boolean) => {
          this.state.visible = value;
        },
        setChatId: (value: string) => {
          this.state.chatId = value;
        },
        setIsSending: (value: boolean) => {
          this.state.isSending = value;
        },
        setIsPolling: (value: boolean) => {
          this.state.isPolling = value;
        },
        setLastCompletedMessageId: (value: string) => {
          this.state.lastCompletedMessageId = value;
        },
        setActionSuggestions: (value: ICopilotSuggestion[]) => {
          this.state.actionSuggestions = value;
        },
        setCurrentSuggestions: (value: ICopilotSuggestion[]) => {
          this.state.currentSuggestions = value;
        },
        setMessageContext: (value: ICopilotContext) => {
          this.state.messageContext = value;
        },
        appendMessages: (value: ICopilotMessage[]) => {
          this.state.messages = [...this.state.messages, ...value];
        },
        setCachedMessages: (value: ICopilotMessage[]) => {
          this.state.cachedMessages = value;
        },
        markMessage: (value: {
          messageId: string;
          like: boolean | undefined;
        }) => {
          this.state.markedMessage = {
            ...this.state.markedMessage,
            [value.messageId]: value.like,
          };
        },
        setTargetId: (value: string) => {
          this.state.targetId = value;
        },
        setBubbleData: (
          value: { description: string; initMessage: string } | undefined
        ) => {
          this.state.bubbleData = value;
        },
      };
    }

    public selectors() {
      return {
        ...super.selectors(),
        allMessages: createSelector(
          () => this.state.messages,
          () => this.state.cachedMessages,
          (completed, incompleted) => {
            return [...completed, ...incompleted];
          }
        ),
        isLoading: createSelector(
          () => this.state.isSending,
          () => this.state.isPolling,
          (sending, polling) => {
            return sending || polling;
          }
        ),
      };
    }

    public effects() {
      return {
        ...super.effects(),
        sendMessage: async (payload: {
          message: string;
          selectedId?: string;
        }) => {
          if (this.getters.isLoading) {
            return;
          }
          this.actions.setCurrentSuggestions.dispatch([]);
          this.actions.setActionSuggestions.dispatch([]);

          try {
            this.actions.setIsSending.dispatch(true);
            this.actions.setCachedMessages.dispatch([
              { content: payload.message, role: "User" },
            ]);
            const currentContext = this.state.messageContext;
            if (currentContext) {
              this.actions.setMessageContext.dispatch({});
            }
            if (!this.state.chatId) {
              const { chatId } = await this.dependencies.copilotApi.createChat(
                payload.message,
                currentContext
              );
              this.actions.setChatId.dispatch(chatId);
            } else {
              await this.dependencies.copilotApi.sendMessage({
                chatId: this.state.chatId,
                userMessage: payload.message,
                userChoice: payload.selectedId,
                currentContext,
              });
            }

            this.actions.setIsSending.dispatch(false);
          } catch (error) {
            // TODO: handle error
            this.actions.setIsSending.dispatch(false);
            return;
          }

          this.actions.pollingMessage.dispatch({});
        },

        pollingMessage: async () => {
          if (!this.state.chatId) {
            // TODO: handle edge case
            return;
          }

          this.actions.setIsPolling.dispatch(true);
          const pollingMessageSubscription: Subscription = timer(
            POLLING_DELAY,
            POLLING_DELAY
          )
            .pipe(
              switchMap(() => {
                return this.state.lastCompletedMessageId
                  ? this.dependencies.copilotApi.pullMessageFrom(
                      this.state.chatId as string,
                      this.state.lastCompletedMessageId
                    )
                  : this.dependencies.copilotApi.pullMessage(
                      this.state.chatId as string
                    );
              })
            )
            .subscribe({
              next: (response) => {
                // handle unexpected error or polling end
                if (!this.state.isPolling) {
                  pollingMessageSubscription.unsubscribe();
                  return;
                }

                const messages = response.messages || [];
                if (messages.length > 0) {
                  const existingMessages = this.state.messages;

                  const completedMessages = messages
                    .filter((msg) => msg.isCompleted)
                    .filter(
                      (msg) =>
                        !existingMessages.find(
                          (_msg) => _msg.messageId === msg.messageId
                        )
                    );
                  const incompletedMessages = messages
                    .filter((msg) => !msg.isCompleted)
                    .filter(
                      (msg) =>
                        !existingMessages.find(
                          (_msg) => _msg.messageId === msg.messageId
                        )
                    );

                  this.actions.setCachedMessages.dispatch(incompletedMessages);
                  if (completedMessages.length > 0) {
                    const lastCompletedMessage =
                      completedMessages[completedMessages.length - 1];
                    this.actions.setLastCompletedMessageId.dispatch(
                      lastCompletedMessage.messageId as string
                    );

                    if (lastCompletedMessage.suggestions) {
                      const plainTextSuggestions =
                        lastCompletedMessage.suggestions.filter(
                          (item) => !item.type
                        );
                      const actionSuggestions =
                        lastCompletedMessage.suggestions.filter(
                          (item) => item.type === "action"
                        );
                      this.actions.setCurrentSuggestions.dispatch(
                        plainTextSuggestions
                      );
                      this.actions.setActionSuggestions.dispatch(
                        actionSuggestions
                      );
                    }

                    if (
                      lastCompletedMessage.references &&
                      lastCompletedMessage.content &&
                      lastCompletedMessage.role === "Assistant"
                    ) {
                      // add references links for markdown text
                      const refs = lastCompletedMessage.references;
                      let content = lastCompletedMessage.content;
                      const links: string[] = [];
                      Object.keys(refs).forEach((refKey) => {
                        // eslint-disable-next-line security/detect-object-injection
                        const ref = refs[refKey];
                        if (ref.type === "asset") {
                          const props =
                            ref.properties as ICopilotReferenceAssetProp;
                          content = content.replaceAll(
                            refKey,
                            `[${props.name}][${props.id}]`
                          );
                          links.push(`[${props.id}]: ${encodeURI(refKey)}\n\n`);
                        } else if (ref.type === "classificationType") {
                          const props =
                            ref.properties as ICopilotReferenceClassificationProp;
                          content = content.replaceAll(
                            refKey,
                            `[${props.options?.displayName || props.name}][${
                              props.guid
                            }]`
                          );
                          links.push(
                            `[${props.guid}]: ${encodeURI(refKey)}\n\n`
                          );
                        } else if (ref.type === "glossaryTerm") {
                          const props =
                            ref.properties as ICopilotReferenceGlossaryProp;
                          content = content.replaceAll(
                            refKey,
                            `[${props.name}][${props.guid}]`
                          );
                          links.push(
                            `[${props.guid}]: ${encodeURI(refKey)}\n\n`
                          );
                        } else if (ref.type === "contact") {
                          const props =
                            ref.properties as ICopilotReferenceContactProp;
                          content = content.replaceAll(
                            refKey,
                            `[${props.displayName}][${props.id}]`
                          );
                          links.push(`[${props.id}]: ${encodeURI(refKey)}\n\n`);
                        } else if (ref.type === "scan") {
                          const props =
                            ref.properties as ICopilotReferenceScanProp;
                          content = content.replaceAll(
                            refKey,
                            `[${props.scan.split("/")[1]}][${props.scan}]`
                          );
                          links.push(
                            `[${props.scan}]: ${encodeURI(refKey)}\n\n`
                          );
                        }
                      });
                      lastCompletedMessage.content = links.join("") + content;
                    }

                    this.actions.appendMessages.dispatch(completedMessages);

                    // polling end
                    if (lastCompletedMessage.role === "Assistant") {
                      this.actions.setIsPolling.dispatch(false);
                      pollingMessageSubscription.unsubscribe();
                    }
                  }
                }
              },
              error: (err) => {
                this.actions.setIsPolling.dispatch(false);
                pollingMessageSubscription.unsubscribe();
              },
            });
        },

        stopPolling: async () => {
          if (this.state.chatId) {
            this.dependencies.copilotApi.stopPull(this.state.chatId);
          }

          this.actions.setIsPolling.dispatch(false);
          if (this.state.cachedMessages) {
            const pausedMessage = this.state.cachedMessages;
            this.actions.setCachedMessages.dispatch([]);
            this.actions.appendMessages.dispatch(pausedMessage);
          }
        },

        close: async () => {
          if (this.state.chatId) {
            this.dependencies.copilotApi.closeChat(this.state.chatId);
          }

          this.actions.resetState.dispatch({});
        },

        postMessageToExtension: async (payload: CopilotPostMessagePayload) => {
          const { type, target, ...others } = payload;
          if (target === "policy" && type === "copilot.createPolicy") {
            const uiSidebar = this.getContainer(rootModels.ui.sidebar);
            const policyApp = uiSidebar.getters.menuItems.find(
              (item) => item.extensionName === "policy"
            );
            if (policyApp) {
              uiSidebar.actions.onMenuItemClick.dispatch({
                extensionName: policyApp.extensionName,
                href: policyApp.href,
              });
            }
          } else if (target === "catalog" && type === "copilot.navigateAsset") {
            this.getContainer(
              rootModels.ui.list.main.commonHelper
            ).actions.replace.dispatch({
              extensionName: "catalog",
              location: {
                pathname: "/entity",
                search: `?guid=${
                  (payload as CopilotPostMessageNavigateAsset).assetId
                }`,
              },
            });
          } else if (
            target === "catalog" &&
            type === "copilot.navigateAssetEdit"
          ) {
            this.getContainer(
              rootModels.ui.list.main.commonHelper
            ).actions.replace.dispatch({
              extensionName: "catalog",
              location: {
                pathname: "/entity/edit",
                search: `?guid=${
                  (payload as CopilotPostMessageEditAsset).assetId
                }`,
              },
            });
          } else if (
            target === "catalog" &&
            type === "copilot.navigateClassification"
          ) {
            this.getContainer(
              rootModels.ui.list.main.commonHelper
            ).actions.replace.dispatch({
              extensionName: "catalog",
              location: {
                pathname: "/management/classifications/classification",
                search: `?id=${
                  (payload as CopilotPostMessageNavigateClassification).id
                }`,
              },
            });
          } else if (
            target === "catalog" &&
            type === "copilot.navigateGlossary"
          ) {
            this.getContainer(
              rootModels.ui.list.main.commonHelper
            ).actions.replace.dispatch({
              extensionName: "catalog",
              location: {
                pathname: "/term",
                search: `?termGuid=${
                  (payload as CopilotPostMessageNavigateGlossary).guid
                }`,
              },
            });
          } else if (
            target === "datasource" &&
            type === "copilot.navigateScan"
          ) {
            this.getContainer(
              rootModels.ui.list.main.commonHelper
            ).actions.replace.dispatch({
              extensionName: "datasource",
              location: {
                pathname: "/registeredSources/source/scanHistoryList",
                search: encodeURI(
                  `?id=datasources/${
                    (payload as CopilotPostMessageNavigateScan).dataSource
                  }/scans/${
                    (payload as CopilotPostMessageNavigateScan).scanName.split(
                      "/"
                    )[1]
                  }`
                ),
              },
            });
          } else {
            this.dependencies.shellExtension.sendMessage(target, {
              type,
              payload: others,
            });
          }
        },

        triggerBubble: async (
          payload: { description: string; initMessage: string } | null
        ) => {
          if (!payload || this.state.visible) {
            this.actions.setBubbleData.dispatch(undefined);
          } else {
            setTimeout(() => {
              this.actions.setBubbleData.dispatch({
                description: payload.description,
                initMessage: payload.initMessage,
              });
            }, BUBBLE_TIMEOUT);
          }
        },
      };
    }

    public epics() {
      return {
        ...super.epics(),
        observePathChange: () => {
          const router = this.getContainer(rootModels.__router);
          return this.rootAction$
            .pipe(
              filter(() => router.state.isInitialized),
              map(() => this.dependencies.router.getLocation()),
              distinctUntilChanged(
                (prev, cur) => prev.state?.key === cur.state?.key
              )
            )
            .pipe(
              tap((to) => {
                const searchParams = new URLSearchParams(to.search);
                if (to.pathname?.endsWith(uiCatalogEntityPath)) {
                  this.actions.triggerBubble.dispatch({
                    description: this.dependencies.intl.formatMessage({
                      id: messageIds.copilot.bubble.summarizeComment
                        .description,
                    }),
                    initMessage: this.dependencies.intl.formatMessage({
                      id: messageIds.copilot.bubble.summarizeComment
                        .initMessage,
                    }),
                  });

                  if (searchParams.has("guid")) {
                    this.actions.setMessageContext.dispatch({
                      reference: {
                        type: "asset",
                        properties: {
                          id: searchParams.get("guid") as string,
                        },
                      },
                    });
                  }
                } else if (
                  to.pathname?.endsWith(
                    uiRegisteredSourcesSourceDetailScanHistoryPath
                  )
                ) {
                  this.actions.triggerBubble.dispatch({
                    description: this.dependencies.intl.formatMessage({
                      id: messageIds.copilot.bubble.understandScan.description,
                    }),
                    initMessage: this.dependencies.intl.formatMessage({
                      id: messageIds.copilot.bubble.understandScan.initMessage,
                    }),
                  });

                  if (searchParams.has("id")) {
                    this.actions.setMessageContext.dispatch({
                      reference: {
                        type: "scan",
                        properties: {
                          id: searchParams.get("id") as string,
                        },
                      },
                    });
                  }
                } else {
                  this.actions.triggerBubble.dispatch(null);
                  this.actions.setMessageContext.dispatch({});
                }
              }),
              switchMapTo(EMPTY)
            );
        },
        observeVisibility: () => {
          return this.rootAction$.pipe(
            ofAction(this.actions.setVisible),
            map((action) => action.payload),
            tap((item) => {
              if (item === true && this.state.bubbleData) {
                this.actions.sendMessage.dispatch({
                  message: this.state.bubbleData.initMessage,
                });
                this.actions.triggerBubble.dispatch(null);
              }
            }),
            mergeMapTo(EMPTY)
          );
        },
      };
    }
  },
  {
    isDynamic: false,
  }
);
