import { rootModels } from "@msbabylon/shell-framework";
import { GetContainer } from "nyax";

export default async function (
  getContainer: GetContainer,
  extensionName: string,
  display: boolean | null
) {
  const manifestModel = getContainer(rootModels.extension.manifest.entity);
  if (!display) {
    manifestModel.actions.batchDelete.dispatch([extensionName]);
  }
}
