export const booleanAdapterFactory =
  (defaultValue: boolean) => (str: string | boolean | null) => {
    if (str === "false" || str === false) {
      return false;
    }
    if (str === "true" || str === true) {
      return true;
    }
    return defaultValue;
  };

export const booleanAdapterDefaultFalse = booleanAdapterFactory(false);
export const booleanAdapterDefaultTrue = booleanAdapterFactory(true);

export const stringAdapterFactory = (defaultValue?: string) => {
  return (str: string | null) => {
    if (str == null) {
      return defaultValue;
    }

    if (str === "undefined" || str === "null") {
      return undefined;
    }

    return str;
  };
};
