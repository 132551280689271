import { createModel, mergeSubModels } from "nyax";
import { TenantDefaultAccount } from "src/models/babylon";
import { createItemsReadWriteModel } from "src/store/shared/itemsReadWrite";
import { tenantDefaultAccountEntityModel } from "src/store/shell-models/babylon/defaultAccount/entity";
export const tenantDefaultAccountHelperModel = createModel(
  class extends mergeSubModels({
    _rw: createItemsReadWriteModel<TenantDefaultAccount>({
      getItemId: (item) => item.id,
      setItems: ({ getContainer }, items) =>
        getContainer(tenantDefaultAccountEntityModel).actions.setItems.dispatch(
          items
        ),
    }),
  }) {
    public selectors() {
      return {
        ...super.selectors(),
        items: () =>
          this.getContainer(tenantDefaultAccountEntityModel).getters.items,
      };
    }

    public effects() {
      return {
        ...super.effects(),
        batchRead: async (payload: {
          tenantIds: string[];
          force?: boolean;
        }) => {
          const { ids: beginReadIds, timestamp } =
            await this.actions._rw.beginRead.dispatch({
              ids: payload.tenantIds,
              force: payload.force,
            });

          try {
            if (beginReadIds.length > 0) {
              const items = await Promise.all(
                beginReadIds.map((tenantId) =>
                  this.dependencies.shellAzureApi
                    .getTenantDefaultPurviewAccount(tenantId)
                    .then((defaultAccountId) => {
                      return {
                        id: tenantId,
                        defaultAccountId,
                      };
                    })
                )
              );

              await this.actions._rw.endRead.dispatch({
                items,
                timestamp,
              });
            }

            const tenantIdSet = new Set(payload.tenantIds);
            return this.getters.items.filter((item) =>
              tenantIdSet.has(item.id)
            );
          } catch (error) {
            await this.actions._rw.endRead.dispatch({
              items: [],
              timestamp,
            });
            throw error;
          }
        },
      };
    }
  }
);
