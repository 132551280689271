import { createModel, createSelector } from "nyax";
import { defaultShellModelBuilder } from "../defaultModelBuilder";

export function createItemsEntityModel<TItem>(
  getItemId: (item: TItem) => string
) {
  return createModel(
    class extends defaultShellModelBuilder {
      public initialState() {
        return {
          byId: {} as Record<string, TItem>,
          allIds: [] as string[],
        };
      }
      public reducers() {
        return {
          batchUpdate: (items: TItem[]) => {
            for (const item of items) {
              const id = getItemId(item);

              if (!(id in this.state.byId)) {
                this.state.allIds.push(id);
              }
              // eslint-disable-next-line security/detect-object-injection
              this.state.byId[id] = item;
            }
          },
          batchDelete: (ids: string[]) => {
            const idSet = new Set(ids);
            this.state.allIds = this.state.allIds.filter(
              (id) => !idSet.has(id)
            );

            for (const id of ids) {
              // eslint-disable-next-line security/detect-object-injection
              delete this.state.byId[id];
            }
          },
        };
      }
      public effects() {
        return {
          clear: async () => {
            await this.actions.batchDelete.dispatch(this.state.allIds);
          },
          setItems: async (items: Array<TItem | string>) => {
            const updateMap = new Map<string, TItem>();
            const deleteSet = new Set<string>();

            items.forEach((item) => {
              if (typeof item === "string") {
                const id = item;
                if (updateMap.has(id)) {
                  updateMap.delete(id);
                }

                deleteSet.add(id);
              } else {
                const id = getItemId(item);
                if (deleteSet.has(id)) {
                  deleteSet.delete(id);
                }

                updateMap.set(id, item);
              }
            });

            const toUpdate: TItem[] = [];
            const toDelete: string[] = [];

            updateMap.forEach((value) => {
              toUpdate.push(value);
            });

            deleteSet.forEach((value) => {
              toDelete.push(value);
            });

            if (toUpdate.length > 0) {
              await this.actions.batchUpdate.dispatch(toUpdate);
            }

            if (toDelete.length > 0) {
              await this.actions.batchDelete.dispatch(toDelete);
            }
          },
        };
      }
      public selectors() {
        return {
          items: createSelector(
            () => this.state.byId,
            () => this.state.allIds,
            // eslint-disable-next-line security/detect-object-injection
            (byId, allIds) => allIds.map((id) => byId[id])
          ),
          getItems: createSelector(() => (ids: string[]) => {
            const byId = this.state.byId;
            // eslint-disable-next-line security/detect-object-injection
            return ids.map((id) => byId[id]);
          }),
        };
      }
    }
  );
}
