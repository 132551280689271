import { ErrorInfo, rootModels, toErrorInfo } from "@msbabylon/shell-framework";
import { AxiosError } from "axios";
import { GetContainer } from "nyax";
import { dependencies } from "src/dependencies";
import messageIds from "src/locales/messageIds";
import { babylonInstanceModel } from "src/store/shell-models/babylon/instance";

export function showNoPermission(
  getContainer: GetContainer,
  showSwitch = false
) {
  const intl = dependencies.intl;
  getContainer(rootModels.app).actions.setAppErrorInfo.dispatch({
    type: "NoPermission",
    title: intl.formatMessage({
      id: messageIds.errors.unauthorized.$title,
    }),
    subtitles: [
      intl.formatMessage({
        id: messageIds.errors.unauthorized.msgNoAccess,
      }),
      intl.formatMessage({
        id: messageIds.errors.unauthorized.msgContactOwner,
      }),
    ],
    linkData: {
      label: intl.formatMessage({
        id: messageIds.shared.learnMore,
      }),
      url: "https://go.microsoft.com/fwlink/?linkid=2150074",
    },
    buttonData: showSwitch
      ? {
          label: intl.formatMessage({
            id: messageIds.errors.unauthorized.selectAnotherAccount,
          }),
          link: window.location.origin + window.location.search,
        }
      : undefined,
  } as ErrorInfo);
  return;
}

export function handleAccountError(getContainer: GetContainer, e?: AxiosError) {
  const intl = dependencies.intl;

  if (e?.isAxiosError) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const axiosError = e as AxiosError<any>;
    if (
      axiosError.response?.status === 403 &&
      axiosError.response?.data?.error?.code === "Unauthorized"
    ) {
      showNoPermission(getContainer, true);
      return;
    }

    if (
      axiosError.response?.status === 403 &&
      axiosError.response.data?.error?.code ===
        "AccountProtectedByPrivateEndpoint"
    ) {
      getContainer(rootModels.app).actions.setAppErrorInfo.dispatch({
        type: "NoPermission",
        title: intl.formatMessage({
          id: messageIds.errors.privateEndpoint.$title,
        }),

        subtitles: [
          intl.formatMessage({
            id: messageIds.errors.privateEndpoint.$message,
          }),
        ],
        linkData: {
          label: intl.formatMessage({
            id: messageIds.shared.learnMore,
          }),
          url: "https://go.microsoft.com/fwlink/?linkid=2153054",
        },
      } as ErrorInfo);
      return;
    }
    if (
      axiosError.response?.status === 400 &&
      axiosError.response.data?.error?.code === "ApiUnavailable"
    ) {
      getContainer(rootModels.app).actions.setAppErrorInfo.dispatch({
        type: "NoPermission",
        title: intl.formatMessage({
          id: messageIds.errors.apiNotAvailable.$title,
        }),
        subtitles: [
          intl.formatMessage({
            id: messageIds.errors.apiNotAvailable.permissionAPINotAvailable,
          }),
        ],
      } as ErrorInfo);
      return;
    }
    if (
      axiosError.response?.status === 0 &&
      axiosError.code === "ERR_NETWORK"
    ) {
      getContainer(rootModels.app).actions.setAppErrorInfo.dispatch({
        type: "NoPermission",
        title: intl.formatMessage({
          id: messageIds.errors.connectAccountFailed.$title,
        }),
        subtitles: [
          intl.formatMessage(
            {
              id: messageIds.errors.connectAccountFailed.$message,
            },
            {
              accountName: axiosError.config?.url?.split(".")[0].split("//")[1],
            }
          ),
        ],
      } as ErrorInfo);
      return;
    }
  }

  dependencies.logger.logEvent(
    "error",
    "Initialization",
    "Init account unknown error"
  );
  getContainer(rootModels.app).actions.setAppErrorInfo.dispatch(toErrorInfo(e));
}

export default async (getContainer: GetContainer) => {
  const babylonModel = getContainer(babylonInstanceModel);
  babylonModel.actions.readCurrentInstance.dispatch({}).catch((e) => {
    return handleAccountError(getContainer, e);
  });
};
