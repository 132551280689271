export enum UserRequestType {
  ReconcileAccount = "ReconcileAccount",
  MergePurviewAccount = "MergePurviewAccount",
  MergePurviewAccountFromSecondary = "MergePurviewAccountFromSecondaryAccount",
}
export interface QueryWorkflowParams {
  operations: QueryWorkflowOperation[];
}
export interface QueryWorkflowOperation {
  type: UserRequestType;
}

export interface WorkflowQueryResult {
  workflows: string[][];
}

export interface WorkflowReconcileRequestPayload {
  accountType: "SingleAccount" | "MultipleAccounts";
  accountRegion?: string;
  tenantRegionCode?: string;
}

export interface WorkflowAccountMergeRequestPayload {
  accountName: string;
  location?: string;
}
