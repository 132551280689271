import { embedTeamsChatModel } from "src/store/shell-models/ui/EmbedTeamsChat";
import { uiCopilotModel } from "src/store/shell-models/ui/copilot";
import { uiLandingModel } from "src/store/shell-models/ui/landing";
import { uiMergeSelectAccountModel } from "src/store/shell-models/ui/mergeSelectAccount";
import { uiReconcileSelectAccountModel } from "src/store/shell-models/ui/reconcileSelectAccount";
import { uiTocModel } from "src/store/shell-models/ui/uiToc";
import { uiViewModels } from "src/store/shell-models/ui/view";

export const uiModels = {
  landing: uiLandingModel,
  toc: uiTocModel,
  teamsChat: embedTeamsChatModel,
  reconcileSelectAccount: uiReconcileSelectAccountModel,
  mergeSelectAccount: uiMergeSelectAccountModel,
  copilot: uiCopilotModel,

  viewByUIId: uiViewModels,
};
