import { ExtensionLogLevel } from "@msbabylon/core";
import { createUIViewModel, rootModels } from "@msbabylon/shell-core";
import { createModel } from "nyax";
import messageIds from "src/locales/messageIds";

export enum FeedbackType {
  Feedback = "feedback",
  Feature = "feature",
}

export const uiViewPanelFeedbackModel = createModel(
  class extends createUIViewModel() {
    public initialState() {
      return {
        ...super.initialState(),
        type: FeedbackType.Feedback,
        emailMe: false,
        email: "",
        comments: "",
      };
    }

    public selectors() {
      return {
        ...super.selectors(),
        title: () =>
          this.dependencies.intl.formatMessage({
            id: messageIds.feedback.$title,
          }),
        readyToSubmit: () =>
          this.state.type === FeedbackType.Feedback &&
          this.state.comments.length > 0,
      };
    }

    public reducers() {
      return {
        ...super.reducers(),
        setType: (value: FeedbackType) => {
          this.state.type = value;
        },
        setEmail: (value: string) => {
          this.state.email = value;
        },
        setEmailMe: (value: boolean) => {
          this.state.emailMe = value;
          this.state.email = "";
        },
        setComments: (value: string) => {
          this.state.comments = value;
        },
      };
    }

    public effects() {
      return {
        ...super.effects(),
        submit: async () => {
          this.dependencies.logger.logEvent(
            ExtensionLogLevel.Info,
            "UserFeedback",
            "",
            { email: this.state.email, comments: this.state.comments },
            true
          );
          this.dependencies.logger.flush();
          await this.dependencies.toast.create({
            type: "success",
            title: this.dependencies.intl.formatMessage({
              id: messageIds.feedback.$sentTitle,
            }),
            description: this.dependencies.intl.formatMessage({
              id: messageIds.feedback.$sentMessage,
            }),
            time: new Date().toISOString(),
          });
          await this.actions.closePanel.dispatch({});
        },
        closePanel: async () => {
          await this.getContainer(
            rootModels.ui.list.panel.commonHelper
          ).actions.closeUntil.dispatch({});
        },
      };
    }
  },
  { isLazy: true, isDynamic: true }
);
