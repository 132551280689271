import { equalsCaseInsensitive, useAsyncMemo } from "@adux/common-react";
import {
  AADDummyObjectType,
  AADExtendedObject,
} from "@adux/common-react/lib/components/AADObject/AADObject";
import { useDependencies as _useDependencies } from "@msbabylon/shell-core";
import { debounce } from "lodash-es";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ShellDependencies } from "src/dependencies";

export function useDependencies() {
  return _useDependencies() as unknown as ShellDependencies;
}

export function useTextBoxState(
  value: string,
  updateValue: (newValue: string) => unknown
): [string, (newValue: string) => unknown] {
  const [_value, _setValue] = useState(value);

  useEffect(() => {
    _setValue(value);
  }, [value]);

  const debouncedUpdateName = useMemo(
    () => debounce(updateValue, 300),
    [updateValue]
  );

  const _updateValue = useCallback(
    (newValue) => {
      _setValue(newValue);
      debouncedUpdateName(newValue);
    },
    [debouncedUpdateName]
  );

  return [_value, _updateValue];
}

export function useIsMountedRef() {
  const isMountedRef = useRef(false);
  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  });
  return isMountedRef;
}

export function useAADObjects(ids: string[]): [AADExtendedObject[], boolean] {
  const dependencies = useDependencies();
  const [list, loading] = useAsyncMemo(async () => {
    const objects = await dependencies.shellGraphApi.getAADObjectsByIds(ids);
    return objects;
  }, [ids]);
  const computedList = useMemo(() => {
    return ids.map((id) => {
      return (
        list?.find((item) => equalsCaseInsensitive(item.id, id)) || {
          id,
          "@odata.type": AADDummyObjectType.Unknown,
          displayName: id,
        }
      );
    });
  }, [ids, list]);
  return [computedList, loading];
}

export function useAADObject(
  id?: string
): [AADExtendedObject | undefined, boolean] {
  const ids = useMemo(() => (id ? [id] : []), [id]);
  const [list, loading] = useAADObjects(ids);
  return [list && list[0], loading];
}

export function useUserThumbnailPhotos(
  ids: string[]
): [Record<string, string>, boolean] {
  const dependencies = useDependencies();
  const [list, loading] = useAsyncMemo(async () => {
    const photos = await dependencies.shellGraphApi.getUserThumbnailPhotosByIds(
      ids
    );
    const ret: Record<string, string> = {};
    for (const key in photos) {
      // eslint-disable-next-line security/detect-object-injection
      if (photos[key]) {
        // eslint-disable-next-line security/detect-object-injection
        ret[key] = photos[key]!;
      }
    }
    return ret;
  }, [ids]);
  const computedList = useMemo(() => list || {}, [list]);
  return [computedList, loading];
}

export function useUserThumbnailPhoto(
  id?: string
): [string | undefined, boolean] {
  const ids = useMemo(() => (id ? [id] : []), [id]);
  const [list, loading] = useUserThumbnailPhotos(ids);
  // eslint-disable-next-line security/detect-object-injection
  return [list && id && list[id], loading];
}
