/** @jsxImportSource @emotion/react */
import { useFonts, useTheme } from "@adux/common-react";
import { css } from "@emotion/react";
import { useGetContainer } from "@msbabylon/shell-core";
import React, { useCallback } from "react";
import { ICopilotSuggestion } from "src/apis/CopilotApi/interface";
import { uiCopilotModel } from "src/store/shell-models/ui/copilot";

interface TextSuggestionsProps {
  suggestions: ICopilotSuggestion[];
  isLoading: boolean;
}

export default React.memo(function TextSuggestions(
  props: TextSuggestionsProps
) {
  const { suggestions, isLoading } = props;

  const theme = useTheme();
  const cssTypography = useFonts();
  const getContainer = useGetContainer();
  const ui = getContainer(uiCopilotModel);

  const onClickSuggestion = useCallback(
    (suggestion: ICopilotSuggestion) => {
      ui.actions.sendMessage.dispatch({
        message: suggestion.displayText,
        selectedId: suggestion.id,
      });
    },
    [ui.actions.sendMessage]
  );

  return (
    <div
      css={css`
        display: flex;
        flex-flow: wrap;
        gap: 6px;
        min-height: 66px;
        justify-content: flex-end;
        align-items: center;
      `}
    >
      {isLoading
        ? null
        : suggestions.map((item) => {
            return (
              <div
                role="button"
                onClick={() => onClickSuggestion(item)}
                key={item.id}
                css={[
                  css`
                    padding: 6px 12px;
                    max-width: 100%;
                    border: 1px solid ${theme.palette.themePrimary};
                    border-radius: 8px;
                    color: ${theme.palette.themePrimary};
                    cursor: pointer;
                  `,
                  cssTypography.body1Strong,
                ]}
              >
                {item.displayText}
              </div>
            );
          })}
    </div>
  );
});
