import { ExtensionLogLevel } from "@msbabylon/core";
import { PurviewMode } from "@msbabylon/purview-util/constants";
import { PurviewEnvironment } from "@msbabylon/purview-util/environments";
import { createModel } from "nyax";
import { SurveyType } from "src/apis/CesAPI";
import { popUpCES } from "src/components/cesContainer";
import { defaultShellModelBuilder } from "src/store/defaultModelBuilder";
import { babylonInstanceModel } from "src/store/shell-models/babylon/instance";

const cesLogEvent = "CesEligibilities";

export const cesModel = createModel(
  class extends defaultShellModelBuilder {
    public effects() {
      return {
        renderNsatForm: async () => {
          requestAnimationFrame(() => {
            this.dependencies.logger.logEvent(
              ExtensionLogLevel.Info,
              "CesNsatForm",
              "Render CES NSAT form",
              {}
            );
            try {
              popUpCES(SurveyType.nps);
            } catch (error) {
              this.dependencies.logger.logEvent(
                ExtensionLogLevel.Error,
                "CesNsatForm",
                "Failed to render CES NSAT form",
                error
              );
            }
          });
        },
        postEligibilities: async () => {
          try {
            if (this.dependencies.application.features.showCesForm) {
              // force the form show up for debug purpose
              this.actions.renderNsatForm.dispatch({});
              return;
            }
            const env = this.dependencies.application.features.environment;
            // not support M365
            if (env.mode === PurviewMode.M365) {
              return;
            }
            // not support envs other than canary/prod
            if (
              env.name !== PurviewEnvironment.Canary &&
              env.name !== PurviewEnvironment.Production
            ) {
              return;
            }
            this.dependencies.logger.logEvent(
              ExtensionLogLevel.Info,
              cesLogEvent,
              "Read CES eligibilities",
              {}
            );
            const instanceModel = this.getContainer(babylonInstanceModel);
            const response = await this.dependencies.cesApi.postEligibilities(
              SurveyType.nps,
              instanceModel.getters.location
            );
            this.dependencies.logger.logEvent(
              ExtensionLogLevel.Info,
              cesLogEvent,
              "Read CES eligibilities result",
              response
            );
            if (response.Eligibility) {
              this.dependencies.cesApi.setExtraConext({
                location: instanceModel.getters.location,
                formsProEligibilityId: response.FormsProEligibilityId,
              });
              this.actions.renderNsatForm.dispatch({});
            }
          } catch (error) {
            // swallow the exception, ces should not block the prod.
            this.dependencies.logger.logEvent(
              ExtensionLogLevel.Error,
              cesLogEvent,
              "Failed to read CES eligibilities",
              error
            );
            return;
          }
        },
      };
    }
  }
);
