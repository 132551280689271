/** @jsxImportSource @emotion/react */
import { HyperLink, useFonts, useTheme } from "@adux/common-react";
import { css } from "@emotion/react";
import { useGetContainer, useUIView } from "@msbabylon/shell-core";
import {
  ShellExtensionUIComponentProps,
  rootModels,
} from "@msbabylon/shell-framework";
import React, { useCallback } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import AppPanel from "src/components/AppPanel";
import messageIds from "src/locales/messageIds";
import { uiViewPanelReleaseNotesModel } from "src/store/shell-models/ui/view/UIPanelReleaseNotes";

export default React.memo<ShellExtensionUIComponentProps>(
  function UIPanelReleaseNotes(props) {
    const { uiId } = props;

    const intl = useIntl();
    const theme = useTheme();
    const cssTypography = useFonts();

    const getContainer = useGetContainer();

    const uiView = getContainer(uiViewPanelReleaseNotesModel, uiId);
    const uiItemHelper = getContainer(rootModels.ui.item.helperByUIId, uiId);
    useUIView(uiView, uiItemHelper);

    const uiPanelListCommonHelper = getContainer(
      rootModels.ui.list.panel.commonHelper
    );

    const notesList = useSelector(() => uiView.getters.releaseNotes);

    const onCloseClick = useCallback(async () => {
      await uiPanelListCommonHelper.actions.closeUntil.dispatch({});
    }, [uiPanelListCommonHelper]);

    return (
      <AppPanel
        onNext={onCloseClick}
        onEsc={onCloseClick}
        labelNext={intl.formatMessage({
          id: messageIds.shared.close,
        })}
      >
        {notesList &&
          notesList.map((note, index) => (
            <div
              key={note.title}
              css={[
                css`
                  padding: 16px 0 16px 16px;
                  border-bottom: 1px solid ${theme.semanticColors.bodyDivider};
                `,
                index === 0 &&
                  css`
                    border-top: 1px solid ${theme.semanticColors.bodyDivider};
                  `,
              ]}
            >
              <div
                css={[
                  css`
                    margin-bottom: 4px;
                  `,
                  cssTypography.body1Stronger,
                ]}
              >
                {note.title}
              </div>
              <div
                css={[
                  css`
                    margin-bottom: 8px;
                  `,
                  cssTypography.body1,
                ]}
              >
                <span>{note.description}</span>
                <span>&nbsp;</span>

                {note.link && (
                  <span>
                    <HyperLink
                      href={note.link}
                      externalLink={true}
                      underline={true}
                      aria-label={intl.formatMessage(
                        {
                          id: messageIds.releaseNotes.learnMoreAbout,
                        },
                        { content: note.title }
                      )}
                    >
                      {intl.formatMessage({
                        id: messageIds.shared.learnMore,
                      })}
                    </HyperLink>
                  </span>
                )}
                <span>&nbsp;</span>
              </div>
              <div css={cssTypography.caption1}>
                <span>
                  {intl.formatDate(note.date, {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  })}
                </span>
              </div>
            </div>
          ))}
      </AppPanel>
    );
  }
);
