import {
  initializeIcons as initAzureIcons,
  isNullOrEmpty,
} from "@adux/common-react";
import "@adux/common-react/dist/babylon-icon.css";
import { Global, css } from "@emotion/react";
import { initializeIcons } from "@fluentui/react";
import { PurviewModeSettings } from "@msbabylon/purview-util/constants";
import { generateGlobalFont } from "@msbabylon/purview-util/styles";
import { StandardPurviewAccountInfo } from "@msbabylon/sdk-hub";
import { ShellEnvironment, uiComponentStore } from "@msbabylon/shell-core";
import {
  ContainerBuildStoreType,
  buildAppWrapper,
  containerBuilderStore,
  featureConstants,
  rootModels,
} from "@msbabylon/shell-framework";
import { AxiosError } from "axios";
import "normalize.css";
import { GetContainer } from "nyax";
import ReactDOM from "react-dom";
import "react-toastify/dist/ReactToastify.css";
import { TenantAccountInfo } from "src/apis/BabylonApi";
import { buildAppError } from "src/components/AppError";
import { buildAppBreadcrumb } from "src/containers/AppBreadcrumb";
import { buildAppDynamicContent } from "src/containers/AppDynamicContent";
import { buildAppLanding } from "src/containers/AppLanding";
import { buildAppSidebarHomeMenu } from "src/containers/AppSidebarHomeMenu";
import { buildAppTopBarCommonMenuButtonDataHooks } from "src/containers/AppTopBarButtonDatas";
import { buildAppTopBarProduct } from "src/containers/AppTopBarProduct";
import { buildAppTopBarToggle } from "src/containers/AppTopBarToggle";
import { buildIsHomeHook } from "src/containers/BuildIsHomeHook";
import { buildAppCopilot } from "src/containers/Copilot/AppCopilot";
import M365UIMainAllApps from "src/containers/M365/M365UIMainAllApps";
import M365UIMainHome from "src/containers/M365/M365UIMainHome";
import DialogMergeSelectCategory from "src/containers/Reconcile/DialogMergeSelectCategory";
import UIPanelDiagnostics from "src/containers/UIPanelDiagnostics";
import UIPanelFeedback from "src/containers/UIPanelFeedback";
import UIPanelInstance from "src/containers/UIPanelInstance";
import UIPanelReleaseNotes from "src/containers/UIPanelReleaseNotes";
import UIPanelSettings from "src/containers/UIPanelSettings";
import { dependencies } from "src/dependencies";
import {
  M365ShellAllAppsId,
  M365ShellAllAppsPathname,
  M365ShellHomeId,
  M365ShellHomePathname,
} from "src/entries/constant";
import initAccount, { handleAccountError } from "src/entries/init/initAccount";
import initBannerMessage from "src/entries/init/initBannerMessage";
import initCustomPortal from "src/entries/init/initCustomPortal";
import initHideExtension from "src/entries/init/initHideExtension";
import initLocale from "src/entries/init/initLocale";
import initMessageListener from "src/entries/init/initMessageListener";
import initNavMenu from "src/entries/init/initNavMenu";
import initReconcile from "src/entries/init/initReconcile";
import { getECFeatureNames, getECResultMapping } from "src/features";
import { cesModel } from "src/store/shell-models/babylon/ces";
import {
  ReconcileStatus,
  babylonInstanceModel,
} from "src/store/shell-models/babylon/instance";
import { releaseNotesModel } from "src/store/shell-models/babylon/releaseNotes";
import { initializeSvgIcons } from "src/util/icon";
import { equalsCaseInsensitive } from "src/util/string";

initializeSvgIcons();
initializeIcons(`${dependencies.application.tier.cdnExtensionUrl}/icons/`);
initAzureIcons(`${dependencies.application.tier.cdnExtensionUrl}/opac-icons/`);

uiComponentStore.addUIComponent("panelInstance", UIPanelInstance);
uiComponentStore.addUIComponent("panelDiagnostics", UIPanelDiagnostics);
uiComponentStore.addUIComponent("panelReleaseNotes", UIPanelReleaseNotes);
uiComponentStore.addUIComponent("panelSettings", UIPanelSettings);
uiComponentStore.addUIComponent("panelBabylonFeedback", UIPanelFeedback);
uiComponentStore.addUIComponent(
  "dialogMergeSelectCategory",
  DialogMergeSelectCategory
);
uiComponentStore.addUIComponent(M365ShellAllAppsId, M365UIMainAllApps);
uiComponentStore.addUIComponent(M365ShellHomeId, M365UIMainHome);
uiComponentStore.addLocationResolver((location) => {
  if (location.pathname === M365ShellAllAppsPathname) {
    return {
      type: M365ShellAllAppsId,
      params: {},
    };
  }
  if (location.pathname === M365ShellHomePathname) {
    return {
      type: M365ShellHomeId,
      params: {},
    };
  }
  return null;
});

const initStore: Partial<ContainerBuildStoreType> = {
  buildAppError: buildAppError,
  buildAppLanding: buildAppLanding,
  buildAppTopBarCommonMenuButtonDataHooks,
  buildAppTopBarProduct: buildAppTopBarProduct,
  buildAppBreadcrumb: buildAppBreadcrumb,
  buildAppSidebarHomeMenu: buildAppSidebarHomeMenu,
  buildIsHomeHook: buildIsHomeHook,
  buildAppDynamicContent,
  buildAppTopBarCustom: buildAppTopBarToggle,
  buildAppRightContainer: buildAppCopilot,
};

containerBuilderStore.init(initStore);

async function readECFeatures(getContainer: GetContainer) {
  const initFeatureNames = getECFeatureNames();
  const ecResult = await getContainer(
    rootModels.feature
  ).actions.batchReadFromCache.dispatch(initFeatureNames);
  return ecResult;
}

ShellEnvironment.beforeAppInitialize = async ({
  getContainer,
  dependencies: _deps,
}) => {
  dependencies.logger.logEvent(
    "info",
    "Initialization",
    "beforeAppInitalize starts."
  );
  const resourceName = dependencies.application.resourceName;
  const babylonModel = getContainer(babylonInstanceModel);
  let account: StandardPurviewAccountInfo | undefined;
  let tenantAccount: TenantAccountInfo | undefined;
  let isReconciledAccount = false;

  if (!isNullOrEmpty(resourceName)) {
    const sharedRoot = getContainer(rootModels.shared);
    try {
      const result = await Promise.allSettled([
        dependencies.babylonApi.getCurrentInstance(),
        dependencies.babylonApi.checkTenant(),
        readECFeatures(getContainer), // pre-read EC features to avoid sequential waiting
      ]);

      account = result[0].status === "fulfilled" ? result[0].value : undefined;
      const accountError =
        result[0].status === "rejected" ? result[0].reason : undefined;
      tenantAccount =
        result[1].status === "fulfilled" ? result[1].value : undefined;
      const tenantAccountError =
        result[1].status === "rejected" ? result[1].reason : undefined;

      const accountName =
        account?.name ||
        tenantAccount?.provisionedAccountProperties?.accountName ||
        resourceName;

      if (accountName) {
        sharedRoot.actions.setResourceName.dispatch(accountName.toLowerCase());
      }
      if (account) {
        babylonModel.actions.setAzureAccountInstance.dispatch(account);
      }
      if (tenantAccount) {
        babylonModel.actions.setTenantAccount.dispatch(tenantAccount);
      }

      // #region reconciled account
      const tenantProvisionedAccountName =
        tenantAccount?.provisionedAccountProperties?.accountName;
      isReconciledAccount =
        !!tenantProvisionedAccountName &&
        equalsCaseInsensitive(resourceName, tenantProvisionedAccountName) &&
        equalsCaseInsensitive(tenantProvisionedAccountName, accountName) &&
        equalsCaseInsensitive(
          tenantAccount?.provisionedAccountType,
          "Microsoft.Purview/accounts"
        );

      const modeSettings = dependencies.application
        .modeSettings as PurviewModeSettings;
      babylonModel.actions.setIsReconciledAccount.dispatch(isReconciledAccount);
      if (modeSettings && isReconciledAccount) {
        modeSettings.tier = "Reconciled";
      }
      babylonModel.actions.setReconcileStatus.dispatch(
        tenantAccount?.isEligibleForManualReconcile
          ? ReconcileStatus.readyForReconcile
          : isReconciledAccount
          ? ReconcileStatus.selfReconciled
          : tenantProvisionedAccountName
          ? ReconcileStatus.othersReconciled
          : ReconcileStatus.none
      );
      // #endregion

      if (account) {
        getContainer(rootModels.app).actions.setAccount.dispatch(account);
      }

      if (accountError || tenantAccountError) {
        throw accountError || tenantAccountError;
      }
    } catch (error) {
      const curLoc = dependencies.router.getLocation();
      if (!curLoc.search?.includes("tenant")) {
        const tid = (await dependencies.auth.getAccount())?.idTokenClaims.tid;
        dependencies.router.replaceUrl({
          ...curLoc,
          search:
            curLoc.search +
            (curLoc.search ? "&" : "?") +
            `${featureConstants.featurePrefix}tenant=${tid ?? ""}`,
        });
      }

      const notesModel = getContainer(releaseNotesModel);
      notesModel.actions.readReleaseNotes.dispatch({});

      if (isReconciledAccount) {
        // Allow no-collection based auth users to access Purview portal when it's a reconciled account
        return;
      }

      // account error
      const accountError = account ? undefined : error;
      // tenant account error
      const tenantAccountError = accountError ? undefined : error;
      let swallowError: boolean = isReconciledAccount;

      if (accountError as AxiosError | undefined) {
        return handleAccountError(getContainer, accountError as AxiosError);
      }

      if (tenantAccountError) {
        swallowError = true;
      }

      if (!swallowError) {
        dependencies.logger.logEvent(
          "error",
          "Initialization",
          "beforeAppInitalize throw error"
        );
        throw error;
      }
    }
  }

  dependencies.logger.logEvent(
    "info",
    "Initialization",
    "beforeAppInitalize ends."
  );
};

ShellEnvironment.onAppInitialize = async ({ getContainer }) => {
  dependencies.logger.logEvent(
    "info",
    "Initialization",
    "onAppInitialize starts."
  );

  try {
    const initExtensions = !!dependencies.application.resourceName;
    if (initExtensions) {
      // fetch geneva features
      const ecResult = await readECFeatures(getContainer);
      const ff = getECResultMapping(ecResult);

      // Do Not Add EC features here !!! It won't work !!!
      // Do Not Add EC features here !!! It won't work !!!
      // Do Not Add EC features here !!! It won't work !!!
      // Do Not Add EC features here !!! It won't work !!!
      // Do Not Add EC features here !!! It won't work !!!
      dependencies.application.features.logo =
        dependencies.application.features.logo || !!ff.CustomBranding;
      dependencies.application.features.accountMerge =
        dependencies.application.features.accountMerge || !!ff.AccountMerge;
      dependencies.application.features.accountMergeSecondary =
        dependencies.application.features.accountMergeSecondary ||
        !!ff.AccountMergeSecondary;
      dependencies.application.features.tryUnifedPortalCallout =
        dependencies.application.features.tryUnifedPortalCallout ||
        !!ff.TryUnifedPortalCallout;
      dependencies.application.features.enableCopilot =
        dependencies.application.features.enableCopilot || !!ff.EnableCopilot;

      await initHideExtension(getContainer, "policy", ff.DataPolicyGUI);
      await initHideExtension(getContainer, "privacyconsent", ff.Privacy);
      await initHideExtension(getContainer, "cseo", ff.EnterpriseData);

      await initNavMenu(
        getContainer,
        "datashare",
        "datashare",
        !!ff.ShowShareV1
      );

      // call cse eligibilities api and pop up the feedback form if eligible after 15 seconds
      setTimeout(() => {
        getContainer(cesModel).actions.postEligibilities.dispatch({});
      }, 15 * 1000);

      // call eligibilities API every day when user stays in the page
      setInterval(() => {
        getContainer(cesModel).actions.postEligibilities.dispatch({});
      }, 24 * 60 * 60 * 1000);

      // TO enable Assessment extension, please add EC here
    }

    await initCustomPortal();
    await initMessageListener(getContainer);
    await initAccount(getContainer);
  } catch (err) {
    dependencies.logger.logEvent(
      "error",
      "Initialization",
      "onAppInitialize failed with error"
    );
    throw err;
  }

  initLocale();

  const notesModel = getContainer(releaseNotesModel);
  notesModel.actions.readReleaseNotes.dispatch({});

  initBannerMessage(getContainer);
  initReconcile(getContainer);

  dependencies.logger.logEvent(
    "info",
    "Initialization",
    "onAppInitialize ends."
  );
};

ShellEnvironment.onAppInitialized = async ({ getContainer }) => {
  dependencies.logger.logEvent("info", "Initialization", "onAppInitialized");
  dependencies.shellExtension.sendMessage("hub", { type: "", payload: "" }); // to kick up Hub extension loaded first
};

const App = containerBuilderStore.store.buildApp();
const AppWrapper = buildAppWrapper();

ReactDOM.render(
  <AppWrapper dependencies={dependencies}>
    <Global
      styles={[
        css`
          ${generateGlobalFont(dependencies.application.tier.cdnExtensionUrl)}
        `,
      ]}
    />
    <App />
  </AppWrapper>,
  document.getElementById("root")
);
