import { createUIViewModel } from "@msbabylon/shell-core";
import { ThemeType } from "@msbabylon/shell-core/lib/services/ThemeService";
import { featureConstants, rootModels } from "@msbabylon/shell-framework";
import { createModel } from "nyax";
import messageIds from "src/locales/messageIds";

const languageFeatureName = "language";

export const uiViewPanelSettingsModel = createModel(
  class extends createUIViewModel() {
    initialState() {
      return {
        ...super.initialState(),
        language: this.dependencies.locale.languageName,
        isDirty: false,
      };
    }

    selectors() {
      return {
        ...super.selectors(),
        title: () =>
          this.dependencies.intl.formatMessage({
            id: messageIds.settings.$title,
          }),
        theme: () => this.getContainer(rootModels.theme).getters.theme,
        language: () => this.state.language,
      };
    }

    reducers() {
      return {
        ...super.reducers(),
        setLanguage: (language: string) => {
          this.state.language = language;
          this.state.isDirty = true;
        },
      };
    }

    effects() {
      return {
        ...super.effects(),
        setTheme: async (theme: ThemeType) => {
          await this.getContainer(
            rootModels.theme
          ).actions.changeThemeRequest.dispatch(theme);
        },
        applyLanguage: async () => {
          this.dependencies.locale.changeLanguage(this.getters.language);
          const languageParamName = `${featureConstants.featurePrefix}${languageFeatureName}`;
          const queryPairs = !window.location.search
            ? []
            : window.location.search
                .substring(1)
                .split("&")
                .map((str) => {
                  const index = str.indexOf("=");
                  return index >= 0
                    ? [str.substring(0, index), str.substring(index + 1)]
                    : [str, ""];
                })
                .filter((e) => e[0] !== languageParamName);
          queryPairs.push([languageParamName, this.getters.language]);
          const query = "?" + queryPairs.map((e) => e.join("=")).join("&");

          window.location.search = query;
        },
        apply: async () => {
          if (this.getters.language !== this.dependencies.locale.languageName) {
            await this.actions.applyLanguage.dispatch({});
          }
        },
      };
    }
  },
  { isLazy: true, isDynamic: true }
);
