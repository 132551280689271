/** @jsxImportSource @emotion/react */
import {
  cssLayout,
  cssTypography,
  HyperLink,
  FabricPrimaryButton as PrimaryButton,
} from "@adux/common-react";
import { css } from "@emotion/react";
import { Spinner, SpinnerSize } from "@fluentui/react";
import { SharedIcon, useGetContainer } from "@msbabylon/shell-core";
import { Container, createSubContainer } from "nyax";
import React, { useCallback, useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import purviewIconLogo from "src/assets/purviewIconLogo.svg";
import SharedBabylonInstanceSwitcher from "src/containers/SharedBabylonInstanceSwitcher";
import { useDependencies } from "src/hook";
import messageIds from "src/locales/messageIds";
import { uiLandingModel } from "src/store/shell-models/ui/landing";
import { uiSharedBabylonInstanceSwitcherModel } from "src/store/shell-models/ui/SharedBabylonInstanceSwitcher";
import { isEmptyOrNull } from "src/util/string";

const redirectToTenantAccount = (() => {
  return /redirectToTenantAccount=true/i.test(window.location.search);
})();

export const LandingSelectAccountPage = React.memo<{
  className?: string;
}>(function AppLanding(props) {
  const { className } = props;

  const intl = useIntl();
  const dependencies = useDependencies();
  const getContainer = useGetContainer();
  const uiLanding = getContainer(uiLandingModel);
  const uiBabylonInstanceSwitcherContainer = useMemo(
    () =>
      createSubContainer(uiLanding, "instanceSwitcher") as Container<
        typeof uiSharedBabylonInstanceSwitcherModel
      >,
    [uiLanding]
  );

  const canContinue = useSelector(
    () =>
      uiBabylonInstanceSwitcherContainer &&
      uiBabylonInstanceSwitcherContainer.getters.canSwitch
  );

  const onContinue = useCallback(() => {
    if (uiBabylonInstanceSwitcherContainer) {
      uiBabylonInstanceSwitcherContainer.actions.switch.dispatch({});
    }
  }, [uiBabylonInstanceSwitcherContainer]);

  useEffect(() => {
    const redirectToHomepage = async () => {
      if (redirectToTenantAccount === true) {
        let resourceName: string | undefined = undefined;
        try {
          const account = await dependencies.babylonApi.checkTenant();
          resourceName = account?.provisionedAccountProperties?.accountName;
        } catch (e) {
          return;
        } finally {
          const urlFeature = dependencies.urlFeature.getFeatureQuery();
          const features = !isEmptyOrNull(urlFeature) ? urlFeature : undefined;
          const url = new URL(
            window.location.origin +
              (resourceName ? `/resource/${resourceName}` : "") +
              (features ? `?${features}` : "")
          );
          window.location.href = url.toString();
        }
      }
    };

    redirectToHomepage();
  }, [dependencies]);

  if (redirectToTenantAccount === true) {
    return (
      <Spinner
        css={css`
          display: flex;
          flex-grow: 1;
        `}
        size={SpinnerSize.large}
      />
    );
  }

  return (
    <div
      css={css`
        display: flex;
        flex-direction: row;
        flex-grow: 1;
      `}
      className={className}
      role="main"
    >
      <div
        css={css`
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          gap: 60px 120px;
          margin: auto;
          flex-basis: 1000px;
          ${cssLayout.mediaQueries.down.xl} {
            gap: 0px 120px;
          }
        `}
      >
        <div
          css={css`
            ${cssLayout.mediaQueries.down.xl} {
              width: 100%;
              display: flex;
              align-items: flex-start;
              margin: 0 25px;
              flex-basis: 525px;
            }
          `}
        >
          <SharedIcon
            css={css`
              ${cssLayout.mediaQueries.down.xl} {
                width: 64px;
                height: auto;
              }
            `}
            src={purviewIconLogo}
            size={250}
          />
        </div>
        <div
          css={css`
            display: flex;
            flex-direction: column;
            margin: 25px;
            flex-basis: 575px;
          `}
        >
          <div
            css={css`
              margin-bottom: 16px;

              ${cssTypography.hero}
            `}
            role="heading"
            aria-level={1}
          >
            {intl.formatMessage({ id: messageIds.instance.$landingTitle })}
          </div>

          <div
            css={css`
              margin-bottom: 16px;

              ${cssTypography.bodyText}
            `}
          >
            <span>
              {intl.formatMessage({
                id: messageIds.instance.$landingDescription,
              })}
            </span>
            <span>&nbsp;</span>
            <span>
              <HyperLink
                href="https://go.microsoft.com/fwlink/?linkid=2149379"
                externalLink={true}
                underline={true}
              >
                {intl.formatMessage({
                  id: messageIds.shared.learnMore,
                })}
              </HyperLink>
            </span>
          </div>

          <SharedBabylonInstanceSwitcher
            container={uiBabylonInstanceSwitcherContainer}
          />
          <div>
            <PrimaryButton disabled={!canContinue} onClick={onContinue}>
              {intl.formatMessage({ id: messageIds.shared.continue })}
            </PrimaryButton>
          </div>
        </div>
      </div>
    </div>
  );
});
