import { EventRow } from "src/logger/EventRow";

export class ApiFailureEvent extends EventRow {
  constructor(
    url: string,
    httpStatus: number | string,
    message?: string,
    details?: string
  ) {
    super("ApiFailure", {
      url,
      httpStatus,
      message,
      details,
    });
  }
}
