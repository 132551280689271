/** @jsxImportSource @emotion/react */
import { cssTypography, SelectOption, useTheme } from "@adux/common-react";
import { css } from "@emotion/react";
import { MessageBarType } from "@fluentui/react";
import { useGetContainer } from "@msbabylon/shell-core";
import { rootModels } from "@msbabylon/shell-framework";
import { Container } from "nyax";
import React, { useCallback, useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import AppMessageBar from "src/components/AppMessageBar";
import AppSelect from "src/components/AppSelect";
import messageIds from "src/locales/messageIds";
import { BabylonAccount } from "src/models/babylon";
import {
  AccountValidationStatus,
  uiSharedBabylonInstanceSwitcherModel,
} from "src/store/shell-models/ui/SharedBabylonInstanceSwitcher";
import { naturalSort } from "src/util/sort";

export default React.memo<{
  className?: string;
  container: Container<typeof uiSharedBabylonInstanceSwitcherModel>;
}>(function SharedBabylonInstanceSwitcher(props) {
  const { className, container } = props;
  const theme = useTheme();

  const intl = useIntl();

  const getContainer = useGetContainer();
  const auth = getContainer(rootModels.auth);
  const userTenantId = useSelector(() => auth.getters.tenantId ?? "");
  const tenants = useSelector(() => container.getters.tenants);
  const tenantOptions = useMemo(
    () =>
      tenants.map((e) => ({
        label: e.displayName,
        value: e.tenantId,
      })),
    [tenants]
  );
  const selectedTenantId = useSelector(() => container.state.selectedTenantId);
  const onSelectedTenantIdChange = useCallback(
    (value: string | null) => {
      container.actions.selectTenantId.dispatch(value!);
    },
    [container]
  );
  const isTenantsLoading = useSelector(
    () => container.getters.isTenantsLoading
  );

  const createSelectOption = useCallback(
    (label: string, friendlyName: string | undefined) => {
      if (friendlyName == null) {
        return undefined;
      }
      return (
        <div
          css={css`
            display: flex;
            width: 100%;
          `}
        >
          <div
            title={label}
            css={css`
              width: 40%;
              text-overflow: ellipsis;
              overflow: hidden;
            `}
          >
            {label}
          </div>
          {friendlyName && (
            <div
              css={css`
                text-overflow: ellipsis;
                overflow: hidden;
                margin-left: 5px;
                color: ${theme.semanticColors.bodySubtext};
              `}
              title={friendlyName}
            >
              {friendlyName}
            </div>
          )}
        </div>
      );
    },
    [theme]
  );
  const instances = useSelector(() => container.getters.instances);
  const defaultAccount = useSelector(() => container.getters.defaultAccount);
  const instanceOptions = useMemo(() => {
    const options: SelectOption<BabylonAccount>[] = [];
    let defaultAccountOpiton: SelectOption<BabylonAccount> | undefined;
    const defaultAccountName = defaultAccount?.defaultAccountId
      ?.split("/")
      .pop();
    for (const instance of instances) {
      if (instance.name === defaultAccountName) {
        const label = intl.formatMessage(
          { id: messageIds.instance.defaultAccountLabel },
          { name: instance.name }
        );
        defaultAccountOpiton = {
          label,
          customLabel: createSelectOption(label, instance.friendlyName),
          value: instance,
        };
      } else {
        const label = instance.name;
        options.push({
          label,
          value: instance,
          customLabel: createSelectOption(label, instance.friendlyName),
        });
      }
    }
    options.sort((a, b) => naturalSort(a.label, b.label));
    if (defaultAccountOpiton) {
      options.unshift(defaultAccountOpiton);
    }
    return options;
  }, [defaultAccount?.defaultAccountId, instances, intl, createSelectOption]);
  const selectedInstance = useSelector(() => container.state.selectedInstance);
  const onSelectedInstanceChange = useCallback(
    (value: BabylonAccount) => {
      container.actions.selectInstance.dispatch(value);
    },
    [container]
  );
  const isInstancesLoading = useSelector(
    () => container.getters.isInstancesLoading
  );

  const validationStatus = useSelector(() => container.state.validationStatus);

  useEffect(() => {
    if (!selectedTenantId && tenants.length > 0) {
      container.actions.selectTenantId.dispatch(userTenantId);
    }
  }, [selectedTenantId, tenants, container, userTenantId]);

  const listAccountFailedReason = useSelector(
    () => container.state.listAccountFailedReason
  );

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
      `}
      className={className}
    >
      <div
        css={css`
          margin-bottom: 16px;
        `}
      >
        <div
          css={css`
            ${cssTypography.heading3}
          `}
        >
          {intl.formatMessage({
            id: messageIds.instance.azureActiveDirectory,
          })}
        </div>
        <AppSelect
          options={tenantOptions}
          value={selectedTenantId}
          onValueChange={onSelectedTenantIdChange}
          isLoading={isTenantsLoading}
          isSearchable={true}
          ariaLabel={intl.formatMessage({
            id: messageIds.instance.azureActiveDirectory,
          })}
        />
      </div>
      <div
        css={css`
          margin-bottom: 16px;
        `}
      >
        <div
          css={css`
            ${cssTypography.heading3}
          `}
        >
          {intl.formatMessage({
            id: messageIds.instance.instanceName,
          })}
        </div>
        <AppSelect
          options={instanceOptions}
          value={selectedInstance}
          onValueChange={onSelectedInstanceChange}
          isLoading={isInstancesLoading}
          isSearchable={true}
          ariaLabel={intl.formatMessage({
            id: messageIds.instance.instanceName,
          })}
          isVirtualized={true}
          styles={{
            subComponentStyles: {
              select: {
                singleValue: {
                  width: "calc(100% - 10px)",
                },
              },
            },
          }}
        />
      </div>
      {validationStatus === AccountValidationStatus.Validating && (
        <div
          css={css`
            margin-bottom: 16px;
          `}
        >
          <AppMessageBar
            messageBarType={MessageBarType.info}
            isMultiline={true}
            styles={{
              innerText: cssTypography.bodyText,
            }}
          >
            {intl.formatMessage({
              id: messageIds.instance.validating,
            })}
          </AppMessageBar>
        </div>
      )}
      {((validationStatus !== AccountValidationStatus.None &&
        validationStatus !== AccountValidationStatus.Validating) ||
        listAccountFailedReason) && (
        <div
          css={css`
            margin-bottom: 16px;
          `}
        >
          <AppMessageBar
            messageBarType={MessageBarType.error}
            isMultiline={true}
            styles={{
              innerText: cssTypography.bodyText,
            }}
          >
            {listAccountFailedReason
              ? intl.formatMessage(
                  {
                    id: messageIds.instance.listAccountFailedMsg,
                  },
                  {
                    reason: listAccountFailedReason,
                  }
                )
              : null}
            {validationStatus === AccountValidationStatus.ServiceUnavailable &&
              intl.formatMessage({
                id: messageIds.instance.internalServerError,
              })}
            {validationStatus ===
              AccountValidationStatus.AccountProtectedByPE &&
              intl.formatMessage({
                id: messageIds.instance.protectedByPE,
              })}
            {validationStatus === AccountValidationStatus.NoPermission &&
              intl.formatMessage({
                id: messageIds.instance.noPermission,
              })}
          </AppMessageBar>
        </div>
      )}
    </div>
  );
});
