import { dependencies } from "src/dependencies";
import { msTenantIds } from "src/entries/constant";
import { getFeatures } from "src/features";

const liveAccountTid = "9188040d-6c67-4c5b-b112-36a304b66dad";

export default async () => {
  const account = await dependencies.auth.getAccount();
  const tid = account?.idTokenClaims.tid;
  const idp = account?.idTokenClaims.idp || "";

  // if the login account is live.com account, the token acquire would fail when we don't have any tenantId specified
  // thus we force to redirect to tenant selection page
  if (
    !!dependencies.application.resourceName &&
    !dependencies.application.features.tenant &&
    (idp === "live.com" || idp.indexOf(liveAccountTid) >= 0)
  ) {
    window.location.href = window.location.origin;
  }

  const isMsTenant = msTenantIds.indexOf(tid || "") >= 0;

  if (getFeatures().customportal && getFeatures().redirect) {
    // As now we only have the production endpoint for M365, disable the auto redirect for M365 case.
    if (isMsTenant && process.env.REACT_APP_UI_ENVIRONMENT === "PROD") {
      window.location.href =
        "https://ms.web.purview.azure.com" +
        window.location.pathname +
        window.location.search;
    } else if (!isMsTenant && process.env.REACT_APP_UI_ENVIRONMENT === "MS") {
      window.location.href =
        "https://web.purview.azure.com" +
        window.location.pathname +
        window.location.search;
    }
  }
};
