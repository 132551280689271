/** @jsxImportSource @emotion/react */
import { Breadcrumb, shouldIgnoreLinkClickHandler } from "@adux/common-react";
import { IBreadcrumbItem } from "@fluentui/react";
import { parseExtensionUIHref } from "@msbabylon/core";
import { useGetContainer } from "@msbabylon/shell-core";
import { extensionConstants, rootModels } from "@msbabylon/shell-framework";
import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { catalogToc } from "src/containers/AppSidebarHomeMenu";
import { useDependencies } from "src/hook";
import messageIds from "src/locales/messageIds";
import { createShellHrefByHref } from "src/util/createShellHrefByHref";

function ignoreClickHandler(
  callback: () => void
): (event?: React.MouseEvent<HTMLElement>) => void {
  return (event?: React.MouseEvent<HTMLElement>) => {
    if (event && shouldIgnoreLinkClickHandler(event)) {
      return;
    }
    event?.preventDefault();
    callback();
  };
}

function useSidebarSelectedMenu() {
  const getContainer = useGetContainer();
  const uiSidebar = getContainer(rootModels.ui.sidebar);
  const menuItems = useSelector(() => uiSidebar.getters.menuItems);
  const selectedMenuId = useSelector(() => uiSidebar.state.selectedMenuItemId);
  return useMemo(
    () => menuItems.find((m) => m.id === selectedMenuId),
    [menuItems, selectedMenuId]
  );
}

// Hide breadcrumb in several cases
function useFilteredBreadcrumbItems(items: IBreadcrumbItem[]) {
  const getContainer = useGetContainer();

  const isTocOpen = useSelector(
    () =>
      getContainer(rootModels.ui.list.side.entity).getters.uiItems.length > 0
  );
  const isLoading = useSelector(
    () =>
      !getContainer(rootModels.ui.list.main.entity).getters.currentUIItem
        ?.isInitialized
  );
  const uiSidebar = getContainer(rootModels.ui.sidebar);
  const isCatalog = useSelector(
    () =>
      uiSidebar.state.selectedMenuItemId ===
      `${extensionConstants.catalog}/${catalogToc.id}`
  );

  return useMemo(() => {
    if (items.length <= 1) {
      return [];
    }
    // work around for breadcrumb flickering
    if (items.length <= 2 && (isTocOpen || isLoading)) {
      return [];
    }
    // Work around for the non-home page breadcrumb. Catalog homepage doesn't show up in the item array, the length is less.
    if (items.length <= 2 && !isTocOpen && !isCatalog) {
      return [];
    }
    return items;
  }, [isCatalog, isLoading, isTocOpen, items]);
}

const HOME_MENU_KEY = "_HOME_MEMU";
const CATALOG_HOME_PATH = "home";

function useBreadbrumbItems() {
  const intl = useIntl();
  const getContainer = useGetContainer();
  const dependencies = useDependencies();
  const uiMainListEntity = getContainer(rootModels.ui.list.main.entity);
  const uiMainListCommonHelper = getContainer(
    rootModels.ui.list.main.commonHelper
  );
  const uiItems = useSelector(() => uiMainListEntity.getters.uiItems);
  const sidebarSelectedMenu = useSidebarSelectedMenu();
  const resourceName = dependencies.application.resourceName || "";

  const breadcrumbItems = useMemo(() => {
    const result = uiItems
      .map((item) => {
        const helper = getContainer(
          rootModels.extension.item.helperBySessionId,
          item.sessionId
        );
        const ext = helper.getters.item;
        if (!ext) {
          // remove empty items in following `filter` method
          return undefined!;
        }
        const extensionName = ext.extensionName;
        return {
          key: item.uiId,
          text: item.title,
          href: createShellHrefByHref(
            {
              resourceName,
              extensionName,
              href: item.location.pathname,
              search: item.location.search,
            },
            dependencies
          ),
          onClick: ignoreClickHandler(() => {
            const index = uiMainListEntity.state.allIds.indexOf(item.uiId);
            uiMainListCommonHelper.actions.closeUntil.dispatch(
              index < 0 ? {} : { index }
            );
          }),
        };
      })
      .filter(Boolean);

    if (sidebarSelectedMenu) {
      result.unshift({
        key: HOME_MENU_KEY,
        text: sidebarSelectedMenu.labelId
          ? intl.formatMessage({ id: sidebarSelectedMenu.labelId })
          : sidebarSelectedMenu.label,
        href: createShellHrefByHref(
          {
            resourceName,
            extensionName: sidebarSelectedMenu.extensionName,
            href: sidebarSelectedMenu.href,
          },
          dependencies
        ),
        onClick: ignoreClickHandler(() => {
          const parsed = parseExtensionUIHref(sidebarSelectedMenu.href);
          uiMainListCommonHelper.actions.replace.dispatch({
            extensionName:
              parsed.extensionName || sidebarSelectedMenu.extensionName,
            location: parsed.location,
          });
        }),
      });
    } else {
      // Use home page link on no sidebar page.
      result.unshift({
        key: HOME_MENU_KEY,
        text: intl.formatMessage({ id: messageIds.home.$title }),
        href: createShellHrefByHref(
          {
            resourceName,
            extensionName: catalogToc.extensionName,
            href: CATALOG_HOME_PATH,
          },
          dependencies
        ),
        onClick: ignoreClickHandler(() => {
          const parsed = parseExtensionUIHref(
            CATALOG_HOME_PATH,
            catalogToc.extensionName
          );
          uiMainListCommonHelper.actions.replace.dispatch({
            extensionName: parsed.extensionName,
            location: parsed.location,
          });
        }),
      });
    }
    return result;
  }, [
    resourceName,
    intl,
    sidebarSelectedMenu,
    uiMainListCommonHelper,
    getContainer,
    uiItems,
    uiMainListEntity,
    dependencies,
  ]);
  return useFilteredBreadcrumbItems(breadcrumbItems);
}

export const buildAppBreadcrumb = () =>
  React.memo<{
    className?: string;
  }>(function AppBreadcrumb({ className }) {
    const items = useBreadbrumbItems();
    return items.length > 0 ? (
      <Breadcrumb
        className={className}
        items={items}
        styles={{
          item: {
            fontWeight: "normal !important",
          },
          listItem: items.length > 1 && {
            ":last-child": {
              visibility: "hidden",
            },
          },
        }}
      />
    ) : (
      <></>
    );
  });
