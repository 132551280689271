/** @jsxImportSource @emotion/react */
import { isEnterKey, isSpacebar, useTheme } from "@adux/common-react";
import { css } from "@emotion/react";
import { ActionButton, Icon, TextField } from "@fluentui/react";
import { useGetContainer } from "@msbabylon/shell-core";
import React, { FormEvent, useCallback, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import IconClean from "src/assets/Copilot/icon-clean.svg";
import messageIds from "src/locales/messageIds";
import { uiCopilotModel } from "src/store/shell-models/ui/copilot";

export default React.memo(function AppCopilotInput() {
  const intl = useIntl();
  const theme = useTheme();

  const getContainer = useGetContainer();
  const ui = getContainer(uiCopilotModel);

  const isPolling = useSelector(() => ui.state.isPolling);

  const [inputVal, updateInputval] = useState<string | undefined>(undefined);

  const onInputChange = useCallback(
    (
      event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      newValue?: string | undefined
    ) => {
      updateInputval(newValue);
    },
    []
  );

  const onSendClick = useCallback(() => {
    if (isPolling) {
      return;
    }
    const _inputVal = inputVal;
    updateInputval(undefined);

    if (_inputVal) {
      ui.actions.sendMessage.dispatch({
        message: _inputVal,
      });
    }
  }, [inputVal, isPolling, ui.actions.sendMessage]);

  const onInputKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter") {
        if (!e.shiftKey) {
          e.preventDefault();
          onSendClick();
        }
      }
    },
    [onSendClick]
  );

  const onSendKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      e.stopPropagation();
      if (isEnterKey(e) || isSpacebar(e)) {
        onSendClick();
      }
    },
    [onSendClick]
  );

  const onResetClick = useCallback(() => {
    ui.actions.close.dispatch({});
  }, [ui.actions.close]);

  const onResetKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLElement>) => {
      e.stopPropagation();
      if (isEnterKey(e) || isSpacebar(e)) {
        onResetClick();
      }
    },
    [onResetClick]
  );

  return (
    <div
      css={css`
        width: 100%;
        display: flex;
        align-items: center;
        gap: 4px;
      `}
    >
      <ActionButton
        styles={{
          root: {
            height: "32px",
          },
          flexContainer: {
            width: "26px",
            overflow: "hidden",
            transition: "width 0.3s ease-in-out",
            justifyContent: "flex-start",
            ":hover": {
              width: "90px",
            },
            ":hover span": {
              display: "block",
              whiteSpace: "nowrap",
            },
          },
          textContainer: {
            display: "none",
          },
          icon: {
            height: "18px",
            width: "18px",
          },
        }}
        iconProps={{
          imageProps: {
            src: IconClean,
          },
        }}
        onClick={onResetClick}
        onKeyDown={onResetKeyDown}
        text={intl.formatMessage({
          id: messageIds.copilot.actions.newChat,
        })}
        role="none"
      />
      <TextField
        css={css`
          flex: 1;
        `}
        styles={{
          fieldGroup: {
            minHeight: "auto",
          },
          field: {
            resize: "none",
          },
          suffix: {
            background: "transparent",
          },
        }}
        multiline
        rows={2}
        value={inputVal || ""}
        suffix={
          (
            <div
              css={css`
                display: flex;
                align-items: center;
              `}
            >
              <Icon
                css={css`
                  cursor: pointer;
                  font-size: 16px;
                `}
                iconName="Send"
                onClick={onSendClick}
                onKeyDown={onSendKeyDown}
                title={intl.formatMessage({
                  id: messageIds.copilot.actions.send,
                })}
                styles={{
                  root: {
                    color: theme.semanticColors.inputIcon,
                    backgroundColor: "transparent",
                  },
                }}
              />
            </div>
          ) as unknown as string
        }
        onChange={onInputChange}
        onKeyDown={onInputKeyPress}
      />
    </div>
  );
});
