/** @jsxImportSource @emotion/react */
import { Text, useFonts, useTheme } from "@adux/common-react";
import { css } from "@emotion/react";
import { DirectionalHint, Icon, TeachingBubble, Toggle } from "@fluentui/react";
import { useGetContainer } from "@msbabylon/shell-core";
import React, { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { productDocumentations } from "src/constants/documentationLinks";
import { getUnifiedPortalAppUrl } from "src/features";
import { useDependencies } from "src/hook";
import messageIds from "src/locales/messageIds";
import { babylonInstanceModel } from "src/store/shell-models/babylon/instance";
import { reconcileModel } from "src/store/shell-models/babylon/reconcile";
import { colorsLightV9 } from "src/themes/m365-light";

interface ITryNewPortalProduct {
  name: string;
  icon: string;
}

interface ITryNewPortalHighlight {
  textBold: string;
  description: string;
}

export const buildAppTopBarToggle = () => {
  const ProductItem = React.memo<{ item: ITryNewPortalProduct }>(({ item }) => {
    const cssTypography = useFonts();
    const dependencies = useDependencies();
    return (
      <div
        css={[
          css`
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 33.3%;
          `,
          cssTypography.body1,
        ]}
      >
        <img
          src={`${dependencies.application.tier.cdnExtensionUrl}/opac-icons/try-new-portal/${item.icon}`}
          alt={item.name}
        />
        <div
          css={css`
            width: 90%;
            margin-left: 5%;
            margin-top: 4px;
            text-align: center;
          `}
        >
          {item.name}
        </div>
      </div>
    );
  });
  const TooltipContent = React.memo<{
    dismissTeachingBubble: () => void;
  }>(({ dismissTeachingBubble }) => {
    const intl = useIntl();
    const theme = useTheme();
    const cssTypography = useFonts();
    const dependencies = useDependencies();
    const backgroundImageUrl = useMemo(
      () =>
        `${dependencies.application.tier.cdnExtensionUrl}/opac-icons/try-new-portal/img-try-new-portal-background.svg`,
      [dependencies]
    );
    const products: ITryNewPortalProduct[] = useMemo(
      () => [
        {
          name: intl.formatMessage({
            id: messageIds.reconcile.tryTheNewPortal.product.M365,
          }),
          icon: "icon-M365.svg",
        },
        {
          name: intl.formatMessage({
            id: messageIds.reconcile.tryTheNewPortal.product.Azure,
          }),
          icon: "icon-Azure.svg",
        },
        {
          name: intl.formatMessage({
            id: messageIds.reconcile.tryTheNewPortal.product.Fabric,
          }),
          icon: "icon-Fabric.svg",
        },
        {
          name: intl.formatMessage({
            id: messageIds.reconcile.tryTheNewPortal.product.AWS,
          }),
          icon: "icon-AWS.svg",
        },
        {
          name: intl.formatMessage({
            id: messageIds.reconcile.tryTheNewPortal.product.other,
          }),
          icon: "icon-other-cloud.svg",
        },
      ],
      [intl]
    );

    const highlights: ITryNewPortalHighlight[] = useMemo(
      () => [
        {
          textBold: intl.formatMessage({
            id: messageIds.reconcile.tryTheNewPortal.newComplianceSolutions
              .textBold,
          }),
          description: intl.formatMessage({
            id: messageIds.reconcile.tryTheNewPortal.newComplianceSolutions
              .description,
          }),
        },
        {
          textBold: intl.formatMessage({
            id: messageIds.reconcile.tryTheNewPortal.sleekModernLookAndFeel
              .textBold,
          }),
          description: intl.formatMessage({
            id: messageIds.reconcile.tryTheNewPortal.sleekModernLookAndFeel
              .description,
          }),
        },
        {
          textBold: intl.formatMessage({
            id: messageIds.reconcile.tryTheNewPortal.multicloudProtection
              .textBold,
          }),
          description: intl.formatMessage({
            id: messageIds.reconcile.tryTheNewPortal.multicloudProtection
              .description,
          }),
        },
      ],
      [intl]
    );
    return !dependencies.application.features.tryUnifedPortalCallout ? (
      <TeachingBubble
        target={`#tryNewPortalToggle`}
        headline={intl.formatMessage({
          id: messageIds.reconcile.tryTheNewPortalHeadline,
        })}
        calloutProps={{
          directionalHint: DirectionalHint.bottomAutoEdge,
          gapSpace: 12,
          preventDismissOnEvent: () => true,
        }}
        hasCloseButton={true}
        onDismiss={dismissTeachingBubble}
        secondaryButtonProps={{
          text: intl.formatMessage({ id: messageIds.shared.learnMore }),
          href: productDocumentations.upgradeForUnifiedPortal,
          target: "_blank",
        }}
      >
        {intl.formatMessage({
          id: messageIds.reconcile.$tryTheNewPortalMessage,
        })}
      </TeachingBubble>
    ) : (
      <TeachingBubble
        target={`#tryNewPortalToggle`}
        calloutProps={{
          directionalHint: DirectionalHint.bottomAutoEdge,
          gapSpace: 12,
          preventDismissOnEvent: () => true,
        }}
        hasCloseButton={true}
        onDismiss={dismissTeachingBubble}
        primaryButtonProps={{
          text: intl.formatMessage({
            id: messageIds.reconcile.tryNow,
          }),
          onClick: async () => {
            const url = await getUnifiedPortalAppUrl(dependencies);
            window.location.href = url.toString();
          },
        }}
        secondaryButtonProps={{
          text: intl.formatMessage({ id: messageIds.shared.learnMore }),
          href: productDocumentations.upgradeForUnifiedPortal,
          target: "_blank",
        }}
        styles={{
          content: { maxWidth: 480 },
          bodyContent: { paddingTop: 0, paddingLeft: 0 },
          body: { marginRight: 0 },
          closeButton: {
            ":hover": {
              backgroundColor: `${colorsLightV9.themeDarkAlt} !important`,
            },
            ":active": {
              backgroundColor: `${colorsLightV9.themeDark} !important`,
            },
          },
          primaryButton: {
            color: theme.palette.white,
            backgroundColor: "transparent",
            ":hover": {
              color: theme.palette.white,
              backgroundColor: `${colorsLightV9.themeDarkAlt} !important`,
            },
            ":active": {
              color: theme.palette.white,
              backgroundColor: `${colorsLightV9.themeDark} !important`,
            },
            ":focus": {
              outline: "none",
              color: theme.palette.white,
              backgroundColor: `${colorsLightV9.themeDark} !important`,
            },
          },
          secondaryButton: {
            backgroundColor: "transparent",
            border: "none",
            ":hover": {
              color: `${colorsLightV9.themePrimary} !important`,
              backgroundColor: `${colorsLightV9.themePrimary} !important`,
            },
            ":active": {
              backgroundColor: `${colorsLightV9.themeDark} !important`,
            },
            ":focus": {
              outline: "none",
              borderColor: `${theme.palette.white} !important`,
            },
          },
          subComponentStyles: {
            callout: {
              root: {
                maxWidth: 480,
              },
              calloutMain: {
                backgroundColor: `${colorsLightV9.themePrimary} !important`,
              },
              beak: {
                backgroundColor: `${colorsLightV9.themePrimary} !important`,
              },
            },
          },
        }}
      >
        <div
          css={css`
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: baseline;
            width: 480px;
            height: 240px;
            padding: 35px 54px 0px;
            background-image: url(${backgroundImageUrl});
          `}
        >
          {products.map((product, index) => (
            <ProductItem key={index} item={product} />
          ))}
        </div>
        <div
          css={css`
            padding-top: 20px;
            padding-left: 24px;
          `}
        >
          <div
            css={[
              css`
                text-align: center;
                overflow-wrap: break-word;
              `,
              cssTypography.title3,
            ]}
          >
            {intl.formatMessage({
              id: messageIds.reconcile.tryTheNewPortal.headline,
            })}
          </div>
          <div
            css={[
              css`
                text-align: center;
              `,
              cssTypography.body2,
            ]}
          >
            {intl.formatMessage({
              id: messageIds.reconcile.tryTheNewPortal.description,
            })}
          </div>
          <div
            css={[
              css`
                margin-top: 12px;
              `,
              cssTypography.body1,
            ]}
          >
            {highlights.map((highlight, index) => (
              <div
                key={index}
                css={css`
                  display: flex;
                  margin-top: 12px;
                `}
              >
                <Icon
                  iconName="CheckMark"
                  style={{
                    display: "block",
                    fontSize: "20px",
                    marginRight: "8px",
                  }}
                />
                <div>
                  <b>{highlight.textBold}</b>&nbsp;
                  {highlight.description}
                </div>
              </div>
            ))}
          </div>
        </div>
      </TeachingBubble>
    );
  });
  return React.memo(() => {
    const theme = useTheme();
    const intl = useIntl();
    const getContainer = useGetContainer();
    const babylonModel = getContainer(babylonInstanceModel);
    const reconcileHelper = getContainer(reconcileModel);

    const isReconciledAccount = useSelector(
      () => babylonModel.state.isReconciledAccount
    );

    const dependencies = useDependencies();
    const resourceName = useSelector(
      () => dependencies.application.resourceName
    );

    const onSwitch = async (evt: unknown, checked?: boolean) => {
      if (checked) {
        const url = await getUnifiedPortalAppUrl(dependencies);
        window.location.href = url.toString();
      }
    };

    const teachingBubbleDismissed = useSelector(
      () => reconcileHelper.state.teachBubbleDismissed
    );

    const dismissTeachingBubble = useCallback(() => {
      reconcileHelper.actions.dismissReconcileTeachingBubble.dispatch({});
    }, [reconcileHelper.actions.dismissReconcileTeachingBubble]);

    if (!resourceName) {
      // The portal is still in account selection page. Won't show banner in this case.
      return <></>;
    }
    if (!isReconciledAccount) {
      return <></>;
    }

    return (
      <div
        css={css`
          display: flex;
          flex-direction: row;
          height: 40px;
          align-items: center;
          gap: 8px;
        `}
      >
        {!teachingBubbleDismissed && (
          <TooltipContent dismissTeachingBubble={dismissTeachingBubble} />
        )}
        <Toggle
          id={"tryNewPortalToggle"}
          onChange={onSwitch}
          styles={{
            root: { marginBottom: 0 },
            pill: {
              borderColor: theme.palette.white,
              selectors: {
                ":hover": {
                  borderColor: theme.palette.white,
                  backgroundColor: theme.palette.themeDarkAlt,
                  selectors: {
                    ".ms-Toggle-thumb": {
                      backgroundColor: theme.palette.white,
                    },
                  },
                },
              },
            },
            thumb: {
              backgroundColor: theme.palette.white,
              ":hover": {
                backgroundColor: theme.palette.white,
              },
            },
          }}
        />
        <Text styles={{ root: { fontSize: 14, lineHeight: 20 } }}>
          {intl.formatMessage({
            id: messageIds.reconcile.tryTheNewMicrosoftPurview,
          })}
        </Text>
      </div>
    );
  });
};
