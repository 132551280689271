/** @jsxImportSource @emotion/react */
import { rootModels, toastContainerIds } from "@msbabylon/shell-framework";
import { GetContainer } from "nyax";
import { toast } from "react-toastify";
import { ToastStyleGuard } from "src/components/ToastStyleGuard";
import AccountMergePopover from "src/containers/Reconcile/AccountMergePopover";
import NewPortalAnnouncementPopover from "src/containers/Reconcile/NewPortalAnnouncementPopover";
import { dependencies } from "src/dependencies";
import { isM365 } from "src/dependencies/mode";
import {
  ReconcileStatus,
  babylonInstanceModel,
} from "src/store/shell-models/babylon/instance";
import { reconcileModel } from "src/store/shell-models/babylon/reconcile";
import { redirectToLandingPage } from "src/util/redirect";

function triggerReconcilePopover(
  getContainer: GetContainer,
  canUpgradeAccount: boolean,
  isSingleAccount: boolean
) {
  dependencies.logger.logEvent(
    "info",
    "Reconcile",
    "reconcile announcement popover triggered."
  );
  const toastId = toast(
    <ToastStyleGuard>
      <NewPortalAnnouncementPopover
        onClose={closeToast}
        onSubmit={submit}
        canUpgrade={canUpgradeAccount}
        isSingleAccount={isSingleAccount}
      />
    </ToastStyleGuard>,
    {
      position: "top-right",
      hideProgressBar: true,
      autoClose: 60 * 1000,
      pauseOnHover: true,
      pauseOnFocusLoss: false,
      draggable: false,
      containerId: toastContainerIds.reconcileAnnouncement,
    }
  );

  function closeToast() {
    dependencies.logger.logEvent("info", "Reconcile", "refuse to reconcile.");
    toast.dismiss(toastId);
    getContainer(
      reconcileModel
    ).actions.dismissReconcileAnnouncementPopover.dispatch({});
  }
  async function submit() {
    if (canUpgradeAccount) {
      redirectToLandingPage("/reconcile", dependencies);
    } else {
      dependencies.logger.logEvent(
        "info",
        "Reconcile",
        "submit request to reconcile."
      );
      await getContainer(
        reconcileModel
      ).actions.submitReconcileRequest.dispatch({});
    }
    toast.dismiss(toastId);
  }
}

function triggerAccountMergePopover(
  getContainer: GetContainer,
  isSecondary?: boolean
) {
  dependencies.logger.logEvent(
    "info",
    "AccountMerge",
    "Account merge popover triggered"
  );
  const babylonModel = getContainer(babylonInstanceModel);
  const uiDialogListCommonHelper = getContainer(
    rootModels.ui.list.dialog.commonHelper
  );
  const toastId = toast(
    <ToastStyleGuard>
      <AccountMergePopover
        onClose={closeToast}
        onSubmit={submit}
        isSecondary={isSecondary}
        tenantAccountName={
          babylonModel.state.tenantAccount?.provisionedAccountProperties
            ?.accountName
        }
      />
    </ToastStyleGuard>,
    {
      position: "top-right",
      hideProgressBar: true,
      autoClose: 60 * 1000,
      pauseOnHover: true,
      pauseOnFocusLoss: false,
      draggable: false,
      containerId: toastContainerIds.reconcileAnnouncement,
    }
  );

  function closeToast() {
    dependencies.logger.logEvent(
      "info",
      "AccountMerge",
      "Account merge popover dismissed"
    );
    toast.dismiss(toastId);
    getContainer(reconcileModel).actions.dismissAccountMergePopover.dispatch(
      {}
    );
  }
  async function submit(assessmentOnly?: boolean) {
    if (isSecondary) {
      if (dependencies.application.features.accountMergeCategoryFilter) {
        toast.dismiss(toastId);
        const onConfirmCallbackId = dependencies.shellExtension.registerFunc(
          async (
            excludeCategories: string[],
            autoResolveCategories: string[]
          ) => {
            await getContainer(
              reconcileModel
            ).actions.submitMergeRequestFromSecondary.dispatch({
              assessmentOnly,
              excludeCategories,
              autoResolveCategories,
            });
          }
        );
        uiDialogListCommonHelper.actions.open.dispatch({
          extensionName: "shell",
          component: {
            type: "dialogMergeSelectCategory",
            params: {
              assessmentOnly,
              primaryButtonCallbackId: onConfirmCallbackId,
            },
          },
        });
      } else {
        await getContainer(
          reconcileModel
        ).actions.submitMergeRequestFromSecondary.dispatch({ assessmentOnly });
        toast.dismiss(toastId);
      }
    } else {
      redirectToLandingPage("/merge", dependencies);
      toast.dismiss(toastId);
    }
  }
}

export default async function (getContainer: GetContainer) {
  if (!dependencies.application.resourceName) {
    // The portal is still in account selection page. Won't show banner in this case.
    return;
  }

  if (
    process.env.REACT_APP_UI_ENVIRONMENT === "MC" ||
    process.env.REACT_APP_UI_ENVIRONMENT === "FF"
  ) {
    return;
  }

  const reconcileHelper = getContainer(reconcileModel);
  await reconcileHelper.actions.loadDismissStateFromLocalStorage.dispatch({});

  const babylonModel = getContainer(babylonInstanceModel);
  if (isM365) {
    return;
  }

  try {
    const reconcileStatus = babylonModel.state.reconcileStatus;
    if (reconcileStatus === ReconcileStatus.readyForReconcile) {
      const dismissed = reconcileHelper.state.popoverDismissed;
      if (dismissed) {
        return;
      }

      await reconcileHelper.actions.checkAll.dispatch({});

      const canUpgradeAccount = reconcileHelper.state.canReconcile;
      if (
        !reconcileHelper.state.canReconcile &&
        !reconcileHelper.state.canSubmitRequest
      ) {
        return;
      }

      triggerReconcilePopover(
        getContainer,
        canUpgradeAccount,
        reconcileHelper.state.isSingleAccount
      );
    } else if (reconcileStatus === ReconcileStatus.selfReconciled) {
      if (!dependencies.application.features.accountMerge) {
        return;
      }
      if (reconcileHelper.state.accountMergePopoverDismissed) {
        return;
      }
      await reconcileHelper.actions.checkIfCanTriggerMerge.dispatch({
        primaryAccountName:
          babylonModel.state.tenantAccount?.provisionedAccountProperties
            ?.accountName,
      });
      if (!reconcileHelper.state.canTriggerMerge) {
        return;
      }
      triggerAccountMergePopover(getContainer);
    } else if (reconcileStatus === ReconcileStatus.othersReconciled) {
      if (!dependencies.application.features.accountMergeSecondary) {
        return;
      }
      if (reconcileHelper.state.accountMergePopoverDismissed) {
        return;
      }
      await reconcileHelper.actions.checkIfCanTriggerMerge.dispatch({
        isFromSecondary: true,
        primaryAccountName:
          babylonModel.state.tenantAccount?.provisionedAccountProperties
            ?.accountName,
      });
      if (!reconcileHelper.state.canTriggerMerge) {
        return;
      }
      triggerAccountMergePopover(getContainer, true);
    }
  } catch {
    // swallow
  }
}
