import { OptionalIntlConfig } from "react-intl";
import { buildMessages } from "src/locales/intlConfigs/buildMessages";
import messages from "../stringResources/stringResources.it.json";

const config: OptionalIntlConfig = {
  locale: "it",
  messages: buildMessages(messages),
};

export default config;
