import { ExtensionManifest } from "@msbabylon/core";
import { rootModels } from "@msbabylon/shell-framework";
import { cloneDeep } from "lodash-es";
import { GetContainer } from "nyax";

function setMenuDisplay(
  manifest: ExtensionManifest | undefined,
  menuId: string,
  show: boolean
) {
  if (manifest) {
    const menu = manifest.menu?.find((v) => v.id === menuId);
    if (menu != null) {
      if (menu.attributes != null) {
        menu.attributes.hide = !show;
      } else {
        menu.attributes = {
          hide: !show,
        };
      }
    }
  }
}

function setMenuDisplayName(
  manifest: ExtensionManifest | undefined,
  menuId: string,
  stringId: string
) {
  if (manifest) {
    const menu = manifest.menu?.find((v) => v.id === menuId);
    if (menu != null) {
      menu.labelId = stringId;
    }
  }
}

export default async function (
  getContainer: GetContainer,
  extensionName: string,
  menuId: string,
  display?: boolean,
  updateDisplayName?: string
) {
  const manifestModel = getContainer(rootModels.extension.manifest.entity);
  const menu = cloneDeep(manifestModel.getters.getItems([extensionName]).pop());
  if (menu != null) {
    if (display != null) {
      setMenuDisplay(menu, menuId, display);
    }
    if (updateDisplayName != null) {
      setMenuDisplayName(menu, menuId, updateDisplayName);
    }
    await manifestModel.actions.setItems.dispatch([menu]);
  }
}
