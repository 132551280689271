import { createUIViewModel } from "@msbabylon/shell-core";
import { createModel } from "nyax";
import messageIds from "src/locales/messageIds";
import { releaseNotesModel } from "src/store/shell-models/babylon/releaseNotes";

export const uiViewPanelReleaseNotesModel = createModel(
  class extends createUIViewModel() {
    public selectors() {
      return {
        ...super.selectors(),
        title: () =>
          this.dependencies.intl.formatMessage({
            id: messageIds.releaseNotes.$title,
          }),
        releaseNotes: () =>
          this.getContainer(releaseNotesModel).state.releaseNotes,
      };
    }
  },
  {
    isLazy: true,
    isDynamic: true,
  }
);
