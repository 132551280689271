import { MessageBar } from "@adux/common-react";
import { MessageBarType } from "@fluentui/react";
import React from "react";
import { useIntl } from "react-intl";
import messageIds from "src/locales/messageIds";

export default React.memo<Parameters<typeof MessageBar>[0]>(function (props) {
  const intl = useIntl();

  return (
    <MessageBar
      dismissButtonAriaLabel={intl.formatMessage({
        id: messageIds.shared.dismiss,
      })}
      {...props}
    ></MessageBar>
  );
});

export { MessageBarType };
