/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { memo, useMemo } from "react";

/**
 * Empty block as placeholder
 * for saving code purpose
 * @param {(string|number)} [h] height of block, if a number is specified, the unit will be "px", otherwise as it is
 * @param {(string|number)} [w] width of block, if a number is specified, the unit will be "px", otherwise as it is
 */
export default memo(
  ({ h, w }: { h?: number | string; w?: number | string }) => {
    const width = useMemo(() => {
      switch (typeof w) {
        case "string":
          return w;
        case "number":
          return `${w}px`;
        default:
          return "unset";
      }
    }, [w]);
    const height = useMemo(() => {
      switch (typeof h) {
        case "string":
          return h;
        case "number":
          return `${h}px`;
        default:
          return "unset";
      }
    }, [h]);
    return (
      <div
        css={css`
          width: ${width};
          height: ${height};
        `}
      />
    );
  }
);
