import { flattenMessages } from "@msbabylon/core";
import defaultMessages from "src/locales/intlConfigs/defaultMessages";

declare type NestedMessages = {
  [key: string]: string | NestedMessages;
};

export function buildMessages(messages: NestedMessages) {
  return Object.assign({}, defaultMessages, flattenMessages(messages));
}
