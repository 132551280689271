/** @jsxImportSource @emotion/react */
import {
  FabricDefaultButton,
  FabricPrimaryButton,
  FilterBox,
  MessageBar,
  Pagination,
  TableList,
  Text,
  useIsMountedRef,
  useTheme,
} from "@adux/common-react";
import { debounce } from "@adux/common-react/lib/utils/shared";
import { css } from "@emotion/react";
import {
  MessageBarType,
  Modal,
  SelectionMode,
  Spinner,
  SpinnerSize,
} from "@fluentui/react";
import { useGetContainer } from "@msbabylon/shell-core";
import { rootModels } from "@msbabylon/shell-framework";
import React, { useCallback, useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import AppHyperLink from "src/components/AppHyperLink";
import { productDocumentations } from "src/constants/documentationLinks";
import { useDependencies } from "src/hook";
import messageIds from "src/locales/messageIds";
import {
  DisplayedAccountItem,
  uiMergeSelectAccountModel,
} from "src/store/shell-models/ui/mergeSelectAccount";
import { cssTextTruncate } from "src/styles/util";
import { redirectToLandingPage } from "src/util/redirect";
import { getAzureRegionDisplayName } from "src/util/region";

export default React.memo(() => {
  const intl = useIntl();
  const theme = useTheme();
  const getContainer = useGetContainer();
  const dependencies = useDependencies();

  const ui = getContainer(uiMergeSelectAccountModel);
  const uiDialogListCommonHelper = getContainer(
    rootModels.ui.list.dialog.commonHelper
  );

  useEffect(() => {
    dependencies.logger.logEvent(
      "info",
      "AccountMerge",
      "entered merge account selection page."
    );
  }, [dependencies.logger]);

  useEffect(() => {
    ui.actions.onInitialize.dispatch({});
  }, [ui.actions.onInitialize]);

  const initialized = useSelector(() => ui.state.initialized);
  const loadingAccounts = useSelector(() => ui.state.loadingAccounts);
  const loadingAccountsFromArm = useSelector(
    () => ui.state.loadingAccountsFromArm
  );
  const loadingTenantAccount = useSelector(() => ui.state.loadingTenantAccount);
  const merging = useSelector(() => ui.state.merging);
  const tenantAccountName = useSelector(() => ui.getters.tenantAccountName);
  const tenantAccountDisplayName = useSelector(
    () => ui.getters.tenantAccountDisplayName
  );
  const tenantAccountSubscription = useSelector(
    () => ui.getters.tenantAccountSubscription
  );
  const selectedAccount = useSelector(() => ui.getters.selectedAccount);
  const accountMergeStatusMap = useSelector(
    () => ui.state.accountMergeStatusMap
  );

  const isPrimaryMergingInProgress = useMemo(() => {
    return Object.values(accountMergeStatusMap).some(
      (val) => val === "Merging"
    );
  }, [accountMergeStatusMap]);

  const onSelectAccount = useCallback(
    (keys: string[]) => {
      ui.actions.setSelectedAccount.dispatch(keys[0]);
    },
    [ui.actions.setSelectedAccount]
  );
  const onMerge = useCallback(
    (assessmentOnly?: boolean) => () => {
      if (dependencies.application.features.accountMergeCategoryFilter) {
        const onConfirmCallbackId = dependencies.shellExtension.registerFunc(
          async (
            excludeCategories: string[],
            autoResolveCategories: string[]
          ) => {
            await ui.actions.mergeAsync.dispatch({
              assessmentOnly,
              excludeCategories,
              autoResolveCategories,
            });
          }
        );
        uiDialogListCommonHelper.actions.open.dispatch({
          extensionName: "shell",
          component: {
            type: "dialogMergeSelectCategory",
            params: {
              assessmentOnly,
              primaryButtonCallbackId: onConfirmCallbackId,
            },
          },
        });
      } else {
        ui.actions.mergeAsync.dispatch({ assessmentOnly });
      }
    },
    [
      dependencies.application.features.accountMergeCategoryFilter,
      dependencies.shellExtension,
      ui.actions.mergeAsync,
      uiDialogListCommonHelper.actions.open,
    ]
  );
  const onCancel = useCallback(() => {
    redirectToLandingPage(
      tenantAccountName != null ? `/resource/${tenantAccountName}` : "",
      dependencies
    );
  }, [dependencies, tenantAccountName]);

  // #region - filter & pagination

  const currentPage = useSelector(() => ui.state.currentPage);
  const pageCount = useSelector(() => ui.getters.pageCount);
  const pageSize = useSelector(() => ui.state.pageSize);
  const setCurrentPage = useCallback(
    (page: number) => {
      ui.actions.setCurrentPage.dispatch(page - 1);
    },
    [ui.actions.setCurrentPage]
  );

  const filterText = useSelector(() => ui.state.filterText);
  const [localFilterText, setFilterText] = React.useState(filterText);
  const isMountedRef = useIsMountedRef();
  const setFilterTextDelayedDebounced = useMemo(
    () =>
      debounce((v: string) => {
        if (isMountedRef.current) {
          ui.actions.setFilterText.dispatch(v);
          ui.actions.setCurrentPage.dispatch(0);
        }
      }, 500),
    [isMountedRef, ui.actions.setCurrentPage, ui.actions.setFilterText]
  );

  useEffect(() => {
    setFilterTextDelayedDebounced(localFilterText);
  }, [setFilterTextDelayedDebounced, localFilterText]);

  const onFilterValueChange = useCallback(
    (
      event: React.ChangeEvent<HTMLInputElement> | undefined,
      newValue?: string | undefined
    ) => {
      const updateValue = newValue || event?.currentTarget.value || "";
      setFilterText(updateValue);
    },
    [setFilterText]
  );

  // #endregion

  // #region - table items

  const filteredAccounts = useSelector(() => ui.getters.filteredAccounts);
  const items = useSelector(() => ui.getters.currentPageAccountsTableItems);

  const disabledKeys = useMemo(
    () => items.filter((e) => e.disabled).map((e) => e.key),
    [items]
  );
  const selectedKeys = useMemo(
    () => (selectedAccount ? [selectedAccount.accountName] : []),
    [selectedAccount]
  );

  const columns = useMemo(() => {
    return [
      {
        key: "displayText",
        name: intl.formatMessage({
          id: messageIds.reconcile.upgrade.accountName,
        }),
        maxWidth: 300,
        onRender: (item: DisplayedAccountItem) => {
          return (
            <span
              style={{
                color: item.disabled
                  ? theme.semanticColors.disabledBodyText
                  : theme.semanticColors.bodyText,
                display: "flex",
                flexDirection: "row",
                overflow: "hidden",
              }}
              title={
                item.disabled && item.disabledMessage
                  ? item.disabledMessage
                  : item.displayText
              }
            >
              {(loadingAccountsFromArm ||
                item.accountFetched === "INPROGRESS") && (
                <Spinner
                  size={SpinnerSize.xSmall}
                  styles={{ root: { marginRight: 8 } }}
                />
              )}
              <span css={cssTextTruncate}>{item.displayText}</span>
            </span>
          );
        },
      },
      {
        key: "subscription",
        name: intl.formatMessage({
          id: messageIds.reconcile.upgrade.subscription,
        }),
        maxWidth: 200,
      },
      {
        key: "location",
        name: intl.formatMessage({
          id: messageIds.reconcile.upgrade.location,
        }),
        minWidth: 60,
        maxWidth: 80,
        onRender: (item: DisplayedAccountItem) => {
          return getAzureRegionDisplayName(intl, item.location);
        },
      },
    ];
  }, [
    intl,
    loadingAccountsFromArm,
    theme.semanticColors.bodyText,
    theme.semanticColors.disabledBodyText,
  ]);

  // #endregion

  if (!initialized || loadingAccounts || loadingTenantAccount) {
    return (
      <Spinner
        css={css`
          display: flex;
          flex-grow: 1;
        `}
        size={SpinnerSize.large}
      />
    );
  }

  return (
    <Modal
      isOpen={true}
      isModeless={true}
      styles={{
        main: {
          padding: 32,
        },
      }}
    >
      <div
        css={css`
          width: 770px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          overflow-y: hidden;
        `}
      >
        <div
          css={css`
            display: flex;
            flex: "1 1 auto";
            flex-direction: column;
            gap: 16px;
            overflow-y: hidden;
          `}
        >
          <div
            css={css`
              font-size: 36px;
              line-height: 32px;
              font-weight: 600;
            `}
          >
            {intl.formatMessage({
              id: messageIds.reconcile.accountMerge.selectAccountToMerge,
            })}
          </div>
          <div
            css={css`
              font-size: 13px;
              line-height: 20px;
            `}
          >
            {intl.formatMessage(
              {
                id: messageIds.reconcile.accountMerge
                  .selectAccountToMergeDescription,
              },
              {
                accountName: tenantAccountDisplayName,
              }
            )}
            &nbsp;
            <AppHyperLink
              href={productDocumentations.mergeToTenantAccount}
              externalLink={true}
            >
              {intl.formatMessage({
                id: messageIds.reconcile.upgrade
                  .learnMoreAboutTenantLevelAccounts,
              })}
            </AppHyperLink>
          </div>
          {loadingAccounts ? (
            <Spinner size={SpinnerSize.large} />
          ) : (
            <>
              {isPrimaryMergingInProgress ? (
                <MessageBar
                  messageBarType={MessageBarType.warning}
                  styles={{ root: { flexShrink: 0 } }}
                >
                  {intl.formatMessage({
                    id: messageIds.reconcile.accountMerge.tenantAlreadyMerging,
                  })}
                  &nbsp;
                  <AppHyperLink
                    href={productDocumentations.mergeToTenantAccount}
                    externalLink={true}
                  >
                    {intl.formatMessage({
                      id: messageIds.shared.learnMore,
                    })}
                  </AppHyperLink>
                </MessageBar>
              ) : selectedAccount != null ? (
                <MessageBar
                  messageBarType={MessageBarType.info}
                  styles={{ root: { flexShrink: 0 } }}
                >
                  {intl.formatMessage(
                    {
                      id: messageIds.reconcile.accountMerge.billingMessage,
                    },
                    {
                      account: tenantAccountDisplayName,
                      subscription: tenantAccountSubscription,
                    }
                  )}
                  &nbsp;
                  <AppHyperLink
                    href={productDocumentations.mergeBilling}
                    externalLink={true}
                  >
                    {intl.formatMessage({
                      id: messageIds.shared.learnMore,
                    })}
                  </AppHyperLink>
                </MessageBar>
              ) : (
                <></>
              )}
              <FilterBox
                styles={{ root: { width: 300 } }}
                iconProps={{ iconName: "Filter" }}
                placeholder={intl.formatMessage({
                  id: messageIds.reconcile.upgrade.filterByKeyword,
                })}
                value={localFilterText}
                onChange={onFilterValueChange}
              />
              {pageCount > 1 && (
                <Text>
                  {intl.formatMessage(
                    {
                      id: messageIds.reconcile.upgrade.showingNumberOfAccounts,
                    },
                    {
                      start: currentPage * pageSize + 1,
                      end: currentPage * pageSize + items.length,
                      total: filteredAccounts.length,
                    }
                  )}
                </Text>
              )}
              <div
                css={css`
                  overflow-y: auto;
                  height: 370px;
                `}
                data-is-scrollable={true}
              >
                <TableList
                  columns={columns}
                  items={items}
                  selectionMode={SelectionMode.single}
                  hideSelectAll={true}
                  onSelectedKeysChange={onSelectAccount}
                  disabledKeys={disabledKeys}
                  selectedKeys={selectedKeys}
                />
              </div>
              <Pagination
                currentPage={currentPage + 1}
                pageCount={pageCount}
                onPageChange={setCurrentPage}
                strings={{
                  previous: intl.formatMessage({
                    id: messageIds.shared.previous,
                  }),
                  next: intl.formatMessage({ id: messageIds.shared.next }),
                  select: intl.formatMessage({
                    id: messageIds.shared.select,
                  }),
                  selectAriaLabel: intl.formatMessage({
                    id: messageIds.shared.selected,
                  }),
                  pageOfNumber: intl.formatMessage({
                    id: messageIds.shared.pageOfNumber,
                  }),
                }}
                styles={{
                  root: pageCount <= 1 && {
                    display: "none",
                  },
                }}
              />
            </>
          )}
        </div>
        <div
          css={css`
            display: flex;
            flex-direction: row;
            gap: 16px;
            margin-top: 24px;
          `}
        >
          <FabricPrimaryButton
            disabled={
              selectedAccount == null || merging || isPrimaryMergingInProgress
            }
            onClick={onMerge(true)}
            text={
              merging
                ? intl.formatMessage({ id: messageIds.shared.processing })
                : intl.formatMessage({
                    id: messageIds.reconcile.accountMerge.assessOnly,
                  })
            }
            split={true}
            menuProps={{
              items: [
                {
                  key: "assess",
                  text: intl.formatMessage({
                    id: messageIds.reconcile.accountMerge.assessOnly,
                  }),
                  onClick: onMerge(true),
                },
                {
                  key: "merge",
                  text: intl.formatMessage({
                    id: messageIds.reconcile.accountMerge.assessAndMerge,
                  }),
                  onClick: onMerge(),
                },
              ],
            }}
          />
          <FabricDefaultButton
            onClick={onCancel}
            text={intl.formatMessage({ id: messageIds.shared.cancel })}
          />
        </div>
      </div>
    </Modal>
  );
});
