/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { ActionButton, Separator, Stack } from "@fluentui/react";
import { useGetContainer } from "@msbabylon/shell-core";
import React, { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import messageIds from "src/locales/messageIds";
import { uiCopilotModel } from "src/store/shell-models/ui/copilot";

export default React.memo(function AppCopiloadLoading(props) {
  const intl = useIntl();

  const getContainer = useGetContainer();
  const copilot = getContainer(uiCopilotModel);

  const isLoading = useSelector(() => copilot.getters.isLoading);

  const [hover, setHover] = useState(false);
  const handleMouseEnter = useCallback(() => {
    setHover(true); // set state to true
  }, []);
  const handleMouseLeave = useCallback(() => {
    setHover(false); // set state to false
  }, []);

  useEffect(() => {
    if (!isLoading) {
      setHover(false);
    }
  }, [isLoading]);

  const handleClick = useCallback(() => {
    copilot.actions.stopPolling.dispatch({});
  }, [copilot.actions.stopPolling]);

  return isLoading ? (
    <Stack onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <Separator>
        <ActionButton
          iconProps={{
            iconName: hover ? "CircleStopSolid" : "ProgressRingDots",
            styles: { root: { fontSize: "16px" } },
          }}
          css={css`
            @keyframes rotating {
              from {
                transform: rotate(0deg);
              }
              to {
                transform: rotate(360deg);
              }
            }
          `}
          styles={{
            icon: {
              margin: 0,
              animationName: hover ? "none" : "rotating",
              animationDuration: "1.3s",
              animationIterationCount: "infinite",
              animationTimingFunction: "cubic-bezier(0.53, 0.21, 0.29, 0.67)",
            },
          }}
          onClick={handleClick}
        >
          {intl.formatMessage({
            id: messageIds.copilot.actions.stopResponding,
          })}
        </ActionButton>
      </Separator>
    </Stack>
  ) : null;
});
