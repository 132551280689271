import naturalCompare from "natural-compare";
import { createModel, createSelector } from "nyax";
import { PurviewAccountArmResource } from "src/models/azure";
import { BabylonAccount } from "src/models/babylon";
import { createItemsEntityModel } from "src/store/shared/itemsEntity";

export const babylonAccountEntityModel = createModel(
  class extends createItemsEntityModel<BabylonAccount>((item) => item.id) {
    public selectors() {
      return {
        ...super.selectors(),
        sortedItems: createSelector(
          (): Array<BabylonAccount> => this.getters.items,
          (items) =>
            items
              .slice()
              .sort((a, b) =>
                naturalCompare(a.name.toLowerCase(), b.name.toLowerCase())
              )
        ),
      };
    }
  }
);

export const babylonAccountArmEntityModel = createModel(
  class extends createItemsEntityModel<PurviewAccountArmResource>(
    (item) => item.id
  ) {}
);
