/** @jsxImportSource @emotion/react */
import { useTheme } from "@adux/common-react";
import { css } from "@emotion/react";
import { IconButton } from "@fluentui/react";
import { memo } from "react";
import { useIntl } from "react-intl";
import messageIds from "src/locales/messageIds";

export interface ToastCloseButtonProps {
  onClose(): void;
  styles?: Partial<{
    top: number;
    right: number;
  }>;
}

export const ToastCloseButton = memo<ToastCloseButtonProps>(
  ({ onClose, styles }) => {
    const theme = useTheme();
    const intl = useIntl();
    return (
      <IconButton
        css={css`
          height: 15px;
          width: 15px;
          position: absolute;
          right: ${styles?.right ?? 15}px;
          top: ${styles?.top ?? 12}px;
        `}
        iconProps={{
          iconName: "ChromeClose",
          styles: { root: { fontSize: "13px" } },
        }}
        styles={{
          root: {
            color: theme.palette.neutralSecondary,
            backgroundColor: "transparent",
          },
          rootHovered: {
            color: theme.palette.neutralPrimary,
            backgroundColor: "transparent",
          },
          rootPressed: {
            color: theme.palette.neutralPrimary,
            backgroundColor: "transparent",
          },
        }}
        onClick={onClose}
        ariaLabel={intl.formatMessage({ id: messageIds.shared.close })}
        title={intl.formatMessage({ id: messageIds.shared.close })}
      />
    );
  }
);
