/** @jsxImportSource @emotion/react */
import { Panel, PanelProps } from "@adux/common-react";
import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import messageIds from "src/locales/messageIds";

export default React.memo<React.PropsWithChildren<PanelProps>>(
  function AppPanel(props) {
    const intl = useIntl();
    const {
      children,
      labelNext: _labelNext,
      labelPrevious: _labelPrevious,
      labelCancel: _labelCancel,
      ...others
    } = props;
    const labelNext = useMemo(
      () =>
        _labelNext ||
        intl.formatMessage({
          id: messageIds.shared.continue,
        }),
      [_labelNext, intl]
    );

    const labelPrevious = useMemo(
      () =>
        _labelPrevious ||
        intl.formatMessage({
          id: messageIds.shared.back,
        }),
      [_labelPrevious, intl]
    );

    const labelCancel = useMemo(
      () =>
        _labelCancel ||
        intl.formatMessage({
          id: messageIds.shared.cancel,
        }),
      [_labelCancel, intl]
    );

    const mergeProps = useMemo(
      () => ({
        ...others,
        labelNext,
        labelPrevious,
        labelCancel,
      }),
      [others, labelNext, labelPrevious, labelCancel]
    );

    return <Panel {...mergeProps}>{children}</Panel>;
  }
);
