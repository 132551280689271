import { removeComments } from "@msbabylon/core";

export const messagesWithComment = {
  copilot: {
    bubble: {
      summarizeComment: {
        description: "Find comment insights with a click!",
        initMessage: "Summarize comment on this asset in this page",
      },
      understandScan: {
        description: "Get valuable insights on this Scan!",
        initMessage: "Get valuable insights on this scan in this page",
      },
      tryIt: "Try it",
    },
    header: {
      title: "Microsoft Purview Copilot",
    },
    topBar: {
      $buttonTitle: "Copilot",
    },
    actions: {
      newChat: "New Chat",
      send: "Send",
      stopResponding: "Stop Responding...",
    },
    feedback: {
      like: "Like",
      dislike: "Dislike",
    },
    messages: {
      actions: {
        copy: "Copy",
        copied: "Copied",
        run: "Run",
      },
      assetType: "Asset type",
      assetDescription: "Asset description",
      fullyQualifiedName: "Fully qualified name",
      classificationName: "Classification name",
      classificationDescription: "Classification description",
      classificationDisplayName: "Classification displayName",
      isSystemType: "Type",
      glossaryTermName: "Name",
      glossaryName: "Glossary name",
      dataSource: "DataSource",
      scan: "Scan name",
    },
  },
  errors: {
    invalidTenant: {
      $title: "Tenant is invalid",
      $message:
        "The tenant or tenant id ({tenant}) is invalid, please check and login again.",
    },
    connectAccountFailed: {
      $title: "Account Error",
      $message: `Cannot connect to account: {accountName} `,
    },
    privateEndpoint: {
      $title: "Not authorized to access this Microsoft Purview account",
      $message: `This Microsoft Purview account is behind a private endpoint. Please access the account from a client in the same virtual network (VNet) that has been configured for the Microsoft Purview account's private endpoint.`,
    },
    unauthorized: {
      $title: "You need permission to access this",
      msgNoAccess: `It looks like you don't have access to this Microsoft Purview account.`,
      msgContactOwner:
        "Contact the account owner or administrators for access.",
      selectAnotherAccount: "Select another account",
    },
    apiNotAvailable: {
      $title: "Permission API not available",
      permissionAPINotAvailable:
        "Failed to get permissions for this Microsoft Purview account. The account might be busy now. Please try refreshing the browser later.",
    },
  },
  instance: {
    $landingTitle: `Select a Microsoft Purview account`,
    $landingDescription:
      "Microsoft Purview is a unified data governance service that enables a foundational understanding of your on-premises, multi-cloud, operational and SaaS data.",
    $title: "Account + subscription",
    azureActiveDirectory: "Microsoft Entra ID",
    currentInstance: "Current account",
    defaultAccountLabel: "{name} (default)",
    listAccountFailedMsg: "Failed to list accounts: {reason}",
    instanceName: "Account name",
    // privacyStatement:
    //   "Account catalog information (e.g. assets, tagging, scans, insights etc) will be deleted immediately after account deletion during Private Preview. Starting with Public Preview, account catalog information will be retained for not more than 90 days after passive account deletion",
    region: "Region",
    resourceGroup: "Resource group",
    selectAnotherBabylonInstance: `Select another Microsoft Purview account`,
    subscriptionId: "Subscription ID",
    viewDiagnostics: "View diagnostics",
    noCollectionPermission:
      "You do not have the required permission to access this. Contact a Collection Administrator for this account.\nRequired permission:\n{permissions}",
    noPermission:
      "You don't have access to this Microsoft Purview account. Contact an Owner or Collection Administrator for access.",
    protectedByPE:
      "This Microsoft Purview account is protected by a private endpoint. Connect from within your private network to access it, or contact your Microsoft Purview account owner for more details.",
    internalServerError: "Internal server error",
    validating: "Validating account permission...",
  },
  help: {
    $title: "Help",
    accessibility: "Accessibility",
    diagnostic: "Diagnostic info",
    guidedTour: "Guided tour",
    knowledgeCenter: "Knowledge center",
    privacy: "Privacy and cookies",
    privacyStatement: "Privacy Statement and location information Terms of Use",
    privacyStatementInShell: "Privacy statement",
  },
  select: {
    filterEllipsis: "Filter...",
    indexOf: "'{0}' of '{1}'",
    multipleSelected: "'{0}' selected",
    nonSelected: "Non-selected",
    noOptions: "No options",
    selectAll: "Select all",
    selected: "Selected",
    selectInputGuidance: "Press Down to open the menu",
    selectMenuGuidance: "Use Up and Down to choose options.",
    selectPlaceholder: "Select...",
  },
  shared: {
    all: "All",
    back: "Back",
    button: "Button",
    cancel: "Cancel",
    clear: "Clear",
    clickHere: "Click here",
    close: "Close",
    confirm: "Confirm",
    continue: "Continue",
    details: "Details",
    disabled: "Disabled",
    dismiss: "Dismiss",
    error: "Error",
    inProgress: "In progress",
    learnMore: "Learn more",
    loadingEllipsis: "Loading...",
    message: "Message",
    new: "New",
    next: "Next",
    no: "No",
    off: "Off",
    ok: "OK",
    on: "On",
    pageOfNumber: "Page {0} of {1}",
    previous: "Previous",
    processing: "Processing",
    search: "Search",
    select: "Select",
    selected: "Selected",
    submit: "Submit",
    success: "Success",
    tableOfContents: "Table of Contents",
    upgrade: "Upgrade",
    yes: "Yes",
  },
  diagnostics: {
    $title: "Diagnostics",
    instanceName: `Microsoft Purview account name`,
  },
  releaseNotes: {
    $title: "Release notes/updates",
    learnMoreAbout: "Learn more about {content}",
  },
  topbar: {
    azurePurviewStudio: "Microsoft Purview",
    microsoftAzure: "Microsoft Purview",
    more: "More",
    purview: "Azure Purview",
    showMenu: "Show menu",
    searchBox: {
      searchAssets: "Search catalog",
    },
    signOut: "Sign out",
    skipToMain: "Skip to main content",
    switchInstance: "Switch instance",
    userInfo: "User: {user}, directory: {directory}",
    viewAccount: "View account",
  },
  sidebar: {
    hidePortalMenu: "Hide portal menu",
    hideTextLabels: "Hide text labels",
    showTextLabels: "Show text labels",
    hideTableOfConents: "Hide table of contents",
    showTableOfConents: "Show table of contents",
    portalMenu: "Portal menu",
    primary: "Primary",
  },
  settings: {
    $title: "Settings",
    theme: {
      $label: "Choose a theme",
      dark: "Dark",
      light: "Light",
    },
    language: {
      $label: "Language",
      apply: "Apply",
    },
    sidebarMode: {
      $label: "Choose your default mode for the portal menu",
      docked: "Docked",
      flyout: "Flyout",
    },
    switchInstanceDescription: "Looking to switch account + subscription?",
  },
  feedback: {
    $commentsLabel: "Comments (optional)",
    $commentsPlaceholder: "Tell us about your experience...",
    $description: "Help us make your experience better.",
    $descriptionInShell: "Thank you for taking the time to give us feedback.",
    $emailLabel: "Email (optional)",
    $feelingLabel: "Are you satisfied with your experience?",
    $footerDescription:
      "Your feedback is collected by Microsoft and used to improve your experience.",
    $sentMessage: "Thank you for your feedback!",
    $sentTitle: "Feedback sent",
    $title: "Give feedback to Microsoft",
    $titleInShell: "Feedback",
    commentsPlaceholder:
      "Give as much detail as you can. Your feedback is public, so don't include any personal information.",
    commentsTitle: "Feedback",
    contactSupport: "Contact support",
    emailMe: "Microsoft can email me about this feedback.",
    emailMePlaceholder: "Enter your email address (optional)",
    featureDescription: "You will be redirected to the Azure Feedback forum.",
    feedbackDescription: "Tell us about your experience.",
    haveFeature: "I have a feature suggestion",
    haveFeedback: "I have feedback",
    needHelp: "Need help fixing an issue?",
    privacyPolicy: "Privacy policy",
    privacyStatement: "Privacy statement",
    submitFeedback: "Submit",
    submitFeedbackInShell: "Submit feedback",
  },
  teams: {
    topBar: {
      $buttonTitle: "Microsoft teams",
    },
  },
  home: {
    $title: "Data catalog",
    "_$title.comment":
      'The word "Data Catalog" here is not a service name and should be translated.',
    $titleInShell: "Home",
    $titleInShellCore: "Home",
  },
  menu: {
    sources: "Data map",
    "_sources.comment":
      'The word "Data map" here is not a service name and should be translated.',
    glossary: "Glossary",
    insights: "Data estate insights",
    policy: "Data policy",
    manage: "Management",
    managementCenter: "Management center",
    privacy: "Privacy (preview)",
    datashare: "Data share (preview)",
    dataquality: "Data quality",
  },
  m365: {
    allApps: {
      app: {
        catalog: {
          description:
            "Find and curate data across your organization with this searchable inventory of assets and their associated metadata.",
          open: "Browse catalog",
        },
        datamap: {
          description:
            "Manage data source connections, set up scans, and create custom classifications to populate this metadata repository.",
          open: "Open Data map",
        },
        dataquality: {
          description:
            "Manage data quality workspaces, configs, and set up data quality scans",
          open: "Open Data quality",
        },
        datashare: {
          description:
            "Share data internally and with other organizations safely and securely.",
          open: "Open Data share",
        },
        insights: {
          description:
            "View up-to-date reports and get actionable insights about the data across your organization.",
          open: "View reports",
        },
        policy: {
          description:
            "Manage data access and SQL DevOps policies in a central location.",
          open: "Open Data policy",
        },
        management: {
          description:
            "Manage your Microsoft Purview account, including account details, connections, user roles, credentials, and more.",
          open: "Manage account",
        },
        privacy: {
          description:
            "Manage privacy operations across your organization with this suite of compliance applications.",
          open: "Open Privacy",
        },
      },
      apps: "Apps",
      open: "Open",
    },
    home: {
      $title: "Home",
      links: "Links",
      quickAccess: "Quick access",
      usefulLinks: {
        documentationDescription:
          "Visit the documentation center for quickstarts, how-to guides, and other resources.",
        documentationTitle: "Documentation",
        overviewDescription:
          "Discover the capabilities offered by Microsoft Purview and learn how to make the most of them.",
        overviewTitle: "Microsoft Purview overview",
        startedDescription:
          "Learn about how to use Microsoft Purview Governance Portal.",
        startedTitle: "Getting started",
      },
      viewAll: "View all",
    },
    switchToNewMessage:
      "Your account was upgraded to tenant level. Try out the new Microsoft Purview portal with your upgraded account.",
  },
  reconcile: {
    learnMoreAboutTheNewPortal: "Learn more about the new portal",
    releaseNewPortal: "We've released the new portal",
    selectAccount: "Select account",
    upgradeAccount: "Upgrade account",
    submitRequest: "Submit request",
    submitRequestNotification: {
      $successTitle: "Upgrade request submitted",
      $successMessage:
        "A Microsoft Purview Enterprise upgrade request was submitted and is now awaiting approval.",
    },
    notNow: "Not now",
    tryNewPortal: "Try new portal",
    tryNow: "Try now",
    tryTheNewMicrosoftPurview: "Try the new Microsoft Purview",
    tryTheNewPortalHeadline: "Try the new portal",
    $tryTheNewPortalMessage:
      "Now that your organization has a tenant-level account, you can try out the new Microsoft Purview portal. Access all of your data and enjoy the same configurations in this new, streamlined interface.",
    tryTheNewPortal: {
      description: "Switch back to the classic portal anytime",
      headline:
        "Start your multicloud journey with the new Microsoft Purview Portal",
      newComplianceSolutions: {
        textBold: "New compliance solutions.",
        description:
          "Combine compliance and risk capabilities with your suite of governance solutions.",
      },
      sleekModernLookAndFeel: {
        textBold: "Sleek, modern look and feel.",
        description:
          "Take advantage of enhanced usability and streamlined experiences.",
      },
      multicloudProtection: {
        textBold: "Multicloud protection.",
        description:
          "Discover, protect, and govern your data estate across and beyond Microsoft cloud platforms and apps.",
      },
      product: {
        Azure: "Microsoft Azure",
        AWS: "Amazon Web Services",
        Fabric: "Microsoft Fabric",
        M365: "Microsoft 365",
        other: "Other cloud platforms and apps",
      },
    },
    $selectReconcileAccountMessage:
      "The new Microsoft Purview portal is ready. Take advantage of its new capabilities by upgrading an existing account to a tenant-level account. Select one of your accounts to get started. {learnMore}",
    $submitRequestMessage:
      "The new Microsoft Purview portal is ready. Take advantage of its new capabilities by upgrading an existing account to a tenant-level account. Request your account admin to select one of your accounts to get started. {learnMore}",
    upgrade: {
      accountName: "Account name",
      checkingPermissions: "Checking permissions...",
      noPermissionForThisAccount:
        "You don't have permission to upgrade this account. Please contact your account admin.",
      notEligible: "Not eligible",
      notEligibleAndRedirect:
        "This tenant is not eligible for upgrade. Page will redirect in 5 seconds.",
      subscription: "Subscription",
      location: "Location",
      selectAnAccountForUpgrade: "Select an account for upgrade",
      $selectAnAccountForUpgradeDescription:
        "The new Microsoft Purview portal requires a tenant-level account. This will be the single account for your organization, providing one platform and data map to achieve enterprise-wide data governance and security. Work with your global admin to select an existing account to upgrade to the tenant level.",
      learnMoreAboutTenantLevelAccounts:
        "Learn more about tenant-level accounts",
      $selectedAccountBannerMessage:
        "You've selected {account}. Its subscription {subscription} will be used for billing.",
      filterByKeyword: "Filter by keyword",
      showingNumberOfAccounts:
        "Showing {start} to {end} of {total} {total, plural, one {account} other {accounts}}.",
    },
    confirmBasePlan: {
      title: "Microsoft Purview tenant account",
      message:
        "Your Microsoft Purview account will be chosen as a tenant level account. To continue upgrading this account, select Confirm and launch the new portal.",
    },
    confirmRegion: {
      title: "Microsoft Purview account in different region than tenant?",
      message:
        "Your new Microsoft Purview account will be in a different region ({accountRegion}) than your Microsoft Entra ID tenant ({tenantRegion}). To continue upgrading this account, select Confirm and launch the new portal.",
    },
    accountMerge: {
      assessAndMerge: "Assess and merge",
      assessOnly: "Assess only",
      billingMessage: `The subscription {subscription} of tenant-level account "{account}" will be used for billing.`,
      categoryTooltip: {
        contactAssignment:
          "If you do not select this, contact assignments configured to glossary terms or assets will be removed.",
        credential:
          "If you do not select this, scans that use credentials to scan data sources will not be migrated as well.",
        customClassificationRule:
          "If you do not select this, scans that applied custom scan rule set will be skipped.",
        customScanRuleSet:
          "If you do not select this, scans that use applied custom scan rule set will not be migrated as well.",
        customType:
          "If you do not select this,\n  - All assets of custom type will not be migrated as well.\n  - All relationships of custom type and affected assets will not be migrated as well.\n  - All the classification instance of the custom type attached to glossary terms or assets will not be migrated.\n  - Managed attributes attached to glossary terms or assets will be removed.",
        dataSource:
          "If you do not select this, future assets scanned will have no resource set rule applied until users configure new resource set rules in the primary account.",
        glossary:
          "If you do not select this,\n  - Workflows applied to glossary or glossary term will not be migrated as well.\n  - Assets will not have glossary associated.",
        keyVault:
          "If you do not select this, scans that use credentials to scan data sources will not be migrated as well.",
        managedVirtualNetwork:
          "If you do not select this, scans that use managed virtual network to scan data sources will not be migrated as well.",
        resourceSetRule:
          "If you do not select this, future assets scanned will have no resource set rule applied until users configure new resource set rules in the primary account.",
        shir: "If you do not select this, scans that use Self-hosted integration runtime to scan data sources will not be migrated as well.",
        uami: "If you do not select this, scans that use user assigned managed identity to scan data sources will not be migrated as well.",
      },
      categoryWarning: {
        asset: {
          contactAssignment:
            "<b>Contact assignment</b> is not selected, contact assignments configured to assets will be removed.",
          customType:
            "<b>Custom type</b> is not selected,<br></br>- All assets of custom type will not be migrated as well.<br></br>- All relationships of custom type and affected assets will not be migrated as well.<br></br>- All the classification instance of the custom type attached to assets will not be migrated.<br></br>- Managed attributes attached to assets will be removed.",
          glossary:
            "<b>Glossary</b> is not selected, assets will not have glossary associated.",
        },
        glossary: {
          contactAssignment:
            "<b>Contact assignment</b> is not selected, contact assignments configured to glossary terms will be removed.",
          customType:
            "<b>Custom type</b> is not selected,<br></br>- All the classification instance of the custom type attached to glossary terms will not be migrated.<br></br>- Managed attributes attached to glossary terms will be removed.",
        },
        scan: {
          credential:
            "<b>Credential</b> is not selected, scans that use credentials to scan data sources will not be migrated as well.",
          customScanRuleSet:
            "<b>Custom scan rule set</b> is not selected, scans that use applied custom scan rule set will not be migrated as well.",
          managedVirtualNetwork:
            "<b>Managed virtual network</b> is not selected, scans that use managed virtual network to scan data sources will not be migrated as well.",
          shir: "<b>Self-hosted integration runtime</b> is not selected, scans that use Self-hosted integration runtime to scan data sources will not be migrated as well.",
        },
        workflow: {
          glossary:
            "<b>Glossary</b> is not selected, workflows applied to glossary or glossary term will not be migrated as well.",
        },
      },
      mergedMessage:
        "Congratulations! This account has been successfully merged to your tenant-level account.",
      mergingMessage:
        "This account is being merged with your tenant-level account.",
      noPublicNetworkAccess:
        "This account has public network access set to disabled. For now merge is only supported for Microsoft Purview accounts with public network access enabled.",
      notSelectableFailed:
        "This account is in failed status and cannot be merged.",
      notSelectableMerged: "This account has already been merged.",
      notSelectableMerging: "This account is currently being merged.",
      notSelectableUAMI:
        "Account with user assigned managed identity (UAMI) is not eligible for merge yet.",
      openNewPortal: "Open new portal",
      popoverTitle: "You can now merge account",
      popoverDescription:
        "Consolidate your tenant account by merging existing accounts. Select an account to be mapped as a new domain in your tenant-level account.",
      popoverSubmitRequestTitle: "Request this account to be merged",
      popoverSubmitRequestDescription: `Consolidate your tenant account by merging existing accounts. Select this account to be mapped as a new domain in your tenant-level account "{tenantAccountName}".`,
      selectAccountToMerge: "Select an account to merge",
      selectAccountToMergeDescription:
        '"{accountName}" is the tenant-level account for your organization. Select an existing account to merge to the tenant account. The selected account will be mapped as a new domain in the tenant account.',
      submitAssess: "Submit assess only request",
      submitMerge: "Submit merge request",
      submitMergeSuccessfully: "Request has been submitted successfully.",
      submittingMerge: "Submitting request",
      switchToNewPortal: "Switch to new portal",
      tenantAlreadyMerging:
        "There is already an account being merged. To merge another one, please wait till it's complete.",
      submitRequestMsgTitle: "Merge request submitted",
      submitRequestMsgDescription:
        "A merge account request was submitted and is now awaiting approval.",
      autoResolveName: "Allow auto-resolve conflict",
      categoryName: "Category name",
      searchByCategory: "Search by category name",
      selectCategoryAssessmentMessage: `Select categories to include for this assessment. You can further specify if you want to automatically resolve conflicts for that category. If a category is "On" for auto-resolve, it might get skipped and won't block the merge process.`,
      selectCategoryAssessmentTitle: "Select categories to run assessment",
      selectCategoryMergeMessage: `Select categories to include for Account Merge. You can further specify if you want to automatically resolve conflicts for that category. If a category is "On" for auto-resolve, it might get skipped and won't block the merge process.`,
      selectCategoryMergeTitle:
        "Select categories to run assessment and merge the account",
      selectCategoryWarningMessage: `Account Merge is a one-time process. You cannot merge the same account again.`,
      selectCategoryTitle: "Specify categories",
      numOfSelected: "{num} of {total} selected",
    },
  },
  countriesOrRegions: {
    AD: "Andorra",
    AE: "United Arab Emirates",
    AF: "Afghanistan",
    AG: "Antigua and Barbuda",
    AI: "Anguilla",
    AL: "Albania",
    AM: "Armenia",
    AO: "Angola",
    AQ: "Antarctica",
    AR: "Argentina",
    AS: "American Samoa",
    AT: "Austria",
    AU: "Australia",
    AW: "Aruba",
    AX: "Åland Islands",
    AZ: "Azerbaijan",
    BA: "Bosnia and Herzegovina",
    BB: "Barbados",
    BD: "Bangladesh",
    BE: "Belgium",
    BF: "Burkina Faso",
    BG: "Bulgaria",
    BH: "Bahrain",
    BI: "Burundi",
    BJ: "Benin",
    BL: "Saint Barthélemy",
    BM: "Bermuda",
    BN: "Brunei",
    BO: "Bolivia",
    BQ: "Bonaire",
    BR: "Brazil",
    BS: "Bahamas",
    BT: "Bhutan",
    BV: "Bouvet Island",
    BW: "Botswana",
    BY: "Belarus",
    BZ: "Belize",
    CA: "Canada",
    CC: "Cocos (Keeling) Islands",
    CD: "Congo (DRC)",
    CF: "Central African Republic",
    CG: "Congo",
    CH: "Switzerland",
    CI: "Côte d’Ivoire",
    CK: "Cook Islands",
    CL: "Chile",
    CM: "Cameroon",
    CN: "China",
    CO: "Colombia",
    CR: "Costa Rica",
    CU: "Cuba",
    CV: "Cabo Verde",
    CW: "Curaçao",
    CX: "Christmas Island",
    CY: "Cyprus",
    CZ: "Czechia",
    DE: "Germany",
    DJ: "Djibouti",
    DK: "Denmark",
    DM: "Dominica",
    DO: "Dominican Republic",
    DZ: "Algeria",
    EC: "Ecuador",
    EE: "Estonia",
    EG: "Egypt",
    ER: "Eritrea",
    ES: "Spain",
    ET: "Ethiopia",
    FI: "Finland",
    FJ: "Fiji",
    FK: "Falkland Islands",
    FM: "Micronesia",
    FO: "Faroe Islands",
    FR: "France",
    GA: "Gabon",
    GB: "United Kingdom",
    GD: "Grenada",
    GE: "Georgia",
    GF: "French Guiana",
    GG: "Guernsey",
    GH: "Ghana",
    GI: "Gibraltar",
    GL: "Greenland",
    GM: "Gambia",
    GN: "Guinea",
    GP: "Guadeloupe",
    GQ: "Equatorial Guinea",
    GR: "Greece",
    GS: "South Georgia and South Sandwich Islands",
    GT: "Guatemala",
    GU: "Guam",
    GW: "Guinea-Bissau",
    GY: "Guyana",
    HK: "Hong Kong SAR",
    HM: "Heard Island and McDonald Islands",
    HN: "Honduras",
    HR: "Croatia",
    HT: "Haiti",
    HU: "Hungary",
    ID: "Indonesia",
    IE: "Ireland",
    IL: "Israel",
    IM: "Isle of Man",
    IN: "India",
    IO: "British Indian Ocean Territory",
    IQ: "Iraq",
    IR: "Iran",
    IS: "Iceland",
    IT: "Italy",
    JE: "Jersey",
    JM: "Jamaica",
    JO: "Jordan",
    JP: "Japan",
    KE: "Kenya",
    KG: "Kyrgyzstan",
    KH: "Cambodia",
    KI: "Kiribati",
    KM: "Comoros",
    KN: "Saint Kitts and Nevis",
    KP: "North Korea",
    KR: "Korea",
    KW: "Kuwait",
    KY: "Cayman Islands",
    KZ: "Kazakhstan",
    LA: "Laos",
    LB: "Lebanon",
    LC: "Saint Lucia",
    LI: "Liechtenstein",
    LK: "Sri Lanka",
    LR: "Liberia",
    LS: "Lesotho",
    LT: "Lithuania",
    LU: "Luxembourg",
    LV: "Latvia",
    LY: "Libya",
    MA: "Morocco",
    MC: "Monaco",
    MD: "Moldova",
    ME: "Montenegro",
    MF: "Saint Martin",
    MG: "Madagascar",
    MH: "Marshall Islands",
    MK: "North Macedonia",
    ML: "Mali",
    MM: "Myanmar",
    MN: "Mongolia",
    MO: "Macao SAR",
    MP: "Northern Mariana Islands",
    MQ: "Martinique",
    MR: "Mauritania",
    MS: "Montserrat",
    MT: "Malta",
    MU: "Mauritius",
    MV: "Maldives",
    MW: "Malawi",
    MX: "Mexico",
    MY: "Malaysia",
    MZ: "Mozambique",
    NA: "Namibia",
    NC: "New Caledonia",
    NE: "Niger",
    NF: "Norfolk Island",
    NG: "Nigeria",
    NI: "Nicaragua",
    NL: "Netherlands",
    NO: "Norway",
    NP: "Nepal",
    NR: "Nauru",
    NU: "Niue",
    NZ: "New Zealand",
    OM: "Oman",
    PA: "Panama",
    PE: "Peru",
    PF: "French Polynesia",
    PG: "Papua New Guinea",
    PH: "Philippines",
    PK: "Pakistan",
    PL: "Poland",
    PM: "Saint Pierre and Miquelon",
    PN: "Pitcairn Islands",
    PR: "Puerto Rico",
    PS: "Palestinian Authority",
    PT: "Portugal",
    PW: "Palau",
    PY: "Paraguay",
    QA: "Qatar",
    RE: "Réunion",
    RO: "Romania",
    RS: "Serbia",
    RU: "Russia",
    RW: "Rwanda",
    SA: "Saudi Arabia",
    SB: "Solomon Islands",
    SC: "Seychelles",
    SD: "Sudan",
    SE: "Sweden",
    SG: "Singapore",
    SH: "St Helena, Ascension, Tristan da Cunha",
    SI: "Slovenia",
    SJ: "Svalbard",
    SK: "Slovakia",
    SL: "Sierra Leone",
    SM: "San Marino",
    SN: "Senegal",
    SO: "Somalia",
    SR: "Suriname",
    SS: "South Sudan",
    ST: "São Tomé and Príncipe",
    SV: "El Salvador",
    SX: "Sint Maarten",
    SY: "Syria",
    SZ: "Eswatini",
    TC: "Turks and Caicos Islands",
    TD: "Chad",
    TF: "French Southern Territories",
    TG: "Togo",
    TH: "Thailand",
    TJ: "Tajikistan",
    TK: "Tokelau",
    TL: "Timor-Leste",
    TM: "Turkmenistan",
    TN: "Tunisia",
    TO: "Tonga",
    TR: "Turkey",
    TT: "Trinidad and Tobago",
    TV: "Tuvalu",
    TW: "Taiwan",
    TZ: "Tanzania",
    UA: "Ukraine",
    UG: "Uganda",
    UM: "U.S. Outlying Islands",
    US: "United States",
    UY: "Uruguay",
    UZ: "Uzbekistan",
    VA: "Vatican City",
    VC: "Saint Vincent and the Grenadines",
    VE: "Venezuela",
    VG: "British Virgin Islands",
    VI: "U.S. Virgin Islands",
    VN: "Vietnam",
    VU: "Vanuatu",
    WF: "Wallis and Futuna",
    WS: "Samoa",
    XE: "Sint Eustatius",
    XJ: "Jan Mayen",
    XK: "Kosovo",
    XS: "Saba",
    YE: "Yemen",
    YT: "Mayotte",
    ZA: "South Africa",
    ZM: "Zambia",
    ZW: "Zimbabwe",
  },
  region: {
    /* eslint-disable sort-keys */
    AutoResolve: "Auto Resolve",
    AustraliaEast: "Australia East",
    AustraliaSoutheast: "Australia Southeast",
    BrazilSouth: "Brazil South",
    CanadaCentral: "Canada Central",
    CanadaEast: "Canada East",
    CentralIndia: "Central India",
    CentralUS: "Central US",
    CentralUSEUAP: "Central US EUAP",
    EastAsia: "East Asia",
    EastUS: "East US",
    EastUS2: "East US 2",
    EastUS2EUAP: "East US 2 EUAP",
    FranceCentral: "France Central",
    GermanyWestCentral: "Germany West Central",
    JapanEast: "Japan East",
    JapanWest: "Japan West",
    KoreaCentral: "Korea Central",
    KoreaSouth: "Korea South",
    NorthCentralUS: "North Central US",
    NorthEurope: "North Europe",
    SouthCentralUS: "South Central US",
    SouthIndia: "South India",
    SoutheastAsia: "Southeast Asia",
    UKSouth: "UK South",
    UKWest: "UK West",
    WestCentralUS: "West Central US",
    WestEurope: "West Europe",
    WestIndia: "West India",
    WestUS: "West US",
    WestUS2: "West US 2",
    WestUS3: "West US 3",
    SouthAfricaNorth: "South Africa North",
    SouthAfricaWest: "South Africa West",
    SwitzerlandNorth: "Switzerland North",
    NorwayEast: "Norway East",
    UAENorth: "UAE North",
    USGovVirginia: "USGov Virginia",
    USGovArizona: "USGov Arizona",
    USGovIowa: "USGov Iowa",
    USGovTexas: "USGov Texas",
    USDoDCentral: "USDoD Central",
    USDoDEast: "USDoD East",
    ChinaEast: "China East",
    ChinaEast2: "China East 2",
    ChinaNorth: "China North",
    ChinaNorth2: "China North 2",
    UsNatEast: "UsNat East",
    UsNatWest: "UsNat West",
    UsSecEast: "UsSec East",
    UsSecWest: "UsSec West",
    /* eslint-enable sort-keys */
  },
  notification: {
    $title: "Notifications",
    dismiss: "Dismiss",
    dismissAll: "Dismiss all",
    errorSemanticDesc: {
      $axiosError: "Something went wrong, please try again later.",
      $browserAuthError:
        "Your session has expired. Please check your internet connection or refresh the page.",
      $networkError: "Please check your internet connection.",
    },
    noContent: "No notifications to show",
    noContentDescription:
      "New notifications from this session will appear here.",
  },
  tours: {
    emptyMessage: "Sorry! No guided tour is available for this section.",
    finish: "Finish",
    guidedTour: "Guided Tour",
    indexOf: "{index} of {sum}",
    next: "Next",
    previous: "Previous",
  },
  panel: {
    closeMessage: "Context pane closed.",
    openMessage: "Context pane opened.",
  },
  error: {
    authError: {
      $message:
        "Your session has expired. Please refresh the page to start a new session.",
      $title: "Session expired",
    },
    extensionManifestLoadFailed: {
      $title: 'Failed to load extension manifest: "{extensionName}"',
    },
    initializationFailed: {
      $title: "Initialization failed",
    },
    networkError: {
      $message: "Failed to load {resource} resource. ",
      $message2: "Please check your network and DNS setting.",
      $title: "Network error",
    },
    notFound: {
      $message:
        "Looks like this page doesn't exist or can't be found. Make sure the URL is correct.",
      $title: "404 - Page not found",
    },
  },
  main: {
    $loadingTitle: "Loading...",
  },
};

const messages = removeComments(messagesWithComment);

export default messages;
