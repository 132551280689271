import { ExtensionLogLevel } from "@msbabylon/core";
import { defaultModelBuilder, rootModels } from "@msbabylon/shell-framework";
import { createModel } from "nyax";
import { EMPTY, combineLatest } from "rxjs";
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  pairwise,
  startWith,
  switchMapTo,
  tap,
} from "rxjs/operators";
import { catalogToc } from "src/containers/AppSidebarHomeMenu";
import { dependencies } from "src/dependencies";
import messageIds from "src/locales/messageIds";
import { babylonInstanceModel } from "src/store/shell-models/babylon/instance";

function parseLocation(path = "", search = "") {
  path = path.slice(path.indexOf("/", 1));
  const params = new URLSearchParams(search.slice(1));
  const searchObject: Record<string, string> = {};
  params.forEach((v, k) => {
    if (k === "searchQueryKeyword") {
      return;
    }
    // eslint-disable-next-line security/detect-object-injection
    searchObject[k] = v;
  });
  return {
    path,
    search: searchObject,
  };
}

export const shellCommonModel = createModel(
  class extends defaultModelBuilder {
    public epics() {
      return {
        ...super.epics(),
        unhandledUIError: () => {
          const commandHandler = this.getContainer(
            rootModels.extension.command.handler
          );
          return this.rootAction$.pipe(
            filter((action) =>
              commandHandler.actions["ui.setError"].is(action)
            ),
            tap((action) => {
              if (
                commandHandler.actions["ui.setError"].is(action) &&
                action.payload != null
              ) {
                const uiItem = this.getContainer(
                  rootModels.ui.item.helperByUIId,
                  action.payload.payload.uiId
                );
                const extensionEntityModel = this.getContainer(
                  rootModels.extension.item.entity
                );
                const extensionName =
                  extensionEntityModel.state.byId[uiItem.getters.item.sessionId]
                    .extensionName;
                this.dependencies.logger.logEvent(
                  ExtensionLogLevel.Critical,
                  "UnhandledError",
                  JSON.stringify(action.payload.payload.error),
                  {
                    ...uiItem.getters.item,
                    source: extensionName,
                  }
                );
              }
            }),
            catchError(() => EMPTY),
            switchMapTo(EMPTY)
          );
        },
        redirectToCatalogHome: () => {
          const router = this.getContainer(rootModels.__router);
          return this.rootAction$
            .pipe(
              filter(() => router.state.isInitialized),
              map(() => this.dependencies.router.getLocation()),
              distinctUntilChanged(
                (prev, cur) => prev.state?.key === cur.state?.key
              )
            )
            .pipe(
              tap((item) => {
                if (
                  item.pathname === "/" &&
                  dependencies.application.resourceName
                ) {
                  const target = {
                    extensionName: catalogToc.extensionName,
                    component: {
                      type: "catalogHome",
                      params: {},
                    },
                  };
                  this.getContainer(
                    rootModels.ui.list.main.commonHelper
                  ).actions.replace.dispatch(target);
                }
              }),
              switchMapTo(EMPTY)
            );
        },
        pageLogging: () => {
          const router = this.getContainer(rootModels.__router);
          return this.rootAction$
            .pipe(
              filter(() => router.state.isInitialized),
              map(() => this.dependencies.router.getLocation()),
              distinctUntilChanged(
                (prev, cur) => prev.state?.key === cur.state?.key
              )
            )
            .pipe(
              pairwise(),
              tap(([from, to]) => {
                this.dependencies.logger.logEvent(
                  ExtensionLogLevel.Info,
                  "Page-Navigation-Event",
                  "",
                  {
                    to: parseLocation(to.pathname, to.search),
                    from: parseLocation(from.pathname, from.search),
                  }
                );
              }),
              switchMapTo(EMPTY)
            );
        },
        panelLogging: () => {
          const panel = this.getContainer(
            rootModels.ui.list.panel.commonHelper
          );
          return this.rootAction$.pipe(
            filter(
              (act) => panel.actions.add.is(act) || panel.actions.delete.is(act)
            ),
            tap((act) => {
              const logData = {
                to: {
                  action: "",
                  options: {},
                },
                from: parseLocation(
                  window.location.pathname,
                  window.location.search
                ),
              };
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              const getSanitizedData = (payload: any) => {
                return {
                  extensionName: payload?.extensionName,
                  uiType: payload?.uiType,
                  component: {
                    type: payload?.component?.type,
                  },
                };
              };
              if (panel.actions.add.is(act)) {
                logData.to.action = "open";
                logData.to.options = getSanitizedData(act.payload);
              }
              if (panel.actions.delete.is(act)) {
                logData.to.action = "close";
                logData.to.options = getSanitizedData(act.payload);
              }
              this.dependencies.logger.logEvent(
                ExtensionLogLevel.Info,
                "Page-Navigation-Event",
                "",
                logData
              );
            }),
            switchMapTo(EMPTY)
          );
        },
        // this is to refresh the catalog home page data with limited re-render
        homeRefresh: () => {
          const uiEntityHelper = this.getContainer(
            rootModels.ui.list.main.commonHelper
          );
          return this.rootAction$
            .pipe(
              map(() => uiEntityHelper.getters.entity.getters.items.length),
              debounceTime(500),
              startWith(0)
            )
            .pipe(
              distinctUntilChanged(),
              tap((len) => {
                if (len === 0 && this.dependencies.shellExtension) {
                  this.dependencies.shellExtension.sendMessage("catalog", {
                    type: "home.refresh",
                    payload: {},
                  });
                }
              }),
              switchMapTo(EMPTY)
            );
        },
        changePageTitle: () => {
          const uiMainListEntity = this.getContainer(
            rootModels.ui.list.main.entity
          );
          const instanceModel = this.getContainer(babylonInstanceModel);
          return combineLatest([
            this.rootAction$.pipe(
              map(() => instanceModel.state.azureAccountInstance?.name),
              filter((name) => name != null),
              distinctUntilChanged()
            ),
            this.rootAction$.pipe(
              map(() => uiMainListEntity.getters.currentUIItem?.title),
              distinctUntilChanged()
            ),
          ]).pipe(
            tap(([instanceName, uiTitle]) => {
              const titleArr = [];
              if (uiTitle) {
                titleArr.push(uiTitle);
              }
              if (instanceName) {
                titleArr.push(instanceName);
              }
              titleArr.push(
                this.dependencies.intl.formatMessage({
                  id: messageIds.topbar.azurePurviewStudio,
                })
              );
              document.title = titleArr.join(" - ");
            }),
            switchMapTo(EMPTY)
          );
        },
      };
    }
  }
);
