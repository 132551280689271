/** @jsxImportSource @emotion/react */
import { useFonts } from "@adux/common-react";
import { css } from "@emotion/react";
import React, { useEffect, useRef } from "react";
import {
  ICopilotMessage,
  ICopilotSuggestion,
} from "src/apis/CopilotApi/interface";
import AppCopilotMessage from "src/containers/Copilot/AppCopilotMessage";
import TextSuggestions from "src/containers/Copilot/AppCopilotSuggestion/TextSuggestions";
import ActionSuggestion from "./AppCopilotSuggestion/ActionSuggestion";

interface AppCopilotMessagesProps {
  messages: ICopilotMessage[];
  actionSuggestions: ICopilotSuggestion[];
  textSuggestions: ICopilotSuggestion[];
  isLoading: boolean;
}

export default React.memo(function AppCopilotMessages(
  props: AppCopilotMessagesProps
) {
  const { messages, actionSuggestions, textSuggestions, isLoading } = props;
  const listEndRef = useRef<HTMLDivElement>(null);

  const cssTypography = useFonts();

  useEffect(() => {
    // scroll to bottom whenever message change
    setTimeout(() => {
      listEndRef.current &&
        listEndRef.current.scrollIntoView({ behavior: "smooth" });
    }, 0);
  }, [messages]);

  return (
    <div
      css={[
        css`
          flex: 1;
          display: flex;
          flex-direction: column;
          flex-wrap: nowrap;
          overflow-y: auto;
          gap: 8px;
          padding: 0 14px;
          word-break: break-word;
          white-space: pre-wrap;
          overflow-x: hidden;
        `,
        cssTypography.body1,
      ]}
    >
      {messages.map((item, idx) => {
        return (
          <AppCopilotMessage message={item} key={`${item.messageId}-${idx}`} />
        );
      })}
      {actionSuggestions.map((item, idx) => {
        return <ActionSuggestion suggestion={item} key={`${item.id}-${idx}`} />;
      })}
      <div ref={listEndRef} />
      <TextSuggestions suggestions={textSuggestions} isLoading={isLoading} />
    </div>
  );
});
