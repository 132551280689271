import { createModel } from "nyax";
import { defaultShellModelBuilder } from "src/store/defaultModelBuilder";

const SDK_LOADED_EVENT = "ms-teams-sdk-loaded";
const SDK_AUTH_FAILED_EVENT = "ms-teams-sdk-auth-failed";
const SDK_CHAT_CLOSED = "ms-teams-sdk-chat-closed";
const DEFAULT_SDK_URI = "https://teams.microsoft.com/embed-client/loader.js";

export const embedTeamsChatModel = createModel(
  class extends defaultShellModelBuilder {
    public initialState() {
      return {
        ...super.initialState(),
        /**
         * the icon element id in top bar
         */
        targetId: undefined as string | undefined,
        /**
         * callout hide or show
         */
        hidden: true,
        loadingSDK: undefined as boolean | undefined,
      };
    }

    public reducers() {
      return {
        ...super.reducers(),
        setTargetId: (value: string) => {
          this.state.targetId = value;
        },
        setHidden: (value: boolean) => {
          this.state.hidden = value;
        },
        setLoadingSDK: (value: boolean | undefined) => {
          this.state.loadingSDK = value;
        },
      };
    }

    public effects() {
      return {
        ...super.effects(),
        initSDK: async ({ windowRef }: { windowRef: Window }) => {
          await this.actions.setLoadingSDK.dispatch(true);

          await new Promise((resolve) => {
            const SDKLoadedEvent = async () => {
              windowRef.removeEventListener(SDK_LOADED_EVENT, SDKLoadedEvent);
              resolve(1);
            };

            windowRef.addEventListener(SDK_LOADED_EVENT, SDKLoadedEvent);

            windowRef.addEventListener(SDK_AUTH_FAILED_EVENT, (e) => {
              // eslint-disable-next-line no-console
              console.error(e);
            });

            windowRef.addEventListener(SDK_CHAT_CLOSED, (e) => {
              // eslint-disable-next-line no-console
              console.debug(e);
            });

            const script = windowRef.document.createElement("script");
            script.src = DEFAULT_SDK_URI;
            windowRef.document.body.appendChild(script);
          })
            .then(() => {
              this.actions.setLoadingSDK.dispatch(false);
            })
            .catch(() => {
              this.actions.setLoadingSDK.dispatch(undefined);
            });
        },
        renderConversation: async ({
          windowRef,
          conversationContainer,
        }: {
          windowRef: Window;
          conversationContainer: HTMLDivElement;
        }) => {
          // @ts-expect-error TeamsClient global object
          const sdk = windowRef.TeamsClient?.SDK;
          if (sdk === undefined) {
            return;
          }
          const account = await this.dependencies.auth.getAccount();
          const loginElement = windowRef.document.createElement("div");
          loginElement.style.display = "none";
          windowRef.document.body.appendChild(loginElement);
          const client = await sdk.create({
            loginHint: account?.userName,
            chatListParentElement: conversationContainer,
            localeCode: this.dependencies.locale.languageName,
            hostWindow: windowRef,
            // see doc: https://domoreexp.visualstudio.com/Teamspace/_git/teams-modular-packages?path=/packages/experiences/embed-client/readme.md&_a=preview&anchor=embed-client-api
            loginPageParentElement: loginElement,
          });
          // render the list of conversations
          const conversations = await client.conversations.get();
          await conversations.render();
        },
      };
    }
  }
);
