/** @jsxImportSource @emotion/react */
import { StarRating, StarRatingProps } from "@adux/common-react";
import { css } from "@emotion/react";
import { PurviewMode } from "@msbabylon/purview-util/constants";
import { toastContainerIds } from "@msbabylon/shell-framework";
import { GetContainer } from "nyax";
import { toast } from "react-toastify";
import { ToastCloseButton } from "src/components/ToastCloseButton";
import { ToastStyleGuard } from "src/components/ToastStyleGuard";
import { dependencies } from "src/dependencies";
import { starRatingFeedbackModel } from "src/store/shell-models/babylon/starRatingFeedback";
import assert from "src/util/assert";

type Config = Pick<
  StarRatingProps,
  "title" | "starDefinition" | "enableComment" | "intlStrings"
> & { id: string };

function triggerStarRating(getContainer: GetContainer, config: Config) {
  const starRatingFeedbackContainer = getContainer(starRatingFeedbackModel);
  const { id, title, starDefinition, enableComment, intlStrings } = config;

  const toastId = toast(
    <ToastStyleGuard>
      <div
        onFocusCapture={stopAutoClose}
        css={css`
          width: 350px;
        `}
      >
        <StarRating
          title={title}
          enableComment={enableComment}
          starDefinition={starDefinition}
          intlStrings={intlStrings}
          onSubmit={handleSubmit}
        />
      </div>
    </ToastStyleGuard>,
    {
      position: "bottom-right",
      autoClose: 60 * 1000,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: true,
      pauseOnFocusLoss: false,
      draggable: false,
      closeButton: <ToastCloseButton onClose={manuallyCloseToast} />,
      containerId: toastContainerIds.starRatingFeedback,
    }
  );

  function stopAutoClose() {
    toast.update(toastId, {
      autoClose: false,
      containerId: toastContainerIds.starRatingFeedback,
    });
  }

  starRatingFeedbackContainer.actions.trackRequest.dispatch({ id, title });

  function closeToast() {
    toast.dismiss(toastId);
  }
  async function manuallyCloseToast() {
    starRatingFeedbackContainer.actions.trackRefuse.dispatch({ id, title });
    closeToast();
  }
  async function handleSubmit(feedback: {
    title: string;
    rating: number;
    comment?: string;
    definition?: string;
  }) {
    starRatingFeedbackContainer.actions.trackSubmit.dispatch({ id, title });
    await starRatingFeedbackContainer.actions.submit.dispatch({
      id,
      ...feedback,
    });
    closeToast();
  }
}

export default (getContainer: GetContainer) => async (config: unknown) => {
  assert(typeof config === "object");
  const env = dependencies.application.features.environment;
  // not support M365
  if (env.mode === PurviewMode.M365) {
    return;
  }
  requestAnimationFrame(() => {
    triggerStarRating(getContainer, config as Config);
  });
};
