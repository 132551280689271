import { ITheme } from "@fluentui/react";
import { lightThemeSet as _lightTheme, ThemeSet } from "@msbabylon/shell-core";
import {
  MainTheme,
  SidebarTheme,
  TopbarTheme,
} from "@msbabylon/shell-framework";
import produce from "immer";

const lightThemeSet: ThemeSet = {
  main: produce(_lightTheme.main as MainTheme, (draft) => {
    draft.topbarHeight = 48;
    draft.sidebarWidth = 48;
    draft.boxShadow =
      "0px 4px 8px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12)";
    draft.zIndex = "1";

    draft.semanticColors.bodyBackground = "#FAFAFA";
    draft.semanticColors.buttonBackground = "#FAFAFA";
    draft.sidebarWidthExpanded = 280;
  }),
  panel: produce(_lightTheme.panel as ITheme, (draft) => {
    draft.semanticColors.bodyBackground = "#FFFFFF";
    draft.semanticColors.buttonBackground = "#FFFFFF";
  }),
  topbar: produce(_lightTheme.topbar as TopbarTheme, (draft) => {
    draft.buttonOutline = "#000 solid 1px";

    draft.palette.themePrimary = "#0F6CBD";
    draft.palette.themeDark = "#0F548C";

    draft.semanticColors.bodyText = "#424242";
    draft.semanticColors.bodyBackground = "#f0f0f0";

    draft.semanticColors.buttonText = "#616161";
    draft.semanticColors.buttonTextHovered = "#0F6CBD";
    draft.semanticColors.buttonTextChecked = "#0F6CBD";
    draft.semanticColors.buttonTextPressed = "#115EA3";
    draft.semanticColors.buttonBackground = "#f0f0f0";
    draft.semanticColors.buttonBackgroundHovered = "#fafafa";
    draft.semanticColors.buttonBackgroundPressed = "#f5f5f5";
    draft.semanticColors.buttonBackgroundChecked = "#ffffff";

    draft.semanticColors.link = "#0F6CBD";
    draft.semanticColors.menuDivider = "#8a8a8a";

    // draft.semanticColors.inputText = "#ffffff";
    // draft.semanticColors.inputBackground = "#0F548C";

    draft.palette.themeTertiary = "#ffffff"; // todo
  }),
  sidebar: produce(_lightTheme.sidebar as SidebarTheme, (draft) => {
    draft.buttonHeight = 48;
    draft.boxShadow =
      "0px 1.2px 3.6px rgba(0, 0, 0, 0.1), 0px 6.4px 14.4px rgba(0, 0, 0, 0.13)";

    draft.semanticColors.bodyText = "#323130";
    draft.semanticColors.bodyBackground = "#f5f5f5";

    draft.semanticColors.buttonText = "#616161";
    draft.semanticColors.buttonTextHovered = "#212121";
    draft.semanticColors.buttonTextChecked = "#0F6CBD";
    draft.semanticColors.buttonBackground = "transparent";
    draft.semanticColors.buttonBackgroundHovered = "#FFFFFF";
    draft.semanticColors.buttonBackgroundPressed = "#FFFFFF";
    draft.semanticColors.buttonBackgroundChecked = "#FFFFFF";
    draft.semanticColors.buttonBackgroundCheckedHovered = "#FFFFFF";
  }),
};

export default lightThemeSet;

// hardcoded colors for try the new portal callout
// GA portal uses v8 controls, but try the new portal callout is designed with v9 theme
export const colorsLightV9 = {
  themeDark: "#132139",
  themePrimary: "#004E8C",
  themeDarkAlt: "#0B3D6F",
};
