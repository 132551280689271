import {
  babylonAccountArmEntityModel,
  babylonAccountEntityModel,
} from "src/store/shell-models/babylon/account/entity";
import {
  babylonAccountArmHelperModel,
  babylonAccountHelperModel,
} from "src/store/shell-models/babylon/account/helper";

export const babylonAccountModels = {
  entity: babylonAccountEntityModel,
  helper: babylonAccountHelperModel,
  armEntity: babylonAccountArmEntityModel,
  armHelper: babylonAccountArmHelperModel,
};
