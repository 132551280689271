import { Nullable } from "@adux/common-react/lib/types/nullable";
import { newGuid } from "@msbabylon/core";
import { createErrorHandlingInterceptor } from "@msbabylon/purview-util/axios";
import { regions } from "@msbabylon/sdk-hub";
import {
  AuthService,
  createAuthorizationHeaderInterceptor,
  LocaleService,
} from "@msbabylon/shell-core";
import axios from "axios";
import { isNull } from "lodash-es";
import { ShellApplication } from "src/models/app";
import { LoggerService } from "src/services/LoggerService";
import {
  createTrackEvent,
  createTrackUnhandledErrorEvent,
} from "src/util/axios";

const tenantHeaderName = `__TENANT_${newGuid()}`;
const cesResourceId = "https://microsoft.onmicrosoft.com/cessurvey/.default";

interface CesEnv {
  global: string;
  europe: string;
}
interface SurveyDef {
  teamName: string;
  surveyName: string;
  formId: string;
  eventName: string;
}
const cesEnvProd: CesEnv = {
  global: "https://world.ces.microsoftcloud.com",
  europe: "https://europe.ces.microsoftcloud.com",
};

export enum SurveyType {
  nsat = "nsat",
  nps = "nps",
}
const nsatSurvey: SurveyDef = {
  teamName: "AzurePurview",
  surveyName: "AzurePurview-NSAT",
  formId:
    "v4j5cvGGr0GRqy180BHbR54XFVLQQ2hAvh0MlGcfQN1UMEtFRlcwT0hKQkxLVkdZNjJKOUQ3TDBSNSQlQCN0PWcu",
  eventName: "nsat",
};
const npsSurvey: SurveyDef = {
  teamName: "AzurePurview",
  surveyName: "AzurePurview-NPS",
  formId:
    "v4j5cvGGr0GRqy180BHbR6n5-318lG5DoqAG6U3AaRJURURENlVOTTBXQlg1MDAwV00wNjZBNktWTiQlQCN0PWcu",
  eventName: "login",
};
const surveyDefMap: Record<SurveyType, SurveyDef> = {
  [SurveyType.nps]: npsSurvey,
  [SurveyType.nsat]: nsatSurvey,
};

export type EligibilitiesResponse = {
  Eligibility: boolean;
  FormsProId?: null;
  FormsProEligibilityId?: string | null;
  SurveyName?: string;
};

function formatStringForCompare(str: string): string {
  if (str == null) {
    return str;
  }
  return str.replace(/\s/g, "").toLowerCase();
}

export class CesApi {
  private readonly _cesEnv: CesEnv = cesEnvProd;
  private readonly _axios = axios.create({});
  private _location = "";
  private _formsProEligibilityId: Nullable<string>;

  constructor(
    private readonly _application: ShellApplication,
    private readonly _auth: AuthService,
    private readonly _locale: LocaleService,
    private readonly _logger: LoggerService
  ) {
    const requestInterceptors = [
      createAuthorizationHeaderInterceptor((config) => {
        if (config.headers == null) {
          config.headers = {};
        }
        // It's not a user input in the object key. https://github.com/nodesecurity/eslint-plugin-security/blob/master/docs/the-dangers-of-square-bracket-notation.md
        // eslint-disable-next-line security/detect-object-injection
        const tenantId = config.headers[tenantHeaderName] as string;
        // eslint-disable-next-line security/detect-object-injection
        delete config.headers[tenantHeaderName];
        return this._acquireToken(tenantId);
      }),
    ];

    this._axios.interceptors.request.use(async (config) => {
      await Promise.all(
        requestInterceptors.map((interceptor) => interceptor(config))
      );

      return config;
    });

    this._axios.interceptors.response.use(
      (resp) => resp,
      createErrorHandlingInterceptor({
        trackUnhandledErrorEvent: createTrackUnhandledErrorEvent(this._logger),
        trackEvent: createTrackEvent(this._logger),
      })
    );
  }

  private _checkEuropeLocation(location: string): boolean {
    const formattedLocation = formatStringForCompare(location);

    return (
      formattedLocation === formatStringForCompare(regions.WestEurope) ||
      formattedLocation === formatStringForCompare(regions.NorthEurope)
    );
  }

  private _getCESEndpoint(location: string): string {
    if (this._checkEuropeLocation(location)) {
      return this._cesEnv.europe;
    }
    return this._cesEnv.global;
  }

  // combined eligibilities API, will post an event and get eligibilities in the same time
  public async postEligibilities(
    type: SurveyType,
    location: string
  ): Promise<EligibilitiesResponse> {
    // eslint-disable-next-line security/detect-object-injection
    const surveyDef = surveyDefMap[type];
    if (this._application.environment.name === "dogfood") {
      return { Eligibility: false }; // not supported in dogfood
    }
    const userId = this._auth.account?.idTokenClaims?.oid;
    const tenantId = this._auth.account?.idTokenClaims?.tid;
    // eslint-disable-next-line security/detect-object-injection
    const baseUrl = this._getCESEndpoint(location);
    const response = await this._axios.post(
      `${baseUrl}/api/v1/${surveyDef.teamName}/Eligibilities/${surveyDef.surveyName}`,
      {},
      {
        params: {
          userId,
          eventName: surveyDef.eventName,
          tenantId,
        },
        headers: {
          [tenantHeaderName]: tenantId!,
        },
      }
    );
    const content = response.data as EligibilitiesResponse;
    return content;
  }

  public getSurveyConfig(surveyType: SurveyType) {
    // eslint-disable-next-line security/detect-object-injection
    const surveyDef = surveyDefMap[surveyType];

    return {
      ...surveyDef,
      userId: this._auth.account?.idTokenClaims?.oid,
      tenantId: this._auth.account?.idTokenClaims?.tid,
      locale: this._locale.languageName,
      isEuropeRegion: this._checkEuropeLocation(this._location),
      productContext: this._getSurveyContext(),
      accessToken: {
        getAccessTokenAsync: () => {
          return this._acquireToken(this._auth.account?.idTokenClaims?.tid);
        },
      },
    };
  }

  public setExtraConext(data: {
    location: string;
    formsProEligibilityId: Nullable<string>;
  }) {
    this._location = data.location;
    this._formsProEligibilityId = data.formsProEligibilityId;
  }

  private _getSurveyContext() {
    const tenantId = this._auth.account?.idTokenClaims?.tid;
    const locale = this._locale.languageName;

    return [
      { key: "Culture", value: locale },
      { key: "locale", value: locale },
      { key: "Referrer", value: window.location.origin },
      { key: "UrlReferrer", value: window.location.href },
      { key: "Version", value: "0.1" },
      {
        key: "ProductVersion",
        value: window.getBuildVersion ? window.getBuildVersion() || "" : "",
      },
      { key: "DeviceType", value: "web" },
      { key: "TenantId", value: tenantId },
      { key: "FormsProEligibilityId", value: this._formsProEligibilityId },
    ].filter((v): v is { key: string; value: string } => !isNull(v.value));
  }

  private _acquireToken(tenantId?: string) {
    return this._auth.acquireToken({
      scopes: [cesResourceId],
      tenant: tenantId,
    });
  }
}
