import moment from "moment";
import "moment/locale/cs";
import "moment/locale/de";
import "moment/locale/es";
import "moment/locale/fr";
import "moment/locale/hu";
import "moment/locale/it";
import "moment/locale/ja";
import "moment/locale/ko";
import "moment/locale/nl";
import "moment/locale/pl";
import "moment/locale/pt";
import "moment/locale/pt-br";
import "moment/locale/ru";
import "moment/locale/sv";
import "moment/locale/tr";
import "moment/locale/zh-cn";
import "moment/locale/zh-tw";
import Moment from "react-moment";
import { dependencies } from "src/dependencies";

const momentLocaleMapping: Record<string, string> = {
  cs: "cs",
  de: "de",
  en: "en",
  es: "es",
  fr: "fr",
  hu: "hu",
  it: "it",
  ja: "ja",
  ko: "ko",
  nl: "nl",
  pl: "pl",
  "pt-BR": "pt-br",
  "pt-PT": "pt",
  ru: "ru",
  sv: "sv",
  tr: "tr",
  "zh-Hans": "zh-cn",
  "zh-Hant": "zh-tw",
};

export default () => {
  const localeName = dependencies.locale.languageName || "en";
  // eslint-disable-next-line security/detect-object-injection
  const momentLanguageName = momentLocaleMapping[localeName];
  moment.locale(momentLanguageName);
  Moment.globalLocale = momentLanguageName;
  document.documentElement.lang = localeName;
};
