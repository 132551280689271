/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Icon } from "@fluentui/react";
import React, { useMemo } from "react";

const ColorPalette = ["#13A10E", "#015cda", "#ff8c00", "#386300", "#68217a"];
const WordsPattern = [
  "Searching for",
  "Generating",
  "Reordering",
  "Fetching",
  "Analyzing",
];
const IconName = [
  "Search",
  "CheckMark",
  "List",
  "CloudDownload",
  "CRMCustomerInsightsApp",
];

interface AppCopilotMessageSystemProps {
  text: string;
}

export default React.memo(function AppCopilotMessageSystem(
  props: AppCopilotMessageSystemProps
) {
  const { text } = props;

  const matchedIndex = useMemo(() => {
    return WordsPattern.findIndex((word) => text.includes(word));
  }, [text]);

  const color = useMemo(() => {
    if (matchedIndex < 0) {
      return ColorPalette[0];
    } else {
      // eslint-disable-next-line security/detect-object-injection
      return ColorPalette[matchedIndex];
    }
  }, [matchedIndex]);

  const iconName = useMemo(() => {
    if (matchedIndex < 0) {
      return "CheckMark";
    } else {
      // eslint-disable-next-line security/detect-object-injection
      return IconName[matchedIndex];
    }
  }, [matchedIndex]);

  return (
    <div
      css={css`
        display: flex;
        align-items: flex-start;
        gap: 6px;
      `}
    >
      <Icon
        iconName={iconName}
        style={{
          color,
          fontSize: "14px",
        }}
      />
      {text}
    </div>
  );
});
