import { newGuid } from "@msbabylon/core";
import {
  AuthService,
  createAuthorizationHeaderInterceptor,
  getResponseData,
} from "@msbabylon/shell-core";
import axios from "axios";
import { ShellApplication } from "src/models/app";
import { PermissionType } from "src/models/babylon";
import { Logger } from "src/services/Logger/interface";

const tenantHeaderName = `__TENANT_${newGuid()}`;

export class RoleService {
  private _generateAccountBaseUrl = () =>
    this._application.appEnv.gatewayEndpoint.replace(
      "{0}",
      this._application.resourceName ?? ""
    ) + "api/gateway";

  private readonly _axios = axios.create({});

  constructor(
    private readonly _application: ShellApplication,
    private readonly _authService: AuthService,
    private readonly _logger: Logger
  ) {
    const requestInterceptors = [
      createAuthorizationHeaderInterceptor((config) => {
        if (config.headers == null) {
          config.headers = {};
        }
        // It's not a user input in the object key. https://github.com/nodesecurity/eslint-plugin-security/blob/master/docs/the-dangers-of-square-bracket-notation.md
        // eslint-disable-next-line security/detect-object-injection
        const tenantId = config.headers[tenantHeaderName] as string;
        // eslint-disable-next-line security/detect-object-injection
        delete config.headers[tenantHeaderName];
        return this._authService.acquireToken({
          scopes: [this._application.appEnv.gatewayAuthResourceUri],
          tenant: tenantId || undefined,
        });
      }),
    ];

    this._axios.interceptors.request.use(async (config) => {
      await Promise.all(
        requestInterceptors.map((interceptor) => interceptor(config))
      );

      return config;
    });
  }

  public async getPermissions(): Promise<string[]> {
    if (!this._application.resourceName) {
      // shouldn't reach this code
      this._logger.logEvent(
        "error",
        "RoleServiceNoResourceName",
        "Account name not exist"
      );
      return [];
    }
    type Obligation = { type: string; collections?: string[] };
    const response = await getResponseData(
      this._axios.post<Record<string, Obligation>>(
        `${this._generateAccountBaseUrl()}/actions/collections/me`,
        [
          PermissionType.DataRead,
          PermissionType.DataWrite,
          PermissionType.ScanRead,
          PermissionType.ScanWrite,
          PermissionType.CollectionRead,
          PermissionType.CollectionWrite,
          PermissionType.ShareRead,
          PermissionType.InsightsRead,
        ]
      )
    );
    const result: string[] = [];
    Object.entries(response).forEach(([permission, obligation]) => {
      if (
        !(obligation.type === "NotApplicable" && obligation.collections == null)
      ) {
        result.push(permission);
      }
    });
    return result;
  }

  public async getRootPermissions(): Promise<PermissionType[]> {
    const rootCollectionName = this._application.resourceName;

    if (!rootCollectionName) {
      // shouldn't reach this code
      this._logger.logEvent(
        "error",
        "RoleServiceNoResourceName",
        "Account name not exist"
      );
      return [];
    }
    const response = await getResponseData(
      this._axios.post<Record<string, PermissionType[]>>(
        `${this._generateAccountBaseUrl()}/collections/actions/me`,
        [rootCollectionName]
      )
    );
    // eslint-disable-next-line security/detect-object-injection
    const result = response[rootCollectionName];
    return result;
  }
}
