import { GuideTourConfig, rootModels } from "@msbabylon/shell-framework";
import { GetContainer } from "nyax";
import { switchBackPurview } from "src/entries/init/initBannerMessage";
import { PermissionType } from "src/models/babylon";
import { babylonInstanceModel } from "src/store/shell-models/babylon/instance";
import { uiCopilotModel } from "src/store/shell-models/ui/copilot";
import starRatingFeedbackHandler from "./handlers/starRatingFeedback";

export default async (getContainer: GetContainer) => {
  const babylonModel = getContainer(babylonInstanceModel);
  // listen messages from extensions
  getContainer(rootModels.extension.command.message).actions.register.dispatch({
    handlers: {
      "friendlyName.update": async (name) => {
        // double check
        if (typeof name === "string" && name.length > 0) {
          // update state
          await babylonModel.actions.setFriendlyName.dispatch(name);
          // broadcasting to all extensions
          await babylonModel.actions.broadcastFriendlyName.dispatch(name);
        }
      },
      starRatingFeedback: starRatingFeedbackHandler(getContainer),
      switchToMsPortal: async () => switchBackPurview(getContainer),
      redirectToPage: async (url) => {
        if (typeof url === "string") {
          // eslint-disable-next-line security/detect-non-literal-fs-filename
          window.open(url, "_self");
        }
      },
    },
  });

  // listen message for guided tour
  getContainer(rootModels.extension.command.message).actions.register.dispatch({
    handlers: {
      guidedTourConfigs: async (config: unknown) => {
        getContainer(rootModels.ui.tour).actions.updateConfigs.dispatch(
          config as GuideTourConfig[]
        );
      },
      guidedTourOpen: async () => {
        getContainer(
          rootModels.ui.tour
        ).actions.refreshGuidedTourPosition.dispatch({});
      },
    },
  });

  // listen message for permissions update
  getContainer(rootModels.extension.command.message).actions.register.dispatch({
    handlers: {
      permissionsUpdate: async (payload) => {
        if (typeof payload === "object" && payload != null) {
          const permissionPayload = payload as {
            permissions?: PermissionType[];
            rootPermissions?: PermissionType[];
          };
          const permissions = permissionPayload.permissions;
          if (Array.isArray(permissions)) {
            babylonModel.actions.updatePermissions.dispatch({ permissions });
          }
          const rootPermissions = permissionPayload.rootPermissions;
          if (Array.isArray(rootPermissions)) {
            babylonModel.actions.updateRootPermissions.dispatch({
              permissions: rootPermissions,
            });
          }
        }
      },
    },
  });

  // listen message for extension iframe blur
  getContainer(rootModels.extension.command.message).actions.register.dispatch({
    handlers: {
      blurActiveIframe: async () => {
        if (document.activeElement instanceof HTMLIFrameElement) {
          document.activeElement.blur();
        }
      },
    },
  });

  // listen message for extension to copilot
  getContainer(rootModels.extension.command.message).actions.register.dispatch({
    handlers: {
      "copilot.viewBySearch": async (payload) => {
        const copilot = getContainer(uiCopilotModel);
        copilot.actions.setVisible.dispatch(true);
        copilot.actions.sendMessage.dispatch({
          message: (payload as string) || "",
        });
      },
    },
  });
};
