import { registerIcons } from "@fluentui/react";
import { ReactComponent as IconCatalogFilled } from "src/assets/M365/icon-catalog-filled.svg";
import { ReactComponent as IconCatalog } from "src/assets/M365/icon-catalog.svg";
import { ReactComponent as IconDataMapFilled } from "src/assets/M365/icon-data-map-filled.svg";
import { ReactComponent as IconDataMap } from "src/assets/M365/icon-data-map.svg";
import { ReactComponent as IconDataShare } from "src/assets/M365/icon-data-share.svg";
import { ReactComponent as IconInsightsFilled } from "src/assets/M365/icon-insights-filled.svg";
import { ReactComponent as IconInsights } from "src/assets/M365/icon-insights.svg";
import { ReactComponent as IconManagementFilled } from "src/assets/M365/icon-management-filled.svg";
import { ReactComponent as IconManagement } from "src/assets/M365/icon-management.svg";
import { ReactComponent as IconPolicyFilled } from "src/assets/M365/icon-policy-filled.svg";
import { ReactComponent as IconPolicy } from "src/assets/M365/icon-policy.svg";
import { ReactComponent as IconPrivacyFilled } from "src/assets/M365/icon-privacy-filled.svg";
import { ReactComponent as IconPrivacy } from "src/assets/M365/icon-privacy.svg";

const IconComponentMap = {
  Catalog: <IconCatalog />,
  CatalogFilled: <IconCatalogFilled />,
  DataMap: <IconDataMap />,
  DataMapFilled: <IconDataMapFilled />,
  DataShare: <IconDataShare />,
  Insights: <IconInsights />,
  InsightsFilled: <IconInsightsFilled />,
  Management: <IconManagement />,
  ManagementFilled: <IconManagementFilled />,
  Policy: <IconPolicy />,
  PolicyFilled: <IconPolicyFilled />,
  Privacy: <IconPrivacy />,
  PrivacyFilled: <IconPrivacyFilled />,
};

type IconNamesType = { [key in keyof typeof IconComponentMap]: string };
const keyPrefix = "SvgIcon";
const icons = Object.entries(IconComponentMap).reduce(
  (obj, [key, component]) => ({ ...obj, [keyPrefix + key]: component }),
  {}
);

export const SvgIconNames: IconNamesType = Object.entries(
  IconComponentMap
).reduce(
  (obj, [key]) => ({ ...obj, [key]: keyPrefix + key }),
  {}
) as IconNamesType;

export const initializeSvgIcons = () => {
  registerIcons({ icons });
};
