import { createUIViewModel } from "@msbabylon/shell-core";
import { createModel } from "nyax";
import { M365ShellHomePathname } from "src/entries/constant";
import messageIds from "src/locales/messageIds";

export const uiViewMainHomeModel = createModel(
  class extends createUIViewModel() {
    public selectors() {
      return {
        ...super.selectors(),
        title: () =>
          this.dependencies.intl.formatMessage({
            id: messageIds.m365.home.$title,
          }),
        location: () => M365ShellHomePathname,
      };
    }
  },
  {
    isLazy: true,
    isDynamic: true,
  }
);
