/** @jsxImportSource @emotion/react */
import { useTheme } from "@adux/common-react";
import { css } from "@emotion/react";
import React from "react";

interface AppCopilotUserMessageProps {
  text: string;
}

export default React.memo(function AppCopilotUserMessage(
  props: AppCopilotUserMessageProps
) {
  const { text } = props;

  const theme = useTheme();

  return (
    <div
      css={css`
        align-self: flex-end;
        margin: 20px 0px 20px 24px;
        padding: 10px 16px;
        border-radius: 8px;
        color: ${theme.palette.white};
        background: linear-gradient(90deg, #2870ea 10.79%, #1b4aef 87.08%);
        box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.12),
          0px 1.6px 3.6px rgba(0, 0, 0, 0.16);
      `}
    >
      {text}
    </div>
  );
});
