// Add the internal tenant ID here
// Note: If the tenant ID is added here, all the accounts in this tenant will be automatically redirect to MS internal portal (ms.web.purview.azure.com), no matter the user who is accessing.
const msTenantIds = [
  "72f988bf-86f1-41af-91ab-2d7cd011db47", // Microsoft tenant
  "c05e53d7-1756-4f57-a43f-fb87ff24313e", // Gondor WCUS tenant
];

// Add Canary tenants IDs in here, which are created in EUAP region and used for Gondor env setting.
const canaryTenantIds = [
  "96478fcb-3986-463a-ac02-17fdf37d985b",
  "ddfd8861-7cbd-43df-ad13-4f9e958cf53b",
];

export { canaryTenantIds, msTenantIds };

export const M365ShellAllAppsId = "mainAllApps";
export const M365ShellAllAppsPathname = "/apps";
export const M365ShellHomeId = "mainHome";
export const M365ShellHomePathname = "/home";

export const LandingPagePaths = {
  reconcile: "/reconcile",
  merge: "/merge",
};

// If the app path is NOT defined here, then we will redirect to the ROOT OF THE UNIFIED PORTAL by default.
export const UnifiedPortalAppPaths: Record<string, string> = {
  datacatalog: "/datacatalog",
};
