import {
  ActionType,
  CreatableSelect,
  CreatableSelectProps,
  CreatableSelectStyles,
  cssLayout,
  Select,
} from "@adux/common-react";
import { mergeStyleSets } from "@fluentui/react";
import React, { useMemo } from "react";
import { IntlShape, useIntl } from "react-intl";
import messageIds from "src/locales/messageIds";

export const getSelectStrings = (intl: IntlShape) => ({
  selectAll: intl.formatMessage({ id: messageIds.select.selectAll }),
  noOptions: intl.formatMessage({ id: messageIds.select.noOptions }),
  all: intl.formatMessage({ id: messageIds.shared.all }),
  selectPlaceholder: intl.formatMessage({
    id: messageIds.select.selectPlaceholder,
  }),
  multipleSelected: intl.formatMessage({
    id: messageIds.select.multipleSelected,
  }),
  clear: intl.formatMessage({ id: messageIds.shared.clear }),
  selected: intl.formatMessage({ id: messageIds.select.selected }),
  nonSelected: intl.formatMessage({ id: messageIds.select.nonSelected }),
  disabled: intl.formatMessage({ id: messageIds.shared.disabled }),
  indexOf: intl.formatMessage({ id: messageIds.select.indexOf }),
  inputGuidance: intl.formatMessage({
    id: messageIds.select.selectInputGuidance,
  }),
  menuGuidance: intl.formatMessage({
    id: messageIds.select.selectMenuGuidance,
  }),
  filterPlaceholder: intl.formatMessage({
    id: messageIds.select.filterEllipsis,
  }),
  loading: intl.formatMessage({ id: messageIds.shared.loadingEllipsis }),
});

const selectStylesConfig = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  menuList: (base: any) => ({
    ...base,
    [cssLayout.heightMediaQueries.down.xs]: {
      maxHeight: "80px",
    },
  }),
};

// For accessibility requirements
export type AppSelectProps<
  TValue,
  TMultiple extends boolean = false
> = CreatableSelectProps<TValue, TMultiple> &
  ({ placeholder: string } | { ariaLabel: string });

const TypedMemo: <T>(c: T) => T = React.memo;
export default TypedMemo(function AppSelect<
  TValue,
  TMultiple extends boolean = false
>(props: AppSelectProps<TValue, TMultiple>) {
  const { isCreatable, styles, ...otherProps } = props;
  const ariaLabel = props.ariaLabel || props.placeholder;
  const intl = useIntl();

  const strings = useMemo(() => getSelectStrings(intl), [intl]);

  const mergedStyles = useMemo<CreatableSelectStyles>(
    () => mergeStyleSets(selectStylesConfig, styles),
    [styles]
  );

  return isCreatable ? (
    <CreatableSelect
      createNewLabel={intl.formatMessage({ id: messageIds.shared.new })}
      strings={strings}
      creatableStrings={{
        button: intl.formatMessage({ id: messageIds.shared.button }),
      }}
      {...otherProps}
      ariaLabel={ariaLabel}
      styles={mergedStyles}
    />
  ) : (
    <Select
      strings={strings}
      {...otherProps}
      ariaLabel={ariaLabel}
      styles={mergedStyles.subComponentStyles?.select}
    />
  );
});

export type SelectActionType = ActionType;
