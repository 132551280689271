import {
  featureConstants,
  isObject,
  newError,
  parseJSON,
} from "@msbabylon/shell-framework";
import { isTestEnv } from "src/constants";
import { ShellDependencies } from "src/dependencies";
import { msTenantIds, UnifiedPortalAppPaths } from "src/entries/constant";
import environmentFeatureAdapter from "src/features/environment";
import settingsAdapter from "src/features/settings";
import tenantFeatureAdapter from "src/features/tenant";
import { getCurrentMainUIItem } from "src/util/createShellHrefByHref";
import {
  booleanAdapterDefaultFalse,
  booleanAdapterDefaultTrue,
  stringAdapterFactory,
} from "./commonAdapters";

export const featureAdapters = {
  environment: environmentFeatureAdapter,
  tenant: tenantFeatureAdapter,
  customportal: booleanAdapterDefaultTrue,
  redirect: booleanAdapterDefaultTrue,
  settings: settingsAdapter,
  language: stringAdapterFactory(),
  theme: stringAdapterFactory(),
  ga: booleanAdapterDefaultTrue,
  policy: booleanAdapterDefaultFalse,
  consent: booleanAdapterDefaultFalse,
  datashare: booleanAdapterDefaultFalse,
  showCesForm: booleanAdapterDefaultFalse,
  logo: booleanAdapterDefaultFalse,
  teamsEmbed: booleanAdapterDefaultFalse,
  enableDataQuality: booleanAdapterDefaultFalse,
  accountMerge: booleanAdapterDefaultFalse,
  accountMergeSecondary: booleanAdapterDefaultFalse,
  accountMergeCategoryFilter: booleanAdapterDefaultTrue,
  enableCopilot: booleanAdapterDefaultFalse,
  enableCopilotScripts: booleanAdapterDefaultFalse,
  tryUnifedPortalCallout: booleanAdapterDefaultFalse,
};

export const featureQueryPairs = (() => {
  return window.location.search
    .substring(1)
    .split("&")
    .filter((str) => str.startsWith(featureConstants.featurePrefix))
    .map((str) => {
      const index = str.indexOf("=");
      return index >= 0
        ? [str.substring(0, index), str.substring(index + 1)]
        : [str, ""];
    });
})();

export const featureQueryString = (() => {
  return featureQueryPairs.length > 0
    ? featureQueryPairs.map((e) => e.join("=")).join("&")
    : "";
})();

export type Features = {
  [P in keyof typeof featureAdapters]: ReturnType<typeof featureAdapters[P]>;
} & {
  ext: Record<string, Record<string, unknown> | undefined>;
};

let features: Features | undefined;
export function getFeatures() {
  if (features) {
    return features;
  }

  try {
    const result: Record<string, unknown> = {};

    const params = new URLSearchParams(featureQueryString);

    // set plain values
    params.forEach((v, key) => {
      if (key === "constructor" || key === "prototype" || key === "__proto__") {
        return;
      }
      if (!/^feature\..+/i.test(key)) {
        return;
      }
      // eslint-disable-next-line security/detect-object-injection
      result[key.substr(8)] = v;
    });

    Object.entries(featureAdapters).forEach(([name, adapter]) => {
      const key = featureConstants.featurePrefix + name;
      const value = params.get(key);
      try {
        // Only allowed feature values can be added here.
        // eslint-disable-next-line security/detect-object-injection
        result[name] = adapter(value);
      } catch (error) {
        throw newError(`Failed to parse feature flag "${name}"`, error);
      }
    });

    const ext: Record<string, unknown> = {};
    params.forEach((value, key) => {
      if (key.startsWith(featureConstants.featureExtPrefix)) {
        const extensionName = key.substring(
          featureConstants.featureExtPrefix.length
        );

        try {
          if (
            extensionName === "constructor" ||
            extensionName === "prototype" ||
            extensionName === "__proto__"
          ) {
            throw new Error("illegal extensionName");
          }

          const extensionValue = parseJSON(value);
          if (isObject(extensionValue)) {
            // Only function variable will be impacted, ignore this
            // eslint-disable-next-line security/detect-object-injection
            ext[extensionName] = extensionValue;
          } else {
            throw new Error("Extension feature flags should be an object");
          }
        } catch (error) {
          throw newError(
            `Failed to parse feature flags for extension "${extensionName}"`,
            error
          );
        }
      }
    });

    features = { ...result, ext } as Features;
    return features;
  } catch (error) {
    // swallow feature parse errors
    return {} as Features;
  }
}

const AzureECFeatureNames = {
  DataPolicyGUI: "DataPolicyGUI",
  Privacy: "Privacy",
  EnterpriseData: "EnterpriseData",
  CustomBranding: "UXCustomBranding",
  ShowShareV1: "ShowShareV1",
  DataQuality: "DataQuality",
  AccountMerge: "UXAccountMerge",
  TryUnifedPortalCallout: "UXTryUnifiedPortalCallout",
  AccountMergeSecondary:
    "WorkflowSvcEnableMergePurviewAccountFromSecondaryAccountWorkflowConfig",
  EnableCopilot: "UXEnableCopilot",
};
type ECName = keyof typeof AzureECFeatureNames;

export const getECFeatureNames = () => {
  return Object.values(AzureECFeatureNames);
};

export const getECResultMapping = (
  ff: StringMap<boolean | null>
): Record<ECName, boolean | null> => {
  const ecObject = AzureECFeatureNames;

  const result: Record<ECName, boolean | null> = {
    DataPolicyGUI: null,
    Privacy: null,
    EnterpriseData: null,
    CustomBranding: null,
    ShowShareV1: null,
    DataQuality: null,
    AccountMerge: null,
    TryUnifedPortalCallout: null,
    AccountMergeSecondary: null,
    EnableCopilot: null,
  };
  Object.entries(ecObject).forEach(([ecKey, ecValue]) => {
    const ecResult = Object.entries(ff).find(([key, result]) => {
      return ecValue === key;
    });
    result[ecKey as ECName] = ecResult ? ecResult[1] : null;
  });

  return result;
};

export async function getUnifiedPortalUrl(
  dependencies: Omit<ShellDependencies, "role">,
  path?: string,
  search?: string
) {
  const account = await dependencies.auth.getAccount();
  const tid = account?.idTokenClaims.tid;
  const isMsTenant = msTenantIds.indexOf(tid || "") >= 0;
  const unifiedPortalDNS =
    getFeatures().environment.name === "dogfood"
      ? "purview.officeppe.com"
      : isTestEnv
      ? "df.purview.microsoft.com"
      : isMsTenant
      ? "sip.purview.microsoft.com"
      : "purview.microsoft.com";
  let urlFeature = dependencies.urlFeature.getFeatureQuery();
  urlFeature = urlFeature.replace("feature.tenant", "tid");
  const url = new URL(
    `https://${unifiedPortalDNS}${path ?? "/"}?${urlFeature}`
  );
  if (search) {
    new URLSearchParams(search).forEach((value, key) => {
      url.searchParams.set(key, value);
    });
  }
  return url;
}

export function getUnifiedPortalAppUrl(
  dependencies: Omit<ShellDependencies, "role">,
  appId?: string | null | undefined
) {
  appId ??= getCurrentMainUIItem(dependencies)?.appId;

  const appPath = appId ? UnifiedPortalAppPaths[appId] : undefined; // eslint-disable-line security/detect-object-injection
  const appUrl = appPath
    ? new URL(appPath, "https://purview.microsoft.com/") // The host is not actually used, just to make the URL constructor happy
    : undefined;

  return getUnifiedPortalUrl(dependencies, appUrl?.pathname, appUrl?.search);
}
