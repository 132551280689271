/** @jsxImportSource @emotion/react */
import { cssTypography } from "@adux/common-react";
import { css } from "@emotion/react";
import { Link } from "@fluentui/react";
import { useGetContainer, useUIView } from "@msbabylon/shell-core";
import {
  ShellExtensionUIComponentProps,
  extensionConstants,
  parseAzureResourceId,
  rootModels,
} from "@msbabylon/shell-framework";
import { Container, createSubContainer } from "nyax";
import React, { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import AppPanel from "src/components/AppPanel";
import SharedBabylonInstanceSwitcher from "src/containers/SharedBabylonInstanceSwitcher";
import messageIds from "src/locales/messageIds";
import { uiSharedBabylonInstanceSwitcherModel } from "src/store/shell-models/ui/SharedBabylonInstanceSwitcher";
import { uiViewPanelInstanceModel } from "src/store/shell-models/ui/view/UIPanelInstance";

export default React.memo<ShellExtensionUIComponentProps>(
  function UIPanelInstance(props) {
    const { uiId } = props;

    const intl = useIntl();

    const getContainer = useGetContainer();

    const uiView = getContainer(uiViewPanelInstanceModel, uiId);
    const uiItemHelper = getContainer(rootModels.ui.item.helperByUIId, uiId);
    const isInitialized = useUIView(uiView, uiItemHelper);

    const uiPanelListCommonHelper = getContainer(
      rootModels.ui.list.panel.commonHelper
    );

    const currentInstance = useSelector(
      () => uiView.getters.azureAccountInstance
    );
    const currentInstanceInfo = useMemo(
      () => parseAzureResourceId(currentInstance?.id ?? ""),
      [currentInstance]
    );

    const uiBabylonInstanceSwitcherContainer = useMemo(
      () =>
        createSubContainer(uiView, "instanceSwitcher") as Container<
          typeof uiSharedBabylonInstanceSwitcherModel
        >,
      [uiView]
    );

    const onViewDiagnosticsClick = useCallback(() => {
      uiPanelListCommonHelper.actions.open.dispatch({
        extensionName: extensionConstants.shell,
        component: {
          type: "panelDiagnostics",
          params: {},
        },
      });
    }, [uiPanelListCommonHelper]);

    const canOk = useSelector(
      () => uiBabylonInstanceSwitcherContainer.getters.canSwitch
    );
    const onOkClick = useCallback(async () => {
      await uiBabylonInstanceSwitcherContainer.actions.switch.dispatch({});
    }, [uiBabylonInstanceSwitcherContainer]);
    const onCancelClick = useCallback(async () => {
      await uiPanelListCommonHelper.actions.closeUntil.dispatch({});
    }, [uiPanelListCommonHelper]);

    return (
      <AppPanel
        onCancel={onCancelClick}
        onNext={onOkClick}
        isNextDisabled={!canOk}
        labelNext={intl.formatMessage({
          id: messageIds.shared.ok,
        })}
      >
        {currentInstance && (
          <div
            css={css`
              display: flex;
              flex-direction: column;
              margin-bottom: 20px;

              ${cssTypography.bodyText}
            `}
          >
            <div
              css={css`
                margin-bottom: 8px;
                ${cssTypography.heading2}
              `}
              role="heading"
              aria-level={3}
            >
              {intl.formatMessage({ id: messageIds.instance.currentInstance })}
            </div>
            <div>
              {intl.formatMessage({ id: messageIds.instance.instanceName })}
              {": "}
              {currentInstance.name}
            </div>
            <div>
              {intl.formatMessage({ id: messageIds.instance.region })}
              {": "}
              {currentInstance.location}
            </div>
            <div>
              {intl.formatMessage({ id: messageIds.instance.resourceGroup })}
              {": "}
              {currentInstanceInfo.resourceGroup}
            </div>
            <div>
              {intl.formatMessage({ id: messageIds.instance.subscriptionId })}
              {": "}
              {currentInstanceInfo.subscriptionId}
            </div>
          </div>
        )}
        <div
          css={css`
            display: flex;
            flex-direction: column;
            margin-bottom: 20px;

            ${cssTypography.bodyText}
          `}
        >
          <div
            css={css`
              margin-bottom: 8px;
              ${cssTypography.heading2}
            `}
            role="heading"
            aria-level={3}
          >
            {intl.formatMessage({
              id: messageIds.instance.selectAnotherBabylonInstance,
            })}
          </div>
          {isInitialized && (
            <SharedBabylonInstanceSwitcher
              container={uiBabylonInstanceSwitcherContainer}
            />
          )}
        </div>

        {currentInstance && (
          <Link
            css={css`
              display: flex;
              flex-direction: column;
              padding: 0 0 14px 0;
              margin-top: auto;
              flex: 0 0 auto;
            `}
            onClick={onViewDiagnosticsClick}
          >
            {intl.formatMessage({ id: messageIds.instance.viewDiagnostics })}
          </Link>
        )}
      </AppPanel>
    );
  }
);
