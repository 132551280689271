import { Action, ActionHelper } from "nyax";
import { AnyAction } from "redux";
import { ofType } from "redux-observable";
import { OperatorFunction } from "rxjs";

export function ofAction<TPayload>(
  actionHelper: ActionHelper<TPayload>
): OperatorFunction<AnyAction, Action<TPayload>> {
  return ofType(actionHelper.type);
}
