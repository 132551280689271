import { PurviewMode } from "@msbabylon/purview-util/constants";
import { getEnvironmentConfig } from "@msbabylon/purview-util/environments";
import { isM365 } from "src/dependencies/mode";

export default (str: string | null) => {
  const environmentName = str ?? process.env.REACT_APP_DEFAULT_ENVIRONMENT;
  const mode = isM365 ? PurviewMode.M365 : PurviewMode.Azure;
  const environment = getEnvironmentConfig(mode, environmentName ?? "");
  return environment;
};
