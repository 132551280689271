import csIntlConfig from "./intlConfigs/cs";
import deIntlConfig from "./intlConfigs/de";
import enIntlConfig from "./intlConfigs/en";
import esIntlConfig from "./intlConfigs/es";
import frIntlConfig from "./intlConfigs/fr";
import huIntlConfig from "./intlConfigs/hu";
import itIntlConfig from "./intlConfigs/it";
import jaIntlConfig from "./intlConfigs/ja";
import koIntlConfig from "./intlConfigs/ko";
import nlIntlConfig from "./intlConfigs/nl";
import plIntlConfig from "./intlConfigs/pl";
import ptbrIntlConfig from "./intlConfigs/pt-BR";
import ptptIntlConfig from "./intlConfigs/pt-PT";
import ruIntlConfig from "./intlConfigs/ru";
import svIntlConfig from "./intlConfigs/sv";
import trIntlConfig from "./intlConfigs/tr";
import zhsIntlConfig from "./intlConfigs/zh-Hans";
import zhtIntlConfig from "./intlConfigs/zh-Hant";
import { injectIntlMsgs as shellCoreInjectIntlMsgs } from "@msbabylon/shell-core";
import { injectIntlMsgs as shellFrameworkInjectIntlMsgs } from "@msbabylon/shell-framework";
import enMessages from "./en.messages";

export const intlConfig = {
  cs: csIntlConfig,
  de: deIntlConfig,
  en: enIntlConfig,
  es: esIntlConfig,
  fr: frIntlConfig,
  hu: huIntlConfig,
  it: itIntlConfig,
  ja: jaIntlConfig,
  ko: koIntlConfig,
  nl: nlIntlConfig,
  pl: plIntlConfig,
  "pt-BR": ptbrIntlConfig,
  "pt-PT": ptptIntlConfig,
  ru: ruIntlConfig,
  sv: svIntlConfig,
  tr: trIntlConfig,
  "zh-Hans": zhsIntlConfig,
  "zh-Hant": zhtIntlConfig,
};

shellFrameworkInjectIntlMsgs(enMessages);
shellCoreInjectIntlMsgs(enMessages);
