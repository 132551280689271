/** @jsxImportSource @emotion/react */
import React, { useMemo } from "react";
import { LandingSelectAccountPage } from "src/containers/LandingSelectAccountPage";
import MergeAccountSelectionPage from "src/containers/Reconcile/MergeAccountSelectionPage";
import ReconcileAccountSelectionPage from "src/containers/Reconcile/ReconcileAccountSelectionPage";
import { LandingPagePaths } from "src/entries/constant";
import { useDependencies } from "src/hook";

export const buildAppLanding = () => {
  return React.memo<{
    className?: string;
  }>(function AppLanding(props) {
    const dependencies = useDependencies();
    const landing = dependencies.landing?.getPath();
    const component = useMemo(() => {
      switch (landing) {
        case LandingPagePaths.reconcile:
          return <ReconcileAccountSelectionPage />;
        case LandingPagePaths.merge:
          return <MergeAccountSelectionPage />;
        default:
          return <LandingSelectAccountPage {...props} />;
      }
    }, [landing, props]);
    return component;
  });
};
