/** @jsxImportSource @emotion/react */
import { cssTypography, SvgIcon, useTheme } from "@adux/common-react";
import { css } from "@emotion/react";
import { memo, ReactNode } from "react";
import Block from "src/components/Block";

export default memo(
  ({
    title,
    iconUrl,
    description,
    titleText,
  }: {
    title: ReactNode;
    iconUrl: string;
    description: ReactNode;
    titleText: string;
  }) => {
    const theme = useTheme();
    return (
      <div
        css={css`
          padding: 16px;
          display: flex;
          gap: 16px;
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: flex-start;
            justify-content: center;
          `}
          aria-hidden
        >
          <SvgIcon src={iconUrl} height={32} altTitle={titleText} />
        </div>
        <div
          css={css`
            width: 208px;
          `}
        >
          <div
            css={css`
              color: ${theme.palette.themePrimary};
              ${cssTypography.heading3}
            `}
          >
            {title}
          </div>
          <Block h={8} />
          <div>{description}</div>
        </div>
      </div>
    );
  }
);
