import { LabelWrapper, TextBox } from "@adux/common-react";
import { Container } from "nyax";
import React, { memo, useCallback, useMemo } from "react";
import { useIntl as useIntlOrigin } from "react-intl";
import { useSelector } from "react-redux";
import messageIds from "src/locales/messageIds";
import { uiViewPanelFeedbackModel } from "src/store/shell-models/ui/view/UIPanelFeedback";

function useIntl() {
  const intl = useIntlOrigin();
  return useMemo(
    () => ({
      intlCommentsTitle: intl.formatMessage({
        id: messageIds.feedback.commentsTitle,
      }),
      intlCommentsPlaceholder: intl.formatMessage({
        id: messageIds.feedback.commentsPlaceholder,
      }),
    }),
    [intl]
  );
}

export default memo(
  ({ uiView }: { uiView: Container<typeof uiViewPanelFeedbackModel> }) => {
    const { intlCommentsTitle, intlCommentsPlaceholder } = useIntl();
    const comments = useSelector(() => uiView.state.comments);
    const handleChange = useCallback(
      (content: string) => {
        uiView.actions.setComments.dispatch(content);
      },
      [uiView.actions.setComments]
    );
    return (
      <LabelWrapper label={intlCommentsTitle} isRequired>
        <TextBox
          multiline
          rows={5}
          value={comments}
          onValueChange={handleChange}
          placeholder={intlCommentsPlaceholder}
          ariaLabel={intlCommentsTitle}
          required={true}
        />
      </LabelWrapper>
    );
  }
);
