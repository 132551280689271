import { newGuid } from "@msbabylon/core";
import {
  createClientNameHeaderInterceptor,
  createErrorHandlingInterceptor,
} from "@msbabylon/purview-util/axios";
import { AADObject } from "@msbabylon/sdk-hub";
import {
  AuthService,
  createAuthorizationHeaderInterceptor,
} from "@msbabylon/shell-core";
import axios, {
  AxiosError,
  AxiosInstance,
  AxiosPromise,
  AxiosRequestConfig,
  Method,
} from "axios";
import { ShellApplication } from "src/models/app";
import { GraphBrandingConfig } from "src/models/graph";
import { LoggerService } from "src/services/LoggerService";
import {
  createTrackEvent,
  createTrackUnhandledErrorEvent,
} from "src/util/axios";
import { isEmptyOrNull } from "src/util/string";

const tenantHeaderName = "tenantId-" + newGuid();

function sliceGroup<T>(arr: T[], size: number) {
  const result = [];
  const num = Math.ceil(arr.length / size);
  for (let i = 0; i < num; ++i) {
    result.push(arr.slice(i * size, i * size + size));
  }
  return result;
}

const GRAPH_AAD_SLICE_LENGTH = 999;
const GRAPH_AAD_MAX_RETRY_TIMES = 5;
const DEFAULT_RETRY_AFTER_SECOND = 10;

// Handle Microsoft Graph API throttling
// reference: https://docs.microsoft.com/en-us/graph/throttling#best-practices-to-handle-throttling
function retryGraphApi<T>(request: () => AxiosPromise<T>) {
  return async function retriedRequest() {
    let count = 0;
    while (++count <= GRAPH_AAD_MAX_RETRY_TIMES) {
      try {
        return await request();
      } catch (error) {
        const response = (error as AxiosError).response;
        if (response?.status === 429) {
          const retryAfter =
            Number(response.headers["retry-after"]) ||
            DEFAULT_RETRY_AFTER_SECOND;
          await new Promise((resolve) => {
            setTimeout(resolve, retryAfter);
          });
        } else {
          throw error;
        }
      }
    }
    throw new Error("Exceed maximum AAD Graph API retry times");
  };
}

interface ResourceListResponse<T> {
  value: T[];
  nextLink: string | undefined;
}

async function listAllAzureResources<T>(
  axios: AxiosInstance,
  request: () => AxiosPromise<ResourceListResponse<T>>,
  nextLinkMethod: Method = "GET"
) {
  const value: T[] = [];

  let response = await request();
  value.push(...response.data.value);

  let nextLink = response.data.nextLink;
  while (nextLink) {
    response = await axios.request<ResourceListResponse<T>>({
      url: nextLink,
      method: nextLinkMethod,
      params: {
        "api-version": undefined,
      },
    });
    value.push(...response.data.value);

    nextLink = response.data.nextLink;
  }

  return value;
}

interface BatchRequest {
  id: string;
  method: string;
  url: string;
}

interface BatchResponse<T> {
  id: string;
  status: number;
  body: T;
}

// reference: https://docs.microsoft.com/en-us/graph/api/profilephoto-get?view=graph-rest-1.0
type AADGraphPhotoSize = 48 | 64 | 96 | 120 | 240 | 360 | 432 | 504 | 648;
// Batch API has limitation on size, 20 maximum per request.
// Reference: https://docs.microsoft.com/en-us/graph/known-issues#limit-on-batch-size
const BATCH_LIMIT_SIZE = 20;

const prefixBase64ImageString = (s: string) => "data:image/jpeg;base64," + s;

export class GraphApi {
  private readonly _axios: AxiosInstance = axios.create({
    baseURL: this._appConfig.environment.graphEndpoint + "v1.0",
  });

  constructor(
    private readonly _appConfig: ShellApplication,
    private readonly _authService: AuthService,
    private readonly _logger: LoggerService
  ) {
    const requestInterceptors = [
      createAuthorizationHeaderInterceptor((config) => {
        if (config.headers == null) {
          config.headers = {};
        }
        // It's not a user input in the object key. https://github.com/nodesecurity/eslint-plugin-security/blob/master/docs/the-dangers-of-square-bracket-notation.md
        // eslint-disable-next-line security/detect-object-injection
        const tenantId = config.headers[tenantHeaderName] as string;
        // eslint-disable-next-line security/detect-object-injection
        delete config.headers[tenantHeaderName];
        return this._authService.acquireToken({
          scopes: [this._appConfig.environment.graphEndpoint + "/.default"],
          tenant: tenantId || undefined,
        });
      }),
      (config: AxiosRequestConfig) => {
        if (config.headers == null) {
          config.headers = {};
        }
        config.headers["Accept-Language"] = "";
        return config;
      },
      createClientNameHeaderInterceptor(),
    ];

    this._axios.interceptors.request.use(async (config) => {
      await Promise.all(
        requestInterceptors.map((interceptor) => interceptor(config))
      );
      return config;
    });

    this._axios.interceptors.response.use(
      (resp) => resp,
      createErrorHandlingInterceptor({
        trackUnhandledErrorEvent: createTrackUnhandledErrorEvent(this._logger),
        trackEvent: createTrackEvent(this._logger),
      })
    );
  }

  public async getAADObjectsByIds(
    objectIds: string[],
    types?: string[]
  ): Promise<AADObject[]> {
    const idsArr = sliceGroup(objectIds, GRAPH_AAD_SLICE_LENGTH);
    const result = (
      await Promise.all(
        idsArr.map((ids) => {
          return listAllAzureResources<AADObject>(
            this._axios,
            retryGraphApi(() =>
              this._axios.post(`directoryObjects/getByIds`, {
                ids,
                types,
              })
            )
          );
        })
      )
    ).reduce((prev, cur) => prev.concat(cur), []);

    return result;
  }

  public async getUserThumbnailPhotosByIds(
    ids: string[],
    options?: { size: AADGraphPhotoSize }
  ): Promise<Record<string, string | null>> {
    const size: AADGraphPhotoSize = options?.size ?? 48;
    const requests = ids.map((id) => ({
      id,
      method: "GET",
      url: `users/${id}/photos/${size}x${size}/$value`,
    }));
    const responses = await this.batch<string>(requests);
    return responses.reduce((ret, response) => {
      const { id, status, body } = response;
      // eslint-disable-next-line security/detect-object-injection
      ret[id] = status === 200 ? prefixBase64ImageString(body) : null;
      return ret;
    }, {} as Record<string, string | null>);
  }

  public async getOrganizationBrandingConfig(): Promise<GraphBrandingConfig> {
    const tenantId = this._authService.account?.idTokenClaims.tid;
    if (isEmptyOrNull(tenantId)) return {};
    return this._callAsync(() =>
      this._axios.get(`organization/${tenantId}/branding`)
    );
  }

  private batch<T>(requests: BatchRequest[]): Promise<BatchResponse<T>[]> {
    const slicedRequests = sliceGroup(requests, BATCH_LIMIT_SIZE);
    const makeRequest = (requests: BatchRequest[]) => {
      return this._callAsync<{ responses: BatchResponse<T>[] }>(() =>
        this._axios.post("$batch", { requests })
      ).then(
        (data) => data.responses,
        // swallow error
        () => []
      );
    };
    return Promise.all(slicedRequests.map(makeRequest)).then((responses) =>
      responses.flat()
    );
  }

  private async _callAsync<T>(request: () => AxiosPromise<T>): Promise<T> {
    const result = await request();
    return result.data;
  }
}
