/** @jsxImportSource @emotion/react */
import { AnnouncementPopover, useTheme } from "@adux/common-react";
import React, { useCallback, useState } from "react";
import { useIntl } from "react-intl";
import AppHyperLink from "src/components/AppHyperLink";
import { productDocumentations } from "src/constants/documentationLinks";
import { useDependencies } from "src/hook";
import messageIds from "src/locales/messageIds";

export default React.memo(
  (props: {
    onClose: () => void;
    onSubmit: (assessmentOnly?: boolean) => Promise<void>;
    isSecondary?: boolean;
    tenantAccountName?: string;
  }) => {
    const { onClose, onSubmit, isSecondary, tenantAccountName } = props;

    const intl = useIntl();
    const theme = useTheme();
    const dependencies = useDependencies();

    const [running, setRunning] = useState(false);

    const doSubmit = useCallback(
      async (assessmentOnly?: boolean) => {
        setRunning(true);
        await onSubmit(assessmentOnly);
        setRunning(false);
      },
      [onSubmit]
    );
    const onClickSubmit = useCallback(() => {
      doSubmit();
    }, [doSubmit]);
    const onClickRunAssessment = useCallback(() => {
      doSubmit(true);
    }, [doSubmit]);

    return (
      <AnnouncementPopover
        headline={intl.formatMessage({
          id: isSecondary
            ? messageIds.reconcile.accountMerge.popoverSubmitRequestTitle
            : messageIds.reconcile.accountMerge.popoverTitle,
        })}
        open={true}
        onDismiss={onClose}
        svgIconProps={{
          src: `${dependencies.application.tier.cdnExtensionUrl}/opac-icons/illustrations/account_merge.svg`,
          styles: {
            root: {
              height: "inherit",
            },
          },
        }}
        primaryButtonProps={{
          text: intl.formatMessage({
            id: isSecondary
              ? messageIds.reconcile.accountMerge.submitAssess
              : messageIds.reconcile.selectAccount,
          }),
          onClick: onClickRunAssessment,
          disabled: running,
          ...(isSecondary && {
            split: true,
            menuProps: {
              items: [
                {
                  key: "assess",
                  text: intl.formatMessage({
                    id: messageIds.reconcile.accountMerge.submitAssess,
                  }),
                  onClick: onClickRunAssessment,
                },
                {
                  key: "merge",
                  text: intl.formatMessage({
                    id: messageIds.reconcile.accountMerge.submitMerge,
                  }),
                  onClick: onClickSubmit,
                },
              ],
              calloutProps: {
                layerProps: { styles: { root: { zIndex: 1500000 } } },
              },
            },
            styles: {
              splitButtonContainer: { backgroundColor: theme.palette.white },
              label: { color: theme.palette.themePrimary },
            },
          }),
        }}
        secondaryButtonProps={{
          text: intl.formatMessage({ id: messageIds.reconcile.notNow }),
          onClick: onClose,
        }}
        strings={{ close: intl.formatMessage({ id: messageIds.shared.close }) }}
      >
        {isSecondary
          ? intl.formatMessage(
              {
                id: messageIds.reconcile.accountMerge
                  .popoverSubmitRequestDescription,
              },
              { tenantAccountName }
            )
          : intl.formatMessage({
              id: messageIds.reconcile.accountMerge.popoverDescription,
            })}
        &nbsp;
        <AppHyperLink
          externalLink={true}
          styles={{
            root: {
              color: theme.palette.white,
              selectors: {
                ":hover": {
                  color: theme.palette.white,
                },
              },
            },
          }}
          href={productDocumentations.mergeToTenantAccount}
        >
          {intl.formatMessage({ id: messageIds.shared.learnMore })}
        </AppHyperLink>
      </AnnouncementPopover>
    );
  }
);
