import { LandingService as _LandingService } from "@msbabylon/shell-core";
import { LandingPagePaths } from "src/entries/constant";

export class LandingService implements _LandingService {
  private _pathname = "";
  public setLocation(location: { pathname: string }) {
    const pathname = location.pathname || "";
    if (Object.values(LandingPagePaths).some((path) => path === pathname)) {
      this._pathname = pathname;
    } else {
      this._pathname = "";
    }
  }
  public getPath() {
    return this._pathname;
  }
}
