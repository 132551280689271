import { rootModels, useGetContainer } from "@msbabylon/shell-core";
import { useSelector } from "react-redux";
import { useDependencies } from "src/hook";

export const buildIsHomeHook = () => () => {
  const getContainer = useGetContainer();
  const dependencies = useDependencies();

  const uiMainListEntity = getContainer(rootModels.ui.list.main.entity);

  return useSelector(() => {
    const loc = dependencies.router.getLocation();
    const isHomPagePath =
      loc.pathname === "/main/catalog/home" || //hard code catalog home page
      loc.pathname === "/";
    return uiMainListEntity.getters.items.length <= 0 || isHomPagePath;
  });
};
