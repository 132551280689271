/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { rootModels, useGetContainer } from "@msbabylon/shell-core";
import {
  AppSidebarButton,
  extensionConstants,
  pathnameConstants,
} from "@msbabylon/shell-framework";
import React, { useCallback } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import iconCatalog from "../assets/icon-catalog.svg";
import messageIds from "../locales/messageIds";
interface IMenuItem {
  id: string;
  extensionName: string;
  href: string;
}

export const catalogToc: IMenuItem = {
  id: "catalogTocPlaceholder",
  extensionName: "catalog",
  href: "catalogTocPlaceholder",
};
export const catalogHomeId = `${extensionConstants.catalog}/${catalogToc.id}`;
export const shellHomeId = `${extensionConstants.shell}/${pathnameConstants.home}`;

export const buildAppSidebarHomeMenu = () => {
  return React.memo<{
    isExpanded: boolean;
  }>(function AppSidebarPage(props) {
    const { isExpanded } = props;

    const intl = useIntl();

    const getContainer = useGetContainer();
    const uiSidebar = getContainer(rootModels.ui.sidebar);
    const selectedMenuItemId = useSelector(
      () => uiSidebar.state.selectedMenuItemId
    );

    const OnMenuItemClick = useCallback(
      (item: IMenuItem) => () => {
        uiSidebar.actions.onMenuItemClick.dispatch({
          extensionName: item.extensionName,
          href: item.href,
        });

        uiSidebar.actions.setOpen.dispatch(false);
      },
      [uiSidebar]
    );

    return (
      <AppSidebarButton
        css={[
          css`
            flex-shrink: 0;
          `,
        ]}
        id={"home"}
        label={intl.formatMessage({ id: messageIds.home.$title })}
        icon={iconCatalog || ""}
        isExpanded={isExpanded}
        isSelected={selectedMenuItemId === catalogHomeId}
        onClick={OnMenuItemClick(catalogToc)}
        role="tab"
      />
    );
  });
};
