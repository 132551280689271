/** @jsxImportSource @emotion/react */
import { cssTypography, useTheme } from "@adux/common-react";
import { css } from "@emotion/react";
import { DefaultButton, FocusZone } from "@fluentui/react";
import { useGetContainer, useUIView } from "@msbabylon/shell-core";
import {
  rootModels,
  ShellExtensionUIComponentProps,
} from "@msbabylon/shell-framework";
import React, { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import {
  PurviewApp,
  useAppLabel,
  usePurviewAllApps,
} from "src/containers/M365/hook";
import messageIds from "src/locales/messageIds";
import { uiViewMainAllAppsModel } from "src/store/shell-models/ui/view/UIMainAllApps";
import { cssTextOverflowMultiLine } from "src/styles/util";

const AppTile = React.memo<{
  data: PurviewApp;
  onOpen: () => void;
}>(({ data, onOpen }) => {
  const theme = useTheme();
  const intl = useIntl();
  const appText = useMemo<{ description: string; open: string }>(() => {
    switch (data.id) {
      case "catalog/catalogTocPlaceholder":
        return {
          description: intl.formatMessage({
            id: messageIds.m365.allApps.app.catalog.description,
          }),
          open: intl.formatMessage({
            id: messageIds.m365.allApps.app.catalog.open,
          }),
        };
      case "datasource/dataMapTocPlaceholder":
        return {
          description: intl.formatMessage({
            id: messageIds.m365.allApps.app.datamap.description,
          }),
          open: intl.formatMessage({
            id: messageIds.m365.allApps.app.datamap.open,
          }),
        };
      case "dataquality/dataQualityTocPlaceholder":
        return {
          description: intl.formatMessage({
            id: messageIds.m365.allApps.app.dataquality.description,
          }),
          open: intl.formatMessage({
            id: messageIds.m365.allApps.app.dataquality.open,
          }),
        };
      case "datashare/datashare":
        return {
          description: intl.formatMessage({
            id: messageIds.m365.allApps.app.datashare.description,
          }),
          open: intl.formatMessage({
            id: messageIds.m365.allApps.app.datashare.open,
          }),
        };
      case "privacyinsights/insights":
        return {
          description: intl.formatMessage({
            id: messageIds.m365.allApps.app.insights.description,
          }),
          open: intl.formatMessage({
            id: messageIds.m365.allApps.app.insights.open,
          }),
        };
      case "policy/policyManagement":
        return {
          description: intl.formatMessage({
            id: messageIds.m365.allApps.app.policy.description,
          }),
          open: intl.formatMessage({
            id: messageIds.m365.allApps.app.policy.open,
          }),
        };
      case "catalog/management":
        return {
          description: intl.formatMessage({
            id: messageIds.m365.allApps.app.management.description,
          }),
          open: intl.formatMessage({
            id: messageIds.m365.allApps.app.management.open,
          }),
        };
      case "privacyconsent/privacyHomePage":
        return {
          description: intl.formatMessage({
            id: messageIds.m365.allApps.app.privacy.description,
          }),
          open: intl.formatMessage({
            id: messageIds.m365.allApps.app.privacy.open,
          }),
        };
      default:
        return {
          description: "",
          open: intl.formatMessage({
            id: messageIds.m365.allApps.open,
          }),
        };
    }
  }, [data.id, intl]);

  const label = useAppLabel(data);
  return (
    <div
      css={[
        css`
          display: flex;
          flex-direction: column;
          border-radius: 4px;
          padding: 16px;
          background-color: ${theme.semanticColors.inputBackground};
          box-shadow: ${theme.effects.elevation4};
          &:hover,
          &:focus {
            box-shadow: ${theme.effects.elevation8};
          }
          &:focus {
            outline-offset: 1px;
          }
        `,
      ]}
      data-is-focusable
    >
      <img
        css={css`
          width: 32px;
          height: 32px;
          margin-bottom: 24px;
        `}
        src={data.icon}
        alt={data.label}
      />
      <div
        css={[
          cssTypography.heading3,
          cssTextOverflowMultiLine(1),
          css`
            margin-bottom: 12px;
          `,
        ]}
      >
        {label}
      </div>
      <div
        css={css`
          flex: 1;
        `}
      >
        <div
          css={[cssTypography.bodyText, cssTextOverflowMultiLine(4)]}
          title={appText.description}
        >
          {appText.description}
        </div>
      </div>
      <div
        css={css`
          margin-top: 12px;
          margin-left: -4px;
          margin-bottom: -4px;
        `}
      >
        <DefaultButton
          styles={{
            root: {
              backgroundColor: theme.palette.white,
              height: 32,
              padding: "0 12px",
              minWidth: "unset",
              borderRadius: 4,
              borderColor: "#d1d1d1",
            },
          }}
          onClick={onOpen}
        >
          {appText.open}
        </DefaultButton>
      </div>
    </div>
  );
});

export default React.memo<ShellExtensionUIComponentProps>(
  function M365UIMainAllApps(props) {
    const { uiId } = props;
    const intl = useIntl();
    const getContainer = useGetContainer();
    const uiView = getContainer(uiViewMainAllAppsModel, uiId);
    const uiItemHelper = getContainer(rootModels.ui.item.helperByUIId, uiId);
    useUIView(uiView, uiItemHelper);
    const { apps, navigateToApp } = usePurviewAllApps();
    const createOpenApp = useCallback(
      (app: PurviewApp) => () => {
        navigateToApp(app);
      },
      [navigateToApp]
    );
    return (
      <div
        css={css`
          padding: 28px 56px;
        `}
      >
        <div
          css={css`
            margin-bottom: 60px;
          `}
        >
          <div
            css={[
              cssTypography.greeting,
              css`
                margin-bottom: 22px;
              `,
            ]}
          >
            {intl.formatMessage({
              id: messageIds.m365.allApps.apps,
            })}
          </div>
          <FocusZone
            css={css`
              display: grid;
              grid-template-columns: repeat(auto-fill, 320px);
              gap: 24px;
              grid-auto-rows: 240px;
            `}
          >
            {apps.map((item) => (
              <AppTile key={item.id} data={item} onOpen={createOpenApp(item)} />
            ))}
          </FocusZone>
        </div>
      </div>
    );
  }
);
