import { ExtensionUISize } from "@msbabylon/core";
import { UIItemOptions, createUIViewModel } from "@msbabylon/shell-core";
import { createModel, createSelector } from "nyax";
import messageIds from "src/locales/messageIds";

export const uiViewDialogMergeSelectCategoryModel = createModel(
  class extends createUIViewModel() {
    public selectors() {
      return {
        ...super.selectors(),
        size: (): ExtensionUISize => "medium",
        title: () =>
          this.dependencies.intl.formatMessage({
            id: messageIds.reconcile.accountMerge.selectCategoryTitle,
          }),
        options: createSelector(
          (): UIItemOptions => ({
            noHeader: true,
            customHeight: "720px",
          })
        ),
      };
    }
  },
  {
    isLazy: true,
    isDynamic: true,
  }
);
