import { PurviewMode } from "@msbabylon/purview-util/constants";
import { getTierConfig } from "@msbabylon/purview-util/tiers";
import {
  dependenciesBuilder,
  LanguageType,
  RouterService,
} from "@msbabylon/shell-core";
import { pathnameConstants, ToastService } from "@msbabylon/shell-framework";
import { AzureApi } from "src/apis/AzureApi";
import { BabylonApi } from "src/apis/BabylonApi";
import { CesApi } from "src/apis/CesAPI";
import { CopilotApi } from "src/apis/CopilotApi";
import { ExtensionApi } from "src/apis/ExtensionApi";
import { FeatureApi } from "src/apis/FeatureApi";
import { GraphApi } from "src/apis/GraphApi";
import { PersonalizationApi } from "src/apis/PersonalizationApi";
import { ReleaseNotesApi } from "src/apis/ReleaseNotesApi";
import { WorkflowApi } from "src/apis/WorkflowApi";
import { SHELL_UI_MODE } from "src/dependencies/mode";
import { getFeatures } from "src/features";
import { intlConfig } from "src/locales";
import { ShellApplication } from "src/models/app";
import { AuthSeviceOverwrite } from "src/services/AuthService";
import { LandingService } from "src/services/LandingService";
import AriaLogger from "src/services/Logger/AriaLogger";
import { LoggerService } from "src/services/LoggerService";
import { RoleService } from "src/services/RoleService";
import { shellModels } from "src/store/shell-models";
import { sha256 } from "src/util/string";

export interface ExtraDependencies {
  shellAzureApi: AzureApi;
  babylonApi: BabylonApi;
  releaseNotesApi: ReleaseNotesApi;
  cesApi: CesApi;
  shellGraphApi: GraphApi;
  personalizationApi: PersonalizationApi;
  workflowApi: WorkflowApi;
  copilotApi: CopilotApi;
}

const builder = dependenciesBuilder
  // define basic and your specific app metadata
  .initAuthService((deps) => {
    return new AuthSeviceOverwrite(deps.application, deps.logger);
  })
  .initAppMetadata<ShellApplication>(() => {
    const resourceName = (() => {
      // It's a static value in regex, no injection could happen here.
      // eslint-disable-next-line security/detect-non-literal-regexp
      const execResult = new RegExp(
        `^\\/${pathnameConstants.resource}\\/((\\w|-)+)`,
        "i"
      ).exec(window.location.pathname);
      if (execResult) {
        return execResult[1].toLowerCase();
      } else {
        return null;
      }
    })();

    const features = getFeatures();
    const env = features.environment;

    const baseEnvironment = {
      name: env.name,
      aadClientId: "632d803a-b0c2-49b4-a944-e13c384c04a8",
      aadInstance: env.aadEndpoint,
      armEndpoint: env.armEndpoint,
      armAuthScope: env.armAuthResourceUri,
      graphEndpoint: env.graphEndpoint,
    };

    const mode = PurviewMode.Azure;
    const tier = getTierConfig(
      mode,
      process.env.REACT_APP_UI_ENVIRONMENT ?? ""
    );

    return {
      features: features,
      resourceName: resourceName,
      environment: baseEnvironment,
      mode: SHELL_UI_MODE,
      modeSettings: {},
      appEnv: env,
      tier,
    };
  })
  .initRouterService(
    (deps) =>
      new RouterService(
        deps.application.resourceName
          ? `/${pathnameConstants.resource}/${deps.application.resourceName}`
          : ""
      )
  )
  .initLogger((deps) => {
    let userhash: string | undefined;

    const auth = {
      getAccountHash: () => {
        if (userhash) {
          return userhash;
        }
        const id = deps.auth?.account?.accountIdentifier;
        if (id) {
          sha256(id).then((result) => (userhash = result));
        }
        return null;
      },
    };
    const logger = new AriaLogger(auth, deps.application.resourceName ?? "");
    return new LoggerService(logger);
  })
  // API to fetch extension manifests.
  .initExtensionApi(
    (deps) => new ExtensionApi(deps.application as unknown as ShellApplication)
  )
  .initNotificationService(() => new ToastService())
  .initLocale(intlConfig)
  .initRoleService((deps) => {
    return new RoleService(
      deps.application as unknown as ShellApplication,
      deps.auth,
      deps.logger
    );
  })
  .initFeatureApi(
    (deps) =>
      new FeatureApi(
        deps.application as unknown as ShellApplication,
        deps.auth,
        deps.logger as unknown as LoggerService
      )
  )
  .initSettings({
    language: getFeatures().language as LanguageType,
    theme: getFeatures().theme as "light" | "dark",
  })
  .initLandingService(() => new LandingService())
  .initTheme({})
  // extend dependencies for your app
  .extend<ExtraDependencies>((deps) => ({
    shellAzureApi: new AzureApi(
      deps.application as unknown as ShellApplication,
      deps.auth,
      deps.logger as unknown as LoggerService
    ),
    babylonApi: new BabylonApi(
      deps.application as unknown as ShellApplication,
      deps.auth,
      deps.logger as unknown as LoggerService
    ),
    releaseNotesApi: new ReleaseNotesApi(
      deps.application as unknown as ShellApplication
    ),
    cesApi: new CesApi(
      deps.application as unknown as ShellApplication,
      deps.auth,
      deps.locale,
      deps.logger as unknown as LoggerService
    ),
    shellGraphApi: new GraphApi(
      deps.application as unknown as ShellApplication,
      deps.auth,
      deps.logger as unknown as LoggerService
    ),
    personalizationApi: new PersonalizationApi(
      deps.application as unknown as ShellApplication,
      deps.auth
    ),
    workflowApi: new WorkflowApi(
      deps.application as unknown as ShellApplication,
      deps.auth,
      deps.logger as unknown as LoggerService
    ),
    copilotApi: new CopilotApi(
      deps.application as unknown as ShellApplication,
      deps.auth
    ),
  }));

export const dependencies = builder.dependencies;
export type ShellDependencies = typeof dependencies & { role: RoleService };

builder.build(shellModels);

// don't add feature flags here any more, go to main.tsx
