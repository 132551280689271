import { defaultModelBuilder } from "@msbabylon/shell-framework";
import { createModel, createSelector } from "nyax";

export const uiTocModel = createModel(
  class extends defaultModelBuilder {
    public initialState() {
      return {
        tocGroupCollapsedState: {} as Record<string, boolean>,
      };
    }

    public reducers() {
      return {
        setCollapsed: (payload: { id: string; collapsed: boolean }) => {
          this.state.tocGroupCollapsedState[payload.id] = payload.collapsed;
        },
      };
    }

    public selectors() {
      return {
        getIsTocGroupCollapsed: createSelector(
          () => this.state.tocGroupCollapsedState,
          (state) => {
            return (id: string) => {
              // eslint-disable-next-line security/detect-object-injection
              return state[id] ?? false;
            };
          }
        ),
      };
    }
  }
);
