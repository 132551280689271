/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Persona, PersonaSize, useTheme } from "@fluentui/react";
import React, { useCallback } from "react";
import { ICopilotReferenceContactProp } from "src/apis/CopilotApi/interface";
import { useUserThumbnailPhoto } from "src/hook";
import { cssTextOverflowMultiLine } from "src/styles/util";

export default React.memo(function ContactInfo(props: {
  contact: ICopilotReferenceContactProp;
}) {
  const { contact } = props;
  const theme = useTheme();
  const [avatar] = useUserThumbnailPhoto(contact.id);
  // const [user] = useAADObject(contact.id);

  const name = contact.displayName;
  const email = contact.mail;
  const contactType = contact.contactType;

  const stopPropagation = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
  }, []);

  return (
    <div
      css={[
        css`
          pointer-events: auto;
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          padding: 10px 0;
          width: 200px;
          height: 60px;
          overflow: hidden;
          font-size: 10px;
        `,
      ]}
      onClick={stopPropagation}
    >
      <Persona
        size={PersonaSize.size32}
        imageUrl={avatar}
        hidePersonaDetails={true}
        text={name}
      />

      <div
        css={css`
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          flex-shrink: 1;
          margin-top: -2px;
          margin-left: 8px;
        `}
      >
        <div
          css={css`
            flex-shrink: 1;
            color: ${theme.palette.themeDarkAlt};
            font-size: 12px;
            font-weight: 600;
          `}
        >
          <div
            css={[
              css`
                flex-shrink: 1;
              `,
              cssTextOverflowMultiLine(1),
            ]}
            title={name}
          >
            {name}
          </div>
        </div>
        <div
          css={css`
            display: flex;
          `}
        >
          <a
            href={`mailto: ${email}`}
            target="_blank"
            rel="noreferrer"
            aria-label={email}
            css={css`
              display: flex;
              gap: 4px;
              text-decoration: none;
              color: ${theme.semanticColors.link};
            `}
            onClick={stopPropagation}
          >
            {email}
          </a>
        </div>
        <div
          css={[
            css`
              color: ${theme.semanticColors.bodySubtext};
            `,
            cssTextOverflowMultiLine(1),
          ]}
          title={contactType}
        >
          {contactType}
        </div>
      </div>
    </div>
  );
});
