import { AuthAccount, AuthService } from "@msbabylon/shell-core";
import { parseJSON } from "@msbabylon/shell-framework";
import { ShellApplication } from "src/models/app";

export class PersonalizationApi {
  public constructor(
    private readonly _appConfig: ShellApplication,
    private readonly _authService: AuthService
  ) {}

  public getLocalStorageItemKey(itemName: string): string {
    const authAccount = this._authService.account as AuthAccount;
    const userKey = authAccount.idTokenClaims.oid || authAccount.userName;
    const resourceKey =
      this._appConfig.resourceId || this._appConfig.resourceName;
    return `${itemName}|${resourceKey}|${userKey}`;
  }

  // #region - banner message for switching from Gondor to MS Purview
  private get _switchExperienceBannerDismissedKey(): string {
    return this.getLocalStorageItemKey("switchExperienceBannerDismissed");
  }
  public async getSwitchExperienceBannerDismissed(): Promise<boolean> {
    try {
      const value = localStorage.getItem(
        this._switchExperienceBannerDismissedKey
      );
      return value == null ? false : (parseJSON(value) as boolean);
    } catch {
      return false;
    }
  }
  public async setSwitchExperienceBannerDismissed(): Promise<void> {
    localStorage.setItem(this._switchExperienceBannerDismissedKey, "true");
  }
  // #endregion

  // #region - announcement popover for reconcile GA account to Unified portal
  private get _reconcilePopoverDismissedKey(): string {
    return this.getLocalStorageItemKey("reconcilePopoverDismissed");
  }
  public async getReconcilePopoverDismissedDate(): Promise<number> {
    try {
      const value = localStorage.getItem(this._reconcilePopoverDismissedKey);
      return value == null
        ? 0
        : // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ((parseJSON(value) as any)?.timestamp as number);
    } catch {
      return 0;
    }
  }
  public async setReconcilePopoverDismissedDate(): Promise<void> {
    localStorage.setItem(
      this._reconcilePopoverDismissedKey,
      JSON.stringify({ timestamp: Date.now() })
    );
  }
  // #endregion

  // #region - Try new portal teaching bubble for reconciled GA account
  private get _reconcileTeachingBubbleDismissedKey(): string {
    return this.getLocalStorageItemKey("reconcileTeachingBubbleDismissed");
  }
  public async getReconcileTeachingBubbleDismissed(): Promise<boolean> {
    try {
      const value = localStorage.getItem(
        this._reconcileTeachingBubbleDismissedKey
      );
      return value == null ? false : (parseJSON(value) as boolean);
    } catch {
      return false;
    }
  }
  public async setReconcileTeachingBubbleDismissed(): Promise<void> {
    localStorage.setItem(this._reconcileTeachingBubbleDismissedKey, "true");
  }
  // #endregion

  // #region - announcement popover for account merge
  private get _accountMergePopoverDismissedKey(): string {
    return this.getLocalStorageItemKey("accountMergePopoverDismissed");
  }
  public async getAccountMergePopoverDismissed(): Promise<boolean> {
    try {
      const value = localStorage.getItem(this._accountMergePopoverDismissedKey);
      return value == null ? false : (parseJSON(value) as boolean);
    } catch {
      return false;
    }
  }
  public async setAccountMergePopoverDismissed(): Promise<void> {
    localStorage.setItem(this._accountMergePopoverDismissedKey, "true");
  }
  // #endregion
}
