import { toErrorMessage } from "@msbabylon/shell-framework";
import { createModel } from "nyax";
import messageIds from "src/locales/messageIds";
import { isMergeStatusSucceeded } from "src/models/account-merge";
import { UserRequestType } from "src/models/workflow";
import { defaultShellModelBuilder } from "src/store/defaultModelBuilder";
import { babylonInstanceModel } from "src/store/shell-models/babylon/instance";
import { equalsCaseInsensitive } from "src/util/string";

export const RECONCILE_ANNOUNCEMENT_POPOVER_COOL_DOWN = 3 * 24 * 3600 * 1000; // 3 days
export const reconcileModel = createModel(
  class extends defaultShellModelBuilder {
    public initialState() {
      return {
        teachBubbleDismissed: true,
        popoverDismissed: true,
        accountMergePopoverDismissed: true,
        canReconcile: false,
        publicNetworkEnabled: false,
        canSubmitRequest: false,
        isSingleAccount: false,
        canTriggerMerge: false,
      };
    }

    public selectors() {
      return {};
    }

    public reducers() {
      return {
        setDismissTeachingBubble: (value: boolean) => {
          this.state.teachBubbleDismissed = value;
        },
        setDismissPopover: (value: boolean) => {
          this.state.popoverDismissed = value;
        },
        setDismissAccountMergePopover: (value: boolean) => {
          this.state.accountMergePopoverDismissed = value;
        },
        setCanReconcile: (value: boolean) => {
          this.state.canReconcile = value;
        },
        setPublicNetworkEnabled: (value: boolean) => {
          this.state.publicNetworkEnabled = value;
        },
        setCanSubmitRequest: (value: boolean) => {
          this.state.canSubmitRequest = value;
        },
        setIsSingleAccount: (value: boolean) => {
          this.state.isSingleAccount = value;
        },
        setCanTriggerMerge: (value: boolean) => {
          this.state.canTriggerMerge = value;
        },
      };
    }

    public effects() {
      return {
        loadReconcileTeachingBubbleDismissed: async () => {
          const dismissed =
            await this.dependencies.personalizationApi.getReconcileTeachingBubbleDismissed();
          this.actions.setDismissTeachingBubble.dispatch(dismissed);
        },
        loadReconcilePopoverDismissed: async () => {
          const dismissedDate =
            await this.dependencies.personalizationApi.getReconcilePopoverDismissedDate();
          const dismissed =
            Date.now() - dismissedDate <
            RECONCILE_ANNOUNCEMENT_POPOVER_COOL_DOWN;
          this.actions.setDismissPopover.dispatch(dismissed);
        },
        loadAccountMergePopoverDismissed: async () => {
          const dismissed =
            await this.dependencies.personalizationApi.getAccountMergePopoverDismissed();
          this.actions.setDismissAccountMergePopover.dispatch(dismissed);
        },
        loadDismissStateFromLocalStorage: async () => {
          await Promise.all([
            this.actions.loadReconcilePopoverDismissed.dispatch({}),
            this.actions.loadReconcileTeachingBubbleDismissed.dispatch({}),
            this.actions.loadAccountMergePopoverDismissed.dispatch({}),
          ]);
        },
        dismissReconcileTeachingBubble: async () => {
          this.actions.setDismissTeachingBubble.dispatch(true);
          await this.dependencies.personalizationApi.setReconcileTeachingBubbleDismissed();
        },
        dismissReconcileAnnouncementPopover: async () => {
          this.actions.setDismissPopover.dispatch(true);
          await this.dependencies.personalizationApi.setReconcilePopoverDismissedDate();
        },
        dismissAccountMergePopover: async () => {
          this.actions.setDismissAccountMergePopover.dispatch(true);
          await this.dependencies.personalizationApi.setAccountMergePopoverDismissed();
        },
        checkIfCanReconcile: async () => {
          if (this.dependencies.application.resourceName) {
            const babylonModel = this.getContainer(babylonInstanceModel);
            const publicNetworkAccess =
              babylonModel?.state?.azureAccountInstance?.properties
                ?.publicNetworkAccess;
            let publicNetworkEnabled = equalsCaseInsensitive(
              publicNetworkAccess,
              "Enabled"
            );
            if (!publicNetworkEnabled) {
              const supportPe =
                await this.dependencies.babylonApi.getInstanceSupportPlatformPE(
                  this.dependencies.application.resourceName
                );
              if (supportPe) {
                publicNetworkEnabled = true;
              }
            }
            if (publicNetworkEnabled) {
              this.actions.setPublicNetworkEnabled.dispatch(true);
              const canReconcile =
                await this.dependencies.babylonApi.checkWhatIfReconcile(
                  this.dependencies.application.resourceName
                );
              this.actions.setCanReconcile.dispatch(canReconcile);
            }
          }
        },
        checkIfCanSubmitReconcileRequest: async () => {
          // temporarily disable this feature as requested by PM (Vishal)
          return;
          // const canSubmitRequest =
          //   await this.dependencies.workflowApi.queryWorkflow(
          //     UserRequestType.ReconcileAccount
          //   );
          // this.actions.setCanSubmitRequest.dispatch(canSubmitRequest);
        },
        submitReconcileRequest: async () => {
          try {
            if (!this.state.isSingleAccount) {
              await this.dependencies.workflowApi.submitReconcileRequest({
                accountType: "MultipleAccounts",
              });
            } else {
              const babylonHelper = this.getContainer(babylonInstanceModel);
              const accountInstance = babylonHelper.state.azureAccountInstance;
              const location = accountInstance?.location;
              await babylonHelper.actions.fetchTenants.dispatch({});
              const tenantInfo = babylonHelper.getters.tenantInfo;
              await this.dependencies.workflowApi.submitReconcileRequest({
                accountType: "SingleAccount",
                accountRegion: location,
                tenantRegionCode: tenantInfo?.countryCode,
              });
            }
            this.dependencies.toast.create({
              type: "success",
              title: this.dependencies.intl.formatMessage({
                id: messageIds.reconcile.submitRequestNotification
                  .$successTitle,
              }),
              description: this.dependencies.intl.formatMessage({
                id: messageIds.reconcile.submitRequestNotification
                  .$successMessage,
              }),
              time: new Date().toISOString(),
            });
          } catch (e) {
            this.dependencies.toast.create({
              type: "error",
              title: this.dependencies.intl.formatMessage({
                id: messageIds.shared.error,
              }),
              description: toErrorMessage(e),
              time: new Date().toISOString(),
            });
          }
        },
        checkIfSingleAccount: async () => {
          const accounts =
            await this.dependencies.babylonApi.getFirstPageAccountList();
          await this.actions.setIsSingleAccount.dispatch(accounts.length === 1);
        },
        checkAll: async () => {
          await Promise.all([
            this.actions.checkIfCanReconcile.dispatch({}),
            this.actions.checkIfSingleAccount.dispatch({}),
          ]);
          if (!this.state.canReconcile && this.state.publicNetworkEnabled) {
            await this.actions.checkIfCanSubmitReconcileRequest.dispatch({});
          }
        },
        checkIfCanTriggerMerge: async (payload: {
          primaryAccountName?: string;
          isFromSecondary?: boolean;
        }) => {
          const [allAccounts, canTriggerWorkflow, allMerges] =
            await Promise.all([
              this.dependencies.babylonApi.getFirstPageAccountList(),
              await this.dependencies.workflowApi.queryWorkflow(
                payload.isFromSecondary
                  ? UserRequestType.MergePurviewAccountFromSecondary
                  : UserRequestType.MergePurviewAccount,
                payload.primaryAccountName,
                payload.isFromSecondary
                  ? {
                      secondaryAccountName:
                        this.dependencies.application.resourceName,
                    }
                  : {}
              ),
              !payload.isFromSecondary && payload.primaryAccountName != null
                ? this.dependencies.babylonApi.listAccountMerges(
                    payload.primaryAccountName
                  )
                : [],
            ]);
          const isSingleAccount = allAccounts.length === 1;
          const allMerged = allAccounts?.every((account) => {
            const merge = allMerges.find(
              (merge) => merge.secondaryAccountName === account.name
            );
            return (
              account.name === payload.primaryAccountName ||
              (merge != null &&
                !merge.assessmentOnly &&
                isMergeStatusSucceeded(merge.status))
            );
          });
          await this.actions.setCanTriggerMerge.dispatch(
            canTriggerWorkflow && !isSingleAccount && !allMerged
          );
        },
        submitMergeRequestFromSecondary: async (payload: {
          assessmentOnly?: boolean;
          excludeCategories?: string[];
          autoResolveCategories?: string[];
        }) => {
          const babylonModel = this.getContainer(babylonInstanceModel);
          try {
            const tenantAccountName =
              babylonModel.state.tenantAccount?.provisionedAccountProperties
                ?.accountName;
            const currentAccount = babylonModel.state.azureAccountInstance;
            if (tenantAccountName && currentAccount) {
              await this.dependencies.workflowApi.submitAccountMergeRequest(
                {
                  accountName: currentAccount.name,
                  location: currentAccount.location,
                },
                tenantAccountName,
                true,
                payload.assessmentOnly,
                payload.excludeCategories,
                payload.autoResolveCategories
              );
            }
            this.dependencies.toast.create({
              type: "success",
              title: this.dependencies.intl.formatMessage({
                id: messageIds.reconcile.accountMerge.submitRequestMsgTitle,
              }),
              description: this.dependencies.intl.formatMessage({
                id: messageIds.reconcile.accountMerge
                  .submitRequestMsgDescription,
              }),
              time: new Date().toISOString(),
            });
          } catch (e) {
            this.dependencies.toast.create({
              type: "error",
              title: this.dependencies.intl.formatMessage({
                id: messageIds.shared.error,
              }),
              description: toErrorMessage(e),
              time: new Date().toISOString(),
            });
          }
        },
      };
    }
  }
);
