import { isNullOrEmpty } from "@adux/common-react";
import {
  StandardPurviewAccountInfo,
  TenantAccountInfo,
} from "@msbabylon/sdk-hub";
import {
  extensionConstants,
  rootModels,
  toErrorMessage,
} from "@msbabylon/shell-framework";
import { createModel, createSelector } from "nyax";
import messageIds from "src/locales/messageIds";
import { AzureTenant } from "src/models/azure";
import { PermissionType } from "src/models/babylon";
import { GraphBrandingConfig } from "src/models/graph";
import { defaultShellModelBuilder } from "src/store/defaultModelBuilder";
import { createItemsEntityModel } from "src/store/shared/itemsEntity";
import { isEmptyOrNull } from "src/util/string";
export enum ReconcileStatus {
  none = "None",
  selfReconciled = "SelfReconciled",
  othersReconciled = "OthersReconciled",
  readyForReconcile = "ReadyForReconcile",
}
export const babylonInstanceModel = createModel(
  class extends defaultShellModelBuilder {
    public initialState() {
      return {
        azureAccountInstance: null as StandardPurviewAccountInfo | null,
        isReconciledAccount: false as boolean,
        reconcileStatus: ReconcileStatus.none as ReconcileStatus,
        permissions: [] as PermissionType[],
        rootPermissions: [] as PermissionType[],
        brandingConfig: {} as GraphBrandingConfig,
        azureTenants: [] as AzureTenant[],
        tenantAccount: undefined as TenantAccountInfo | undefined,
      };
    }

    public selectors() {
      return {
        getFriendlyName: createSelector(
          () => this.state.azureAccountInstance,
          (instance) => instance?.properties?.friendlyName
        ),
        getDisplayedName: createSelector(
          () => this.state.azureAccountInstance,
          (): string | undefined =>
            this.getContainer(rootModels.shared).getters.resourceName ??
            undefined,
          (instance, resourceName) =>
            instance != null
              ? instance.properties?.friendlyName || resourceName || undefined
              : resourceName
        ),
        hasInstance: createSelector(
          () => this.state.azureAccountInstance,
          (azureInstance) => {
            return !!azureInstance;
          }
        ),
        logoImageUrl: createSelector(
          () => this.state.brandingConfig,
          (brandingConfig) => {
            const cdnUrl = brandingConfig.cdnList?.[0];
            const logoRelativeUrl = brandingConfig.bannerLogoRelativeUrl;
            if (isNullOrEmpty(cdnUrl) || isNullOrEmpty(logoRelativeUrl)) {
              return undefined;
            }
            return `https://${cdnUrl}/${logoRelativeUrl}`;
          }
        ),
        location: createSelector(
          () => this.state.azureAccountInstance,
          (azureInstance) => {
            let regionString = "";
            regionString = azureInstance?.location || "";
            return regionString;
          }
        ),
        tenantInfo: createSelector(
          () => this.dependencies.application.features.tenant,
          () => this.state.azureTenants,
          (tenantId, azureTenants) => {
            return tenantId
              ? azureTenants.find((t) => t.tenantId === tenantId)
              : null;
          }
        ),
      };
    }

    public reducers() {
      return {
        setAzureAccountInstance: (value: StandardPurviewAccountInfo) => {
          this.state.azureAccountInstance = value;
        },
        setIsReconciledAccount: (value: boolean) => {
          this.state.isReconciledAccount = value;
        },
        setReconcileStatus: (value: ReconcileStatus) => {
          this.state.reconcileStatus = value;
        },
        setPermission: (value: PermissionType[]) => {
          this.state.permissions = value;
        },
        setRootPermission: (value: PermissionType[]) => {
          this.state.rootPermissions = value;
        },
        setFriendlyName: (value: string) => {
          if (this.state.azureAccountInstance !== null) {
            this.state.azureAccountInstance.properties.friendlyName = value;
          }
        },
        setBrandingConfig: (value: GraphBrandingConfig) => {
          this.state.brandingConfig = value;
        },
        setTenants: (value: AzureTenant[]) => {
          this.state.azureTenants = value;
        },
        setTenantAccount: (value?: TenantAccountInfo) => {
          this.state.tenantAccount = value;
        },
      };
    }

    public effects() {
      return {
        updatePermissions: async (payload: {
          permissions: PermissionType[];
        }) => {
          const permissions = payload.permissions;
          this.actions.setPermission.dispatch(payload.permissions);
          const uiSearch = this.getContainer(rootModels.ui.search);

          const supportLiveSearch = this.state.isReconciledAccount;
          const disableSearchBox =
            !supportLiveSearch &&
            !permissions.includes(PermissionType.DataRead);

          if (!disableSearchBox) {
            uiSearch.actions.setDisabled.dispatch(false);
            uiSearch.actions.setSearchBoxTitle.dispatch("");
          } else {
            uiSearch.actions.setDisabled.dispatch(true);
            uiSearch.actions.setSearchBoxTitle.dispatch(
              this.dependencies.intl.formatMessage(
                { id: messageIds.instance.noCollectionPermission },
                { permissions: PermissionType.DataRead }
              )
            );
          }
        },
        updateRootPermissions: async (payload: {
          permissions: PermissionType[];
        }) => {
          this.actions.setRootPermission.dispatch(payload.permissions);
        },
        fetchRootPermissions: async () => {
          const permissions = await this.dependencies.role.getRootPermissions();
          await this.actions.updateRootPermissions.dispatch({ permissions });
        },
        readCurrentInstance: async () => {
          if (!isEmptyOrNull(this.dependencies.application.resourceName)) {
            this.getContainer(rootModels.role) // use role model first to reduce additional API call
              .actions.getPermissions.dispatch(false)
              .then((permissions) => {
                return this.actions.updatePermissions.dispatch({
                  permissions: permissions as PermissionType[],
                });
              });
            this.actions.fetchRootPermissions.dispatch({});
            if (this.dependencies.application.features.logo) {
              this.actions.readBrandingLogo.dispatch({});
            }
          }
        },
        readBrandingLogo: async () => {
          try {
            const brandingConfig =
              await this.dependencies.shellGraphApi.getOrganizationBrandingConfig();
            await this.actions.setBrandingConfig.dispatch(brandingConfig);
          } catch {
            // Graph api will fail if there is no custom logo. Do nothing here and not throw error
          }
        },
        broadcastFriendlyName: async (friendlyName: string) => {
          await this.dependencies.shellExtension.broadcastMessage({
            type: "friendlyName.update",
            payload: friendlyName,
          });
        },

        openKnowledgeCenter: async () => {
          this.getContainer(
            rootModels.ui.list.main.commonHelper
          ).actions.replace.dispatch({
            extensionName: extensionConstants.catalog,
            component: {
              type: "knowledgeCenter",
              params: {},
            },
          });
          return null;
        },
        fetchTenants: async () => {
          try {
            const tenants = await this.dependencies.azureApi.getTenants();
            this.actions.setTenants.dispatch(tenants as AzureTenant[]);
          } catch (e) {
            this.dependencies.toast.create({
              type: "error",
              title: this.dependencies.intl.formatMessage({
                id: messageIds.shared.error,
              }),
              description: toErrorMessage(e),
              time: "",
            });
          }
        },
      };
    }
  }
);

export const babylonInstanceEntityModel = createModel(
  class extends createItemsEntityModel<StandardPurviewAccountInfo>(
    (item) => item.name
  ) {}
);
