/** @jsxImportSource @emotion/react */
import { memo, PropsWithChildren, useMemo, useState } from "react";
import RadioContext from "./RadioContext";
import { RadioValue } from "./typing";

interface RadioGroupProps<T extends RadioValue = string> {
  value?: T;
  onChange?(value: T): void;
}

export default memo(function RadioGroup<T extends RadioValue = string>({
  children,
  ...props
}: PropsWithChildren<RadioGroupProps<T>>) {
  // self controlled value
  const [value, setValue] = useState<T>();

  // if user specified props.value, this control will be controlled
  const controlled = useMemo(() => "value" in props, [props]);

  // context for RadioItem
  const context = useMemo(
    () => ({
      value: controlled ? props.value : value,
      setValue: controlled ? props.onChange : setValue,
    }),
    [controlled, props.onChange, props.value, value]
  );

  return (
    <RadioContext.Provider value={context}>{children}</RadioContext.Provider>
  );
});
