/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { CommandBar } from "@fluentui/react";
import { useGetContainer } from "@msbabylon/shell-core";
import React, { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import messageIds from "src/locales/messageIds";
import { uiCopilotModel } from "src/store/shell-models/ui/copilot";

interface MessageTooltipContentProps {
  messageId: string;
}
export default React.memo(function MessageTooltipContent(
  props: MessageTooltipContentProps
) {
  const { messageId } = props;

  const intl = useIntl();
  const getContainer = useGetContainer();
  const copilot = getContainer(uiCopilotModel);

  const markedMessages = useSelector(() => copilot.state.markedMessage);
  const isLike = useMemo(
    // eslint-disable-next-line security/detect-object-injection
    () => markedMessages[messageId],
    [markedMessages, messageId]
  );

  const handleLikeClick = useCallback(() => {
    copilot.actions.markMessage.dispatch({
      messageId,
      like: isLike === true ? undefined : true,
    });
    copilot.actions.loggerDomain.trackMessageLike.dispatch({ messageId });
  }, [
    copilot.actions.loggerDomain.trackMessageLike,
    copilot.actions.markMessage,
    isLike,
    messageId,
  ]);

  const handleDislikeClick = useCallback(() => {
    copilot.actions.markMessage.dispatch({
      messageId,
      like: isLike === false ? undefined : false,
    });
    copilot.actions.loggerDomain.trackMessageDislike.dispatch({ messageId });
  }, [
    copilot.actions.loggerDomain.trackMessageDislike,
    copilot.actions.markMessage,
    isLike,
    messageId,
  ]);

  return (
    <CommandBar
      items={[
        {
          key: "likeMessage",
          iconProps: { iconName: isLike === true ? "LikeSolid" : "Like" },
          iconOnly: true,
          title: intl.formatMessage({
            id: messageIds.copilot.feedback.like,
          }),
          onClick: handleLikeClick,
        },
        {
          key: "dislikeMessage",
          iconProps: {
            iconName: isLike === false ? "DislikeSolid" : "Dislike",
          },
          iconOnly: true,
          title: intl.formatMessage({
            id: messageIds.copilot.feedback.dislike,
          }),
          onClick: handleDislikeClick,
        },
      ]}
      css={css`
        button {
          min-width: auto;
          width: 28px;
          height: 28px;
          padding: 0;
        }
      `}
      styles={{
        root: {
          padding: "0",
          height: "28px",
        },
        primarySet: {
          flexGrow: 0,
        },
      }}
    />
  );
});
