import { createCssObject } from "@adux/common-react";
import { css } from "@emotion/react";

export const cssTextTruncate = createCssObject({
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
} as const);

export const cssTextBreakWord = createCssObject({
  wordBreak: "break-word",
} as const);

export const cssTextOverflowMultiLine = (line: number) => css`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: ${line};
  -webkit-box-orient: vertical;
`;
