/** @jsxImportSource @emotion/react */
import { SvgIcon } from "@adux/common-react";
import { css } from "@emotion/react";
import {
  DirectionalHint,
  Link,
  TooltipDelay,
  TooltipHost,
  useTheme,
} from "@fluentui/react";
import { useGetContainer } from "@msbabylon/shell-core";
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useIntl } from "react-intl";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import {
  ICopilotReference,
  ICopilotReferenceAssetProp,
  ICopilotReferenceClassificationProp,
  ICopilotReferenceGlossaryProp,
  ICopilotReferenceScanProp,
} from "src/apis/CopilotApi/interface";
import iconAssets from "src/assets/Copilot/icon-assets.svg";
import iconClassification from "src/assets/Copilot/icon-classification.svg";
import iconContact from "src/assets/Copilot/icon-contact.svg";
import iconCopied from "src/assets/Copilot/icon-copied.svg";
import iconCopy from "src/assets/Copilot/icon-copy.svg";
import iconRun from "src/assets/Copilot/icon-run.svg";
import iconTerm from "src/assets/Copilot/icon-term.svg";
import MessageTooltipContent from "src/containers/Copilot/AppCopilotMessage/MessageTooltipContent";
import messageIds from "src/locales/messageIds";
import { uiCopilotModel } from "src/store/shell-models/ui/copilot";
import LinkTooltipContent from "./LinkTooltipContent";
import { KqlToADXUrl } from "./utils";
interface AppCopilotMessageAssistantProps {
  text: string;
  messageId?: string;
  isCompleted?: boolean;
  references?: StringMap<ICopilotReference>;
}

export default React.memo(function AppCopilotMessageAssistant(
  props: AppCopilotMessageAssistantProps
) {
  const { text, messageId, isCompleted, references } = props;
  const getContainer = useGetContainer();
  const copilot = getContainer(uiCopilotModel);

  const intl = useIntl();
  const theme = useTheme();

  const handleClick = useCallback(
    (
      e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement | HTMLElement>,
      href?: string
    ) => {
      e.preventDefault();
      if (href && references) {
        if (references[decodeURI(href)]) {
          const currentRef = references[decodeURI(href)];
          if (currentRef.type === "asset") {
            copilot.actions.postMessageToExtension.dispatch({
              target: "catalog",
              type: "copilot.navigateAsset",
              assetId: (currentRef.properties as ICopilotReferenceAssetProp).id,
            });
          } else if (currentRef.type === "classificationType") {
            copilot.actions.postMessageToExtension.dispatch({
              target: "catalog",
              type: "copilot.navigateClassification",
              id: (currentRef.properties as ICopilotReferenceClassificationProp)
                .guid,
            });
          } else if (currentRef.type === "glossaryTerm") {
            copilot.actions.postMessageToExtension.dispatch({
              target: "catalog",
              type: "copilot.navigateGlossary",
              guid: (currentRef.properties as ICopilotReferenceGlossaryProp)
                .guid,
            });
          } else if (currentRef.type === "scan") {
            copilot.actions.postMessageToExtension.dispatch({
              target: "datasource",
              type: "copilot.navigateScan",
              dataSource: (currentRef.properties as ICopilotReferenceScanProp)
                .dataSource,
              scanName: (currentRef.properties as ICopilotReferenceScanProp)
                .scan,
            });
          }
        }
      }
    },
    [copilot.actions.postMessageToExtension, references]
  );

  const LinkRenderer = React.memo(
    (props: { children: ReactNode; href?: string }) => {
      const ref = props.href && references && references[decodeURI(props.href)];

      const refIconSrc = useMemo(() => {
        if (!ref) return "";
        if (ref.type === "asset") {
          return iconAssets;
        }
        if (ref.type === "classificationType") {
          return iconClassification;
        }
        if (ref.type === "contact") {
          return iconContact;
        }
        if (ref.type === "glossaryTerm") {
          return iconTerm;
        }
        return "";
      }, [ref]);

      return (
        <TooltipHost
          styles={{
            root: {
              position: "relative",
            },
          }}
          content={
            messageId && isCompleted ? (
              <LinkTooltipContent
                messageId={messageId}
                references={references}
                href={props.href}
              />
            ) : undefined
          }
          delay={TooltipDelay.medium}
          directionalHint={DirectionalHint.bottomCenter}
          calloutProps={{
            gapSpace: 0,
            calloutMaxHeight: 300,
          }}
        >
          <Link
            css={css`
              display: inline-flex;
              gap: 2px;
            `}
            href={props.href}
            onClick={(e) => handleClick(e, props.href)}
          >
            {ref && (
              <SvgIcon
                styles={{
                  root: {
                    cursor: "pointer",
                  },
                }}
                height={12}
                src={refIconSrc}
              />
            )}
            {props.children}
          </Link>
        </TooltipHost>
      );
    }
  );

  const ParagraphRenderer = React.memo(({ children }) => {
    return (
      <div
        css={css`
          margin: 0px;
        `}
      >
        {children}
      </div>
    );
  });

  const UnorderedListRenderer = React.memo(({ children }) => {
    return (
      <ul
        css={css`
          display: flex;
          flex-direction: column;
          gap: 12px;
          margin: 0;
          padding-left: 20px;
          white-space: normal;
        `}
      >
        {children}
      </ul>
    );
  });

  const CodeRenderer = React.memo(
    (props: { children: ReactNode; className?: string }) => {
      const { children, className } = props;
      const [isCopied, setIsCopied] = useState(false);

      const isKQL = useMemo(() => className === "language-KQL", [className]);

      const handleRunCLick = useCallback(() => {
        if (children?.valueOf()) {
          const kql = children?.valueOf().toString();
          const ADXUrl = KqlToADXUrl(kql);
          if (ADXUrl) {
            // eslint-disable-next-line security/detect-non-literal-fs-filename
            window.open(ADXUrl, "_blank");
          }
        }
      }, [children]);

      const handleCopyClick = useCallback(() => {
        if (children?.valueOf()) {
          navigator.clipboard.writeText(children?.valueOf().toString());
          setIsCopied(true);
        }
      }, [children]);

      useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (isCopied) {
          timeoutId = setTimeout(() => setIsCopied(false), 2000);
        }
        return () => {
          if (timeoutId) {
            clearTimeout(timeoutId);
          }
        };
      }, [isCopied]);

      return (
        <code
          css={css`
            white-space: pre-wrap;
            position: relative;
            display: flex;
            flex-direction: row;
          `}
        >
          {children}
          <div
            css={css`
              display: flex;
              flex-direction: column;
              gap: 8px;
            `}
          >
            {isKQL && (
              <SvgIcon
                styles={{
                  root: {
                    display: "flex",
                    cursor: "pointer",
                    alignItems: "flex-start",
                  },
                }}
                title={intl.formatMessage({
                  id: messageIds.copilot.messages.actions.run,
                })}
                height={16}
                src={iconRun}
                onClick={handleRunCLick}
              />
            )}
            <SvgIcon
              styles={{
                root: {
                  display: "flex",
                  cursor: "pointer",
                  alignItems: "flex-start",
                },
              }}
              title={
                isCopied
                  ? intl.formatMessage({
                      id: messageIds.copilot.messages.actions.copied,
                    })
                  : intl.formatMessage({
                      id: messageIds.copilot.messages.actions.copy,
                    })
              }
              height={16}
              src={isCopied ? iconCopied : iconCopy}
              onClick={handleCopyClick}
            />
          </div>
        </code>
      );
    }
  );

  const TableRenderer = React.memo(({ children }) => {
    if (!children) return null;
    return (
      <div
        css={css`
          overflow-x: auto;
        `}
      >
        <table
          css={css`
            width: max-content;
            border-collapse: collapse;
            & th,
            td {
              border: 1px solid ${theme.semanticColors.inputBorder};
              padding: 2px;
            }
          `}
        >
          {children}
        </table>
      </div>
    );
  });

  return (
    <TooltipHost
      content={
        messageId && isCompleted ? (
          <MessageTooltipContent messageId={messageId} />
        ) : undefined
      }
      delay={TooltipDelay.zero}
      directionalHint={DirectionalHint.topCenter}
      calloutProps={{
        gapSpace: -10,
        styles: {
          root: {
            padding: 4,
          },
        },
      }}
    >
      <div
        css={css`
          margin-right: 24px;
          padding: 10px 16px;
          border-radius: 8px;
          box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.12),
            0px 1.6px 3.6px rgba(0, 0, 0, 0.16);
        `}
      >
        <ReactMarkdown
          children={text}
          remarkPlugins={[remarkGfm]}
          components={{
            a: LinkRenderer,
            p: ParagraphRenderer,
            ul: UnorderedListRenderer,
            code: CodeRenderer,
            table: TableRenderer,
          }}
        />
      </div>
    </TooltipHost>
  );
});
