import {
  AppInsightsCore,
  IExtendedConfiguration,
  IExtendedTelemetryItem,
} from "@microsoft/1ds-core-js";
import { IChannelConfiguration, PostChannel } from "@microsoft/1ds-post-js";
import {
  ExtensionLogLevel,
  ExtensionLogLevelName,
  extensionLogLevelByName,
} from "@msbabylon/core";
import { ExtensionLogEvent, Logger } from "src/services/Logger/interface";

const PIIEventName = "PIIEvent";

const sessionId = crypto
  .getRandomValues(new Uint8Array(8))
  .reduce((prev, cur) => {
    return prev + ("0" + cur.toString(16)).slice(-2);
  }, "");

export default class implements Logger {
  private readonly _aria: AppInsightsCore;
  constructor(
    private _auth: { getAccountHash(): string | null },
    private _purviewName: string = ""
  ) {
    const appInsightsCore: AppInsightsCore = new AppInsightsCore();
    const postChannel: PostChannel = new PostChannel();
    const endpoint = process.env.REACT_APP_ARIA_LOGGING_URL;
    const coreConfig: IExtendedConfiguration = {
      instrumentationKey: process.env.REACT_APP_ARIA_LOGGING_KEY!,
      extensions: [postChannel],
      extensionConfig: [],
      disableCookiesUsage: true,
      endpointUrl: endpoint,
    };

    const postChannelConfig: IChannelConfiguration = {
      eventsLimitInMem: 5000,
    };
    coreConfig.extensionConfig![postChannel.identifier] = postChannelConfig;

    //Initialize SDK
    appInsightsCore.initialize(coreConfig, []);

    this._aria = appInsightsCore;
  }

  public logEvent(
    logLevel: ExtensionLogLevel | ExtensionLogLevelName,
    eventName: string,
    message: string,
    data?: unknown,
    isPII?: boolean
  ): void;
  public logEvent(event: ExtensionLogEvent): void;
  public logEvent(...args: unknown[]) {
    const event: IExtendedTelemetryItem = { name: "", data: {} };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const setEventData = (key: string, data: any) =>
      // eslint-disable-next-line security/detect-object-injection
      (event.data![key] = data);

    const userHash = this._auth.getAccountHash();

    const env = process.env.REACT_APP_UI_ENVIRONMENT!;
    setEventData("env", env);

    if (typeof args[0] === "object") {
      if ((args[0] as ExtensionLogEvent).isPII) {
        event.name = PIIEventName;
      } else {
        event.name = "ExtensionEvent";
      }
      Object.entries(args[0] as Record<string, unknown>).forEach(
        ([key, value]) => {
          setEventData(key, JSON.stringify(value));
        }
      );
    } else {
      if (args[4]) {
        event.name = PIIEventName;
      } else {
        event.name = "ShellEvent";
      }
      Object.entries(args[0] as Record<string, unknown>).forEach(
        ([key, value]) => {
          setEventData(key, JSON.stringify(value));
        }
      );
      setEventData(
        "logLevel",
        typeof args[0] === "string"
          ? extensionLogLevelByName[args[0] as ExtensionLogLevelName]
          : (args[0] as ExtensionLogLevel)
      );
      setEventData("eventName", args[1] as string);
      setEventData("message", args[2] as string);
      setEventData("data", JSON.stringify(args[3]));
      setEventData(
        "buildVersion",
        window.getBuildVersion ? window.getBuildVersion() || "" : ""
      );
    }

    setEventData("hostMode", process.env.REACT_APP_UI_MODE as string);

    if (userHash != null) {
      setEventData("userhash", userHash);
    }

    setEventData("sessionId", sessionId);
    setEventData("time", new Date().toISOString());
    setEventData("purview", this._purviewName);
    setEventData("pathname", window.location.pathname);
    setEventData("search", window.location.search);

    this._aria.track(this.cleanData(event));
  }

  public flush() {
    this._aria.flush();
  }

  private cleanData(data: IExtendedTelemetryItem) {
    try {
      return JSON.parse(
        JSON.stringify(data).replace(/Bearer ey[^\s'"\\]*/g, "")
      );
    } catch {
      return data;
    }
  }
}
