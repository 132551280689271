import { newGuid } from "@msbabylon/core";
import {
  createClientNameHeaderInterceptor,
  createErrorHandlingInterceptor,
} from "@msbabylon/purview-util/axios";
import {
  AuthService,
  FeatureApi as _FeatureApi,
  createAuthorizationHeaderInterceptor,
  getResponseData,
} from "@msbabylon/shell-core";
import axios from "axios";
import { ShellApplication } from "src/models/app";
import { LoggerService } from "src/services/LoggerService";
import {
  createTrackUnhandledErrorEvent,
  createTrackEvent,
} from "src/util/axios";

const tenantHeaderName = `__TENANT_${newGuid()}`;

export class FeatureApi implements _FeatureApi {
  private _generateAccountBaseUrl = () =>
    this._application.appEnv.gatewayEndpoint.replace(
      "{0}",
      this._application.resourceName ?? ""
    ) + "account";

  private readonly _axios = axios.create({});
  private readonly _apiVersion = "2019-11-01-preview";

  constructor(
    private readonly _application: ShellApplication,
    private readonly _authService: AuthService,
    private readonly _logger: LoggerService
  ) {
    const requestInterceptors = [
      createAuthorizationHeaderInterceptor((config) => {
        if (config.headers == null) {
          config.headers = {};
        }
        // It's not a user input in the object key. https://github.com/nodesecurity/eslint-plugin-security/blob/master/docs/the-dangers-of-square-bracket-notation.md
        // eslint-disable-next-line security/detect-object-injection
        const tenantId = config.headers[tenantHeaderName] as string;
        // eslint-disable-next-line security/detect-object-injection
        delete config.headers[tenantHeaderName];
        return this._authService.acquireToken({
          scopes: [this._application.appEnv.gatewayAuthResourceUri],
          tenant: tenantId || undefined,
        });
      }),
      createClientNameHeaderInterceptor(),
    ];

    this._axios.interceptors.request.use(async (config) => {
      await Promise.all(
        requestInterceptors.map((interceptor) => interceptor(config))
      );

      return config;
    });

    this._axios.interceptors.response.use(
      (resp) => resp,
      createErrorHandlingInterceptor({
        trackUnhandledErrorEvent: createTrackUnhandledErrorEvent(this._logger),
        trackEvent: createTrackEvent(this._logger),
      })
    );
  }

  public async getFeatures<T = boolean | null>(
    features: string[]
  ): Promise<Record<string, T>> {
    if (!this._application.resourceName) {
      // if no account is selected, return empty features
      return {};
    }

    // Get features API has a limit, allowing a maximum of 500 features to be fetched per request.
    // Set chunk size to 400 to have a sufficient buffer.
    const chunkSize = 400;
    const promiseArrary = [];
    for (let i = 0; i < features.length; i += chunkSize) {
      const featureChunk = features.slice(i, i + chunkSize);
      promiseArrary.push(
        getResponseData<{ features: Record<string, T> }>(
          this._axios.post(
            `${this._generateAccountBaseUrl()}/features`,
            { features: featureChunk },
            {
              params: {
                "api-version": this._apiVersion,
              },
            }
          )
        )
      );
    }
    const results = await Promise.all(promiseArrary);

    const featureResult = Object.assign({}, ...results.map((e) => e.features));
    features.forEach((featureName) => {
      // eslint-disable-next-line security/detect-object-injection
      featureResult[featureName] = featureResult[featureName] ?? (null as T);
    });
    return featureResult;
  }
}
