/** @jsxImportSource @emotion/react */
import { useTheme } from "@adux/common-react";
import { css } from "@emotion/react";
import { divProperties, getNativeProps } from "@fluentui/react";
import { memo, PropsWithChildren, useCallback, useContext } from "react";
import assert from "src/util/assert";
import { handleKeyPress, isEnterKey, isSpaceKey } from "src/util/dom";
import RadioContext from "./RadioContext";
import { RadioValue } from "./typing";

interface RadioItemProps extends React.HTMLAttributes<HTMLDivElement> {
  disabled?: boolean;
  value: RadioValue;
}

export default memo((props: PropsWithChildren<RadioItemProps>) => {
  const { disabled = false, value: itemValue, children, ...otherProps } = props;
  const divProps = getNativeProps(otherProps, divProperties);

  const context = useContext(RadioContext);
  const theme = useTheme();

  assert(context !== null, "Don't use RadioItem outside the RadioContext");

  const { value, setValue } = context;
  const handleClick = useCallback(() => {
    if (setValue !== undefined) {
      setValue(itemValue);
    }
  }, [itemValue, setValue]);
  return (
    <div
      css={css`
        ${disabled
          ? `filter: grayscale(100%);
            cursor: not-allowed;
            &:active {
              pointer-events: none;
            }
          `
          : `
            cursor: pointer;
            transition: border-color 0.5s, box-shadow 0.5s;
            &:hover {
              box-shadow: ${theme.effects.elevation8};
            }
          `}
        border: 1px solid
            ${value === itemValue ? theme.palette.themePrimary : "transparent"};
        box-shadow: ${theme.effects.elevation4};
        border-radius: 2px;
      `}
      onClick={handleClick}
      onKeyPress={handleKeyPress([isEnterKey, isSpaceKey], handleClick)}
      tabIndex={0}
      role="radio"
      aria-checked={!divProps.role ? value === itemValue : undefined}
      {...divProps}
    >
      {children}
    </div>
  );
});
