/** @jsxImportSource @emotion/react */
import { cssTypography } from "@adux/common-react";
import { css } from "@emotion/react";
import {
  rootModels,
  ShellExtensionUIComponentProps,
  useGetContainer,
  useUIView,
} from "@msbabylon/shell-core";
import { Fragment, memo, useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import AppPanel from "src/components/AppPanel";
import Block from "src/components/Block";
import messageIds from "src/locales/messageIds";
import {
  FeedbackType,
  uiViewPanelFeedbackModel,
} from "src/store/shell-models/ui/view/UIPanelFeedback";
import ChooseFeedbackType from "./ChooseFeedbackType";
import EmailSettings from "./EmailSettings";
import FeedbackComments from "./FeedbackComments";
import MessageBar from "./MessageBar";

export default memo<ShellExtensionUIComponentProps>(({ uiId }) => {
  const intl = useIntl();
  const intlDescription = useMemo(
    () =>
      intl.formatMessage({
        id: messageIds.feedback.$description,
      }),
    [intl]
  );

  const intlSubmit = useMemo(
    () =>
      intl.formatMessage({
        id: messageIds.feedback.submitFeedback,
      }),
    [intl]
  );

  const getContainer = useGetContainer();

  const uiView = getContainer(uiViewPanelFeedbackModel, uiId);
  const uiItemHelper = getContainer(rootModels.ui.item.helperByUIId, uiId);
  useUIView(uiView, uiItemHelper);

  const feedbackType = useSelector(() => uiView.state.type);

  const readyToSubmit = useSelector(() => uiView.getters.readyToSubmit);

  const handleSubmit = useCallback(async () => {
    await uiView.actions.submit.dispatch({});
  }, [uiView]);

  const handleClickCancel = useCallback(async () => {
    await uiView.actions.closePanel.dispatch({});
  }, [uiView.actions.closePanel]);

  return (
    <AppPanel
      onCancel={handleClickCancel}
      onNext={handleSubmit}
      isNextDisabled={!readyToSubmit}
      labelNext={intlSubmit}
      styles={{
        childWrapper: {
          flex: "1 1 auto !important",
        },
      }}
    >
      <MessageBar />
      <div
        css={css`
          ${cssTypography.bodyText}
        `}
      >
        {intlDescription}
      </div>
      <Block h={16} />
      <ChooseFeedbackType uiView={uiView} />
      <Block h={16} />
      {feedbackType === FeedbackType.Feedback ? (
        <Fragment>
          <FeedbackComments uiView={uiView} />
          <Block h={16} />
          <div
            css={css`
              margin-top: auto;
            `}
          />
          <EmailSettings uiView={uiView} />
          <Block h={18} />
        </Fragment>
      ) : null}
    </AppPanel>
  );
});
