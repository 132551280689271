/** @jsxImportSource @emotion/react */
import { MessageBar } from "@fluentui/react";
import { useGetContainer } from "@msbabylon/shell-core";
import React, { useCallback, useState } from "react";
import { ICopilotSuggestion } from "src/apis/CopilotApi/interface";
import iconCursorClick from "src/assets/Copilot/icon-cursor-click.svg";
import { uiCopilotModel } from "src/store/shell-models/ui/copilot";
interface ActionSuggestionProps {
  suggestion: ICopilotSuggestion;
}

export default React.memo(function ActionSuggestion(
  props: ActionSuggestionProps
) {
  const { suggestion } = props;

  const getContainer = useGetContainer();
  const copilot = getContainer(uiCopilotModel);

  const [visible, setVisible] = useState(true);

  const onClickMessageBar = useCallback(() => {
    if (
      suggestion.type === "action" &&
      suggestion.parameter?.action === "createPolicyForAsset"
    ) {
      copilot.actions.postMessageToExtension.dispatch({
        target: "policy",
        type: "copilot.createPolicy",
        assetId: suggestion.parameter?.assetId,
      });
    } else if (
      suggestion.type === "action" &&
      suggestion.parameter?.action === "editAsset"
    ) {
      copilot.actions.postMessageToExtension.dispatch({
        target: "catalog",
        type: "copilot.navigateAssetEdit",
        assetId: suggestion.parameter?.assetId,
      });
    } else if (
      suggestion.type === "action" &&
      suggestion.parameter?.action === "navigateAsset"
    ) {
      copilot.actions.postMessageToExtension.dispatch({
        target: "catalog",
        type: "copilot.navigateAsset",
        assetId: suggestion.parameter?.assetId,
      });
    }

    setVisible(false);
  }, [
    copilot.actions.postMessageToExtension,
    suggestion.parameter?.action,
    suggestion.parameter?.assetId,
    suggestion.type,
  ]);

  return visible ? (
    <MessageBar
      styles={{
        root: {
          minHeight: "auto",
          width: "auto",
          marginRight: "24px",
          borderRadius: "8px",
          boxShadow:
            "0px 0.3px 0.9px rgba(0, 0, 0, 0.12), 0px 1.6px 3.6px rgba(0, 0, 0, 0.16)",
          cursor: "pointer",
        },
        text: {
          fontSize: "13px",
        },
        actions: {
          gap: "8px",
          button: {
            minWidth: "auto",
            height: "auto",
            border: "none",
          },
        },
      }}
      messageBarIconProps={{
        imageProps: {
          src: iconCursorClick,
          width: 16,
          height: 16,
        },
      }}
      onClick={onClickMessageBar}
    >
      {suggestion.displayText}
    </MessageBar>
  ) : null;
});
